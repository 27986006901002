import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Tooltip } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getContactsListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useNavigate } from "react-router-dom";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 276px);`};

  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;

const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const requiredColumns = ["phone", "email", "city", "title", "created_at"];

const ContactSearchPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);

  const getContactsList = async ({ page_no, page_size, query }) => {
    setLoadingData(true);
    let payload = query !== "" ? { query: query } : null;
    let response = await getContactsListApi({
      page: page_no,
      page_size: page_size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoadingData(false);
  };
  const getContactColumns = async () => {
    const columnsList = [...rootStore.contactStore.columnsList];
    let filteredColumns = requiredColumns
      .map((requiredColumn) =>
        columnsList.find((column) => column["column_name"] === requiredColumn)
      )
      .filter(Boolean);
    let formattedColumns = FormattedAgGridColumns(filteredColumns);

    setColumns(formattedColumns);
  };

  const init = async (searchQuery) => {
    setLoading(true);
    setPage(0);
    setSize(pageSize);
    setCount(0);
    await getContactsList({
      page_no: 0,
      page_size: pageSize,
      query: searchQuery,
    });
    await getContactColumns();
    setLoading(false);
  };

  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    setPage(0);
    setSize(e.target.value);
    await getContactsList({
      page_no: 0,
      page_size: e.target.value,
      query: searchQuery,
    });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);

    await getContactsList({
      page_no: page - 1,
      page_size: size,
      query: searchQuery,
    });
  };

  useEffect(() => {
    //track search state query, and if route is contacts page, then do the search in page based on query

    const searchState = rootStore.userStore.searchBarState;
    if (searchState.route === "/contacts/search") {
      if (searchState.query !== "") {
        setSearchQuery(searchState.query);
        init(searchState.query);
      } else {
        navigate("/contacts");
      }
    }
  }, [rootStore.userStore.searchBarState.query]);

  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",

      width: 230,
      cellRenderer: (params) => {
        let parts = [params.data.first_name, params.data.last_name].filter(
          Boolean
        );
        let fullName = parts.length ? parts.join(" ") : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={fullName}>
                <TextHighlight>{fullName}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Contact Search Results</Header>
          </TopWrapper>

          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={nameColumn.concat(columns)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={size}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/contacts/${row.data.id}`);
                  }}
                  overlayNoRowsTemplate={"No Contacts found."}
                />
              </div>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={page}
                  row={row}
                  page_size={size}
                  size={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                />
              </PaginationWrapper>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </TableWrapper>
          )}
        </Container>
      </ParentContainer>
      <Footer />
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(ContactSearchPage);

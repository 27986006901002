import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import { EmojiConvertor } from "emoji-js";
import PhoneIcon from "@mui/icons-material/Phone";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import { consoleLogger, dataRenderer } from "../../Functions";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const emoji = new EmojiConvertor();
emoji.replace_mode = "unified";
emoji.allow_native = true;

const MessageBox = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  overflowWrap: "anywhere",
  width: "320px",
  fontSize: "16px",
  backgroundColor: "#d9fdd3",
  padding: "5px 3px 5px 3px",
  marginBottom: "5px",
  marginRight: "10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    right: "-14px",
    top: "0px",
    border: "15px solid",
    borderTopRightRadius: "3px",
    borderColor: "#d9fdd3 transparent transparent transparent",
  },
}));

const ImageBoxWrapper = styled("div")`
  width: 100%;
  position: relative;
  // aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 5px;
`;

const ImageBox = styled("img")`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  color: "#a6a6a6",
  marginBottom: theme.spacing(1),
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  marginBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "inline",
}));

const Bold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  display: "inline",
}));

const Italic = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  display: "inline",
}));

const Strikethrough = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  display: "inline",
}));

const Monospace = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  display: "inline",
}));

const Quote = styled(Box)(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  color: theme.palette.text.secondary,
}));

const BulletedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const NumberedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: 0,
  display: "list-item",
  listStyleType: "disc",
}));

const InlineCode = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5),
  borderRadius: "4px",
  display: "inline",
}));

const Hour = styled(Box)(({ theme }) => ({
  fontSize: "11px",
  lineHeight: "15px",
  whiteSpace: "nowrap",
  opacity: 0.6,
}));
const MessageVideo = styled("video")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: "10px",
  backgroundColor: "#000",
}));

const components = {
  p: ({ children }) => <Paragraph>{children}</Paragraph>,
  a: ({ children, href }) => <StyledLink href={href}>{children}</StyledLink>,
  em: ({ children }) => <Italic>{children}</Italic>,
  strong: ({ children }) => <Bold>{children}</Bold>,
  del: ({ children }) => <Strikethrough>{children}</Strikethrough>,
  ul: ({ children }) => <BulletedList>{children}</BulletedList>,
  ol: ({ children }) => <NumberedList>{children}</NumberedList>,
  li: ({ children }) => <ListItemStyled>{children}</ListItemStyled>,
  blockquote: ({ children }) => <Quote>{children}</Quote>,
  code: ({ children }) => <InlineCode>{children}</InlineCode>,
};
const WhatsappTemplateMessageComponent = ({
  components,
  data,
  image,
  handleLoadImage,
  imageLoaded,
}) => {
  const shouldHandleItalic = (str) => /^:.+:$/.test(str.trim()); //only if the input is not emoji, do italic -> :alarm_clock: (starts and ends with :)

  const formatWhatsappMarkdown = (markdown = "") => {
    markdown = markdown.replace(/(?<!\n)\*(.*?)\*(?!\s*\n)/g, "**$1**"); // bold

    if (shouldHandleItalic(markdown)) {
      markdown = markdown.replace(/(?<!\n)_(.*?)_(?!\s*\n)/g, "*$1*"); // italic
    }

    markdown = markdown.replace(/~(.*?)~/g, "~~$1~~"); // Strikethrough

    markdown = markdown.replace(/```(.*?)```/g, "`$1`"); // Monospace

    markdown = markdown.replace(/\n/g, "  \n"); // markdown requires two spaces at the end for line breaks
    markdown = markdown.replace(/\n\s*\n/g, "\n\n"); // to handle paragraphs after list items

    return emoji.replace_colons(markdown);
  };

  const getComponentByType = (type) => {
    return components.filter(
      (x) => x.type.toLowerCase() === type.toLowerCase()
    );
  };

  const replacePlaceholders = (string, values) => {
    return string.replace(/\{\{(\d+)\}\}/g, (_, index) => {
      const i = parseInt(index) - 1;
      return values[i] !== undefined ? values[i] : `{{${index}}}`;
    });
  };

  const RenderHeader = () => {
    const headerData = getComponentByType("header");

    return (
      <>
        {headerData.map((header, index) => {
          if (header.format.toLowerCase() === "text") {
            return (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  padding: "4px",
                }}
              >
                <Bold
                  sx={{ color: "#474747", fontFamily: "Poppins" }}
                  key={index}
                >
                  {header.text}
                </Bold>
              </Box>
            );
          } else if (header.format.toLowerCase() === "video") {
            return !imageLoaded ? (
              <ImageBoxWrapper
                sx={{ height: "200px", backgroundColor: "#c9c9c9" }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: 54,
                    height: 54,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <PlayCircleOutlineIcon
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={handleLoadImage}
                  />
                </Box>
              </ImageBoxWrapper>
            ) : (
              <MessageVideo controls>
                <source src={image != null ? image : ""} type="video/mp4" />
                Your browser does not support the video tag.
              </MessageVideo>
            );
          } else if (header.format.toLowerCase() === "image") {
            return (
              <ImageBoxWrapper>
                <ImageBox
                  key={index}
                  src={image != null ? image : header.example.header_handle[0]}
                  alt="loading..."
                >
                  {header.text}
                </ImageBox>
                {!imageLoaded && (
                  <Box
                    sx={{
                      position: "absolute",
                      width: 54,
                      height: 54,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <DownloadIcon
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleLoadImage}
                    />
                  </Box>
                )}
              </ImageBoxWrapper>
            );
          }
          return null;
        })}
      </>
    );
  };

  const RenderBody = () => {
    let text = "";
    let bodyData = [];
    let footerData = [];
    if (components.length > 0) {
      bodyData = getComponentByType("body")[0];
      footerData = getComponentByType("footer")[0]
        ? getComponentByType("footer")[0]
        : [];
      text = bodyData.text ? bodyData.text : "";

      //replace placeholders with variables from payload if present
      if (
        bodyData.hasOwnProperty("example") &&
        bodyData["example"].hasOwnProperty("body_text")
      ) {
        text = replacePlaceholders(text, bodyData["example"]["body_text"][0]);
      }
    }
    return (
      <>
        <Box sx={{ paddingX: "4px" }}>
          <Markdown
            options={{
              overrides: components,
              forceBlock: true,
            }}
          >
            {formatWhatsappMarkdown(text)}
          </Markdown>
          {footerData.length !== 0 && (
            <FooterText>{footerData.text}</FooterText>
          )}
        </Box>
      </>
    );
  };

  const RenderButtons = () => {
    const buttonData = getComponentByType("buttons")[0]
      ? getComponentByType("buttons")[0]
      : [];

    return buttonData.length !== 0 ? (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {buttonData.buttons?.map((button, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: index !== 0 ? "95%" : "100%",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px",
                borderTop: "1px solid rgba(166, 166, 166, .5)",
              }}
            >
              {button.type.toLowerCase() === "url" && (
                <>
                  <OpenInNewIcon sx={{ color: "#3db7f6", fontSize: "16px" }} />
                  <a
                    key={index}
                    href={button.url}
                    style={{
                      textDecoration: "none",
                      color: "#3db7f6",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {button.text}
                  </a>
                </>
              )}
              {button.type.toLowerCase() === "phone_number" && (
                <>
                  <PhoneIcon sx={{ color: "#3db7f6", fontSize: "16px" }} />
                  <a
                    key={index}
                    href={`tel:${button.phone_number}`}
                    style={{
                      textDecoration: "none",
                      color: "#3db7f6",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {button.text}
                  </a>
                </>
              )}
            </Box>
          );
        })}
      </Box>
    ) : null;
  };

  const getStatusTickComponent = () => {
    if (data.id === "") {
      return <ErrorIcon style={{ fontSize: 13, opacity: 0.6, color: "red" }} />; //some error if id is empty
    }
    if (data.status === null) {
      return <DoneIcon style={{ fontSize: 16, opacity: 0.6 }} />;
    } else {
      if (data.status === "delivered") {
        return <DoneAllIcon style={{ fontSize: 16, color: "#53bdeb" }} />;
      } else if (data.status === "read") {
        return <DoneAllIcon style={{ fontSize: 16, color: "#53bdeb" }} />;
      } else if (data.status === "sent") {
        return <DoneIcon style={{ fontSize: 16, opacity: 0.6 }} />;
      } else if (data.status === "waiting") {
        return <AccessTimeIcon style={{ fontSize: 12, opacity: 0.6 }} />;
      }
    }
  };

  const getMessageTime = (messageData) => {
    if (messageData.status === null) {
      return messageData.created_at;
    }
    if (messageData.status === "read" && messageData.read_at !== null) {
      return messageData.read_at;
    } else if (
      messageData.status === "delivered" &&
      messageData.delivered_at !== null
    ) {
      return messageData.delivered_at;
    } else if (messageData.sent_at !== null) {
      return messageData.sent_at;
    }
    return messageData.created_at;
  };

  return (
    <MessageBox>
      <Box sx={{ paddingX: "5px", position: "relative" }}>
        {data.hasOwnProperty("user") &&
          data.user !== null &&
          data.user.hasOwnProperty("name") &&
          data.user.name !== null && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#075E54",
                }}
              >
                {dataRenderer(data.user.name)}
              </Typography>{" "}
            </Box>
          )}
        <RenderHeader />
        <RenderBody />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {moment(getMessageTime(data)).isValid() && (
            <Hour>{moment(getMessageTime(data)).format("hh:mm A")}</Hour>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: "2px",
            }}
          >
            {getStatusTickComponent()}
          </Box>
        </Box>
      </Box>

      <RenderButtons />
    </MessageBox>
  );
};

export default WhatsappTemplateMessageComponent;

import { FilterAlt } from "@mui/icons-material";
import { Badge, Box, IconButton, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchByColumnDialog from "./dialogs/SearchByColumnDialog";
const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
const HeaderText = styled(Typography)`
  font: normal normal 700 13px Open Sans;
  margin: 0px;
`;
const AgGridColumnWithFilterIcon = ({
  headerText,
  dataType,
  handleSearch,
  queryData,
  optionList = [],
}) => {
  const [anchor, setAnchor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (queryData !== null) {
      setSearchQuery(queryData);
    }
  }, [queryData]);
  return (
    <Container>
      <HeaderText>{headerText}</HeaderText>
      {queryData !== null ? (
        <IconButton
          style={{ padding: "0px" }}
          color="primary"
          onClick={(e) => {
            setAnchor(e.currentTarget);
          }}
          size="small"
        >
          <Badge variant="dot" color="error">
            <FilterAlt style={{ width: "20px", height: "20px" }} />
          </Badge>
        </IconButton>
      ) : (
        <IconButton
          style={{ padding: "0px" }}
          color="primary"
          onClick={(e) => {
            setAnchor(e.currentTarget);
          }}
          size="small"
        >
          <FilterAlt style={{ width: "20px", height: "20px" }} />
        </IconButton>
      )}

      {anchor !== null && (
        <SearchByColumnDialog
          anchor={anchor}
          handleClose={() => setAnchor(null)}
          handleSearch={() => {
            handleSearch(searchQuery);
            setSearchQuery("");
            setAnchor(null);
          }}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          dataType={dataType}
          isClearButtonDisabled={queryData === null}
          handleClear={() => {
            handleSearch(null);
            setSearchQuery("");
            setAnchor(null);
          }}
          optionList={optionList}
        />
      )}
    </Container>
  );
};

export default AgGridColumnWithFilterIcon;

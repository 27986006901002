import React from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Close } from "@material-ui/icons";

const CaptionField = styled(TextField)(({ theme }) => ({
  width: "95%",
  margin: "auto",
  backgroundColor: "white",
  borderRadius: "8px",
  "& .MuiInputBase-root": {
    maxHeight: "150px",
    overflowY: "auto",
    padding: "8px",
  },
  "& textarea": {
    maxHeight: "150px",
    overflowY: "auto",
    resize: "none",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "8px",
  overflowY: "auto",
}));

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "92%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  overflowY: "auto",
  boxSizing: "border-box",
}));
const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const WhatsappChatImageSendScreen = ({
  caption,
  setCaption,
  setOpenImageSendView,
  fileURL,
  handleSendMedia,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMedia();
    }
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={() => {
            setOpenImageSendView(false);
          }}
          style={{
            height: "18px",
            width: "18px",
            position: "absolute",
            top: "20px",
            right: "30px",
            cursor: "pointer",
          }}
        >
          <Close />
        </IconButton>
        <Box>
          <Image src={fileURL} style={{ zIndex: 0 }} />
        </Box>
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f0f2f5",
          padding: "6px",
          height: "8%",
        }}
      >
        <BottomContainer>
          {/* <CaptionField
            sx={{
              "& fieldset": { border: "none" },
            }}
            inputProps={{
              min: 0,
              "aria-label": "weight",
              style: {
                fontSize: "14px",
              },
            }}
            multiline
            placeholder="Add a caption"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
            onKeyDown={handleKeyDown}
          /> */}
        </BottomContainer>
        <IconButton onClick={handleSendMedia}>
          <SendIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default WhatsappChatImageSendScreen;

import { Divider, InputLabel, Modal, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { getUserProjectsApi, getAccountMembersApi } from "../../Api";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { consoleLogger, getLocalizedText } from "../../Functions";
import rootStore from "../../stores/RootStore";
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const Message = styled(Typography)`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  margin-bottom: 10px;
`;
const ResponseMessage = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 30px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
`;

const CreateLeadFromCustomerModal = (props) => {
  const [project, setProject] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [member, setMember] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (isUserMember()) {
      const projectsList = [...rootStore.authStore.projectList];
      setProjectList(projectsList);
      setMember(rootStore.userStore.UserData.id);

      if (projectsList.length === 1) {
        setProject(projectsList[0]["id"]);
        handleCreate(projectsList[0]["id"], rootStore.userStore.UserData.id);
      } else {
        setLoading(false);
      }
    } else {
      //fetch all members
      let memberList = await getProjectMemberList();
      setMemberList(memberList);

      //preselect if a member mataches customer owner
      consoleLogger("custData:: ", props.customerData);
      if (
        props.customerData.hasOwnProperty("owner") &&
        props.customerData.owner !== null
      ) {
        const memberIndex = memberList.findIndex(
          (member) => member.id === props.customerData.owner.id
        );
        if (memberIndex !== -1) {
          setMember(memberList[memberIndex]["id"]);
          await getUserProjectList(memberList[memberIndex]["id"]);
        }
      }

      setLoading(false);
    }
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleCreate = async (project, memberID) => {
    const teams = rootStore.authStore.getTeamsForProject(project);
    let teamID = "";
    const defaultTeamIndex = teams.findIndex(
      (team) => team.name.toLowerCase() === "default"
    );
    if (defaultTeamIndex !== -1) {
      teamID = teams[defaultTeamIndex]["id"];
    } else {
      teamID = teams.length > 0 ? teams[0]["id"] : "";
    }

    props.createLeadCallback({
      project_id: project,
      team_id: teamID,
      lead_owner_id: memberID,
      customer_data: props.customerData,
    });
    handleClose();
  };
  const getProjectMemberList = async (id) => {
    try {
      let response = await getAccountMembersApi();
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  const getUserProjectList = async (id) => {
    try {
      let response = await getUserProjectsApi({
        user_id: id,
      });
      setProjectList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const isUserMember = () => {
    let accountRole = rootStore.userStore.UserData.role;

    if (accountRole === "member") {
      return true;
    }
    return false;
  };

  const handleProject = async (event) => {
    setProject(event.target.value);
  };

  const renderSelectProjectField = () => {
    if (isUserMember()) {
      return (
        <InputWrapper>
          <InputLabel
            id="demo-simple-select-label"
            style={{
              font: "normal normal 600 10px Open Sans",
              color: "#4d4e4f",
            }}
          >
            {getLocalizedText("select_project")}*
          </InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={project}
            label="Select Member"
            required
            onChange={handleProject}
            style={{
              width: "150px",
              font: "normal normal 500 12px Open Sans",
            }}
          >
            {projectList.length > 0 &&
              projectList.map((e, i) => {
                return (
                  <MenuItem
                    key={i}
                    style={{ fontSize: "12px" }}
                    id={e.id}
                    value={e.id}
                  >
                    {e.name}
                  </MenuItem>
                );
              })}
          </Select>
        </InputWrapper>
      );
    } else {
      return (
        <InputWrapper>
          <InputLabel
            id="demo-simple-select-label"
            style={{
              font: "normal normal 600 10px Open Sans",
              color: "#4d4e4f",
            }}
          >
            {getLocalizedText("select_project")}*
          </InputLabel>

          <Select
            disabled={member === ""}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={project}
            label="Select Project"
            required
            onChange={handleProject}
            style={{
              width: "150px",
              font: "normal normal 500 12px Open Sans",
            }}
          >
            {projectList.length > 0 &&
              projectList.map((e, i) => {
                return (
                  <MenuItem
                    key={i}
                    style={{ fontSize: "12px" }}
                    id={e.id}
                    value={e.id}
                  >
                    {e.name}
                  </MenuItem>
                );
              })}
          </Select>
        </InputWrapper>
      );
    }
  };

  return (
    !loading && (
      <>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <ModelWrapper
                component={"form"}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCreate(project, member);
                }}
              >
                <>
                  <Header>{getLocalizedText("create_lead","project")}</Header>
                  <SubHeader>
                    {getLocalizedText("select_from_below_dropdown")}
                  </SubHeader>
                  <Divider style={{ margin: "10px 0px" }} />

                  <Row>
                    {!isUserMember() && (
                      <InputWrapper>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_member")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={member}
                          required
                          label="Select Member"
                          onChange={(e) => {
                            setMember(e.target.value);
                            setProject("");
                            getUserProjectList(e.target.value);
                          }}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {memberList.length > 0 &&
                            memberList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </InputWrapper>
                    )}
                    {renderSelectProjectField()}
                  </Row>
                </>

                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    variant="contained"
                    color="inherit"
                    style={{
                      width: "100%",
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    id="assign-btn"
                    variant="contained"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "100%",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("create")}
                  </Button>
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      </>
    )
  );
};

export default observer(CreateLeadFromCustomerModal);

import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { dealsPageChipFilters, dealsPageColumnHeaders } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Chip, Typography } from "@mui/material";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { getDealsListApi } from "../../Api";
import ViewDealSidepanel from "../../components/view_deal_sidepanel/ViewDealSidepanel";
import { useNavigate } from "react-router";
import { dataRenderer, getLocalizedText } from "../../Functions";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import AgGridColumnWithFilterIcon from "../../components/column_header/AgGridColumnWithFilterIcon";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const ChipsContainer = styled(Box)`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  column-gap: 8px;
`;

const ProductsPage = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openViewDealPanel, setOpenViewDealPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const projectMembers = [...rootStore.authStore.projectMemberList];
  let userID = localStorage.getItem("uid");
  const navigate = useNavigate();

  const generateQueryPayload = (queryList) => {
    let tempQueryPayload = {};
    for (const key in queryList) {
      let queryData = queryList[key];
      if (queryData["value"] !== null && queryData["value"] !== "") {
        if (queryData["data_type"] === "multiselect") {
          let listOfString = queryData["value"].map((entry) => entry["value"]);
          if (key === "deal_owner") {
            tempQueryPayload["deal_owner_ids"] = listOfString;
          } else {
            tempQueryPayload[key] = listOfString;
          }
        } else {
          tempQueryPayload[key] = queryData["value"];
        }
      }
    }
    return tempQueryPayload;
  };
  const getDealsList = async ({ page, page_size, query }) => {
    setRow([]);
    const queryPayload = generateQueryPayload(query);
    let response = await getDealsListApi({
      page: page,
      page_size: page_size,
      payload: { query: queryPayload },
      queryParams: { scope: rootStore.userStore.dealsPageScope },
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    setLoading(true);
    await getDealsList({ page: 0, page_size: pageSize, query: filterQuery });
    setLoading(false);
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getDealsList({
      page: 0,
      page_size: e.target.value,
      query: filterQuery,
    });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getDealsList({ page: page - 1, page_size: size, query: filterQuery });
    setLoading(false);
  };

  const handleChip = async (scope) => {
    rootStore.userStore.updateDealsPageScope(scope);
    setLoading(true);
    setPage(0);
    await getDealsList({
      page: 0,
      page_size: size,
      query: filterQuery,
    });
    setLoading(false);
  };

  const handleSearch = async (searchValue, fieldName, dataType) => {
    let tempFilterQuery = { ...filterQuery };
    tempFilterQuery[fieldName] = { value: searchValue, data_type: dataType };
    setFilterQuery(tempFilterQuery);
    setLoading(true);
    setPage(0);
    await getDealsList({
      page: 0,
      page_size: size,
      query: tempFilterQuery,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const LeadIdColumn = [
    {
      field: "flid",
      headerName: getLocalizedText(
        "associated_lead_id_or_entity",
        "project"
      ).toUpperCase(),

      width: 130,

      cellRenderer: (params) => {
        let value =
          params.data.hasOwnProperty("lead") && params.data["lead"] !== null
            ? dataRenderer(params.data.lead.flid)
            : "-";
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", height: "100%" }}
            onClick={(e) => {
              e.preventDefault();
              if (
                params.data.hasOwnProperty("lead") &&
                params.data["lead"] !== null
              ) {
                navigate(
                  `/users/${userID}/leads/${params.data["lead"]["id"]}`,
                  {
                    state: {
                      lead_status: null,
                      scope: "deals_page",
                    },
                  }
                );
              }
            }}
          >
            <Tooltip title={value}>
              <TextHighlight>{value}</TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const getFilterByDealOwnerOptions = () => {
    return projectMembers.map((entry) => ({
      label: entry["name"],
      value: entry["id"],
    }));
  };

  const lastColumnsOfDatatable = [
    {
      field: "deal_owner",
      headerName: "DEAL OWNER",

      width: 150,
      headerComponent: AgGridColumnWithFilterIcon,
      headerComponentParams: {
        dataType: "multiselect",
        handleSearch: (searchValue) => {
          handleSearch(searchValue, "deal_owner", "multiselect");
        },
        headerText: "DEAL OWNER",
        queryData: filterQuery.hasOwnProperty("deal_owner")
          ? filterQuery["deal_owner"]["value"]
          : null,
        optionList: getFilterByDealOwnerOptions(),
      },
      cellRenderer: (params) => {
        let value =
          params.data.deal_owner !== null
            ? dataRenderer(params.data.deal_owner.name)
            : "-";
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "priority",
      headerName: "DEAL PRIORITY",

      width: 150,
      cellRenderer: (params) => {
        if (params.data.priority.toLowerCase() === "low") {
          return (
            <Chip
              style={{ fontWeight: "bold" }}
              color="default"
              label={params.data.priority}
            />
          );
        } else if (params.data.priority.toLowerCase() === "medium") {
          return (
            <Chip
              style={{ fontWeight: "bold" }}
              color="warning"
              label={params.data.priority}
            />
          );
        } else if (params.data.priority.toLowerCase() === "high") {
          return (
            <Chip
              style={{ fontWeight: "bold" }}
              color="success"
              label={params.data.priority}
            />
          );
        }
      },
    },

    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      minWidth: 150,
      resizable: false,
      headerComponent: AgGridColumnWithFilterIcon,
      headerComponentParams: {
        dataType: "multiselect",
        handleSearch: (searchValue) => {
          handleSearch(searchValue, "status", "multiselect");
        },
        headerText: "STATUS",
        queryData: filterQuery.hasOwnProperty("status")
          ? filterQuery["status"]["value"]
          : null,
        optionList: [
          { label: "Won", value: "won" },
          { label: "Lost", value: "lost" },
        ],
      },
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.status);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">
                {dataRenderer(value)}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Deals</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
          </Row>
          <ChipsContainer>
            {dealsPageChipFilters.map((data, index) => {
              return (
                <Chip
                  key={index}
                  color={
                    rootStore.userStore.dealsPageScope === data.value
                      ? "primary"
                      : "default"
                  }
                  label={data.label}
                  onClick={() => {
                    handleChip(data.value);
                  }}
                />
              );
            })}
          </ChipsContainer>
          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={LeadIdColumn.concat(
                    dealsPageColumnHeaders
                  ).concat(lastColumnsOfDatatable)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowData(row.data);
                    setOpenViewDealPanel(true);
                  }}
                />
              </div>
            ) : (
              <ProgressWrapper>
                <LoadingAnimationComponent size={"small"} />
              </ProgressWrapper>
            )}
          </TableWrapper>
          <PaginationWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openViewDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewDealPanel}
          onClose={() => {
            setSelectedRowData({});
            setOpenViewDealPanel(false);
          }}
        >
          <ViewDealSidepanel
            details={selectedRowData}
            setOpen={setOpenViewDealPanel}
            open={openViewDealPanel}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(ProductsPage);

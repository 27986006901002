import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { validateDisabledSubFeature } from "../../../Functions";
import { Box, Tooltip, Typography, styled } from "@mui/material";
import TableSkeleton from "../../home_tabs/components/TableSkeleton";
import { AgGridReact } from "ag-grid-react";
import rootStore from "../../../stores/RootStore";
import { FormattedAgGridColumns } from "../../../ColumnUtils";
import EntityPaginationComponent from "../../pagination/EntityPaginationComponent";
import { sizes } from "../../../Config";

const Header = styled(Typography)`
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const Container = styled(Box)`
  min-height: 100vh;
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
  width: 100%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const requiredColumns = [
  "phone",
  "email",
  "city",
  "title",
  "created_at",
];
const CustomerFilterTableComponent = ({
  loading,
  handlePageSize,
  handlePagination
}) => {
  let navigate = useNavigate();

  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
    preventDefaultOnContextMenu: validateDisabledSubFeature(
      "context_menu_in_lead_listing"
    ),
  };

  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",

      width: 230,
      cellRenderer: (params) => {
        let parts = [params.data.first_name, params.data.last_name].filter(
          Boolean
        );
        let fullName = parts.length ? parts.join(" ") : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={fullName}>
                <TextHighlight>{fullName}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const getColumns = () => {
    const columnsList = [...rootStore.customerStore.columnsList];
    let filteredColumns = requiredColumns
      .map((requiredColumn) =>
        columnsList.find((column) => column["column_name"] === requiredColumn)
      )
      .filter(Boolean);
    let formattedColumns = FormattedAgGridColumns(filteredColumns);

    return nameColumn.concat(formattedColumns);
  };

  return (
    <>
      <Container>
        <Row
          style={{
            marginBottom: "16px",
          }}
        >
          <Header>Search Results</Header>
        </Row>
        <Wrapper>
          {!loading ? (
            <>
              <Box
                sx={{
                  "& .ag-root-wrapper-body": {
                    height: "calc(100vh - 127px)",
                  },
                  "& .ag-checkbox-input-wrapper input": {
                    cursor: "pointer",
                  },
                }}
              >
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={rootStore.customerStore.filteredCustomersList}
                    defaultColDef={defaultColDef}
                    columnDefs={getColumns()}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={
                      rootStore.customerStore.filterListingInfo.page_size
                    }
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      navigate(`/customers/${row.data.id}`);
                    }}
                    overlayNoRowsTemplate={"No Contacts found."}
                  />
                </div>
              </Box>
              <PaginationWrapper>
                <EntityPaginationComponent
                  page_no={
                    rootStore.customerStore.filterListingInfo["page_number"]
                  }
                  page_size={
                    rootStore.customerStore.filterListingInfo["page_size"]
                  }
                  count={rootStore.customerStore.filterListingInfo["count"]}
                  paginationData={rootStore.customerStore.getFilterCustomerPaginationCounterData()}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                  pageSizeOptionList={sizes}
                />
              </PaginationWrapper>
            </>
          ) : (
            <TableSkeleton height={"calc(100vh - 98px)"} />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default observer(CustomerFilterTableComponent);

import React from "react";
import Markdown from "markdown-to-jsx";
import { styled } from "@mui/material/styles";
import { Typography, Link, List, ListItem, Box } from "@mui/material";
import moment from "moment";
import { EmojiConvertor } from "emoji-js";

const emoji = new EmojiConvertor();
emoji.replace_mode = "unified";
emoji.allow_native = true;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "8px",
  position: "relative",
}));

const MessageBox = styled(Box)(({ theme }) => ({
  width: "75%",
  fontSize: "16px",
  backgroundColor: "#d9fed3",
  padding: "5px 20px 20px 10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    right: "-14px",
    top: "0px",
    border: "20px solid",
    borderTopRightRadius: "3px",
    borderColor: "#d9fed3 transparent transparent transparent", // same as the bubble background color
  },
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "inline",
}));

const Bold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  display: "inline",
}));

const Italic = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  display: "inline",
}));

const Strikethrough = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  display: "inline",
}));

const Monospace = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  display: "inline",
}));

const Quote = styled(Box)(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  color: theme.palette.text.secondary,
}));

const BulletedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const NumberedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: 0,
  display: "list-item",
  listStyleType: "disc",
}));

const InlineCode = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5),
  borderRadius: "4px",
  display: "inline",
}));

export const Hour = styled(Box)(({ theme }) => ({
  fontSize: "11px",
  lineHeight: "15px",
  whiteSpace: "nowrap",
  opacity: 0.6,
}));

const components = {
  p: ({ children }) => <Paragraph>{children}</Paragraph>,
  a: ({ children, href }) => <StyledLink href={href}>{children}</StyledLink>,
  em: ({ children }) => <Italic>{children}</Italic>,
  strong: ({ children }) => <Bold>{children}</Bold>,
  del: ({ children }) => <Strikethrough>{children}</Strikethrough>,
  ul: ({ children }) => <BulletedList>{children}</BulletedList>,
  ol: ({ children }) => <NumberedList>{children}</NumberedList>,
  li: ({ children }) => <ListItemStyled>{children}</ListItemStyled>,
  blockquote: ({ children }) => <Quote>{children}</Quote>,
  code: ({ children }) => <InlineCode>{children}</InlineCode>,
};

const DoubleCheck = () => {
  return (
    <svg viewBox="0 0 16 15" width="16" height="15" className="double-check">
      <path
        fill="#89a187"
        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
      ></path>
    </svg>
  );
};

const shouldHandleItalic = (str) => /^:.+:$/.test(str.trim());    //only if the input is not emoji, do italic -> :alarm_clock: (starts and ends with :)

const WhatsappTextMessagePreview = ({ text }) => {
  const formatWhatsappMarkdown = (markdown) => {
    markdown = markdown.replace(/(?<!\n)\*(.*?)\*(?!\s*\n)/g, "**$1**"); // bold

    if (shouldHandleItalic(text)) {
      markdown = markdown.replace(/(?<!\n)_(.*?)_(?!\s*\n)/g, "*$1*"); // italic
    }

    markdown = markdown.replace(/~(.*?)~/g, "~~$1~~"); // Strikethrough

    markdown = markdown.replace(/```(.*?)```/g, "`$1`"); // Monospace

    markdown = markdown.replace(/\n/g, "  \n"); // markdown requires two spaces at the end for line breaks
    markdown = markdown.replace(/\n\s*\n/g, "\n\n");  // to handle paragraphs after list items

    return emoji.replace_colons(markdown);
  };

  return (
    <Container>
      <MessageBox>
        <Markdown
          options={{
            overrides: components,
            forceBlock: true,
          }}
        >
          {formatWhatsappMarkdown(text)}
        </Markdown>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 8,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Hour>{moment().format("hh:mm A")}</Hour>
          <Box sx={{ marginLeft: "2px" }}>
            <DoubleCheck />
          </Box>
        </Box>
      </MessageBox>
    </Container>
  );
};

export default WhatsappTextMessagePreview;

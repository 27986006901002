import React from "react";
import {
  Box,
  IconButton,
  Menu,
  OutlinedInput,
  styled,
  Tooltip,
} from "@mui/material";
import { Close, Search } from "@material-ui/icons";
import { MultiSelect } from "react-multi-select-component";

const Container = styled(Box)`
  display: flex;
  width: 320px;
  height: fit-content;
  align-items: center;
  gap: 8px;
  padding: 16px;
`;
const TextInput = styled(OutlinedInput)`
  height: 40px;
  border: none;
  outline: none;
  width: 100%;
  color: #4d4e4f;
  font: normal normal 500 14px Open Sans;
`;
const SearchByColumnDialog = ({
  anchor,
  handleClose,
  handleSearch,
  searchQuery,
  setSearchQuery,
  dataType,
  isClearButtonDisabled,
  handleClear,
  optionList,
}) => {
  const open = Boolean(anchor);
  const renderFieldBasedOnDataType = () => {
    if (dataType === "string") {
      return (
        <TextInput
          type="text"
          placeholder="Enter value"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      );
    } else if (dataType === "integer") {
      return (
        <TextInput
          type="number"
          placeholder="Enter value"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      );
    } else if (dataType === "multiselect") {
      return (
        <Box
          sx={{
            "&": {
              width: "206px",
              fontSize: "12px",
              border: "none",
              alignItems: "center",
              color: "black",
            },
            "& .dropdown-heading": {
              height: "30px",
            },
            "& .options": {
              maxHeight: "200px",
            },
          }}
        >
          <MultiSelect
            options={optionList}
            value={
              searchQuery !== null && searchQuery !== "" ? searchQuery : []
            }
            onChange={(selected) => {
              setSearchQuery(selected);
            }}
          />
        </Box>
      );
    }
  };

  const isSearchButtonDisabled = () => {
    if (dataType === "string" || dataType === "integer") {
      return searchQuery === "";
    } else if (dataType === "multiselect") {
      if (searchQuery !== null && searchQuery !== "") {
        return searchQuery.length === 0;
      }
    }
    return true;
  };

  return (
    <Menu
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      id="long-menu"
      disableScrollLock
      anchorEl={anchor}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        style: {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            borderRadius: "8px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "100%",
              left: 14,
              transform: "translateX(-50%)",
              width: 0,
              height: 0,
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: "5px solid white",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "left", vertical: "top" }}
    >
      <Container>
        {renderFieldBasedOnDataType()}

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Tooltip title={"Search"}>
            <IconButton
              disabled={isSearchButtonDisabled()}
              style={{
                border: "1px solid",
                borderColor: isSearchButtonDisabled() ? "#bdbdbd" : "#89b7e7",
                borderRadius: "8px",
                padding: "4px",
              }}
              color="primary"
              onClick={handleSearch}
              size="small"
            >
              <Search />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Remove Filter"}>
            <IconButton
              disabled={isClearButtonDisabled}
              style={{
                border: "1px solid",
                borderColor: isClearButtonDisabled ? "#bdbdbd" : "#89b7e7",
                borderRadius: "8px",
                padding: "4px",
              }}
              color="error"
              onClick={handleClear}
              size="small"
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Container>
    </Menu>
  );
};

export default SearchByColumnDialog;

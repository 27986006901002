import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import FirstForm from "../create_lead_form/FirstForm";
import SecondForm from "../create_lead_form/SecondForm";
import {
  createContactApi,
  createLeadForProject,
  createOrganizationApi,
  editContactApi,
  searchContactByEmailApi,
  searchContactByPhoneApi,
  searchOrganizationsByNameApi,
  submitLeadApi,
} from "../../Api";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  consoleLogger,
  getLocalizedText,
  isDateInRange,
  isDateTimeInRange,
} from "../../Functions";
import { myTheme } from "../../themeUtils";
import rootStore from "../../stores/RootStore";
import { useEffect } from "react";
import {
  AppBar,
  CircularProgress,
  Toolbar,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import analytics from "../../Analytics";
import { Close } from "@material-ui/icons";
import MuiAlert from "@mui/material/Alert";
import { titleCase } from "title-case";
import { LoadingButton } from "@mui/lab";
import EntityDataPickerDialog from "../customer_page_components/EntityDataPickerDialog";
const drawerWidth = "50vw";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  margin: 0px;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-end;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;
const CreateLeadComponent = ({
  setOpen,
  refresh,
  setRefresh,
  scope = "regular",
  xdata = {},
}) => {
  const pid = rootStore.authStore.projectId;
  const { userStore } = rootStore;
  const [tabIdx, setTabIdx] = useState(0);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [filledParentChildMap, setFilledParentChildMap] = useState({});
  const [columnList, setColumnList] = useState([]);
  const [requiredColumnList, setRequiredColumnList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [contactDetails, setContactDetails] = useState({});
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [formData, setFormData] = useState({});
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showOnlyRequiredFields, setShowOnlyRequiredFields] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projectID, setProjectID] = useState("");
  const [teamID, setTeamID] = useState("");
  const [entitiesListByType, setEntitiesListByType] = useState({});
  const [openEntityDataPickerDialog, setOpenEntityDataPickerDialog] =
    useState(false);
  const [entityType, setEntityType] = useState("");
  const [isContactDirty, setIsContactDirty] = useState(false);
  const [isOrganizationDirty, setIsOrganizationDirty] = useState(false);
  const [dialogContext, setDialogContext] = useState("off_focus"); //state variable to track from where dialog is evoked
  const [dirtyField, setDirtyField] = useState("");

  const validateMandatoryFields = ({ formData }) => {
    let filled_fields = { ...formData };
    for (let i = 0; i < mandatoryFields.length; i++) {
      let item = mandatoryFields[i];

      if (filled_fields.hasOwnProperty(item["field"])) {
        if (
          filled_fields[item["field"]]["value"] === "" ||
          filled_fields[item["field"]]["value"] === null
        ) {
          setErrorMessage(`Please fill "${item["headerName"]}" field`);
          return false;
        }
      } else {
        setErrorMessage(`Please fill "${item["headerName"]}" field`);
        return false;
      }
    }
    for (let key in filledParentChildMap) {
      let childIDList = filledParentChildMap[key];
      for (let i = 0; i < childIDList.length; i++) {
        let index = columnList.findIndex(
          (column) => column["id"] === childIDList[i]
        );
        if (index !== -1) {
          let item = columnList[index];
          if (item["required"] === true) {
            if (filled_fields.hasOwnProperty(item["field"])) {
              if (
                filled_fields[item["field"]]["value"] === "" ||
                filled_fields[item["field"]]["value"] === null
              ) {
                setErrorMessage(`Please fill "${item["headerName"]}" field`);
                return false;
              }
            } else {
              setErrorMessage(`Please fill "${item["headerName"]}" field`);
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const inRange = (num, min, max) => num >= min && num <= max;
  const validateFieldsWithRange = ({ formData }) => {
    let filled_fields = { ...formData };

    for (let key in filled_fields) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnMeta = columnList[index];
        if (
          columnMeta["min"] !== null &&
          columnMeta["max"] !== null &&
          columnMeta["min"] !== "" &&
          columnMeta["max"] !== "" &&
          filled_fields[key]["value"] !== null &&
          filled_fields[key]["value"] !== ""
        ) {
          if (columnMeta["data_type"] === "integer") {
            if (
              !inRange(
                parseFloat(filled_fields[key]["value"]),
                parseFloat(columnMeta["min"]),
                parseFloat(columnMeta["max"])
              )
            ) {
              setErrorMessage(
                `Please ensure the '${columnMeta["headerName"]}' value is between ${columnMeta["min"]} and ${columnMeta["max"]}.`
              );
              return false;
            }
          } else if (columnMeta["data_type"] === "datetime") {
            if (
              !isDateTimeInRange(
                columnMeta["min"],
                columnMeta["max"],
                filled_fields[key]["value"]
              )
            ) {
              setErrorMessage(
                `Please ensure the '${
                  columnMeta["headerName"]
                }' value is between ${IsoToLocalTime(
                  columnMeta["min"]
                )} and ${IsoToLocalTime(columnMeta["max"])}.`
              );
              return false;
            }
          } else if (columnMeta["data_type"] === "date") {
            if (
              !isDateInRange(
                columnMeta["min"],
                columnMeta["max"],
                filled_fields[key]["value"]
              )
            ) {
              setErrorMessage(
                `Please ensure the '${
                  columnMeta["headerName"]
                }' value is between ${IsoToLocalDate(
                  columnMeta["min"]
                )} and ${IsoToLocalDate(columnMeta["max"])}.`
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const formFieldSeparator = (data) => {
    let normalFields = {};
    let customFields = {};
    for (const key in data) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let value = data[key]["value"];
        if (data[key]["type"] === "integer") {
          value = parseInt(data[key]["value"]);
        } else if (data[key]["type"] === "array") {
          let tempList = [];
          data[key]["value"].forEach((item) => {
            tempList.push(item["value"]);
          });
          value = tempList.join(",");
        } else {
          value = data[key]["value"];
          if (columnList[index].hasOwnProperty("set_title_case")) {
            if (columnList[index]["set_title_case"] === true) {
              value = titleCase(data[key]["value"]);
            }
          }
        }
        if (columnList[index]["is_custom_param"]) {
          let newKey = key.replace("custom_params_", "");
          customFields[newKey] = value;
        } else {
          normalFields[key] = value;
        }
      }
    }
    return { ...normalFields, custom_params: customFields };
  };

  const generateContactPayloadData = (data) => {
    let contactFields = {};
    for (const key in data) {
      let value = data[key]["value"];
      if (data[key]["type"] === "integer") {
        value = parseInt(data[key]["value"]);
      } else if (data[key]["type"] === "array") {
        let tempList = [];
        data[key]["value"].forEach((item) => {
          tempList.push(item["value"]);
        });
        value = tempList.join(",");
      } else {
        value = data[key]["value"];
        let index = columnList.findIndex(
          (column) => column["contact_column"] === key
        );
        if (index !== -1) {
          if (columnList[index].hasOwnProperty("set_title_case")) {
            if (columnList[index]["set_title_case"] === true) {
              value = titleCase(data[key]["value"]);
            }
          }
        }
      }
      contactFields[key] = value;
    }

    return contactFields;
  };

  const getContactAndLeadData = (data) => {
    let contactData = {};
    let leadData = {};
    for (const key in data) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columnList[index];
        if (columnData["contact_column"] !== null) {
          contactData[columnData["contact_column"]] = data[columnData["field"]];
        }
        leadData[columnData["field"]] = data[columnData["field"]];
      }
    }
    return { contactData: contactData, leadData: leadData };
  };

  const canUpdateContact = (generatedContactData, contactDetails) => {
    for (const key in generatedContactData) {
      if (generatedContactData[key] !== contactDetails[key]) {
        return true;
      }
    }
    return false;
  };

  const isColumnEditable = (columnData) => {
    const field = columnData["field"];
    const contactField =
      columnData["contact_column"] !== null ? columnData["contact_column"] : "";
    const isEditable = rootStore.userStore.isColumnEditable(
      columnData,
      projectID
    );

    if (!isEditable) {
      return false;
    } else {
      if (scope === "customer") {
        if (contactField !== "") {
          return false;
        }
      }

      if (Object.keys(contactDetails).length > 0) {
        if (field === "phone" || field === "email") {
          return true;
        }
      }

      if (Object.keys(contactDetails).length > 0) {
        if (field === "organization_name") {
          return true;
        }
      }

      if (Object.keys(contactDetails).length > 0 && contactField !== "") {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleSubmit = async ({ contactDetails, formData }) => {
    setSubmittingForm(true);
    setErrorMessage("");
    setIsSubmitFail(false);
    if (validateMandatoryFields({ formData: formData })) {
      if (validateFieldsWithRange({ formData: formData })) {
        setIsSubmitFail(false);
        setErrorMessage("");

        //addon lead limit check
        const limitExceeded =
          await rootStore.subscriptionState.hasReachedMaxLeadsLimitForProject({
            newLeadCount: 1,
            pid: projectID,
          });
        if (limitExceeded) {
          setErrorMessage(
            "You've reached the maximum number of leads allowed for this project. Please get in touch with support for assistance or to upgrade your plan."
          );
          setIsSubmitFail(true);
          setSubmittingForm(false);
          return;
        }

        // check if contact data found and has changed on next click
        if (isContactDirty) {
          let payload = {};
          let response = [];

          if (dirtyField === "phone") {
            if (
              formData.hasOwnProperty("phone") &&
              formData["phone"]["value"] !== null &&
              formData["phone"]["value"] !== ""
            ) {
              payload = { phone: formData["phone"]["value"] };
              response = await searchContactByPhoneApi({
                payload: payload,
              });
            }
          }

          if (dirtyField === "email") {
            if (
              formData.hasOwnProperty("email") &&
              formData["email"]["value"] !== null &&
              formData["email"]["value"] !== ""
            ) {
              payload = { email: formData["email"]["value"] };
              response = await searchContactByEmailApi({
                payload: payload,
              });
            }
          }
          setEntityType("contact");
          setEntitiesListByType({
            ...entitiesListByType,
            ["contact"]: response,
          });
          setDirtyField("");
          setIsContactDirty(false);
          if (response.length > 0) {
            setDialogContext("form_submit");
            setOpenEntityDataPickerDialog(true);
            return;
          } else {
            if (Object.keys(contactDetails).length > 0) {
              setContactDetails({});
              prefillEntityFields("contact", payload);
            }
          }
        }

        // check if organization data found and has changed on next click

        if (isOrganizationDirty) {
          if (
            formData.hasOwnProperty("organization_name") &&
            formData["organization_name"]["value"] !== null &&
            formData["organization_name"]["value"] !== ""
          ) {
            let response = await searchOrganizationsByNameApi({
              queryParams: { name: formData["organization_name"]["value"] },
            });
            setIsOrganizationDirty(false);
            if (response.length > 0) {
              setDialogContext("form_submit");
              setOpenEntityDataPickerDialog(true);
              return;
            }
          }
        }

        let tempContactDetails = contactDetails;
        let tempFormData = formData;
        let contactAndLeadData = getContactAndLeadData({ ...tempFormData });
        let contactData = generateContactPayloadData(
          contactAndLeadData.contactData
        );
        let leadData = formFieldSeparator(contactAndLeadData.leadData);
        if (Object.keys(tempContactDetails).length > 0) {
          await createLeadWithExistingContact({
            contactData: contactData,
            leadData: leadData,
            contactDetails: tempContactDetails,
          });
        } else {
          let orgIndex = columnList.findIndex(
            (column) => column["contact_column"] === "organization_name"
          );
          if (orgIndex !== -1) {
            if (
              organizationDetails.hasOwnProperty("id") &&
              organizationDetails["id"] !== null &&
              organizationDetails["id"] !== ""
            ) {
              contactData["organization_id"] = organizationDetails["id"];
              await createdLeadWithNewContact({
                contactData: contactData,
                leadData: leadData,
              });
            } else {
              await createContactWithOrganization({
                leadData: leadData,
                contactData: contactData,
              });
            }
          } else {
            await createdLeadWithNewContact({
              contactData: contactData,
              leadData: leadData,
            });
          }
        }
      } else {
        setIsSubmitFail(true);
      }
    } else {
      setIsSubmitFail(true);
    }
    setSubmittingForm(false);
  };

  const CustomTab = withStyles({
    root: {
      width: "100%",
      color: "#4D4E4F",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
      maxWidth: "none",
      paddingLeft: "24px",
    },
    wrapper: {
      alignItems: "flex-start",
    },
    selected: {
      color: "#4079DA",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
  })(Tab);

  useEffect(() => {
    setup();
    analytics.triggerEvent(
      4625000028,
      "create_lead_form_load",
      "leads_page",
      "",
      {}
    );
  }, []);

  const setup = async () => {
    let newColumnList = [];
    let projectID = "";
    let teamID = "";
    if (scope === "customer") {
      setShowOnlyRequiredFields(true);

      //lead count fetch
      rootStore.authStore.checkAndFetchTotalProjectLeadCount(
        xdata["project_id"]
      ); //fetch total leads count in background

      //set project and team ids
      projectID = xdata["project_id"];
      teamID = xdata["team_id"];

      await rootStore.userStore.checkAndFetchColumnPermissions(
        xdata["project_id"]
      );
      //check and fetch and use store column list for different project id
      await rootStore.userStore.checkAndFetchCreateLeadColumnMetaList(
        xdata["project_id"]
      );
      // check if project is current and set columns
      if (xdata["project_id"] !== pid) {
        newColumnList = rootStore.userStore.getCreateLeadColumnMetaList(
          xdata["project_id"]
        );

        consoleLogger("cols::: ", newColumnList);
        consoleLogger("customer data::: ", xdata["customer_data"]);
      } else {
        newColumnList = [...userStore.AllColumnsList];
      }
      //setting contact details and form data for prefilling
      setContactDetails(xdata["customer_data"]);
      let mappedData = mappedContactFields(
        newColumnList,
        xdata["customer_data"]
      );
      setFormData({ ...formData, ...mappedData });
    } else {
      //lead count fetch
      rootStore.authStore.checkAndFetchTotalProjectLeadCount(pid); //fetch total leads count in background

      projectID = pid;
      teamID = rootStore.authStore.teamId;
      newColumnList = [...userStore.AllColumnsList];
    }
    setTeamID(teamID);
    setProjectID(projectID);
    setColumnList(newColumnList);
    const requiredColumns = getRequiredColumns(newColumnList);
    setRequiredColumnList(requiredColumns);
    setupMandatoryFields(newColumnList);
    setLoading(false);
  };

  const getRequiredColumns = (columnList) => {
    let columns = [];
    let parentCols = columnList.filter(
      (item) => item["required"] === true && item["derived"] === false
    );
    columns.push(...parentCols);

    for (let item of parentCols) {
      let parentId = item["id"];
      let children = columnList.filter(
        (child) =>
          child["child_of"] !== null && child["child_of"]["id"] === parentId
      );

      columns.push(...children);
    }

    return columns;
  };

  const setupMandatoryFields = (columnList) => {
    let mandatory_field_list = [];
    columnList.forEach((item) => {
      if (
        item["required"] === true &&
        item["derived"] === false &&
        item["enable_for_create"] === true &&
        item["child_of"] === null
      ) {
        let tempObj = { headerName: item["headerName"] };
        tempObj["field"] = item["field"];

        mandatory_field_list.push(tempObj);
      }
    });
    setMandatoryFields(mandatory_field_list);
  };

  const mappedContactFields = (columnList, contactData) => {
    let tempObj = {};

    columnList.forEach((column) => {
      if (column["contact_column"] !== null) {
        if (contactData.hasOwnProperty(column["contact_column"])) {
          let value = contactData[column["contact_column"]];
          if (value !== null) {
            tempObj[column["field"]] = {
              value: value,
              type: column["data_type"],
            };
          }
        }
      }
    });
    return tempObj;
  };

  const prefillEntityFields = (entityType, entityData) => {
    let dataToPrefill = {};

    if (entityType === "contact") {
      setDirtyField("");
      setIsContactDirty(false);
      setIsOrganizationDirty(false);
      columnList.forEach((column) => {
        if (
          column["contact_column"] !== null &&
          column["contact_column"] !== ""
        ) {
          if (entityData.hasOwnProperty(column["contact_column"])) {
            dataToPrefill[column["field"]] = {
              value: entityData[column["contact_column"]],
              type: column["data_type"],
            };
          }
        }
      });
      let orgColumnIndex = columnList.findIndex(
        (column) => column["contact_column"] === "organization_name"
      );
      if (orgColumnIndex !== -1) {
        if (
          entityData.hasOwnProperty("organization") &&
          entityData["organization"] !== null
        ) {
          setOrganizationDetails(entityData["organization"]);
        } else {
          setOrganizationDetails({});
        }
      }
    } else if (entityType === "organization") {
      let orgColumnIndex = columnList.findIndex(
        (column) => column["field"] === "organization_name"
      );
      if (orgColumnIndex !== -1) {
        let orgColumnData = columnList[orgColumnIndex];
        dataToPrefill[orgColumnData["field"]] = {
          value:
            entityData.hasOwnProperty("name") && entityData["name"] !== null
              ? entityData["name"]
              : "",
          type: orgColumnData["data_type"],
        };
      }
    }

    setFormData({ ...formData, ...dataToPrefill });
  };

  const handleEntity = async (fieldName, fieldValue) => {
    if (fieldValue.length < 3) {
      return;
    }

    let payload = {};

    if (fieldName === "phone" || fieldName === "email") {
      if (isContactDirty) {
        let response = [];
        if (fieldName === "phone" && dirtyField === "phone") {
          payload = { phone: fieldValue };
          response = await searchContactByPhoneApi({
            payload: payload,
          });
        } else if (fieldName === "email" && dirtyField === "email") {
          payload = { email: fieldValue };
          response = await searchContactByEmailApi({
            payload: payload,
          });
        }
        setDirtyField("");
        setIsContactDirty(false);
        setEntityType("contact");
        setEntitiesListByType({
          ...entitiesListByType,
          ["contact"]: response,
        });
        if (response.length > 0) {
          setDialogContext("off_focus");
          setOpenEntityDataPickerDialog(true);
        } else {
          if (Object.keys(contactDetails).length > 0) {
            setContactDetails({});
            prefillEntityFields("contact", payload);
          }
        }
      }
    } else if (fieldName === "organization_name" && isOrganizationDirty) {
      let response = await searchOrganizationsByNameApi({
        queryParams: { name: fieldValue },
      });
      setIsOrganizationDirty(false);
      setEntityType("organization");
      setEntitiesListByType({
        ...entitiesListByType,
        ["organization"]: response,
      });
      if (response.length > 0) {
        setDialogContext("off_focus");
        setOpenEntityDataPickerDialog(true);
      }
    }
  };

  const handleNextButton = async () => {
    setIsSubmitFail(false);

    // check if contact data found and has changed on next click
    let payload = {};
    if (isContactDirty) {
      let response = [];
      if (dirtyField === "phone") {
        if (
          formData.hasOwnProperty("phone") &&
          formData["phone"]["value"] !== null &&
          formData["phone"]["value"] !== ""
        ) {
          payload = { phone: formData["phone"]["value"] };
          response = await searchContactByPhoneApi({
            payload: payload,
          });
        }
      }

      if (dirtyField === "email") {
        if (
          formData.hasOwnProperty("email") &&
          formData["email"]["value"] !== null &&
          formData["email"]["value"] !== ""
        ) {
          payload = { email: formData["email"]["value"] };
          response = await searchContactByEmailApi({
            payload: payload,
          });
        }
      }

      setEntityType("contact");
      setEntitiesListByType({
        ...entitiesListByType,
        ["contact"]: response,
      });
      setDirtyField("");
      setIsContactDirty(false);
      if (response.length > 0) {
        setDialogContext("next_navigation");
        setOpenEntityDataPickerDialog(true);
        return;
      } else {
        if (Object.keys(contactDetails).length > 0) {
          setContactDetails({});
          prefillEntityFields("contact", payload);
          setTabIdx(1);
        }
      }
    }

    // check if organization data found and has changed on next click

    if (isOrganizationDirty) {
      if (
        formData.hasOwnProperty("organization_name") &&
        formData["organization_name"]["value"] !== null &&
        formData["organization_name"]["value"] !== ""
      ) {
        let response = await searchOrganizationsByNameApi({
          queryParams: { name: formData["organization_name"]["value"] },
        });
        setIsOrganizationDirty(false);
        if (response.length > 0) {
          setDialogContext("next_navigation");
          setOpenEntityDataPickerDialog(true);
          return;
        }
      }
    }

    setTabIdx(1);
  };

  const createLead = async ({ contactID, leadData }) => {
    let response = {};
    if (scope === "regular") {
      let payload = {
        contact_id: contactID,
        lead_data: leadData,
      };
      response = await submitLeadApi({
        payload: payload,
      });
    } else if (scope === "customer") {
      leadData["lead_owner_id"] = xdata["lead_owner_id"];
      let createLeadPayload = {
        contact_id: contactID,
        lead_data: leadData,
      };
      response = await createLeadForProject({
        pid: projectID,
        data: createLeadPayload,
      });
    }
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      rootStore.authStore.updateTotalProjectLeadCount({
        pid: projectID,
        count: 1,
      });
      setRefresh(!refresh);
      setIsSubmitSuccess(true);
    }
  };

  const createdLeadWithNewContact = async ({ contactData, leadData }) => {
    let createContactResponse = await createContactApi({
      payload: contactData,
    });
    if (createContactResponse.hasError()) {
      if (
        createContactResponse.data.hasOwnProperty("code") &&
        createContactResponse.data["code"] === "1029"
      ) {
        await handleDuplicateContact();
      } else {
        setErrorMessage(createContactResponse.errorMessage);
        setIsSubmitFail(true);
      }

      setErrorMessage(createContactResponse.errorMessage);
      setIsSubmitFail(true);
    } else {
      await createLead({
        contactID: createContactResponse.data["id"],
        leadData: leadData,
      });
    }
  };

  const createLeadWithExistingContact = async ({
    contactData,
    leadData,
    contactDetails,
  }) => {
    if (canUpdateContact(contactData, contactDetails)) {
      let updateContactResponse = await editContactApi({
        contactID: contactDetails["id"],
        payload: contactData,
      });
      if (updateContactResponse.hasError()) {
        setErrorMessage(updateContactResponse.errorMessage);
        setIsSubmitFail(true);
      } else {
        await createLead({
          contactID: contactDetails["id"],
          leadData: leadData,
        });
      }
    } else {
      await createLead({ contactID: contactDetails["id"], leadData: leadData });
    }
  };

  const createContactWithOrganization = async ({ leadData, contactData }) => {
    let orgIndex = columnList.findIndex(
      (column) => column["contact_column"] === "organization_name"
    );
    if (orgIndex !== -1) {
      const orgColumnData = columnList[orgIndex];
      if (
        formData.hasOwnProperty(orgColumnData["field"]) &&
        formData[orgColumnData["field"]]["value"] !== null &&
        formData[orgColumnData["field"]]["value"] !== ""
      ) {
        let organizationResponse = await createOrganizationApi({
          payload: { name: formData[orgColumnData["field"]]["value"] },
        });
        if (organizationResponse.hasError()) {
          setErrorMessage(organizationResponse.errorMessage);
          setIsSubmitFail(true);
        } else {
          contactData["organization_id"] = organizationResponse.data["id"];
          await createdLeadWithNewContact({
            contactData: contactData,
            leadData: leadData,
          });
        }
      } else {
        await createdLeadWithNewContact({
          contactData: contactData,
          leadData: leadData,
        });
      }
    }
  };

  //----------commented temporarily------------------

  const handleDuplicateContact = async () => {
    let tempContactData = {};
    if (formData.hasOwnProperty("phone")) {
      let response = await searchContactByPhoneApi({
        payload: { phone: formData["phone"]["value"] },
      });
      if (response.length > 0) {
        tempContactData = response[0];
      }
    }
    if (Object.keys(tempContactData).length === 0) {
      if (formData.hasOwnProperty("email")) {
        let response = await searchContactByEmailApi({
          payload: { email: formData["email"]["value"] },
        });
        if (response.length > 0) {
          tempContactData = response[0];
        }
      }
    }
    if (Object.keys(tempContactData).length > 0) {
      setIsContactDirty(false);
      setContactDetails(tempContactData);
      let organizationData =
        tempContactData.hasOwnProperty("organization") &&
        tempContactData["organization"] !== null
          ? tempContactData["organization"]
          : {};
      setOrganizationDetails(organizationData);
      let mappedData = mappedContactFields(columnList, tempContactData);
      let resultantData = { ...formData, ...mappedData };
      setFormData(resultantData);
      await handleSubmit({
        contactDetails: tempContactData,
        formData: resultantData,
      });
    } else {
      setErrorMessage("Some error occurred!\nError code:1016");
      setIsSubmitFail(true);
    }
  };

  const RenderCancelOrPreviousButton = () => {
    if (tabIdx === 0) {
      return (
        <Button
          onClick={() => {
            analytics.triggerEvent(
              4625000265,
              "cance_button_tap",
              "create_lead_form",
              "cance_button",
              { order: "primary_info_tab" }
            );
            setOpen(false);
          }}
          variant="outlined"
          color="primary"
          style={{ width: "100px", textTransform: "none" }}
        >
          {getLocalizedText("cancel")}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            analytics.triggerEvent(
              4625000032,
              "previous_button_tap",
              "create_lead_form",
              "previous_button",
              { order: "custom_params_tab" }
            );
            setIsSubmitFail(false);
            setErrorMessage("");
            setTabIdx(0);
          }}
          variant="outlined"
          color="primary"
          style={{ width: "100px", textTransform: "none" }}
        >
          {getLocalizedText("prev")}
        </Button>
      );
    }
  };

  const RenderNextOrSubmitButton = () => {
    if (tabIdx === 0) {
      return (
        <Button
          type="submit"
          onClick={() => {
            analytics.triggerEvent(
              4625000031,
              "next_button_tap",
              "create_lead_form",
              "next_button",
              { order: "primary_info_tab" }
            );
          }}
          variant="contained"
          style={{
            width: "100px",
            ...myTheme.Button.btnBlue,
            textTransform: "none",
          }}
        >
          {getLocalizedText("next")}
        </Button>
      );
    } else {
      if (submittingForm) {
        return (
          <LoadingButton
            loading
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "100px",
            }}
          >
            <span> {getLocalizedText("submit")}</span>
          </LoadingButton>
        );
      } else {
        return (
          <Button
            onClick={() => {
              analytics.triggerEvent(
                4625000033,
                "submit_button_tap",
                "create_lead_form",
                "submit_button",
                { order: "custom_params_tab" }
              );
            }}
            type="submit"
            variant="contained"
            style={{
              width: "100px",
              ...myTheme.Button.btnBlue,
              textTransform: "none",
            }}
          >
            {getLocalizedText("submit")}
          </Button>
        );
      }
    }
  };

  return (
    <>
      {!loading ? (
        !isSubmitSuccess ? (
          <Box
            sx={{ position: "relative", width: drawerWidth, height: "100%" }}
            role="presentation"
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              if (tabIdx === 0) {
                handleNextButton();
              } else {
                handleSubmit({
                  contactDetails: contactDetails,
                  formData: formData,
                });
              }
            }}
          >
            <AppBar
              component={"nav"}
              position="sticky"
              color="inherit"
              elevation={0}
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                bgcolor: "#f9f9fc",
              }}
            >
              <Toolbar
                style={{
                  paddingBottom: "0px",
                  height: "100%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Column
                  style={{
                    rowGap: "16px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Row
                    style={{ justifyContent: "space-between", height: "100%" }}
                  >
                    <ModelHeader>
                      {getLocalizedText("create_new_lead", "project")}
                    </ModelHeader>
                    <Box sx={{ width: "fit-content" }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                variant="small"
                                color={"primary"}
                                sx={{ marginRight: "14px" }}
                                onClick={() => {
                                  setShowOnlyRequiredFields(
                                    !showOnlyRequiredFields
                                  );
                                }}
                                checked={showOnlyRequiredFields}
                              />
                            }
                            sx={{ fontSize: "14px" }}
                            label={getLocalizedText("show_required_fields")}
                          />
                        </Box>
                        <IconButton
                          style={{
                            justifyContent: "space-between",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    </Box>
                  </Row>
                </Column>
              </Toolbar>
            </AppBar>

            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "row",
                flex: 1,
              }}
            >
              <Grid
                container
                spacing={0}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <Grid
                  item
                  xs={3}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tabs
                    orientation="vertical"
                    value={tabIdx}
                    textColor="primary"
                    indicatorColor="primary"
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#f9f9fc",
                      borderColor: "divider",
                      borderRight: "1px solid #ddd",
                    }}
                    onChange={(event, newValue) => {
                      if (newValue === 0) {
                        handleNextButton();
                      }
                      setTabIdx(newValue);
                    }}
                  >
                    <CustomTab label={getLocalizedText("primary_info")} />;
                    <CustomTab label={getLocalizedText("custom_params")} />;
                  </Tabs>
                </Grid>

                <Grid
                  item
                  xs={9}
                  sx={{
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  <ModelWrapper>
                    {(() => {
                      const columnsList = showOnlyRequiredFields
                        ? requiredColumnList
                        : columnList;
                      if (tabIdx === 0) {
                        return (
                          <FirstForm
                            key={showOnlyRequiredFields ? "required" : "all"}
                            value={formData}
                            setValue={setFormData}
                            handleEntity={handleEntity}
                            isColumnEditable={isColumnEditable}
                            filledParentChildMap={filledParentChildMap}
                            setFilledParentChildMap={setFilledParentChildMap}
                            setIsSubmitFail={setIsSubmitFail}
                            columnsList={columnsList}
                            projectID={projectID}
                            teamID={teamID}
                            setIsContactDirty={setIsContactDirty}
                            setIsOrganizationDirty={setIsOrganizationDirty}
                            setDirtyField={setDirtyField}
                          />
                        );
                      } else if (tabIdx === 1) {
                        return (
                          <SecondForm
                            key={showOnlyRequiredFields ? "required" : "all"}
                            value={formData}
                            setValue={setFormData}
                            isColumnEditable={isColumnEditable}
                            filledParentChildMap={filledParentChildMap}
                            setFilledParentChildMap={setFilledParentChildMap}
                            setIsSubmitFail={setIsSubmitFail}
                            columnsList={columnsList}
                            projectID={projectID}
                            teamID={teamID}
                          />
                        );
                      }
                    })()}
                  </ModelWrapper>
                </Grid>
              </Grid>
            </Box>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: drawerWidth,
                bgcolor: "#f9f9fc",
              }}
            >
              <Toolbar
                style={{
                  width: "100%",
                  padding: "16px",
                  position: "relative",
                  borderTop: "1px solid #ddd",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <RenderCancelOrPreviousButton />
                  <RenderNextOrSubmitButton />
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </Box>
        ) : (
          <Box sx={{ height: "100%", width: drawerWidth }}>
            <ModelWrapper
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MessageWrapper>
                <Message>
                  {getLocalizedText("lead_created_successfully", "project")}
                </Message>
                <Button
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000034,
                      "close_button_tap",
                      "create_lead_form",
                      "close_button",
                      {}
                    );
                    setOpen(false);
                  }}
                  variant="outlined"
                  style={{
                    ...myTheme.Button.btnLight,
                    width: "fit-content",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </ModelWrapper>
          </Box>
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            width: drawerWidth,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {openEntityDataPickerDialog && (
        <EntityDataPickerDialog
          scope="create_lead"
          entityDataList={
            entitiesListByType.hasOwnProperty(entityType)
              ? entitiesListByType[entityType]
              : []
          }
          entityType={entityType}
          handlePostContinue={(entityData) => {
            prefillEntityFields(entityType, entityData);
            if (Object.keys(entityData).length === 0) {
              setContactDetails({});
              setOrganizationDetails({});
              setIsContactDirty(false);
              setIsOrganizationDirty(false);
              setDirtyField("");
            } else {
              if (entityType === "contact") {
                setContactDetails(entityData);
              } else if (entityType === "organization") {
                setOrganizationDetails(entityData);
              }

              if (dialogContext === "next_navigation") {
                handleNextButton();
              } else if (dialogContext === "form_submit") {
                handleSubmit({
                  contactDetails: contactDetails,
                  formData: formData,
                });
              }
              setOpenEntityDataPickerDialog(false);
            }
          }}
          open={openEntityDataPickerDialog}
          setOpen={setOpenEntityDataPickerDialog}
        />
      )}
    </>
  );
};

export default CreateLeadComponent;

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Modal,
  Select,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { getMembersApiV2, leadAssignApi } from "../../Api";
import {
  Alert,
  CircularProgress,
  Divider,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Avatar,
  OutlinedInput,
  Link,
} from "@mui/material";
import {
  currentDayNameGetter,
  getISODateTimeByTimeSlot,
  getLocalizedText,
  getLocalTimeFromIsoString,
  getTimeSlotsForPriorityLead,
  validateDisabledSubFeature,
  checkFeaturePermission,
} from "../../Functions";
import { Close } from "@material-ui/icons";
import { MultiSelect } from "react-multi-select-component";
import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MemberFilterModal from "./MemberFilterModal";
import profile_image from "../../assets/profile_placeholder.png";
import moment from "moment";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 550px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ProfileImage = styled("img")`
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;
const ImageWrapper = styled(Avatar)`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a6a8;
  padding: 0px;
  position: relative;
  background-color: white;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeadsAssignModal = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [multiSelectMemberList, setMultiSelectMemberList] = useState([]);
  const [detailedMembersList, setDetailedMembersList] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { authStore, userStore } = rootStore;
  const [flag, setFlag] = useState(false);
  const [canAssignAllLead, setCanAssignAllLead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [comments, setComments] = useState("");

  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlot, setTimeSlot] = useState("");

  //member filters
  const [selectedRolesFilter, setSelectedRolesFilter] = useState([]);
  const [selectedTeamsFilter, setSelectedTeamsFilter] = useState([]);

  const handleAllPageLeadAssign = async () => {
    let numberOfPages = Math.ceil(props.totalLeadCount / props.pageSize);
    let memberIndex = 0;
    for (let i = 0; i < numberOfPages; i++) {
      let leadIDs = await props.getAllLeadIDsToAssign(i);

      const numLeads = leadIDs.length;
      const numMembers = selectedMembers.length;
      const memberLeads = {};
      selectedMembers.forEach((member) => {
        memberLeads[member.value] = [];
      });

      for (let j = 0; j < numLeads; j++) {
        const lead = leadIDs[j];
        const member = selectedMembers[memberIndex].value;
        memberLeads[member].push(lead);
        memberIndex = (memberIndex + 1) % numMembers;
      }

      for (const [member, leads] of Object.entries(memberLeads)) {
        if (leads.length > 0) {
          let payload = {
            lead_ids: leads,
            assign_to: member,
            erase_history: flag,
          };
          if (timeSlot !== "") {
            let dateTimeString = getISODateTimeByTimeSlot(timeSlot);
            payload["hard_update_by"] = dateTimeString;
          }

          if (comments !== "") {
            payload["comments"] = comments;
          }
          try {
            await leadAssignApi({
              payload: payload,
            });
            // Handle response if needed
          } catch (error) {
            console.log(error);
            // Handle error if needed
          }
        }
      }
    }
  };

  const handleCurrentPageSelectedLeadAssign = async () => {
    const numLeads = props.leads.length;
    const numMembers = selectedMembers.length;
    const memberLeads = {};
    selectedMembers.forEach((member) => {
      memberLeads[member.value] = [];
    });

    // Round Robin logic
    // Iterate over each lead and assign leads round-robin to each member
    for (let i = 0; i < numLeads; i++) {
      const lead = props.leads[i];
      const memberIndex = i % numMembers; // Calculate member index using modulo operation
      const member = selectedMembers[memberIndex].value; // Get the member for this lead
      memberLeads[member].push(lead); // Assign lead to member
    }

    /* THE BELOW COMMENTED IS BATCH ASSIGN CODE - MIGHT USE IN THE FUTURE

    // Calculate the base number of leads each member should receive
    const baseLeadsPerMember = Math.floor(numLeads / numMembers);

    // Calculate the number of members that should receive an extra lead
    const numMembersWithExtraLead = numLeads % numMembers;

    let leadIndex = 0;

    for (let i = 0; i < numMembers; i++) {
      // Calculate the number of leads this member should receive
      const leadsForThisMember =
        baseLeadsPerMember + (i < numMembersWithExtraLead ? 1 : 0);
      const member = selectedMembers[i].value; // Get the member for this lead

      // Assign leads to this member
      memberLeads[member] = props.leads.slice(
        leadIndex,
        leadIndex + leadsForThisMember
      );

      // Update counters
      leadIndex += leadsForThisMember;
    }
*/

    // Iterate over each member and make API call to assign leads
    for (const [member, leads] of Object.entries(memberLeads)) {
      if (leads.length > 0) {
        let payload = {
          lead_ids: leads,
          assign_to: member,
          erase_history: flag,
        };
        if (timeSlot !== "") {
          let dateTimeString = getISODateTimeByTimeSlot(timeSlot);
          payload["hard_update_by"] = dateTimeString;
        }
        if (comments !== "") {
          payload["comments"] = comments;
        }
        try {
          await leadAssignApi({
            payload: payload,
          });
          // Handle response if needed
        } catch (error) {
          console.log(error);
          // Handle error if needed
        }
      }
    }
  };

  const handleAssign = async () => {
    setLoading(true);
    if (canAssignAllLead) {
      await handleAllPageLeadAssign();
    } else {
      await handleCurrentPageSelectedLeadAssign();
    }

    setLoading(false);
    setIsAssigned(true);
    setTimeout(() => {
      props.setRefresh(!props.refresh);
      props.setOpen(false);
    }, [800]);
  };

  const handleFilterSubmit = (teamsFilterValue, rolesFilterValue) => {
    //resetting selections and applying filters for members
    setSelectedMembers([]);
    setSelectedRolesFilter(rolesFilterValue);
    setSelectedTeamsFilter(teamsFilterValue);

    let member_list = memberList;

    //apply team filter
    if (teamsFilterValue.length !== 0) {
      if (!teamsFilterValue.find((x) => x.value === "All")) {
        // if "All teams" filter is selected, don't filter
        const selectedTeamIDs = teamsFilterValue.map((x) => x.value);
        member_list = member_list.filter((x) =>
          selectedTeamIDs.includes(x.team.id)
        );
      }
    }

    //apply role filter
    if (rolesFilterValue.length !== 0) {
      const selectedRoleValues = rolesFilterValue.map((x) => x.value);
      member_list = member_list.filter((x) =>
        selectedRoleValues.includes(x.role)
      );
    }

    let modifiedList = [];
    let detailedList = [];
    member_list.forEach((e) => {
      let newObj = { label: e.name, value: e.id, name: e.name };
      modifiedList.push(newObj);
      let labelStr = `${e.name} || ${e.role} || ${e.team.name}`;
      let newObj1 = {
        label: labelStr,
        value: e.id,
        name: e.name,
        team: e.team,
        role: e.role,
        profile_pic: e.profile_pic,
        not_set_lead_count:
          e.leads_count.hasOwnProperty("not_set_leads") &&
          e.leads_count["not_set_leads"] !== null &&
          e.leads_count["not_set_leads"] !== -1
            ? e.leads_count["not_set_leads"]
            : "-",
      };
      detailedList.push(newObj1);
    });
    setMultiSelectMemberList(modifiedList);
    setDetailedMembersList(detailedList);
  };

  const valueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map((x, index) => {
          let text = x.name;
          if (index < selected.length - 1) {
            text += ", ";
          }
          return text;
        })
      : "No Items Selected";
  };

  const detailedOptionRenderer = ({ checked, option, onClick, disabled }) => {
    return option.value === "" && !option.hasOwnProperty("name") ? (
      <div
        className={`item-renderer ${disabled && "disabled"}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "14px",
          }}
        >
          <Link
            onClick={(e) => {
              onClick();
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{
              textDecoration: "none",
              color: checked ? "#3f51b5" : "inherit",
            }}
          >
            {" "}
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              {option.label}
            </Typography>
          </Link>
          <Link
            disabled={selectedMembers.length === 0}
            sx={{
              textDecoration: "none",
              color: "#000",
            }}
            onClick={(e) => {
              if (selectedMembers.length > 0) {
                setSelectedMembers([]);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            {" "}
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              Clear
            </Typography>
          </Link>
        </Box>
      </div>
    ) : (
      <div
        className={`item-renderer ${disabled && "disabled"}`}
        onClick={(e) => {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Card
          square={true}
          elevation={2}
          id={option.id}
          value={option.id}
          style={{
            marginBottom: 4,
            marginLeft: 1,
            marginRight: 1,
            width: "98%",
            maxWidth: "98%",
            height: "70px",
            backgroundColor: checked ? "#E8F1FA" : null,
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CardActionArea>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Checkbox
                  color="primary"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 8,
                  }}
                  checked={checked}
                  onChange={(e) => {
                    //onClick();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <ImageWrapper>
                  <ProfileImage
                    src={
                      option.profile_pic != null
                        ? option.profile_pic
                        : profile_image
                    }
                    alt="loading..."
                  />
                </ImageWrapper>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: 2,
                    marginLeft: 8,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      {option.name}
                    </Typography>
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "12px",
                        textAlign: "left",
                        color: "#b7b7b7",
                      }}
                    >
                      Not Set: {option.not_set_lead_count}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "12px",
                        textAlign: "left",
                        color: "#b7b7b7",
                      }}
                    >
                      Role: {option.role}
                    </Typography>
                    <Typography
                      style={{
                        width: "fit-content",
                        fontSize: "12px",
                        textAlign: "right",
                        color: "#b7b7b7",
                      }}
                    >
                      Team: {option.team.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  };

  const mutliSelectOptionRenderer = ({ checked, option, onClick }) => {
    return option.value === "" && !option.hasOwnProperty("name") ? (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "14px",
          }}
        >
          <Link
            onClick={(e) => {
              onClick();
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{
              textDecoration: "none",
              color: checked ? "#3f51b5" : "inherit",
            }}
          >
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              {option.label}
            </Typography>
          </Link>
          <Link
            disabled={selectedMembers.length === 0}
            onClick={(e) => {
              if (selectedMembers.length > 0) {
                setSelectedMembers([]);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              Clear
            </Typography>
          </Link>
        </Box>
      </div>
    ) : (
      <CardActionArea
        onClick={(e) => {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Card
          square={false}
          id={option.id}
          value={option.id}
          style={{
            marginBottom: 1,
            padding: 2,
            width: "100%",
            height: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            style={{ width: "10%" }}
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {option.name}
            </Typography>
          </Box>
        </Card>
      </CardActionArea>
    );
  };

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    let teamID =
      rootStore.authStore.teamId !== null
        ? rootStore.authStore.teamId !== "All"
          ? rootStore.authStore.teamId
          : ""
        : null;
    let member_list = await getMembersApiV2({
      projectID: rootStore.authStore.projectId,
      teamID: teamID,
    });
    setMemberList(member_list);
    let modifiedList = [];
    let detailedList = [];
    member_list.forEach((e) => {
      let newObj = { label: e.name, value: e.id, name: e.name };
      modifiedList.push(newObj);
      let labelStr = `${e.name} || ${e.role} || ${e.team.name}`;
      let newObj1 = {
        label: labelStr,
        value: e.id,
        name: e.name,
        team: e.team,
        role: e.role,
        profile_pic: e.profile_pic,
        not_set_lead_count:
          e.leads_count.hasOwnProperty("not_set_leads") &&
          e.leads_count["not_set_leads"] !== null &&
          e.leads_count["not_set_leads"] !== -1
            ? e.leads_count["not_set_leads"]
            : "-",
      };
      detailedList.push(newObj1);
    });
    setMultiSelectMemberList(modifiedList);
    setDetailedMembersList(detailedList);
    let time_slots = getTimeSlotsForPriorityLead();
    setTimeSlots(time_slots);
    setInitLoading(false);
  };

  const handleFlag = (e) => {
    setFlag(e.target.checked);
  };

  const canHaveDetailedMemberList = () => {
    if (userStore.UserData !== null) {
      let accountRole = userStore.UserData.role;
      let projectRole = authStore.getCurrentProjectRole();

      if (accountRole === "admin" || accountRole === "owner") {
        return true;
      } else {
        if (projectRole === "manager") {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const RenderButton = () => {
    if (loading) {
      return (
        <LoadingButton
          loading
          loadingIndicator="Assigning…"
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            font: "normal normal 600 10px Open Sans",
            width: "100%",
          }}
        >
          <span>Assigning...</span>
        </LoadingButton>
      );
    } else {
      if (isAssigned == true) {
        return (
          <Button
            disabled
            variant="contained"
            style={{
              font: "normal normal 600 10px Open Sans",
              width: "100%",
              textTransform: "none",
              backgroundColor: "green",
              color: "white",
            }}
          >
            Assigned
          </Button>
        );
      } else {
        return (
          <Button
            onClick={handleAssign}
            variant="contained"
            color="primary"
            disabled={selectedMembers.length === 0}
            style={{
              font: "normal normal 600 10px Open Sans",
              width: "100%",
              textTransform: "none",
            }}
          >
            {getLocalizedText("assign")}
          </Button>
        );
      }
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer
          style={{
            width: canHaveDetailedMemberList() ? "550px" : "400px",
          }}
        >
          {!initLoading ? (
            <Box>
              <ModelWrapper>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Header>{`Assign ${props.leads.length} ${getLocalizedText(
                    "lead",
                    "project"
                  )}(s) To`}</Header>
                  <IconButton
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <SubHeader>Select members from below dropdown</SubHeader>
                <Divider style={{ margin: "10px 0px" }} />
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    color: "#4d4e4f",
                    marginBottom: "4px",
                  }}
                >
                  {getLocalizedText("select_members")}
                </InputLabel>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      "&": {
                        zIndex: "2",
                        maxWidth: canHaveDetailedMemberList() ? "94%" : "100%",
                        width: canHaveDetailedMemberList() ? "94%" : "100%",
                      },
                      "& .select-item": {
                        padding: 0,
                      },
                      "& .select-item.selected": {
                        background: "none",
                      },
                      "& .select-item:hover": {
                        background: "none",
                      },
                    }}
                  >
                    <MultiSelect
                      hasSelectAll={true}
                      valueRenderer={valueRenderer}
                      ItemRenderer={
                        canHaveDetailedMemberList()
                          ? detailedOptionRenderer
                          : mutliSelectOptionRenderer
                      }
                      options={
                        canHaveDetailedMemberList()
                          ? detailedMembersList
                          : multiSelectMemberList
                      }
                      value={selectedMembers}
                      className={"global_filter_multi_select"}
                      onChange={(e) => {
                        setSelectedMembers(e);
                      }}
                      labelledBy="Select Members"
                    />
                  </Box>
                  {checkFeaturePermission("assign_leads_member_filter") && (
                    <IconButton
                      size="medium"
                      onClick={() => {
                        setFilterPopupOpen(true);
                      }}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <FilterAltIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
                <Box
                  component={"fieldset"}
                  sx={{
                    margin: "18px 0px",
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <legend
                        style={{
                          width: "auto",
                          marginBottom: "0px",
                          fontSize: "12px",
                        }}
                      >
                        Mark as Priority
                      </legend>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <InputLabel
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                            marginBottom: "4px",
                          }}
                        >
                          Process by
                        </InputLabel>

                        <Select
                          name="process_by"
                          displayEmpty
                          variant="outlined"
                          value={timeSlot}
                          onChange={(e) => {
                            setTimeSlot(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            font: "normal normal 500 12px Open Sans",
                            height: "30px",
                          }}
                        >
                          {timeSlots.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <InputWrapper>
                    <InputLabel
                      style={{
                        font: "normal normal 600 10px Open Sans",
                        color: "#4d4e4f",
                        marginBottom: "4px",
                      }}
                    >
                      Comments
                    </InputLabel>
                    <OutlinedInput
                      multiline={true}
                      rows={3}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={comments}
                      name={"comments"}
                      type={"text"}
                      placeholder={"Enter Value"}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </InputWrapper>
                </Box>
                {props.enableAssignAllLead && (
                  <FormControlLabel
                    disabled={
                      selectedMembers.length === 0 ||
                      props.totalLeadCount >
                        rootStore.userStore.leadsBulkAssignLimit
                    }
                    control={
                      <Checkbox
                        color="primary"
                        checked={canAssignAllLead}
                        onChange={(e) => {
                          setCanAssignAllLead(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <FlagText>{`Check this to Assign all ${
                        props.totalLeadCount
                      } ${getLocalizedText("lead", "project")}(s)`}</FlagText>
                    }
                  />
                )}
                {validateDisabledSubFeature("erase_history") && (
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label={
                      <FlagText>{getLocalizedText("erase_history")}</FlagText>
                    }
                    onChange={handleFlag}
                  />
                )}
                <ButtonWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Alert
                      style={{
                        padding: "0px 5px",
                        fontSize: "12px",
                        borderRadius: "4px 4px 4px 4px",
                      }}
                      severity="info"
                    >
                      {`A maximum of ${
                        rootStore.userStore.leadsBulkAssignLimit
                      } ${getLocalizedText(
                        "leads",
                        "project"
                      ).toLowerCase()} can be selected for this action.`}
                    </Alert>
                    {selectedMembers.length > 1 && (
                      <Alert
                        style={{
                          padding: "0px 5px",
                          fontSize: "12px",
                          borderRadius: "4px 4px 4px 4px",
                        }}
                        severity="info"
                      >
                        {getLocalizedText(
                          "leads_will_be_assigned_equally_to_selected_members",
                          "project"
                        )}
                      </Alert>
                    )}
                  </Box>
                  <RenderButton />
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          ) : (
            <Box
              style={{
                width: "100%",
                height: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
      {filterPopupOpen && (
        <MemberFilterModal
          open={filterPopupOpen}
          setOpen={setFilterPopupOpen}
          handleSubmit={handleFilterSubmit}
          selectedTeamsFilter={selectedTeamsFilter}
          selectedRolesFilter={selectedRolesFilter}
          setSelectedTeamsFilter={setSelectedTeamsFilter}
          setSelectedRolesFilter={setSelectedRolesFilter}
        />
      )}
    </>
  );
};

export default LeadsAssignModal;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { DatePicker } from "antd";
import { reportDownloadDateRanges } from "../../Db";
import {
  getLocalizedText,
  getLocalTimeStringFromIsoDateString,
  getStartEndDatesForReport,
  IsoToLocalDate,
} from "../../Functions";
import { getAdminUsersApi, downloadUsersAttendanceApi } from "../../Api";
import { MultiSelect } from "react-multi-select-component";
import CsvDownloader from "react-csv-downloader";
import moment from "moment";

const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const DownloadLink = styled("Typography")`
  font: normal normal normal 24px Open Sans;
  color: blue;
  text-decoration: underline;
  margin: 0px;
  height: fit-content;
  text-align: center;
  cursor: pointer;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AttendanceReportDownloadSidepanel = (props) => {
  const classes = useStyles();
  const { RangePicker } = DatePicker;
  const [details, setDetails] = useState({
    users: [],
  });
  const [customDate, setCustomDate] = useState(null);
  const [userOptionsList, setUserOptionsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [generatedReportProgress, setGeneratedReportProgress] = useState(false);
  const [disableGenerateReportButton, setDisableGenerateReportButton] =
    useState(false);
  const [data, setData] = useState(null);

  const csvColumns = [
    { id: "user", displayName: "USER" },
    { id: "phone", displayName: "PHONE" },
    { id: "date", displayName: "DATE" },
    { id: "attendance_in_time", displayName: "ATTENDANCE IN TIME" },
    { id: "attendance_out_time", displayName: "ATTENDANCE OUT TIME" },
    { id: "total_in_time", displayName: "TOTAL IN TIME" },
    { id: "status", displayName: "STATUS" },
  ];

  const [loading, setLoading] = useState(true);

  const handleChangeRangePicker = (range) => {
    setCustomDate(range);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setGeneratedReportProgress(true);
    setDisableGenerateReportButton(true);

    let response = await generateCsvData();

    let finalList = response.map((record) => {
      const totalMinutes = record.total_in_time || 0;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return {
        ...record,
        attendance_in_time: getLocalTimeStringFromIsoDateString(
          record.attendance_in_time
        ),
        attendance_out_time: getLocalTimeStringFromIsoDateString(
          record.attendance_out_time
        ),
        total_in_time: `${hours} hrs ${minutes} mins`,
      };
    });
    finalList.sort((a, b) => moment(a.date).diff(moment(b.date)));
    setData(finalList);
    setGeneratedReportProgress(false);
  };

  const presetOrder = ["owner", "admin", "member"];
  const getUsersList = async () => {
    try {
      let response = await getAdminUsersApi();
      let filteredList = response.data.filter(
        (obj) =>
          obj["active"] === true &&
          (obj["role"] === "member" || obj["role"] === "admin")
      );
      const map = new Map();
      presetOrder.forEach((x, i) => map.set(x, i));
      filteredList.sort((x, y) => map.get(x.role) - map.get(y.role));
      return filteredList;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const init = async () => {
    let tempUserList = await getUsersList();
    setUsersList(tempUserList);
    let newList = [];
    tempUserList.forEach((e) => {
      let newObj = { label: e.name, value: e.id };
      newList.push(newObj);
    });
    setUserOptionsList(newList);

    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const getUserByID = (id) => {
    return usersList.find((user) => user.id === id);
  };

  const generateCsvData = async () => {
    let userIDs = details["users"].map((user) => {
      return user.value;
    });

    let payload = {
      user_ids: userIDs,
    };

    if (customDate !== null) {
      payload["attendance_start_date"] = customDate[0].toISOString();
      payload["attendance_end_date"] = customDate[1].toISOString();
    } else {
      const dateRanges = getStartEndDatesForReport(details["date_filter"]);
      payload["attendance_start_date"] = dateRanges["start_date"];
      payload["attendance_end_date"] = dateRanges["end_date"];
    }

    const response = await downloadUsersAttendanceApi({
      payload: payload,
    });

    let formattedData = [];

    response.forEach((record) => {
      const {
        user,
        attendance_in_time,
        attendance_out_time,
        attendance_date,
        total_in_time,
      } = record;

      const date = moment(attendance_date).format("YYYY-MM-DD");

      if (attendance_in_time !== null && attendance_out_time !== null) {
        let inTime = attendance_in_time;
        let outTime = attendance_out_time;

        // Find by user.id and date
        const existingRecord = formattedData.find(
          (item) => item.userId === user.id && item.date === date
        );

        if (!existingRecord) {
          formattedData.push({
            userId: user.id,
            user: user.name,
            phone: getUserByID(user.id)["phone"] || "",
            date: IsoToLocalDate(attendance_date),
            attendance_in_time: inTime,
            attendance_out_time: outTime,
            total_in_time: total_in_time || 0,
            status: "Marked",
          });
        } else {
          const existingInTime = existingRecord.attendance_in_time;
          const existingOutTime = existingRecord.attendance_out_time;

          if (moment(inTime).isBefore(existingInTime)) {
            existingRecord.attendance_in_time = inTime;
          }

          if (moment(outTime).isAfter(existingOutTime)) {
            existingRecord.attendance_out_time = outTime;
          }
          existingRecord.total_in_time += total_in_time || 0;
        }
      }
    });

    return formattedData;
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getLocalizedText("download_report")}</ModelHeader>
          </Toolbar>
        </AppBar>
        <ModelWrapper
          component={"form"}
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 64px)",
            justifyContent: "space-between",
          }}
        >
          <InputContainer>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>{getLocalizedText("date_range")}*</Label>
                  <FormControl
                    sx={{ width: "100%" }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      disabled={disableGenerateReportButton}
                      classes={{
                        icon: classes.icon,
                      }}
                      displayEmpty
                      required
                      onChange={(e) => {
                        handleChange(e);
                        setCustomDate(null);
                      }}
                      disableUnderline
                      variant="outlined"
                      name="date_filter"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      {reportDownloadDateRanges.map((e, i) => {
                        return (
                          <MenuItem
                            key={i}
                            style={{ fontSize: "12px" }}
                            id={i}
                            value={e.value}
                          >
                            {e.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </Grid>
              {details.hasOwnProperty("date_filter") &&
                details["date_filter"] === "Custom Date Range" && (
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>{getLocalizedText("start_and_end_date")}*</Label>
                      <RangePicker
                        disabled={disableGenerateReportButton}
                        value={customDate}
                        placeholder={["From", "To"]}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        onChange={handleChangeRangePicker}
                        style={{
                          borderColor: "#bfbfbf",
                          width: "100%",
                          borderRadius: "0",
                          height: "30px",
                          borderShadow: "none",
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                )}

              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Users*</Label>
                  <FormControl
                    sx={{ width: "100%" }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <MultiSelect
                      disabled={disableGenerateReportButton}
                      options={userOptionsList}
                      value={details.users}
                      className={"chart_multi_select"}
                      onChange={(e) => {
                        setDetails({ ...details, users: e });
                      }}
                      labelledBy="Select User"
                    />
                  </FormControl>
                </InputWrapper>
              </Grid>
            </Grid>
          </InputContainer>

          <Box
            style={{
              padding: "0 30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 1,
              height: "100%",
            }}
          >
            {generatedReportProgress && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Message>
                  {getLocalizedText("generating_report_do_not_refresh")}
                </Message>
                <ProgressWrapper>
                  <CircularProgress />
                </ProgressWrapper>
              </Box>
            )}

            {data !== null && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Message>
                  {getLocalizedText("please_download_report_from_link_below")}
                </Message>
                <CsvDownloader
                  filename={`report_${Math.round(new Date() / 1000)}_${moment(
                    new Date()
                  ).format("YYYY-MM-DD")}`}
                  extension=".csv"
                  separator=","
                  wrapColumnChar='"'
                  columns={csvColumns}
                  datas={data}
                >
                  <DownloadLink>
                    {" "}
                    {`report_${Math.round(new Date() / 1000)}_${moment(
                      new Date()
                    ).format("YYYY-MM-DD")}`}
                  </DownloadLink>
                </CsvDownloader>
              </Box>
            )}
          </Box>
          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    disableGenerateReportButton || details["users"].length === 0
                  }
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor:
                      disableGenerateReportButton ||
                      details["users"].length === 0
                        ? null
                        : "#185DD2",
                    color: "white",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {getLocalizedText("generate_report")}
                </Button>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AttendanceReportDownloadSidepanel;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Drawer, Grid, makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { currencylist, dealPriorityList } from "../../Db";
import { createLeadDealApi, getPredefinedDealsApi } from "../../Api";
import { Percent } from "@mui/icons-material";
import { ExpandMore } from "@material-ui/icons";
import ViewProductSidepanel from "../view_product_sidepanel/ViewProductSidepanel";
import AddCustomDealTableComponent from "./table_component/AddCustomDealTableComponent";
import ViewCustomItemInfopanel from "./view_custom_item_infopanel/ViewCustomItemInfopanel";
import { getLocalizedText } from "../../Functions";
import { v4 as uuidv4 } from "uuid";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AddPredefinedDealSidepanel = (props) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    name: "",
    deal_value: "",
    cost_of_service: "",
    deal_value_currency: "INR",
    priority: "Medium",
    original_price: "",
    discount: "",
    discount_in_price: "",
    margin: "",
  });
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [discountType, setDiscountType] = useState("price");
  const [predefinedDealList, setPredefinedDealList] = useState([]);
  const [selectedPredefiendDeal, setSelectedPredefinedDeal] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    let tempDetails = { ...details };
    tempDetails[e.target.name] = e.target.value;
    setDetails(tempDetails);
  };

  const addPredefinedDeal = async () => {
    let rawData = { ...details };
    let payload = {
      name: rawData.name,
      deal_value_currency: rawData.deal_value_currency,
      priority: rawData.priority,
      margin: rawData.margin,
      discount_type: discountType,
    };

    payload["deal_value"] =
      rawData["deal_value"] !== null && rawData["deal_value"] !== ""
        ? parseInt(rawData["deal_value"])
        : 0;
    payload["original_price"] =
      rawData["original_price"] !== null && rawData["original_price"] !== ""
        ? parseInt(rawData["original_price"])
        : 0;
    payload["cost_of_service"] =
      rawData["cost_of_service"] !== null && rawData["cost_of_service"] !== ""
        ? parseInt(rawData["cost_of_service"])
        : 0;

    payload["discount"] =
      rawData["discount"] !== null && rawData["discount"] !== ""
        ? parseFloat(rawData["discount"])
        : 0;
    payload["discount_in_price"] =
      rawData["discount_in_price"] !== null &&
      rawData["discount_in_price"] !== ""
        ? parseFloat(rawData["discount_in_price"])
        : 0;

    let dealItems = [];
    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: productItem["rate"],
        amount: productItem["amount"],
        quantity: productItem["quantity"],
      };

      // discount and discount in price are float values - when we added line items
      tempObj["discount"] = productItem["discount"];
      tempObj["discount_in_price"] = productItem["discount_in_price"];
      tempObj["discount_type"] = productItem["discount_type"];
      if (productItem["product"]["type"] === "product") {
        tempObj["product_id"] = productItem["product"]["id"];
      } else {
        tempObj["item"] = productItem["product"]["name"];
        tempObj["type"] = productItem["product"]["type"];

        tempObj["desc"] = productItem["product"]["desc"];
      }
      dealItems.push(tempObj);
    });
    payload["deal_items"] = dealItems;
    let response = await createLeadDealApi({
      payload: payload,
      leadID: props.leadID,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addPredefinedDeal();
  };

  const getPredefinedDealList = async () => {
    let response = await getPredefinedDealsApi();
    setPredefinedDealList(response);
  };
  const init = async () => {
    await getPredefinedDealList();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const postSelectPredefinedDeal = (dealID) => {
    let index = predefinedDealList.findIndex((deal) => deal["id"] === dealID);
    if (index !== -1) {
      setSelectedPredefinedDeal(dealID);
      let data = predefinedDealList[index];
      let tempDealDetails = {
        name: data["name"],
        deal_value: data["deal_value"],
        deal_value_currency: data["deal_value_currency"],
        priority: data["priority"],
        cost_of_service:
          data.hasOwnProperty("cost_of_service") &&
          data["cost_of_service"] !== null
            ? data["cost_of_service"]
            : "",
        original_price:
          data.hasOwnProperty("original_price") &&
          data["original_price"] !== null
            ? data["original_price"]
            : "",
        discount:
          data.hasOwnProperty("discount") && data["discount"] !== null
            ? data["discount"]
            : "",
        discount_in_price:
          data.hasOwnProperty("discount_in_price") &&
          data["discount_in_price"] !== null
            ? data["discount_in_price"]
            : "",
        margin:
          data.hasOwnProperty("margin") && data["margin"] !== null
            ? data["margin"]
            : "",
      };
      let discountType =
        data.hasOwnProperty("discount_type") &&
        data["discount_type"] !== null &&
        data["discount_type"] !== ""
          ? data["discount_type"]
          : "price";
      setDiscountType(discountType);
      let tempProductList = [];
      if (
        data.hasOwnProperty("predefined_deal_items") &&
        data["predefined_deal_items"] !== null
      ) {
        data["predefined_deal_items"].forEach((productItem) => {
          let tempObj = {
            rate: productItem["rate"],
            amount: productItem["amount"],
            quantity: productItem["quantity"],
            discount:
              productItem.hasOwnProperty("discount") &&
              productItem["discount"] !== null
                ? productItem["discount"]
                : "",
            discount_in_price:
              productItem.hasOwnProperty("discount_in_price") &&
              productItem["discount_in_price"] !== null
                ? productItem["discount_in_price"]
                : "",
            discount_type:
              productItem.hasOwnProperty("discount_type") &&
              productItem["discount_type"] !== null
                ? productItem["discount_type"]
                : "",
          };
          if (productItem["product"] !== null) {
            tempObj["product"] = productItem["product"];
            tempObj["product"]["type"] = "product";
          } else {
            let tempProductData = {
              name: productItem["item"],
              id: uuidv4(),
              type: "custom_item",
            };
            tempObj["product"] = tempProductData;
          }
          tempProductList.push(tempObj);
        });
      }
      setProductAddedList(tempProductList);
      setDetails(tempDealDetails);
    }
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getLocalizedText("add_predefined_deal")}</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            <InputContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("predefined_deal")}*</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        required
                        onChange={(e) => {
                          postSelectPredefinedDeal(e.target.value);
                        }}
                        name="selected_predefined_deal"
                        disableUnderline
                        variant="outlined"
                        value={selectedPredefiendDeal}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "0px",
                        }}
                      >
                        {predefinedDealList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item.id}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("name")}*`}</Label>
                    <OutlinedInput
                      style={{ height: "30px", overflow: "hidden" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      disabled
                      onChange={handleChange}
                      name="name"
                      required
                      value={details["name"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <AddCustomDealTableComponent
                      canEdit={false}
                      tableData={productAddedList}
                      handleDelete={() => {}}
                      handleEdit={() => {}}
                      handleView={(selectedItem) => {
                        setSelectedProductData(selectedItem);

                        setSelectedItemType(selectedItem.product.type);

                        setOpenViewProductModal(true);
                      }}
                      handleAddCustomItem={() => {}}
                      handleAddProduct={() => {}}
                    />
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <InputWrapper>
                      <Label>{getLocalizedText("original_price")}</Label>
                      <OutlinedInput
                        style={{ height: "30px", overflow: "hidden" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        disabled
                        onChange={handleChange}
                        name="original_price"
                        value={details["original_price"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                    <InputWrapper style={{ width: "100px" }}>
                      <Label>{getLocalizedText("currency")}</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          required
                          disabled
                          onChange={handleChange}
                          name="deal_value_currency"
                          disableUnderline
                          variant="outlined"
                          value={details["deal_value_currency"]}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",

                            borderRadius: "0px",
                          }}
                        >
                          {currencylist.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.code}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item.code}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Label style={{ fontSize: "14px" }}>
                        {getLocalizedText("discount")}
                      </Label>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <InputWrapper>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              border: "1px solid #cccccc",
                              padding: "12px",
                              paddingTop: "0px",
                            }}
                          >
                            <FormControlLabel
                              disabled
                              value="price"
                              checked={discountType === "price"}
                              onChange={() => {
                                setDiscountType("price");
                              }}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  {getLocalizedText("price")}
                                </Label>
                              }
                            />
                            <OutlinedInput
                              style={{
                                height: "30px",
                                width: "100%",
                                overflow: "hidden",
                              }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Label
                                    style={{ fontSize: "12px", color: "gray" }}
                                  >
                                    {details["deal_value_currency"]}
                                  </Label>
                                </InputAdornment>
                              }
                              disabled
                              onChange={handleChange}
                              name="discount_in_price"
                              value={details["discount_in_price"]}
                              placeholder="Enter value"
                            />
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #cccccc",
                              padding: "12px",
                              paddingTop: "0px",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <FormControlLabel
                              disabled
                              onChange={() => {
                                setDiscountType("percentage");
                              }}
                              checked={discountType === "percentage"}
                              value="percentage"
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  {getLocalizedText("percentage")}
                                </Label>
                              }
                            />
                            <OutlinedInput
                              disabled
                              style={{
                                height: "30px",
                                width: "100%",
                                overflow: "hidden",
                              }}
                              inputProps={{
                                min: 0,
                                max: 100,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Percent
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      color: "gray",
                                    }}
                                  />
                                </InputAdornment>
                              }
                              onChange={handleChange}
                              name="discount"
                              value={details["discount"]}
                              placeholder="Enter value"
                            />
                          </Box>
                        </Box>
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("final_deal_value")}*`}</Label>
                    <OutlinedInput
                      style={{ height: "30px", overflow: "hidden" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      disabled
                      onChange={handleChange}
                      name="deal_value"
                      required
                      value={details["deal_value"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>
                      {getLocalizedText("cost_of_service_or_product")}
                    </Label>
                    <OutlinedInput
                      style={{ height: "30px", overflow: "hidden" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      disabled
                      onChange={handleChange}
                      name="cost_of_service"
                      value={details["cost_of_service"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("margin")}</Label>
                    <Box
                      sx={{
                        border: "1px solid #BDBDBD",
                        padding: "6px 12px",
                        height: "30px",
                        borderRadius: "4px",
                      }}
                    >
                      <Label style={{ fontSize: "12px" }}>
                        {details["margin"]}
                      </Label>
                    </Box>
                  </InputWrapper>
                </Grid>

                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("priority")}*`}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        required
                        disabled
                        onChange={handleChange}
                        name="priority"
                        disableUnderline
                        variant="outlined"
                        value={details["priority"]}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "0px",
                        }}
                      >
                        {dealPriorityList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
              </Grid>
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#185DD2",
                      color: "white",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>
              {getLocalizedText("predefined_deal_added_successfully")}
            </Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openViewProductModal &&
        (selectedItemType === "product" ? (
          <Drawer
            disableEnforceFocus
            anchor={"right"}
            open={openViewProductModal}
            onClose={() => {
              setSelectedProductData({});
              setOpenViewProductModal(false);
            }}
          >
            <ViewProductSidepanel
              productID={selectedProductData["product"]["id"]}
              setOpen={setOpenViewProductModal}
              open={openViewProductModal}
            />
          </Drawer>
        ) : (
          <Drawer
            disableEnforceFocus
            anchor={"right"}
            open={openViewProductModal}
            onClose={() => {
              setSelectedProductData({});
              setOpenViewProductModal(false);
            }}
          >
            <ViewCustomItemInfopanel details={selectedProductData} />
          </Drawer>
        ))}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddPredefinedDealSidepanel;

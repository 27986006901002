import React, { useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { addStageApi, editStageApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import ColorPickerModal from "../color_picker_modal/ColorPickerModal";
import { Rectangle } from "@mui/icons-material";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
import { Slider, Typography } from "@mui/material";
import { colorsList } from "../../Config";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import CustomCheckboxHeader from "../custom_tooltip_headers/CustomCheckboxHeader";
import { MultiSelect } from "react-multi-select-component";
import rootStore from "../../stores/RootStore";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableUpdateFieldChipComponent from "./draggable_chip_component/DraggableUpdateFieldChipComponent";
import { Add } from "@material-ui/icons";
import AddUpdateFieldDialog from "./AddUpdateFieldDialog";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const Row = styled(Box)`
  display: flex;
  width: 420px;
  column-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  margin-bottom: 6px;
  white-space: nowrap;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;

const probabilityList = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 100,
    label: "100%",
  },
];
const defaultColor = "#4d4e4f";
const AddStageModal = (props) => {
  const statusList = [...rootStore.authStore.statusList];
  const [color, setColor] = useState("#4d4e4f");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [user, setUser] = useState({
    stage: "",
    mark_as_won: "No",
    mark_as_lost: "No",
    mark_as_closed: "No",
    probability: 0,
    rotting_in: "",
    rank: null,
  });
  const [flag, setFlag] = useState(false);
  const [isDeleteProtected, setIsDeleteProtect] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedColumnList, setSelectedColumnList] = useState({});
  const [columnsList, setColumnsList] = useState([]);
  const [openAddUpdateFieldDialog, setOpenAddUpdateFieldDialog] =
    useState(false);

  const handleClick = () => {
    setUser({ stage: "" });
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const editStage = async () => {
      let data = { stage: user.stage, stage_id: props.editableData.id };
      data["mark_as_won"] = user.mark_as_won === "Yes" ? true : false;
      data["mark_as_lost"] = user.mark_as_lost === "Yes" ? true : false;
      data["mark_as_closed"] = user.mark_as_closed === "Yes" ? true : false;
      data["probability"] = user.probability;
      data["is_visible"] = flag;
      data["delete_protected"] = isDeleteProtected;
      if (isStageMarked()) {
        data["rotting_in"] = null;
      } else {
        data["rotting_in"] =
          user["rotting_in"] !== "" ? parseInt(user["rotting_in"]) : null;
      }
      if (color !== "") {
        data["color"] = color;
      }
      data["rank"] = null;
      if (!flag) {
        data["rank"] = null;
      } else {
        data["rank"] =
          user.rank === "" || user.rank === null ? null : parseInt(user.rank);
      }
      let tempAvailableStatusesIDs = [];
      selectedStatuses.forEach((obj) => {
        tempAvailableStatusesIDs.push(obj["value"]);
      });
      data["available_statuses"] = tempAvailableStatusesIDs;
      let tempList = [];
      Object.keys(selectedColumnList).forEach((key) => {
        let tempObj = {
          id: key,
          required: selectedColumnList[key]["required"],
          overwrite: selectedColumnList[key]["overwrite"],
          append: selectedColumnList[key]["append"],
        };
        tempList.push(tempObj);
      });
      data["update_fields"] = tempList;
      try {
        let response = await editStageApi({
          id: props.id,
          data: data,
        });
        setUser({
          name: "",
        });
        props.setIsEdit(false);
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    const addStage = async () => {
      let data = { stage: user.stage };
      data["mark_as_won"] = user.mark_as_won === "Yes" ? true : false;
      data["mark_as_lost"] = user.mark_as_lost === "Yes" ? true : false;
      data["mark_as_closed"] = user.mark_as_closed === "Yes" ? true : false;
      data["probability"] = user.probability;
      data["delete_protected"] = isDeleteProtected;
      if (isStageMarked()) {
        data["rotting_in"] = null;
      } else {
        data["rotting_in"] =
          user["rotting_in"] !== "" ? parseInt(user["rotting_in"]) : null;
      }
      if (color !== "") {
        data["color"] = color;
      }
      data["rank"] = null;
      if (!flag) {
        data["rank"] = null;
      } else {
        data["rank"] =
          user.rank === "" || user.rank === null ? null : parseInt(user.rank);
      }
      let tempAvailableStatusesIDs = [];
      selectedStatuses.forEach((obj) => {
        tempAvailableStatusesIDs.push(obj["value"]);
      });
      data["available_statuses"] = tempAvailableStatusesIDs;
      let tempList = [];
      Object.keys(selectedColumnList).forEach((key) => {
        let tempObj = {
          id: key,
          required: selectedColumnList[key]["required"],
          overwrite: selectedColumnList[key]["overwrite"],
          append: selectedColumnList[key]["append"],
        };
        tempList.push(tempObj);
      });
      data["update_fields"] = tempList;
      try {
        let response = await addStageApi({
          id: props.id,
          data: data,
        });
        setUser({
          name: "",
        });
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.isEdit) {
      editStage();
    } else {
      addStage();
    }
  };
  const handleClose = () => {
    setUser({ stage: "" });
    props.setOpen(false);
    props.setIsEdit(false);
  };

  const handleFlag = (e) => {
    setFlag(e.target.checked);
  };

  const moveChip = useCallback(
    (dragKey, hoverKey) => {
      // Create a shallow copy of the selectedColumnList object
      const updatedChips = { ...selectedColumnList };

      // Convert the object into an array of [key, value] pairs
      const entries = Object.entries(updatedChips);

      // Find the index positions of the drag and hover keys
      const dragIndex = entries.findIndex(([key]) => key === dragKey);
      const hoverIndex = entries.findIndex(([key]) => key === hoverKey);

      // Remove the dragged entry from the array
      const [removed] = entries.splice(dragIndex, 1);

      // Insert the removed entry at the hover index
      entries.splice(hoverIndex, 0, removed);

      // Convert the array of entries back into an object
      const reorderedChips = Object.fromEntries(entries);

      // Update the selectedColumnList state with the reordered object
      setSelectedColumnList(reorderedChips);
    },
    [selectedColumnList]
  );

  const getUniqueColorCodeListLength = () => {
    const uniqueColors = new Set(
      props.stageList
        .map((stage) => stage.color)
        .filter((color) => color !== null)
    );
    return Array.from(uniqueColors).length;
  };

  const setupDefaultColor = () => {
    const index = getUniqueColorCodeListLength();
    const resulatantColor =
      index > colorsList.length ? defaultColor : colorsList[index];
    setColor(resulatantColor);
  };

  useEffect(() => {
    //filtering out contact_column, parent and child columns
    let filteredColumns = [...rootStore.userStore.AllColumnsList].filter(
      (column) =>
        !column["derived"] &&
        column["editable"] &&
        (column["contact_column"] === null ||
          column["contact_column"] === "") &&
        !column["parent"] &&
        column["child_of"] === null
    );
    filteredColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnsList(filteredColumns);
    if (props.isEdit) {
      analytics.triggerEvent(
        4625000204,
        "edit_stage_form_load",
        "project_stages_page",
        "",
        {}
      );
      setUser({
        stage: props.editableData.stage,
        mark_as_won: props.editableData.mark_as_won === true ? "Yes" : "No",
        mark_as_lost: props.editableData.mark_as_lost === true ? "Yes" : "No",
        mark_as_closed:
          props.editableData.mark_as_closed === true ? "Yes" : "No",
        probability:
          props.editableData.probability !== null &&
          props.editableData.probability !== ""
            ? props.editableData.probability
            : 0,
        rotting_in:
          props.editableData.hasOwnProperty("rotting_in") &&
          props.editableData.rotting_in !== null
            ? props.editableData.rotting_in
            : "",
        rank:
          props.editableData.hasOwnProperty("rank") &&
          props.editableData.rank !== ""
            ? props.editableData.rank
            : null,
      });
      setFlag(props.editableData.is_visible);
      setIsDeleteProtect(props.editableData.delete_protected);
      if (props.editableData.color !== null) {
        setColor(props.editableData.color);
      }

      let tempAvailableStatusesIDs =
        props.editableData.hasOwnProperty("available_statuses") &&
        props.editableData["available_statuses"] !== null
          ? props.editableData["available_statuses"]
          : [];
      let tempSelectedAvailableStatuses = [];
      tempAvailableStatusesIDs.forEach((id) => {
        let index = statusList.findIndex((obj) => obj["id"] === id);
        if (index !== -1) {
          let statusData = statusList[index];
          let tempObj = {
            label: statusData["status"],
            value: statusData["id"],
          };
          tempSelectedAvailableStatuses.push(tempObj);
        }
      });
      setSelectedStatuses(tempSelectedAvailableStatuses);

      if (
        props.editableData.hasOwnProperty("update_fields") &&
        props.editableData.update_fields.length > 0
      ) {
        let tempList = {};
        props.editableData.update_fields.forEach((item) => {
          let index = filteredColumns.findIndex(
            (column) => column["id"] === item["column_meta"]["id"]
          );
          if (index !== -1) {
            tempList[item["column_meta"]["id"]] = {
              label: filteredColumns[index]["headerName"],
              required: item["required"],
              overwrite: item["overwrite"],
              append: item.hasOwnProperty("append") ? item["append"] : false,
            };
          }
        });
        setSelectedColumnList(tempList);
      }
    } else {
      analytics.triggerEvent(
        4625000200,
        "add_stage_form_load",
        "project_stages_page",
        "",
        {}
      );
      setupDefaultColor();
    }
  }, []);

  const canEditField = () => {
    if (props.isEdit === true) {
      if (props.editableData["default"] === true) {
        return false;
      }
    }
    return true;
  };

  const isRottingFieldDisabled = () => {
    if (isStageMarked()) {
      return true;
    } else {
      if (props.isEdit === true) {
        if (props.editableData["default"] === true) {
          return true;
        }
      }
    }

    return false;
  };

  const isStageMarked = () => {
    return (
      user.mark_as_won === "Yes" ||
      user.mark_as_closed === "Yes" ||
      user.mark_as_lost === "Yes"
    );
  };

  const getStatusesOptions = () => {
    return statusList.map((status) => ({
      label: status["status"],
      value: status["id"],
    }));
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {/* <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            /> */}
            <Container>
              {props.isEdit ? (
                <Header>{getLocalizedText("edit_details")}</Header>
              ) : (
                <Header>{getLocalizedText("add_a_new_stage")}</Header>
              )}
              <Form component={"form"} onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    height: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Grid item xs={12}>
                    <Row>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={getLocalizedText("stage_name")}
                          tooltip={getLocalizedText(
                            "enter_the_new_lead_stage",
                            "project"
                          )}
                          required={true}
                          textStyle={{
                            color: !canEditField() ? "#bdbdbd" : "#4d4e4f",
                          }}
                        />
                        <Input
                          value={user.stage}
                          required
                          type="text"
                          placeholder="Enter name here..."
                          name="stage"
                          onChange={handleChange}
                          disabled={!canEditField()}
                        />
                      </InputWrapper>
                      <InputWrapper
                        style={{
                          width: "fit-content",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <Label>Select Color</Label> */}
                        <Rectangle
                          style={{
                            color: color,
                            cursor: "pointer",
                            width: "45px",
                            height: "auto",
                            marginBottom: "-7px",
                          }}
                          onClick={() => {
                            setOpenColorPicker(true);
                          }}
                        />
                      </InputWrapper>
                    </Row>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Rank"}
                        tooltip={"Set the rank for stage."}
                      />
                      <Input
                        value={user.rank}
                        type="number"
                        placeholder="Enter value"
                        name="rank"
                        onChange={handleChange}
                        disabled={!canEditField()}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={4}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Mark As Won"}
                        tooltip={getLocalizedText(
                          "indicate_whether_the_lead_stage_marked_as_won",
                          "project"
                        )}
                        textStyle={{
                          color: !canEditField() ? "#bdbdbd" : "#4d4e4f",
                        }}
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={user.mark_as_won}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            mark_as_won: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={
                            <Radio
                              disabled={
                                !canEditField() ||
                                user.mark_as_closed === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={
                            <Radio
                              disabled={
                                !canEditField() ||
                                user.mark_as_closed === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={4}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Mark As Lost"}
                        tooltip={getLocalizedText(
                          "indicate_whether_the_lead_stage_marked_as_lost",
                          "project"
                        )}
                        textStyle={{
                          color: !canEditField() ? "#bdbdbd" : "#4d4e4f",
                        }}
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={user.mark_as_lost}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            mark_as_lost: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={
                            <Radio
                              disabled={
                                !canEditField() ||
                                user.mark_as_won === "Yes" ||
                                user.mark_as_closed === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={
                            <Radio
                              disabled={
                                !canEditField() ||
                                user.mark_as_won === "Yes" ||
                                user.mark_as_closed === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={4}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Mark As Closed"}
                        tooltip={getLocalizedText(
                          "indicate_whether_the_lead_Stage_marked_as_closed",
                          "project"
                        )}
                        textStyle={{
                          color: !canEditField() ? "#bdbdbd" : "#4d4e4f",
                        }}
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={user.mark_as_closed}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            mark_as_closed: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={
                            <Radio
                              disabled={
                                !canEditField() ||
                                user.mark_as_won === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={
                            <Radio
                              disabled={
                                !canEditField() ||
                                user.mark_as_won === "Yes" ||
                                user.mark_as_lost === "Yes"
                              }
                              size="small"
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                  {/* <Grid item>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Probability"}
                        tooltip={""}
                        textStyle={{
                          color: !canEditField() ? "#bdbdbd" : "#4d4e4f",
                        }}
                      />
                      <Slider
                        disabled={!canEditField()}
                        style={{ width: "410px" }}
                        aria-label="Custom marks"
                        step={10}
                        value={user.probability}
                        valueLabelDisplay="auto"
                        marks={probabilityList}
                        onChange={(e, value) => {
                          setUser({ ...user, probability: value });
                        }}
                      />
                    </InputWrapper>
                  </Grid> */}
                  {props.isEdit && (
                    <Grid item xs={4}>
                      <FormControlLabel
                        disabled={!canEditField()}
                        control={<Checkbox checked={flag} color="primary" />}
                        label={
                          <CustomCheckboxHeader
                            header={getLocalizedText("visible")}
                            tooltip={
                              "Specify if the stage should be displayed in listing and details."
                            }
                          />
                        }
                        onChange={handleFlag}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isDeleteProtected} color="primary" />
                      }
                      label={
                        <CustomCheckboxHeader
                          header={"Delete Protection"}
                          tooltip={getLocalizedText(
                            "check_to_prevent_lead_delete_with_this_stage",
                            "project"
                          )}
                        />
                      }
                      onChange={(e) => {
                        setIsDeleteProtect(e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Rotting in (days)"}
                        tooltip={getLocalizedText(
                          "set_number_of_days_before_lead_considered_rotten_in_this_stage",
                          "project"
                        )}
                        textStyle={{
                          color: isRottingFieldDisabled()
                            ? "#bdbdbd"
                            : "#4d4e4f",
                        }}
                      />
                      <Input
                        min={0}
                        value={user.rotting_in}
                        type="number"
                        placeholder="Enter value"
                        name="rotting_in"
                        onChange={handleChange}
                        disabled={isRottingFieldDisabled()}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={"Available Statuses"}
                        tooltip={
                          "Indicate what all statuses should be shown for this stage."
                        }
                        textStyle={{
                          color: !canEditField() ? "#bdbdbd" : "#4d4e4f",
                        }}
                      />
                      <Box
                        sx={{
                          "&": {
                            width: "100%",
                            fontSize: "12px",
                            border: "none",
                            alignItems: "center",
                            color: "black",
                            zIndex: "9999",
                          },
                          "& .dropdown-heading": {
                            height: "30px",
                          },
                          "& .options": {
                            maxHeight: "150px",
                          },
                        }}
                      >
                        <MultiSelect
                          disabled={!canEditField()}
                          options={getStatusesOptions()}
                          value={selectedStatuses}
                          onChange={(selected) => setSelectedStatuses(selected)}
                        />
                      </Box>
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      component={"fieldset"}
                      sx={{ border: "1px solid #cccccc", padding: "12px" }}
                    >
                      <legend
                        style={{
                          width: "auto",
                          marginBottom: "0px",
                          fontSize: "16px",
                        }}
                      >
                        Fields to be Updated
                      </legend>

                      {Object.keys(selectedColumnList).length > 0 && (
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={2}
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <DndProvider backend={HTML5Backend}>
                            {Object.keys(selectedColumnList).map(
                              (key, index) => {
                                return (
                                  <Grid item key={key}>
                                    <DraggableUpdateFieldChipComponent
                                      handleDelete={() => {
                                        const filteredColumnList = {
                                          ...selectedColumnList,
                                        };
                                        delete filteredColumnList[key];
                                        setSelectedColumnList(
                                          filteredColumnList
                                        );
                                      }}
                                      chipKey={key}
                                      index={index}
                                      isDisabled={!canEditField()}
                                      isRequired={
                                        selectedColumnList[key]["required"]
                                      }
                                      label={selectedColumnList[key]["label"]}
                                      moveChip={moveChip}
                                    />
                                  </Grid>
                                );
                              }
                            )}
                          </DndProvider>
                        </Grid>
                      )}
                      <Button
                        disabled={!canEditField()}
                        style={{
                          textTransform: "none",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                        color="primary"
                        variant="text"
                        onClick={() => {
                          setOpenAddUpdateFieldDialog(true);
                        }}
                      >
                        <Add /> Add Update Field
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000205,
                          "cancel_button_tap",
                          "edit_stage_form",
                          "cancel_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000201,
                          "cancel_button_tap",
                          "add_stage_form",
                          "cancel_button",
                          {}
                        );
                      }
                      handleClick();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000206,
                          "submit_button_tap",
                          "edit_stage_form",
                          "submit_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000202,
                          "submit_button_tap",
                          "add_stage_form",
                          "submit_button",
                          {}
                        );
                      }
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      {openColorPicker && (
        <ColorPickerModal
          color={color}
          setColor={setColor}
          open={openColorPicker}
          setOpen={setOpenColorPicker}
        />
      )}
      {openAddUpdateFieldDialog && (
        <AddUpdateFieldDialog
          open={openAddUpdateFieldDialog}
          canEditField={canEditField}
          handlePostAddUpdateField={(updateFieldData) => {
            if (
              !selectedColumnList.hasOwnProperty(updateFieldData["column_id"])
            ) {
              let index = columnsList.findIndex(
                (column) => column["id"] === updateFieldData["column_id"]
              );
              selectedColumnList[updateFieldData["column_id"]] = {
                label: columnsList[index]["headerName"],
                required: updateFieldData["is_required"],
                overwrite: updateFieldData["is_overwrite"],
                append: updateFieldData["is_append"],
              };
            }
          }}
          selectedColumnList={selectedColumnList}
          setOpen={setOpenAddUpdateFieldDialog}
          columnsList={columnsList}
        />
      )}
    </>
  );
};

export default observer(AddStageModal);

import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  Button,
  Modal,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { myTheme } from "../../../../themeUtils";
import React, { useState, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { consoleLogger, getLocalizedText } from "../../../../Functions";
import { createWhatsappBAApi, updateWhatsappBAApi } from "../../../../Api";
import MuiAlert from "@mui/material/Alert";
import { Grid, FormControl, Typography, OutlinedInput } from "@mui/material";
import { businessWhatsappProviderData } from "../../../../Db";
import rootStore from "../../../../stores/RootStore";

const dialogWidth = 600;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const WhatsappProviderDialog = ({
  open,
  setOpen,
  currentProviderData,
  formData,
  setRefresh,
  setRefreshType,
  isEdit,
  setIsEdit,
}) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    name: "",
    ep: "",
    api_key: "",
    project_id: "",
  });
  const projectList = [...rootStore.authStore.projectList];
  const [errorMessage, setErrorMessage] = useState("");
  const [providerData, setProviderData] = useState({});
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const init = () => {
    if (isEdit) {
      setProviderData(currentProviderData);
      if (
        currentProviderData?.xdata !== null &&
        currentProviderData?.xdata !== undefined
      ) {
        if (currentProviderData["provider_name"] === "Meta WABA") {
          setDetails((prevDetails) => ({
            ...prevDetails,
            ...currentProviderData.xdata,
            api_key: "*************************",
            ep: currentProviderData?.ep ?? "",
            name: currentProviderData.name ? currentProviderData.name : "",
            waba_account_id: currentProviderData.waba_account_id,
            waba_display_number: currentProviderData.waba_display_number,
            waba_phone_number_id: currentProviderData.waba_phone_number_id,
            project_id:
              currentProviderData.project !== null
                ? currentProviderData.project.id
                : "",
          }));
        }
      } else {
        setDetails((prevDetails) => ({
          ...prevDetails,
          ...currentProviderData.xdata,
          name: currentProviderData.name ? currentProviderData.name : "",
          api_key: "*************************",
          ep: currentProviderData?.ep ?? "",
        }));
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const isProjectMenuItemDisabled = (projectID) => {
    let isDisabled = false;

    formData.forEach((item) => {
      if (item.hasOwnProperty("project")) {
        if (item.project.id === projectID) {
          isDisabled = true;
        }
      }
    });

    return isDisabled;
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {};
    let xdata = {};
    let response = {};

    payload["xdata"] = xdata;
    if (providerData["provider_name"] === "Meta WABA") {
      payload["waba_account_id"] = details["waba_account_id"];
      payload["waba_phone_number_id"] = details["waba_phone_number_id"];
      payload["waba_display_number"] = details["waba_display_number"];
      if (details["project_id"] !== "") {
        payload["project_id"] = details["project_id"];
      }
    } else {
      payload["ep"] = details["ep"];
      Object.keys(details).forEach((key) => {
        if (details[key] !== "") {
          if (key !== "api_key" && key !== "ep") {
            xdata[key] = details[key];
          }
        }
      });
    }
    const index = businessWhatsappProviderData.findIndex((x) => {
      return x.provider_name === providerData["provider_name"];
    });

    if (index !== 1) {
      payload["provider_name"] = businessWhatsappProviderData[index]["name"];
    }
    payload["name"] = details["name"];

    consoleLogger("payload:: ", payload);
    if (isEdit) {
      response = await updateWhatsappBAApi({
        wabaID: currentProviderData["id"],
        payload: payload,
      });
    } else {
      payload["api_key"] = details["api_key"];
      response = await createWhatsappBAApi({ payload: payload });
    }

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setIsSubmitSuccess(true);
      setRefreshType("whatsapp");
      setRefresh(true);
    }
  };

  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };

  const renderFormFields = () => {
    if (providerData["provider_name"] === "Carpus") {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("url")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="ep"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.ep || ""}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.api_key || ""}
                disabled={isEdit}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("from_number")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="from"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.from || ""}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    } else if (providerData["provider_name"] === "Meta WABA") {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.api_key || ""}
                disabled={isEdit}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("whatsapp_business_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="waba_account_id"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.waba_account_id || ""}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("whatsapp_phone_number_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="waba_phone_number_id"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.waba_phone_number_id || ""}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("display_phone_number")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="waba_display_number"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.waba_display_number || ""}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>Project</Label>
            <FormControl sx={{ width: "100%" }}>
              <Select
                classes={{
                  icon: classes.icon,
                }}
                name="project_id"
                displayEmpty
                variant="outlined"
                value={details.project_id || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "0px",
                }}
              >
                {projectList.map((project, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={project["id"]}
                      disabled={isProjectMenuItemDisabled(project.id)}
                    >
                      {project["name"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </>
      );
    } else if (providerData["provider_name"] === "Textlocal") {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("url")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="ep"
                value={details.ep || ""}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "url",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                value={details.api_key}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "api_key",
                  style: { fontSize: "12px" },
                }}
                disabled={isEdit}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("application_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="application_id"
                value={details.application_id}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "application_id",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    } else {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("url")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="ep"
                value={details.ep || ""}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "url",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                value={details.api_key}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "api_key",
                  style: { fontSize: "12px" },
                }}
                disabled={isEdit}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("application_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="application_id"
                value={details.application_id}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "application_id",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    }
  };

  const renderInputField = (labelKey, fieldName) => (
    <InputWrapper>
      <Label>{getLocalizedText(labelKey)}*</Label>
      <FormControl sx={{ width: "100%" }}>
        <OutlinedInput
          placeholder="Enter value"
          required
          onChange={handleChange}
          name={fieldName}
          value={details[fieldName] || ""}
          style={{ height: "30px" }}
          inputProps={{
            "aria-label": fieldName,
            style: { fontSize: "12px" },
          }}
        />
      </FormControl>
    </InputWrapper>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!isSubmitSuccess ? (
        <ModelContainer>
          <Box component="form" onSubmit={handleSubmit}>
            <AppBar
              component="nav"
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{ top: 0, bottom: "auto", width: "100%", right: 0 }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <HeaderWrapper>
                    <Header>
                      {isEdit
                        ? providerData["provider_name"]
                        : getLocalizedText("whatsapp_configuration")}
                    </Header>
                    <SubHeader>
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Box>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <InputWrapper>
                <Label>{getLocalizedText("title")}*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    placeholder="Enter value"
                    required
                    onChange={handleChange}
                    name="name"
                    style={{ height: "30px" }}
                    inputProps={{
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={details.name || ""}
                  />
                </FormControl>
              </InputWrapper>
              {!isEdit && (
                <InputWrapper>
                  <Label>{getLocalizedText("provider")}*</Label>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      classes={{
                        icon: classes.icon,
                      }}
                      displayEmpty
                      required
                      name="provider_name"
                      onChange={(e) => {
                        setProviderData(e.target.value);
                      }}
                      value={providerData}
                      disableUnderline
                      variant="outlined"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        borderRadius: "0px",
                      }}
                    >
                      {businessWhatsappProviderData.map((item, i) => {
                        return (
                          <MenuItem
                            value={item}
                            key={i}
                            style={{ fontSize: "12px" }}
                            disabled={item.disabled}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              )}
              {renderFormFields()}
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      ) : (
        <ModelContainer
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
            <Message>Saved successfully!</Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        </ModelContainer>
      )}
    </Modal>
  );
};

export default WhatsappProviderDialog;

import * as React from "react";
import Table from "@mui/material/Table";
import { Box, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { dataRenderer } from "../../../../Functions";

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "8px 12px",
  whiteSpace: "nowrap",
}));
const TableDataCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "8px 12px",
  whiteSpace: "nowrap",
}));
const TableItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "600",
  padding: 0,
}));
const TableSubText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  padding: "2px 0px",
}));

export default function TableComponentAreaBased({ tableData = [] }) {
  const shouldShowTax = () => {
    return tableData.some(
      (item) =>
        item.tax !== null &&
        item.tax !== undefined &&
        item.tax !== 0 &&
        item.tax !== ""
    );
  };

  const canShowSizeRelatedCols = () => {
    return tableData.some(
      (item) =>
        item.width &&
        item.height &&
        item.width !== 0 &&
        item.height !== 0 &&
        item.width !== "" &&
        item.height !== ""
    );
  };

  const checkIfRowHasSize = (row) => {
    return (
      row.width &&
      row.height &&
      row.width !== 0 &&
      row.height !== 0 &&
      row.width !== "" &&
      row.height !== ""
    );
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        height: "calc(100vh - 576px)",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px", // Customize scrollbar width
          height: "6px", // Customize scrollbar height for horizontal scroll
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Color of the scrollbar thumb
          borderRadius: "10px", // Roundness of the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Color of the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
        },
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Item & Description</TableHeaderCell>
            {canShowSizeRelatedCols() && (
              <TableHeaderCell align="right">Size (W x H)</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Unit</TableHeaderCell>
            {canShowSizeRelatedCols() && (
              <TableHeaderCell align="right">In Units</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Qty</TableHeaderCell>
            {canShowSizeRelatedCols() && (
              <TableHeaderCell align="right">Total Units</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            {shouldShowTax() && (
              <TableHeaderCell align="right">Tax (%)</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Amount</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  <TableItemTitle>{row["name"]}</TableItemTitle>
                  {row["desc"]}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {Array.isArray(row["additional_details"]) &&
                      row["additional_details"].map((detail, index) => (
                        <TableSubText
                          key={index}
                          style={{
                            textAlign: "left",
                            border: 0,
                          }}
                        >
                          • {dataRenderer(detail.field)}:{" "}
                          {dataRenderer(detail.value)}
                        </TableSubText>
                      ))}
                  </Box>
                </TableDataCell>
                {canShowSizeRelatedCols() ? (
                  <>
                    <TableDataCell align="right">
                      {checkIfRowHasSize(row)
                        ? row["width"] + " x " + row["height"]
                        : "-"}
                    </TableDataCell>
                    <TableDataCell align="right">{row["unit"]}</TableDataCell>

                    <TableDataCell align="right">
                      {row["in_unit"]}
                    </TableDataCell>
                  </>
                ) : (
                  <TableDataCell align="right">{row["unit"]}</TableDataCell>
                )}
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                {canShowSizeRelatedCols() && (
                  <TableDataCell align="right">
                    {row["total_unit"]}
                  </TableDataCell>
                )}
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                {shouldShowTax() && (
                  <TableDataCell align="right">
                    {dataRenderer(row["tax"])}
                  </TableDataCell>
                )}
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell
                style={{ textAlign: "center", height: "calc(100vh - 634px)" }}
                colSpan={6}
              >
                no items.
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

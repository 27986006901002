import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Button, Chip, Toolbar, Typography, Tooltip } from "@mui/material";
import { Grid } from "@material-ui/core";
import { dataRenderer } from "../../../Functions";
import { Circle, Rectangle } from "@mui/icons-material";
import rootStore from "../../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;

const ViewAdminStageSidepanel = ({ details, handleEdit }) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  const columns = [
    {
      field: "column",
      headerName: "COLUMN",
      minWidth: 200,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.column_meta.name);
        return (
          <Tooltip>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "overwrite",
      headerName: "OVERWRITE",
      width: 120,

      cellRenderer: (params) => {
        if (params.data.overwrite === null) {
          return "-";
        } else {
          if (params.data.overwrite === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        }
      },
    },
    {
      field: "append",
      headerName: "APPEND",
      width: 100,

      cellRenderer: (params) => {
        if (
          params.data.hasOwnProperty("append") &&
          params.data.append !== null
        ) {
          if (params.data.append === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "required",
      headerName: "REQUIRED",
      width: 120,
      cellRenderer: (params) => {
        if (params.data.required === null) {
          return "-";
        } else {
          if (params.data.required === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        }
      },
    },
  ];

  const statusList = [...rootStore.authStore.statusList];
  const renderBoolValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      if (value === false) {
        return (
          <Chip color="warning" label={"No"} style={{ width: "fit-content" }} />
        );
      } else {
        return (
          <Chip
            color="success"
            label={"Yes"}
            style={{ width: "fit-content" }}
          />
        );
      }
    }
    return "-";
  };

  const renderListOfStatatuses = (idList) => {
    if (idList.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {idList.map((id, idx) => {
            let index = statusList.findIndex((status) => status["id"] === id);
            if (index !== -1) {
              let statusData = statusList[index];
              return (
                <Box sx={{ display: "flex", columnGap: "8px" }} key={idx}>
                  <Circle
                    style={{
                      width: "8px",
                      height: "8px",
                      marginTop: "6px",
                      color: "gray",
                    }}
                  />
                  <Detail>{statusData["status"]}</Detail>
                </Box>
              );
            }
          })}
        </Box>
      );
    }
    return <Detail>-</Detail>;
  };

  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <ModelHeader>Stage Details</ModelHeader>
            <Button
              style={{
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "bold",
                width: "fit-content",
              }}
              onClick={handleEdit}
              color="primary"
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Stage</Label>
              <Detail>{dataRenderer(details["stage"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Color</Label>
              {details["color"] !== null && details["color"] !== "" ? (
                <Rectangle
                  style={{
                    color: details["color"],
                    cursor: "pointer",
                    width: "30px",
                    height: "auto",
                  }}
                />
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Mark As Won</Label>
              {renderBoolValue(details["mark_as_won"])}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Mark As Lost</Label>
              {renderBoolValue(details["mark_as_lost"])}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Mark As Closed</Label>
              {renderBoolValue(details["mark_as_closed"])}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Delete Protection</Label>
              {renderBoolValue(details["delete_protected"])}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Rotting in (days)</Label>
              <Detail>{dataRenderer(details["rotting_in"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Available Statuses</Label>

              {details["available_statuses"] !== null
                ? renderListOfStatatuses(details["available_statuses"])
                : "-"}
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SectionHeader>Update Fields</SectionHeader>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={
                    details.update_fields !== null ? details.update_fields : []
                  }
                  defaultColDef={defaultColDef}
                  columnDefs={columns}
                  animateRows={true}
                  suppressCellFocus
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewAdminStageSidepanel;

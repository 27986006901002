import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { IsoToLocalTime, dataRenderer } from "../../Functions";
import { Circle } from "@mui/icons-material";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react-lite";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
`;

const ViewActivityInfoPanel = ({ data }) => {
  const [updateFieldList, setUpdateFieldList] = useState([]);
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const renderListOfStrings = (str) => {
    let list = str.split("\n");
    if (list.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item) => {
            return (
              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Circle
                  style={{
                    width: "8px",
                    height: "8px",
                    marginTop: "6px",
                    color: "gray",
                  }}
                />
                <Detail>{item}</Detail>
              </Box>
            );
          })}
        </Box>
      );
    }
    return "-";
  };

  const setupUpdateFields = () => {
    if (
      data.hasOwnProperty("updated_fields") &&
      data["updated_fields"] !== null
    ) {
      let tempList = [];
      let updateFields = data["updated_fields"];
      Object.keys(updateFields).forEach((key) => {
        if (key === "custom_params") {
          Object.keys(updateFields["custom_params"]).forEach((innerKey) => {
            let index = columnsList.findIndex(
              (column) => column["field"] === "custom_params_" + innerKey
            );
            if (index !== -1) {
              let tempObj = {
                label: columnsList[index]["headerName"],
                value: updateFields["custom_params"][innerKey],
              };
              tempList.push(tempObj);
            }
          });
        } else {
          let index = columnsList.findIndex(
            (column) => column["field"] === key
          );
          if (index !== -1) {
            let value = updateFields[key];
            if(columnsList[index]["data_type"] === "datetime"){
              value = IsoToLocalTime(updateFields[key]);
            }
            let tempObj = {
              label: columnsList[index]["headerName"],
              value: value,
            };
            tempList.push(tempObj);
          }
        }
      });
      setUpdateFieldList(tempList);
    }
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columnHeaders = [
    {
      field: "label",
      headerName: "LABEL",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        return dataRenderer(params.data.label);
      },
    },

    {
      field: "value",
      headerName: "VALUE",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        return dataRenderer(params.data.value);
      },
    },
  ];

  useEffect(() => {
    setupUpdateFields();
  }, []);

  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Activity Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Performed By</Label>
              <Detail>
                {data["user"] !== null
                  ? dataRenderer(data["user"]["name"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Activity Type</Label>
              <Detail>{dataRenderer(data["activity_type"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Time of Activity</Label>
              <Detail>
                {data["activity_performed_at"] !== null
                  ? IsoToLocalTime(data["activity_performed_at"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Description</Label>
              <Detail>
                {data["activity_desc"] !== null
                  ? renderListOfStrings(data["activity_desc"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Additional Comments</Label>
              {data["additional_comments"] !== null &&
              data["additional_comments"].length > 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {data["additional_comments"].map((item) => {
                    return (
                      <Box sx={{ display: "flex", columnGap: "8px" }}>
                        <Circle
                          style={{
                            width: "8px",
                            height: "8px",
                            marginTop: "6px",
                            color: "gray",
                          }}
                        />
                        <Detail>{item}</Detail>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SectionHeader>Updated Fields</SectionHeader>
              {updateFieldList.length > 0 ? (
                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", marginTop: "16px" }}
                >
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={updateFieldList}
                    defaultColDef={defaultColDef}
                    columnDefs={columnHeaders}
                    animateRows={true}
                    suppressCellFocus
                  />
                </div>
              ) : (
                <Detail>-</Detail>
              )}
            </Box>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default observer(ViewActivityInfoPanel);

import { Button, Box, Typography, DialogTitle, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Info } from "@material-ui/icons";
import { myTheme } from "../../../themeUtils";
import { getLocalizedText } from "../../../Functions";
import { callInfoSheetScope } from "../../../utils/enum";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import rootStore from "../../../stores/RootStore";

const Header = styled(Typography)(({ theme }) => ({
  font: " normal normal 600 20px Open Sans",
  color: "#4d4e4f",
}));
const SubHeader = styled(Typography)(({ theme }) => ({
  font: " normal normal 400 13px Open Sans",
  color: "#4d4e4f",
  marginTop: "8px",
  marginBottom: "16px",
}));
const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const CallInfoHeader = (props) => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  const { phoneCallState, authStore } = rootStore;

  const getHeaderText = () => {
    let text = "";
    if (phoneCallState.isCallCompleted()) {
      if (props.scope !== callInfoSheetScope.lead_details) {
        text = "pending_details_for_call";
      } else {
        text = "call_completed";
      }
    } else {
      text = "call_in_progress";
    }
    return getLocalizedText(text);
  };

  const showLeadsButton = () => {
    let leadInfo = {};
    if (phoneCallState.isUserLeadOwner() === false) {
      return false;
    } else {
      if (
        props.scope === callInfoSheetScope.lead_details ||
        props.scope === callInfoSheetScope.lead_details_call_log ||
        props.scope === callInfoSheetScope.lead_details_call_icon
      ) {
        return false;
      }
      if (props.callStatus.hasOwnProperty("lead")) {
        leadInfo = props.callStatus["lead"];
      }

      if (
        leadInfo.hasOwnProperty("id") &&
        leadInfo["id"] !== null &&
        leadInfo["id"] !== ""
      ) {
        if (authStore.leadDetailStatus["lead_id"] !== leadInfo["id"]) {
          return true;
        }
      }
      return false;
    }
  };
  const getSubHeaderText = () => {
    let text = "";

    if (phoneCallState.isUserLeadOwner()) {
      if (phoneCallState.isCallCompleted()) {
        if (props.scope === callInfoSheetScope.lead_details_call_icon) {
          text = getLocalizedText(
            "please_update_the_pending_call_details_for_the_previous_call_before_contacting_a_new_lead",
            "project"
          );
        } else if (props.scope === callInfoSheetScope.home_project) {
          text =
            "Please update the pending call details for the previous call before switching to a different project.";
        } else if (props.scope === callInfoSheetScope.home_call_icon) {
          text =
            "Please update the pending call details for the previous call.";
        } else if (
          props.scope === callInfoSheetScope.lead_details_call_icon ||
          props.scope === callInfoSheetScope.lead_details_call_log
        ) {
          if (!props.isSameLead()) {
            text = getLocalizedText(
              "click_on_go_to_lead_and_submit_the_call_details",
              "project"
            );
          }
        } else {
          text = "Kindly update call details by clicking on 'submit log'";
        }
      } else {
        text =
          "If the call has been completed, please click 'exit call' to update the call status";
      }
    } else {
      text = getLocalizedText(
        "you_are_not_lead_owner_contact_admin",
        "project"
      );
    }

    return text;
  };
  return (
    <DialogTitle
      id="alert-dialog-title"
      style={{ fontWeight: "bold", padding: "0px", margin: "16px" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <Info color="primary" style={{ marginRight: "8px" }} />
          <Header>{getHeaderText()}</Header>
        </Row>
        {showLeadsButton() && (
          <Button
            size="small"
            onClick={() => {
              if (props.callStatus["lead"]["id"] !== null) {
                props.onClick();
                navigate(
                  `/users/${uid}/leads/${props.callStatus["lead"]["id"]}`
                );
              }
            }}
            variant="outlined"
            color="primary"
            style={{ color: myTheme.Button.colorBlue, textTransform: "none" }}
          >
            {getLocalizedText("go_to_lead", "project")}
          </Button>
        )}
      </Box>
      <SubHeader
        style={{
          color: phoneCallState.isUserLeadOwner() ? "#4d4e4f" : "#ff0000",
        }}
        gutterBottom
      >
        {getSubHeaderText()}
      </SubHeader>
      <Divider></Divider>
    </DialogTitle>
  );
};

export default observer(CallInfoHeader);

import React, { useEffect, useState } from "react";
import { getListApi } from "../../Api";
import { createLeadReplaceFilterEpWithProject, createLeadReplaceFilterPlaceHolderEp, getSlug } from "../../Functions";
import Select from "react-select";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

const CreateLeadSearchableCustomSelectField = ({
  columnMeta,
  value,
  handleChange,
  isDisabled,
  mapToParentSelection = false,
  isRequired,
  scope = "create_and_edit_form",
  isValueChanged,
  parentValue = null,
  projectID,
  teamID,
}) => {
  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensuring it appears above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for options
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "loading" message
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the selected value text
      color:
        scope === "update_fields" && !isValueChanged
          ? "#E8E8E8" // Change the color based on scope and isValueChanged
          : "#4d4e4f", // Default color
    }),
  };

  const [optionsList, setOptionsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const getListDetails = async (filter_input) => {
    let tempType = "list";
    let tempList = [];
    if (filter_input.charAt(0) === "/" || filter_input.startsWith("ep:")) {
      let slug = "";

      if (filter_input.charAt(0) === "/") {
        slug = createLeadReplaceFilterEpWithProject(filter_input, projectID);
      } else if (filter_input.startsWith("ep:")) {
        let inputString = filter_input.replace("ep:", "");
        if (inputString.charAt(0) === "/") {
          inputString = inputString.slice(1);
        }
        slug = createLeadReplaceFilterPlaceHolderEp(inputString, projectID, teamID);
      }
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (
      filter_input.charAt(0) !== "/" &&
      filter_input !== "" &&
      filter_input !== null
    ) {
      try {
        let parseJSON = JSON.parse(filter_input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (filter_input.length > 0) {
          let list = filter_input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };
  const init = async () => {
    let tempOptionsList = [];
    if (mapToParentSelection === false) {
      let tempListDetails = await getListDetails(columnMeta["filter_input"]);
      if (tempListDetails["type"] === "list") {
        tempListDetails["list"].forEach((str) => {
          let tempObj = { label: str, value: str };
          tempOptionsList.push(tempObj);
        });
      } else {
        tempListDetails["list"].forEach((obj) => {
          let tempObj = {
            label: obj["name"],
            value: obj["value"],
          };
          tempOptionsList.push(tempObj);
        });
      }
    } else {
      let childOptionsList = getChildOptionList();
      childOptionsList.forEach((str) => {
        let tempObj = { label: str, value: str };
        tempOptionsList.push(tempObj);
      });
    }

    let index = tempOptionsList.findIndex((obj) => obj["value"] === value);
    if (index !== -1) {
      setSelectedOption(tempOptionsList[index]);
    } else {
      if (value !== "") {
        let tempObj = { label: value, value: value };
        tempOptionsList.unshift(tempObj);
        setSelectedOption(tempObj);
      } else {
        setSelectedOption(null);
      }
    }
    setOptionsList(tempOptionsList);
  };
  useEffect(() => {
    init();
  }, [columnMeta, value, parentValue]);

  const getChildOptionList = () => {
    let child_render_list = [];
    if (parentValue !== null) {
      try {
        let child_data = JSON.parse(columnMeta.filter_input);
        child_render_list = child_data.hasOwnProperty(parentValue["value"])
          ? child_data[parentValue["value"]]
          : [];
      } catch (error) {
        console.log("Errored while parsing child field filter input:::", error);
        child_render_list = [];
      }
    }
    return child_render_list;
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Select
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isDisabled={isDisabled}
        options={optionsList}
        value={selectedOption}
        onChange={(selectedOption) => {
          setSelectedOption(selectedOption);
          handleChange(selectedOption !== null ? selectedOption["value"] : "");
        }}
        closeMenuOnSelect={true}
        styles={customStyles}
        isClearable
        required={isRequired}
      />
      {!isDisabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, position: "absolute" }}
          value={value}
          required={isRequired}
          onChange={() => {}} // This prevents React warnings about read-only input
        />
      )}
    </Box>
  );
};

export default observer(CreateLeadSearchableCustomSelectField);

import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import WhatsappTemplateMessageComponent from "./WhatsappTemplateMessageComponent";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { consoleLogger, dataRenderer } from "../../Functions";
import image_placeholder from "../../assets/logo_placeholder.png";
import { CardActionArea } from "@material-ui/core";
import { getWhatsappLeadChatMediaApi } from "../../Api";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const MessageImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const MessageBoxRight = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  overflowWrap: "anywhere",
  width: "320px",
  fontSize: "16px",
  backgroundColor: "#d9fdd3",
  padding: "5px 3px 5px 3px",
  marginBottom: "5px",
  marginRight: "10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    right: "-14px",
    top: "0px",
    border: "15px solid",
    borderTopRightRadius: "3px",
    borderColor: "#d9fdd3 transparent transparent transparent",
  },
}));
const MessageBoxLeft = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  overflowWrap: "anywhere",
  width: "320px",
  fontSize: "16px",
  backgroundColor: "#ffffff",
  padding: "5px 3px 5px 3px",
  marginBottom: "5px",
  marginLeft: "10px",
  borderRadius: "10px",
  position: "relative",
  "&:after": {
    content: '""',
    width: 0,
    height: 0,
    position: "absolute",
    left: "-14px",
    top: "0px",
    border: "15px solid",
    borderTopLeftRadius: "3px",
    borderColor: "#ffffff transparent transparent transparent",
  },
}));
const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  marginBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "inline",
}));

const Bold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  display: "inline",
}));

const Italic = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  display: "inline",
}));

const Strikethrough = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  display: "inline",
}));

const Monospace = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  display: "inline",
}));

const Quote = styled(Box)(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  color: theme.palette.text.secondary,
}));

const BulletedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const NumberedList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: 0,
  display: "list-item",
  listStyleType: "disc",
}));

const InlineCode = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5),
  borderRadius: "4px",
  display: "inline",
}));

const Hour = styled(Box)(({ theme }) => ({
  fontSize: "11px",
  lineHeight: "15px",
  whiteSpace: "nowrap",
  opacity: 0.6,
}));
const ImageBoxWrapper = styled(CardActionArea)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "10px",
  backgroundColor: "white",
  marginBottom: "5px",
  position: "relative",
}));

const ImageBox = styled("img")(({ theme }) => ({
  width: "100%",
  height: "160px",
  objectFit: "cover",
}));
const MessageVideo = styled("video")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: "10px",
  backgroundColor: "#000",
}));

const components = {
  p: ({ children }) => <Paragraph>{children}</Paragraph>,
  a: ({ children, href }) => <StyledLink href={href}>{children}</StyledLink>,
  em: ({ children }) => <Italic>{children}</Italic>,
  strong: ({ children }) => <Bold>{children}</Bold>,
  del: ({ children }) => <Strikethrough>{children}</Strikethrough>,
  ul: ({ children }) => <BulletedList>{children}</BulletedList>,
  ol: ({ children }) => <NumberedList>{children}</NumberedList>,
  li: ({ children }) => <ListItemStyled>{children}</ListItemStyled>,
  blockquote: ({ children }) => <Quote>{children}</Quote>,
  code: ({ children }) => <InlineCode>{children}</InlineCode>,
};

const WhatsappChatMessageComponent = ({ data, templateList, id }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageURL, setImageURL] = React.useState("");

  const getMessageTime = (messageData) => {
    if (messageData.status === null) {
      return messageData.created_at;
    }
    if (messageData.status === "read" && messageData.read_at !== null) {
      return messageData.read_at;
    } else if (
      messageData.status === "delivered" &&
      messageData.delivered_at !== null
    ) {
      return messageData.delivered_at;
    } else if (messageData.sent_at !== null) {
      return messageData.sent_at;
    }
    return messageData.created_at;
  };

  const getMessageText = (messageType) => {
    if (messageType === null) {
      return data.message.text;
    } else if (messageType === "text") {
      if (data.message.hasOwnProperty("body")) {
        return data.message.body;
      }
      return data.message.text;
    }
    return data.message?.text;
  };

  const getComponentByType = (type) => {
    return data["message_parameters"].find(
      (x) => x.type.toLowerCase() === type
    );
  };

  const loadMessageImage = async (type = "regular") => {
    let mediaID = "";
    let messageType = Object.keys(data.message)[0]; // Extracts "text", "image", or "video"
    if (type === "header") {
      const headerData = getComponentByType("header");
      messageType = headerData["parameters"][0]["type"];
      mediaID = headerData["parameters"][0][messageType]["id"];
    } else {
      mediaID =
        data.direction === "outbound"
          ? data.message[messageType]?.id
          : data.message.id;
    }
    let queryParams = {
      media_id: mediaID,
      media_type: messageType,
      wm_id: data.id,
    };
    const response = await getWhatsappLeadChatMediaApi({
      leadID: id,
      queryParams: queryParams,
    });

    const imageBlob = new Blob([response], {
      type: messageType === "image" ? "image/jpeg" : "video/mp4",
    });
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImageURL(imageObjectURL);

    setImageLoaded(true);
  };

  const renderMessageContent = () => {
    return (
      <>
        <Box sx={{ paddingX: "4px", width: "100%" }}>
          {data.hasOwnProperty("user") &&
            data.user !== null &&
            data.user.hasOwnProperty("name") &&
            data.user.name !== null && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent:
                    data.direction === "outbound" ? "flex-end" : "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color:
                      data.direction === "outbound" ? "#075E54" : "#f37442",
                  }}
                >
                  {dataRenderer(data.user.name)}
                </Typography>{" "}
              </Box>
            )}
          {data.message?.hasOwnProperty("image") ? (
            //image rendering
            <ImageBoxWrapper onClick={loadMessageImage} disabled={imageLoaded}>
              <MessageImage
                src={imageLoaded ? imageURL : image_placeholder}
                alt="image"
              />
              {!imageLoaded && (
                <Box
                  sx={{
                    position: "absolute",
                    width: 54,
                    height: 54,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DownloadIcon style={{ color: "#fff" }} />
                </Box>
              )}
            </ImageBoxWrapper>
          ) : data.message?.hasOwnProperty("video") ? (
            // Video rendering
            <>
              <ImageBoxWrapper
                onClick={loadMessageImage}
                disabled={imageLoaded}
                sx={{
                  height: imageLoaded ? "auto" : "200px",
                  backgroundColor: "#c9c9c9",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: 54,
                    height: 54,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <PlayCircleOutlineIcon style={{ color: "#fff" }} />
                </Box>
              </ImageBoxWrapper>
              {imageLoaded && (
                <MessageVideo controls>
                  <source src={imageURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </MessageVideo>
              )}
            </>
          ) : (
            <Markdown
              options={{
                overrides: components,
                forceBlock: true,
              }}
            >
              {getMessageText(data.message_type)}
            </Markdown>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {moment(getMessageTime(data)).isValid() && (
              <Hour>{moment(getMessageTime(data)).format("hh:mm A")}</Hour>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: "2px",
              }}
            >
              {data.direction === "outbound" && getStatusTickComponent()}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const getStatusTickComponent = () => {
    if (data.id === "") {
      return <ErrorIcon style={{ fontSize: 13, opacity: 0.6, color: "red" }} />; //some error if id is empty
    }
    if (data.status === null) {
      return <DoneIcon style={{ fontSize: 16, opacity: 0.6 }} />; //not delivered
    } else {
      if (data.status === "delivered") {
        return <DoneAllIcon style={{ fontSize: 16, color: "#53bdeb" }} />;
      } else if (data.status === "read") {
        return <DoneAllIcon style={{ fontSize: 16, color: "#53bdeb" }} />;
      } else if (data.status === "sent") {
        return <DoneIcon style={{ fontSize: 16, opacity: 0.6 }} />;
      } else if (data.status === "waiting") {
        return <AccessTimeIcon style={{ fontSize: 12, opacity: 0.6 }} />; //before refetching
      }
    }
  };

  const getWhatsappTemplate = (templateID) => {
    const index = templateList.findIndex(
      (template) => template.id === templateID
    );
    if (index !== -1) {
      return templateList[index];
    }
    return {};
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent:
          data.direction === "outbound" ? "flex-end" : "flex-start",
      }}
    >
      {data.whatsapp_template_id !== null &&
      data.whatsapp_template_id !== "" ? (
        <WhatsappTemplateMessageComponent
          components={getWhatsappTemplate(data.whatsapp_template_id).components}
          data={data}
          image={imageLoaded ? imageURL : null}
          handleLoadImage={() => {
            loadMessageImage("header");
          }}
          imageLoaded={imageLoaded}
        />
      ) : data.direction === "outbound" ? (
        <MessageBoxRight>{renderMessageContent()}</MessageBoxRight>
      ) : (
        <MessageBoxLeft>{renderMessageContent()}</MessageBoxLeft>
      )}
    </Box>
  );
};

export default WhatsappChatMessageComponent;

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { createAdminQuotationApi } from "../../Api";
import TemplateInfoScreen from "./screens/TemplateInfoScreen";
import rootStore from "../../stores/RootStore";
import ConfigureShippingAddressModal from "./ConfigureShippingAddressModal";
import AddProductModal from "./dialogs/AddProductModal";
import EditProductModal from "./dialogs/EditProductModal";
import ViewProductModal from "./dialogs/ViewProductModal";
import AdditionalInformationScreen from "./screens/AdditionalInformationScreen";
import { v4 as uuidv4 } from "uuid";
import ViewAreaBasedProductModal from "./dialogs/ViewAreaBasedProductModal";
import EditAreaBasedProductModal from "./dialogs/EditAreaBasedProductModal";
import AddAreaBasedProductModal from "./dialogs/AddAreaBasedProductModal";
import ColorPickerModalV2 from "../color_picker_modal/ColorPickerModalV2";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 50vw;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const CloneAdminQuoteTemplateSidepanel = ({
  setOpen,
  setRefresh,
  open,
  selectedTemplateData,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [showShippingConfig, setShowShippingConfig] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    company_address: "",
    company_email: "",
    company_website: "",
    company_state: "",
    company_country: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    show_shipping_address: false,
  });
  const [addressConfig, setAddressConfig] = useState({
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zipcode: "",
    billing_gst: "",
    shipping_name: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "",
    shipping_zipcode: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    show_terms_and_conditions: false,
    show_additional_information: false,
    show_quotation_number: false,
    terms_and_conditions: "",
    additional_information: "",
  });
  const [logoURL, setLogoURL] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateDetails, setTemplateDetails] = useState({
    quotation_title: "",
    invoice_title: "",
    template_subject: "",
    template_type: "template_basic",
  });

  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [styleDetails, setStyleDetails] = useState({
    table_header: { background_color: "", text_color: "" },
    grand_total: { background_color: "", text_color: "" },
  });
  const [selectedStyleField, setSelectedStyleField] = useState({
    type: "",
    property: "",
  });

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    setTemplateName(selectedTemplateData.name);

    // if template version is 2 get the styles from schema data
    let templateVersion =
      selectedTemplateData.hasOwnProperty("schema") &&
      selectedTemplateData["schema"] !== null &&
      selectedTemplateData["schema"].hasOwnProperty("template_version") &&
      selectedTemplateData["schema"]["template_version"] !== null &&
      selectedTemplateData["schema"]["template_version"] !== ""
        ? selectedTemplateData["schema"]["template_version"]
        : 1;

    if (templateVersion > 1) {
      setStyleDetails(selectedTemplateData["schema"]["styles"]);
    }

    let tempDetails = {
      template_subject: selectedTemplateData.schema.subject
        ? selectedTemplateData.schema.subject
        : "",
      template_type:
        selectedTemplateData.schema.hasOwnProperty("template_type") &&
        selectedTemplateData.schema.template_type !== null &&
        selectedTemplateData.schema.template_type !== ""
          ? selectedTemplateData.schema.template_type
          : "template_basic",
    };
    if (
      selectedTemplateData.schema.title !== null &&
      selectedTemplateData.schema.title !== undefined
    ) {
      tempDetails["quotation_title"] = selectedTemplateData.schema.title;
    } else {
      tempDetails["quotation_title"] = selectedTemplateData.schema
        .quotation_title
        ? selectedTemplateData.schema.quotation_title
        : "";
      tempDetails["invoice_title"] = selectedTemplateData.schema.invoice_title
        ? selectedTemplateData.schema.invoice_title
        : "";
    }
    setTemplateDetails(tempDetails);
    //company details
    if (selectedTemplateData.schema.company_details !== null) {
      let tempCompanyDetails = {};
      for (let key in selectedTemplateData.schema.company_details) {
        if (key === "logo_url") {
          setLogoURL(selectedTemplateData.schema.company_details[key]);
        } else {
          tempCompanyDetails[key] =
            selectedTemplateData.schema.company_details[key];
        }
      }
      setCompanyDetails(tempCompanyDetails);
    }

    //products list
    if (
      selectedTemplateData.schema.items !== null &&
      selectedTemplateData.schema.items !== undefined
    ) {
      let tempItemList = [];
      //loop through products list
      for (const item of selectedTemplateData.schema.items) {
        let row = {};
        //loop through keys in a row item
        for (let key in item) {
          if (key === "name") {
            row["item"] = {};
            row["item"][key] = item[key];
          } else {
            row[key] = row[key] = item[key];
          }
        }
        tempItemList.push(row);
      }
      setProductAddedList(tempItemList);
    }
    if (
      selectedTemplateData.schema.address_variables !== null &&
      selectedTemplateData.schema.address_variables !== undefined
    ) {
      setAddressConfig(selectedTemplateData.schema.address_variables);
    }
    if (
      selectedTemplateData.schema.additional_details !== null &&
      selectedTemplateData.schema.additional_details !== undefined
    ) {
      setAdditionalDetails(selectedTemplateData.schema.additional_details);
    }
    if (
      selectedTemplateData.schema.billing_details !== null &&
      selectedTemplateData.schema.billing_details !== undefined
    ) {
      setBillingDetails(selectedTemplateData.schema.billing_details);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloneItem = (item) => {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.item.id = uuidv4();

    const newProductsList = [...productAddedList, newItem];

    setProductAddedList(newProductsList);
  };

  const getValidIntegerValue = (value) => {
    if (value !== "" && value !== null) {
      return parseInt(value);
    }
    return "";
  };
  const getValidFloatValue = (value) => {
    if (value !== "" && value !== null) {
      const floatValue = parseFloat(value);
      return floatValue.toFixed(2);
    }
    return "";
  };

  const handleSubmit = async () => {
    let formData = {
      name: templateName,
    };
    let schema = {
      quotation_title: templateDetails["quotation_title"],
      invoice_title: templateDetails["invoice_title"],
      subject: templateDetails["template_subject"],
      company_details: { ...companyDetails, logo_url: logoURL },
      billing_details: { ...billingDetails },
      additional_details: { ...additionalDetails },
      address_variables: addressConfig,
      show_gst: false,
      template_type: templateDetails["template_type"],
      styles: styleDetails,
      template_version: 2, // template version is hardcoded - to support styles even if we clone a older template version as styles are supported version 2 onwards
    };

    if (companyDetails["company_country"] === "India") {
      schema["show_gst"] = true;
    }

    let itemList = [];
    productAddedList.forEach((productItem) => {
      if (templateDetails["template_type"] === "template_area_based") {
        let tempObj = {
          rate: getValidIntegerValue(productItem["rate"]),
          amount: getValidFloatValue(productItem["amount"]),
          quantity: getValidIntegerValue(productItem["quantity"]),
          discount: getValidIntegerValue(productItem["discount"]),
          desc: productItem["desc"],
          width: getValidIntegerValue(productItem["width"]),
          height: getValidIntegerValue(productItem["height"]),
          unit: productItem["unit"],
          in_unit: getValidFloatValue(productItem["in_unit"]),
          total_unit: getValidFloatValue(productItem["total_unit"]),
          additional_details: productItem["additional_details"],
        };
        tempObj["name"] = productItem["item"]["name"];
        itemList.push(tempObj);
      } else {
        let tempObj = {
          rate: getValidIntegerValue(productItem["rate"]),
          amount: getValidFloatValue(productItem["amount"]),
          quantity: getValidIntegerValue(productItem["quantity"]),
          discount: getValidIntegerValue(productItem["discount"]),
          desc: productItem["desc"],
        };
        tempObj["name"] = productItem["item"]["name"];
        itemList.push(tempObj);
      }
    });
    schema["items"] = itemList;

    formData["schema"] = schema;
    let response = await createAdminQuotationApi({
      projectID: rootStore.authStore.projectId,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      convertImageToBase64(file);
    }
  };

  const convertImageToBase64 = (imageFile) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result;
      setLogoURL(base64);
    };
    reader.readAsDataURL(imageFile);
  };

  const postClickCallback = () => {
    setShowShippingConfig(true);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const RenderButton = () => {
    if (activeStep === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Next
          </Button>
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Prev
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Box>
      );
    }
  };

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Clone Template</ModelHeader>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            if (activeStep < 1) {
              handleNext();
            } else {
              handleSubmit();
            }
          }}
        >
          <InputContainer>
            {(() => {
              if (activeStep === 0) {
                return (
                  <TemplateInfoScreen
                    companyDetails={companyDetails}
                    handleChange={(name, value) => {
                      setCompanyDetails({ ...companyDetails, [name]: value });
                    }}
                    handleTemplateName={(value) => {
                      setTemplateName(value);
                    }}
                    templateName={templateName}
                    handleTemplateDetail={(name, value) => {
                      setTemplateDetails({ ...templateDetails, [name]: value });
                    }}
                    templateDetails={templateDetails}
                    handleLogo={(event) => {
                      handleLogo(event);
                    }}
                    productAddedList={productAddedList}
                    setProductAddedList={setProductAddedList}
                    setOpenAddProductModal={setOpenAddProductModal}
                    setOpenEditProductModal={setOpenEditProductModal}
                    setOpenViewProductModal={setOpenViewProductModal}
                    setSelectedProductData={setSelectedProductData}
                    handleCloneItem={handleCloneItem}
                  />
                );
              } else {
                return (
                  <AdditionalInformationScreen
                    additionalDetails={additionalDetails}
                    billingDetails={billingDetails}
                    companyDetails={companyDetails}
                    postClickCallback={postClickCallback}
                    handleAdditionalDetails={(name, value) => {
                      setAdditionalDetails({
                        ...additionalDetails,
                        [name]: value,
                      });
                    }}
                    handleBillingDetails={(name, value) => {
                      setBillingDetails({ ...billingDetails, [name]: value });
                    }}
                    handleChange={(name, value) => {
                      setCompanyDetails({ ...companyDetails, [name]: value });
                    }}
                    handleBillingVariables={(name, value) => {
                      setAddressConfig({ ...addressConfig, [name]: value });
                    }}
                    addressConfig={addressConfig}
                    styleDetails={styleDetails}
                    handleStyleDetails={(type, property) => {
                      setSelectedStyleField({ type: type, property: property });
                      setOpenColorPicker(true);
                    }}
                  />
                );
              }
            })()}
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: "50vw", bgcolor: "#f9f9fc" }}
          >
            <Toolbar style={{ width: "50vw", position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}

              <ButtonWrapper>
                <RenderButton />
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>Quote template created successfully!</Message>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
      {showShippingConfig && (
        <ConfigureShippingAddressModal
          setOpen={setShowShippingConfig}
          open={showShippingConfig}
          onSubmitCallback={(shippingDetails) => {
            const tempObj = { ...addressConfig, ...shippingDetails };
            setAddressConfig(tempObj);
          }}
          addressConfig={addressConfig}
        />
      )}
      {openAddProductModal &&
        (templateDetails.template_type === "template_area_based" ? (
          <AddAreaBasedProductModal
            open={openAddProductModal}
            setOpen={setOpenAddProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
          />
        ) : (
          <AddProductModal
            open={openAddProductModal}
            setOpen={setOpenAddProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
          />
        ))}
      {openEditProductModal &&
        (templateDetails.template_type === "template_area_based" ? (
          <EditAreaBasedProductModal
            open={openEditProductModal}
            setOpen={setOpenEditProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
            editableProduct={selectedProductData}
            setEditableProduct={setSelectedProductData}
          />
        ) : (
          <EditProductModal
            open={openEditProductModal}
            setOpen={setOpenEditProductModal}
            productAddedList={productAddedList}
            setProductAddedList={setProductAddedList}
            editableProduct={selectedProductData}
            setEditableProduct={setSelectedProductData}
          />
        ))}

      {openViewProductModal &&
        (templateDetails.template_type === "template_area_based" ? (
          <ViewAreaBasedProductModal
            open={openViewProductModal}
            setOpen={setOpenViewProductModal}
            data={selectedProductData}
            setData={setSelectedProductData}
          />
        ) : (
          <ViewProductModal
            open={openViewProductModal}
            setOpen={setOpenViewProductModal}
            data={selectedProductData}
            setData={setSelectedProductData}
          />
        ))}
      {openColorPicker && (
        <ColorPickerModalV2
          color={
            styleDetails[selectedStyleField["type"]][
              selectedStyleField["property"]
            ]
          }
          handleClose={() => {
            setOpenColorPicker(false);
          }}
          handlePostApply={(color) => {
            let tempStyles = { ...styleDetails };
            tempStyles[selectedStyleField["type"]][
              selectedStyleField["property"]
            ] = color;
            setStyleDetails(tempStyles);
            setOpenColorPicker(false);
          }}
          open={openColorPicker}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "50vw",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CloneAdminQuoteTemplateSidepanel;

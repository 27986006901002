import React from "react";
import Select from "react-select";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

const SearchableSelectField = ({
  value,
  handleChange,
  isDisabled,
  isRequired,
  optionsList,
}) => {
  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensuring it appears above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for options
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "loading" message
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the selected value text
      color: "#4d4e4f",
    }),
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Select
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isDisabled={isDisabled}
        options={optionsList}
        value={value}
        onChange={(selectedOption) => {
          handleChange(selectedOption);
        }}
        closeMenuOnSelect={true}
        styles={customStyles}
        isClearable
        required={isRequired}
      />
      {!isDisabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, position: "absolute" }}
          value={value}
          required={isRequired}
          onChange={() => {}} // This prevents React warnings about read-only input
        />
      )}
    </Box>
  );
};

export default observer(SearchableSelectField);

import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  Modal,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { getAccountMembersApi, assignCustomerApi } from "../../Api";
import {
  Alert,
  CircularProgress,
  Divider,
  IconButton,
  Avatar,
  OutlinedInput,
  Link,
} from "@mui/material";
import { getLocalizedText, checkFeaturePermission } from "../../Functions";
import { Close } from "@material-ui/icons";
import { MultiSelect } from "react-multi-select-component";
import { LoadingButton } from "@mui/lab";
import profile_image from "../../assets/profile_placeholder.png";

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 550px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ProfileImage = styled("img")`
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;
const ImageWrapper = styled(Avatar)`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a6a8;
  padding: 0px;
  position: relative;
  background-color: white;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AssignCustomerDialog = ({ open, setOpen, setRefresh, customers }) => {
  const [memberList, setMemberList] = useState([]);
  const [multiSelectMemberList, setMultiSelectMemberList] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [comments, setComments] = useState("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await setupMemberList();
    setInitLoading(false);
  };

  const handleCurrentPageSelectedLeadAssign = async () => {
    const numCustomers = customers.length;
    const numMembers = selectedMembers.length;
    const memberCustomers = {};
    selectedMembers.forEach((member) => {
      memberCustomers[member.value] = [];
    });

    // Round Robin logic
    // Iterate over each customer and assign customers round-robin to each member
    for (let i = 0; i < numCustomers; i++) {
      const lead = customers[i];
      const memberIndex = i % numMembers; // Calculate member index using modulo operation
      const member = selectedMembers[memberIndex].value; // Get the member for this customer
      memberCustomers[member].push(lead); // Assign customer to member
    }

    // Iterate over each member and make API call to assign customers
    for (const [member, customers] of Object.entries(memberCustomers)) {
      if (customers.length > 0) {
        const customerIds = customers.map((item) => item.id);
        let payload = {
          customer_ids: customerIds,
          assign_to: member,
        };

        if (comments !== "") {
          payload["comments"] = comments;
        }
        try {
          await assignCustomerApi({
            payload: payload,
          });
          // Handle response if needed
        } catch (error) {
          console.log(error);
          // Handle error if needed
        }
      }
    }
  };

  const handleAssign = async () => {
    setLoading(true);

    await handleCurrentPageSelectedLeadAssign();
    setLoading(false);
    setOpen(false);
    setRefresh(true);
  };

  const valueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map((x, index) => {
          let text = x.name;
          if (index < selected.length - 1) {
            text += ", ";
          }
          return text;
        })
      : "No Items Selected";
  };

  const mutliSelectOptionRenderer = ({ checked, option, onClick }) => {
    return option.value === "" && !option.hasOwnProperty("name") ? (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "14px",
          }}
        >
          <Link
            onClick={(e) => {
              onClick();
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{
              textDecoration: "none",
              color: checked ? "#3f51b5" : "inherit",
            }}
          >
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              {option.label}
            </Typography>
          </Link>
          <Link
            disabled={selectedMembers.length === 0}
            onClick={(e) => {
              if (selectedMembers.length > 0) {
                setSelectedMembers([]);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              Clear
            </Typography>
          </Link>
        </Box>
      </div>
    ) : (
      <CardActionArea
        onClick={(e) => {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Card
          square={false}
          id={option.id}
          value={option.id}
          style={{
            marginBottom: 1,
            padding: 2,
            width: "100%",
            height: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            style={{ width: "10%" }}
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Typography
              style={{
                width: "fit-content",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {option.name}
            </Typography>
          </Box>
        </Card>
      </CardActionArea>
    );
  };

  const setupMemberList = async () => {
    let member_list = await getAccountMembersApi();
    setMemberList(member_list);
    let modifiedList = [];
    let detailedList = [];
    member_list.forEach((e) => {
      let newObj = { label: e.name, value: e.id, name: e.name };
      modifiedList.push(newObj);
      let labelStr = `${e.name}`;
      let newObj1 = {
        label: labelStr,
        value: e.id,
        name: e.name,
        profile_pic: e.profile_pic,
      };
      detailedList.push(newObj1);
    });
    setMultiSelectMemberList(modifiedList);
  };

  const RenderButton = () => {
    if (loading) {
      return (
        <LoadingButton
          loading
          loadingIndicator="Assigning…"
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            font: "normal normal 600 10px Open Sans",
            width: "100%",
          }}
        >
          <span>Assigning...</span>
        </LoadingButton>
      );
    } else {
      return (
        <Button
          onClick={handleAssign}
          variant="contained"
          color="primary"
          disabled={selectedMembers.length === 0}
          style={{
            font: "normal normal 600 10px Open Sans",
            width: "100%",
            textTransform: "none",
          }}
        >
          {getLocalizedText("assign")}
        </Button>
      );
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer
          style={{
            width: "400px",
          }}
        >
          {!initLoading ? (
            <Box>
              <ModelWrapper>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Header>{`Assign ${customers.length} Customer(s) To`}</Header>
                  <IconButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <SubHeader>Select members from below dropdown</SubHeader>
                <Divider style={{ margin: "10px 0px" }} />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <InputWrapper>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        font: "normal normal 600 10px Open Sans",
                        color: "#4d4e4f",
                        marginBottom: "4px",
                      }}
                    >
                      {getLocalizedText("select_members")}
                    </InputLabel>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          "&": {
                            zIndex: "2",
                            maxWidth: "100%",
                            width:"100%",
                          },
                          "& .select-item": {
                            padding: 0,
                          },
                          "& .select-item.selected": {
                            background: "none",
                          },
                          "& .select-item:hover": {
                            background: "none",
                          },
                        }}
                      >
                        <MultiSelect
                          hasSelectAll={true}
                          valueRenderer={valueRenderer}
                          ItemRenderer={
                            mutliSelectOptionRenderer
                          }
                          options={
                            multiSelectMemberList
                          }
                          value={selectedMembers}
                          className={"global_filter_multi_select"}
                          onChange={(e) => {
                            setSelectedMembers(e);
                          }}
                          labelledBy="Select Members"
                        />
                      </Box>
                    </Box>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel
                      style={{
                        font: "normal normal 600 10px Open Sans",
                        color: "#4d4e4f",
                        marginBottom: "4px",
                      }}
                    >
                      Comments
                    </InputLabel>
                    <OutlinedInput
                      multiline={true}
                      rows={3}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={comments}
                      name={"comments"}
                      type={"text"}
                      placeholder={"Enter Value"}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </InputWrapper>
                </Box>

                <ButtonWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {selectedMembers.length > 1 && (
                      <Alert
                        style={{
                          padding: "0px 5px",
                          fontSize: "12px",
                          borderRadius: "4px 4px 4px 4px",
                        }}
                        severity="info"
                      >
                        Customers will be assigned equally to all the selected
                        members!
                      </Alert>
                    )}
                  </Box>
                  <RenderButton />
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          ) : (
            <Box
              style={{
                width: "100%",
                height: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AssignCustomerDialog;

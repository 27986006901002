import {
  Box,
  Button,
  Modal,
  Typography,
  AppBar,
  Toolbar,
  Divider,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../Functions";
import { apiKeySettingWebhookDescriptions } from "../../../Db";
import rootStore from "../../../stores/RootStore";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "100%",
  width: "600px",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 20px #185dd21f",
  borderRadius: "8px",
  padding: "30px",
  border: "1px solid #c5d7f1",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  position: "relative",
  paddingTop: "16px",
}));

const CodeBlock = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  fontSize: "14px",
  color: "#4d4e4f",
  backgroundColor: "#f5f5f5", // Light grey background for code block
  padding: "12px",
  borderRadius: "4px",
  border: "1px solid #e0e0e0",
  whiteSpace: "pre-wrap", // Allow wrapping for long lines
  wordBreak: "break-word", // Break long words if necessary
  display: "block", // Ensure block-level behavior
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  maxHeight: "400px",
  overflowY: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "600px",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "100%",
  paddingTop: "16px",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 14px Open Sans",
}));

const URL = process.env.REACT_APP_API_BASE_URL;

const ApiKeySettingInfoDialog = ({ open, setOpen, configData }) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const getLinkURl = () => {
    const accountID =
      rootStore.userStore.UserData !== null &&
      rootStore.userStore.UserData.hasOwnProperty("account") &&
      rootStore.userStore.UserData["account"] !== null
        ? rootStore.userStore.UserData["account"]["id"]
        : "";
    const formPayloadData = {};
    const mappingData =
      configData.hasOwnProperty("data") &&
      configData["data"] !== null &&
      configData["data"].hasOwnProperty("mapping") &&
      configData["data"]["mapping"] !== null
        ? configData["data"]["mapping"]
        : {};
    Object.keys(mappingData).forEach((fieldName) => {
      formPayloadData[fieldName] = "";
    });
    const payloadDataString = JSON.stringify(formPayloadData, null, 2); // Pretty print JSON
    let url = "";
    let integrationType = configData.hasOwnProperty("type")
      ? configData["type"]
      : "";
    let apiKey =
      configData.hasOwnProperty("data") &&
      configData["data"] !== null &&
      configData["data"].hasOwnProperty("api_key") &&
      configData["data"]["api_key"] !== null
        ? configData["data"]["api_key"]
        : "";
    if (integrationType === "housing") {
      url = `${URL}external/housing/${accountID}/create`;
    } else if (integrationType === "99acres") {
      url = `${URL}external/99acres/${accountID}/create`;
    }
    return `curl -k -X POST ${url} --header 'Content-Type: application/json' --header 'api_key: ${apiKey}' --data '${payloadDataString}'`;
  };

  const getTitleText = () => {
    let integrationType = configData.hasOwnProperty("type")
      ? configData["type"]
      : "";
    if (integrationType === "housing") {
      return "Connect housing.com to LeadPixie";
    } else if (integrationType === "99acres") {
      return "Connect 99acres to LeadPixie";
    }
    return "";
  };

  const getDescriptions = () => {
    let integrationType = configData.hasOwnProperty("type")
      ? configData["type"]
      : "";
    if (apiKeySettingWebhookDescriptions.hasOwnProperty(integrationType)) {
      return apiKeySettingWebhookDescriptions[integrationType];
    }
    return [];
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <Container>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: "100%", height: "30px" }}
          >
            <Toolbar
              style={{
                padding: "0px",
                width: "100%",
                position: "relative",
                display: "flex",
              }}
            >
              <HeaderWrapper>
                <Header>{getTitleText()}</Header>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider />

          <InnerContainer>
            <Box
              style={{
                marginLeft: "10px",
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {getDescriptions().map((e, i) => {
                return (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "8px",
                    }}
                  >
                    <Label
                      style={{
                        fontSize: "14px",
                        marginRight: "8px",
                      }}
                    >
                      {i + 1 + "."}
                    </Label>
                    <Label style={{ fontSize: "14px" }}>{e}</Label>
                  </Box>
                );
              })}
            </Box>
            <Box
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <TextWrapper>
                  <CodeBlock component="pre">{getLinkURl()}</CodeBlock>
                </TextWrapper>
              </Box>
            </Box>
          </InnerContainer>

          <ButtonWrapper>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="inherit"
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                textTransform: "none",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </ButtonWrapper>
        </Container>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => {
            setOpenSnackBar(false);
          }}
          message="Copied to clipboard!"
        />
      </ModelContainer>
    </Modal>
  );
};

export default ApiKeySettingInfoDialog;

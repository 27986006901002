import { OutlinedInput } from "@mui/material";
import React from "react";

const CustomTextField = ({
  fontSize = "12px",
  isRequired,
  value,
  fieldName,
  fieldType,
  hintText,
  isDiabled,
  handleBlur,
  handleChange
}) => {
  return (
    <OutlinedInput
      style={{ height: "38px" }}
      inputProps={{
        min: 0,
        "aria-label": "weight",
        style: {
          fontSize: fontSize,
        },
      }}
      required={isRequired}
      value={value}
      name={fieldName}
      type={fieldType}
      disabled={isDiabled}
      placeholder={hintText}
      onBlur={(event) => {
        handleBlur(event);
      }}
      onChange={(event) => {
        handleChange(event);
      }}
    />
  );
};

export default CustomTextField;

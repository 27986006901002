import {
  Airplay,
  AccountCircle,
  CheckCircleOutline,
  Dvr,
  FormatListBulleted,
  FormatListNumberedRtl,
  LocalOffer,
  People,
  PriorityHigh,
  Settings,
  SettingsInputAntenna,
  ViewColumn,
  Reorder,
  Work,
  WhatsApp,
} from "@material-ui/icons";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import {
  Diversity1,
  DynamicForm,
  MoveDown,
  TableRows,
  ViewCozy,
} from "@mui/icons-material";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

const defaultTableWidgetSize = 10;
const pageSize = 25;
const leadListingPageSize = 100;
const leadListingRowBuffer = 50; //this value is used in lead listing pages as row buffer for ag data grid
const sizes = [25, 50, 100];
const bulkAssignLimit = 1000;
let features = "unknown";
let app_id = process.env.REACT_APP_ID;
let app_version = "2.0.480124.20250124";
let device_os = "web";
const accountIDsForNewPaginationAccess = [
  "30b86792-c13d-47c1-86a1-24cd6fc851bf",
  "5318e7aa-ae9f-42e8-a6ad-070d84464223",
];
const demoAccountID = "8c4ca3cb-c875-483f-af85-e5a481b4eabf";
const whatsappBusinessAccessAccountIDs = [
  "5318e7aa-ae9f-42e8-a6ad-070d84464223",
];
const userIDsForWABAAccess = ["8b25f916-62bf-47db-9a76-295106d9cd5f"];
const fbLoginConfigID = 3344432285859182;
const fbAppID = "2413694435490006";
const refetch_time = 15;
const domainName = ".leadpixie.ai";
const columnWidth = {
  small: 100,
  medium: 150,
  large: 250,
};
const severityOrder = {
  critical: 3,
  warning: 2,
  info: 1,
};

const callLogsLimit = 25;

const StackedBarConfig = {
  options: {
    scales: {
      y: {
        beginAtZero: true,
        grace: "10%",
      },
    },
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      legend: {
        position: "right",
        labels: {
          // Use the legendCallback function to customize the legend labels
          generateLabels: (chart) => {
            const getLegendData = (label) => {
              let total = 0;
              let index = chart.data.datasets.findIndex(
                (dataset) => dataset.label === label
              );
              if (index !== -1) {
                let dataset = chart.data.datasets[index];
                dataset.data.forEach((value) => (total += value));
              }

              return total;
            };

            return chart.data.datasets.map((data, index) => {
              const meta = chart.getDatasetMeta(index);
              return {
                text: `${data.label}: ${getLegendData(data.label)}`,
                fillStyle: data.backgroundColor,
                hidden: !meta.visible,
                index: index,
                datasetIndex: index,
              };
            });
          },
        },
      },
      datalabels: {
        offset: -2,
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          const chart = context.chart;

          // Find the visible datasets and collect their data for the current index
          const visibleDatasetValues = chart.data.datasets
            .map((dataset, index) => {
              const meta = chart.getDatasetMeta(index);
              return {
                visible: meta.visible,
                data: dataset.data[context.dataIndex], // Get data for the current index
                index, // Store the dataset index
              };
            })
            .filter((item) => item.visible && item.data !== undefined); // Filter only visible datasets

          // Calculate the total sum of visible datasets for the given data index
          const totalSum = visibleDatasetValues.reduce(
            (total, item) => total + item.data,
            0
          );

          // Find the topmost visible dataset index
          const topmostVisibleDataset = Math.max(
            ...visibleDatasetValues.map((item) => item.index) // Get the index of the topmost visible dataset
          );

          // Check if the current dataset is the topmost visible one
          const isTopmostDataset =
            context.datasetIndex === topmostVisibleDataset;

          return isTopmostDataset ? totalSum : ""; // Return sum if it's the topmost dataset, otherwise empty
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  },
};

const AdminProjectMenuList = [
  {
    name: "Settings",
    path: "/project-administration/settings",
    icon: <Settings />,
  },

  {
    name: "Members",
    path: "/project-administration/members",
    icon: <People />,
  },
  {
    name: "Teams",
    path: "/project-administration/teams",
    icon: <Diversity1 />,
  },
  {
    name: "Work Day Templates",
    path: "/project-administration/workday-templates",
    icon: <WorkHistoryIcon />,
  },
  {
    name: "Sources",
    path: "/project-administration/sources",
    icon: <SettingsInputAntenna />,
  },
  {
    name: "Stages",
    path: "/project-administration/stages",
    icon: <FormatListNumberedRtl />,
  },
  {
    name: "Statuses",
    path: "/project-administration/statuses",
    icon: <FormatListBulleted />,
  },
  {
    name: "Stage Directives",
    path: "/project-administration/statustostage",
    icon: <MoveDown />,
  },
  {
    name: "Columns Settings",
    icon: <TableRows />,
    submenu_list: [
      {
        name: "Columns",
        path: "/project-administration/columns",
        icon: <ViewColumn />,
      },
      {
        name: "Columns Groups",
        path: "/project-administration/columngroups",
        icon: <Reorder />,
      },
    ],
  },

  {
    name: "Jobs",
    path: "/project-administration/jobs",
    icon: <Work />,
  },
  {
    name: "Predefined Deals",
    path: "/project-administration/predefined-deals",
    icon: <LocalOffer />,
  },
  {
    name: "Quotations",
    path: "/project-administration/quotations",
    icon: <ReceiptLongIcon />,
  },
  {
    name: "Views",
    icon: <ViewCozy />,
    submenu_list: [
      {
        name: "View Tabs",
        path: "/project-administration/view-tabs",
        icon: <Dvr />,
      },
      {
        name: "View Groups",
        path: "/project-administration/view-groups",
        icon: <Airplay />,
      },
    ],
  },
  {
    name: "Prioritization",
    icon: <PriorityHigh />,
    submenu_list: [
      {
        name: "Quality",
        path: "/project-administration/quality",
        icon: <CheckCircleOutline />,
      },
    ],
  },
  {
    name: "Lead Form",
    path: "/project-administration/lead-form",
    icon: <DynamicForm />,
  },
  {
    name: "WhatsApp Templates",
    icon: <WhatsApp />,
    submenu_list: [
      {
        name: "Personal",
        path: "/project-administration/whatsapp-templates/personal",
        icon: <AccountCircle />,
      },
      // {
      //   name: "Business",
      //   path: "/project-administration/whatsapp-templates/business",
      //   icon: <Work />,
      // },
    ],
  },
];

const TeamLeadAccessNameList = ["Settings", "Jobs"];

const autoDialerCampaignTypeList = [
  { label: "Agentwise - Equally", value: "agent_wise" },
];

const LeadFilterTypeList = [
  { label: "All Leads", key: "all_leads" },
  { label: "Leads Created Between", key: "leads_created_between" },
  { label: "Leads Updated Between", key: "leads_updated_between" },
];

const formBuilderConfig = {
  settings: {
    disableNavigationArrows: true,
    disableProgressBar: true,
    disableWheelSwiping: true,
    showQuestionsNumbers: false,
  },
  blocks: [
    {
      id: "",
      name: "",
      attributes: {
        label: "",
        description: "",
      },
      innerBlocks: [],
    },
  ],
};

const welcomeScreenConfig = {
  name: "welcome-screen",
  attributes: {
    buttonText: "Let's start!",
    nextBtnLabel: false,
    classnames: "",
    description: "",
    label: "",
    customHTML: "",
    layout: "stack",
    attachmentFocalPoint: {
      x: 0.5,
      y: 0.5,
    },
    attachmentFancyBorderRadius: false,
    attachmentBorderRadius: "0px",
    attachmentMaxWidth: "none",
  },
};

const groupConfig = {
  name: "group",
  attributes: {
    nextBtnLabel: false,
    classnames: "",
    description: "",
    label: "",
    customHTML: "",
    layout: "stack",
    attachmentFocalPoint: {
      x: 0.5,
      y: 0.5,
    },
    attachmentFancyBorderRadius: false,
    attachmentBorderRadius: "0px",
    attachmentMaxWidth: "none",
  },
};

const smartViewRoleList = [
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Lead",
    value: "lead",
  },
  {
    label: "Member",
    value: "member",
  },
];

const pageSchemaConfig = {
  top_section: {
    heading: "Personal Loans",
    key_points: [
      "Personal Loans upto 50Lakhs",
      "Cheapest Rate of Interest",
      "Instant Approval",
      "Quick and Easy Process",
    ],
    bg: "https://djr5js929hkg.cloudfront.net/4b2b1578-b5b3-4dbb-ad6f-1a35a844d6f1/static/media/bg1.7fd1c744e6b1d8766b90.jpg",
  },
  about_section: {
    heading: "Key Features",
    card_content: [
      {
        icon_type: "calendar",
        title: "Extend upto 5 years",
      },
      {
        icon_type: "percent",
        title: "1.33% per month",
      },
      {
        icon_type: "rupee",
        title: "5,000 to 5,00,000",
      },
    ],
  },
  bottom_section: {
    heading: "Money View Personal loans:",
    key_points: [
      "Complete Digital Process",
      "Faster Disbursal",
      "Anywhere in the country",
    ],
  },
  footer: "Copyright© 2023 Vibe Fintech | Money View. All Rights Reserved",
  form_id: "b3077d1d-e0b7-442e-bca1-e1421e749b3f",
};

const colorsList = [
  "#ff0000",
  "#00ff00",
  "#0000ff",
  "#ff9900",
  "#ff00ff",
  "#00ffff",
  "#ff3300",
  "#33ff00",
  "#0033ff",
  "#ffcc00",
  "#cc00ff",
  "#00ccff",
  "#ff66ff",
  "#66ff33",
  "#3366ff",
  "#ff3366",
  "#ccff66",
  "#6633ff",
  "#ff6633",
  "#99ff66",
  "#9966ff",
  "#ff9966",
  "#66ff99",
  "#ff3399",
  "#9933ff",
];

const CustomRowStyling = {
  default: {
    position: "relative",
  },
  staleData: {
    position: "relative",
    opacity: "0.2",
  },
};

const defaultLocalizationTextConfig = {
  Lead: "Lead",
  lead: "lead",
  LEAD: "LEAD",
};

export {
  pageSize,
  columnWidth,
  features,
  app_id,
  app_version,
  device_os,
  sizes,
  bulkAssignLimit,
  StackedBarConfig,
  refetch_time,
  AdminProjectMenuList,
  TeamLeadAccessNameList,
  autoDialerCampaignTypeList,
  LeadFilterTypeList,
  formBuilderConfig,
  welcomeScreenConfig,
  groupConfig,
  smartViewRoleList,
  pageSchemaConfig,
  colorsList,
  domainName,
  severityOrder,
  callLogsLimit,
  CustomRowStyling,
  leadListingPageSize,
  defaultTableWidgetSize,
  accountIDsForNewPaginationAccess,
  demoAccountID,
  whatsappBusinessAccessAccountIDs,
  userIDsForWABAAccess,
  fbLoginConfigID,
  fbAppID,
  leadListingRowBuffer,
  defaultLocalizationTextConfig,
};

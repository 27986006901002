import React from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import { dataRenderer } from "../../Functions";

const Container = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  border: "1px solid #eceef2",
});

const ChartBottom = styled(Box)({
  width: "100%",
  height: "100%",
});

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const Text = styled(Typography)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "22px",
  margin: 0,
  textAlign: "left",
  color: "#4d4e4f",
});

const CounterText = styled(Typography)({
  fontWeight: 600,
  fontSize: "90px",
  color: "#4d4e4f",
});

//char limit for counter text
const limit = 11;

const OrganizationDetailTopWidget = (props) => {
  const counterValue = 0;

  const getTextFontSize = () => {
    return "5vw";
  };

  const getCounterText = (value) => {
    if (value != null) {
      if (value.toString().length <= limit) {
        return value;
      } else {
        return value.toString().slice(0, limit - 1) + "..";
      }
    }
  };

  const ChartBottomSection = () => {
    if (props.data !== null) {
      return (
        <Wrapper>
          <CounterText
            style={{
              textAlign: "center",
              fontSize: getTextFontSize(),
            }}
          >
            {getCounterText(dataRenderer(props.value))}
          </CounterText>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    }
  };

  return (
    <>
      <Container>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px",
            height: "20%",
          }}
        >
          <Text
            sx={{
              fontSize: "16px",
            }}
          >
            {props.title}
          </Text>
        </Box>

        <ChartBottom>
          <ChartBottomSection />
        </ChartBottom>
      </Container>
    </>
  );
};

export default observer(OrganizationDetailTopWidget);

import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  withStyles,
  Grid,
  Button,
  Drawer,
} from "@material-ui/core";
import { Add, ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomerDetailsApi } from "../../Api";
import CustomInfoTab from "../../components/customer_page_tabs/CustomInfoTab";
import BasicInfoTab from "../../components/customer_page_tabs/BasicInfoTab";
import FilesTab from "../../components/customer_page_tabs/FilesTab";
import LeadsTab from "../../components/customer_page_tabs/LeadsTab";
import { isAccountIdMatched } from "../../Functions";
import HistoryTab from "../../components/customer_page_tabs/HistoryTab";
import CreateLeadFromCustomerModal from "../../components/customer_page_components/CreateLeadFromCustomerModal";
import ActitivitiesTab from "../../components/customer_page_tabs/ActitivitiesTab";
import CreateLeadComponentWithSections from "../../components/create_lead_modal/CreateLeadComponentWithSections";
import CustomerDetailsWABAChatSidepanel from "../../components/whatsapp_business_chat_components/CustomerDetailsWABAChatSidepanel";
import CustomerDetailsStickyIconSidebar from "../../components/user_tabs/CustomerDetailsStickyIconSidebar";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MessageText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);

const CustomerDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const tabsToGroups = rootStore.customerStore.tabsToGroups;
  const groupsToColumns = rootStore.customerStore.groupsToColumns;
  const tabs = rootStore.customerStore.tabsList;
  const [loading, setLoading] = useState(true);
  const [loadingCustomerDetails, setLoadingCustomerDetails] = useState(true);
  const [customerData, setCustomerData] = useState({});
  const [refreshCustomerDetails, setRefreshCustomerDetails] = useState(false);
  const [openCreateLead, setOpenCreateLead] = useState(false);
  const [openCreateLeadPanel, setOpenCreateLeadPanel] = useState(false);
  const [createLeadData, setCreateLeadData] = useState({});
  const [openWhatsAppBusinessChat, setOpenWhatsAppBusinessChat] =
    useState(false);
  const projectList = [...rootStore.authStore.projectList];

  const getCustomerDetails = async () => {
    setLoadingCustomerDetails(true);
    let response = await getCustomerDetailsApi({ customerID: id });
    setCustomerData(response);
    setLoadingCustomerDetails(false);
  };

  const handleWhatsappBusiness = () => {
    setOpenWhatsAppBusinessChat(true);
  };

  const init = async () => {
    await getCustomerDetails();
    if (tabs.length > 0) {
      rootStore.customerStore.updateDetailsCurrentTabID(tabs[0]["id"]);
    }
    setLoading(false);
  };

  const refetchCustomerDetails = async () => {
    let response = await getCustomerDetailsApi({ customerID: id });
    setCustomerData(response);
    setRefreshCustomerDetails(false);
  };

  useEffect(() => {
    if (refreshCustomerDetails) {
      refetchCustomerDetails();
    }
  }, [refreshCustomerDetails]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();

    return () => {
      rootStore.customerStore.updateCustomerLeadsScope("open");
    };
  }, []);

  const isCustomerFromCurrentAccount = () => {
    if (customerData.hasOwnProperty("account_id")) {
      return isAccountIdMatched({ accountID: customerData["account_id"] });
    }
    return false;
  };

  const isUserMember = () => {
    let accountRole = rootStore.userStore.UserData.role;

    if (accountRole === "member") {
      return true;
    }
    return false;
  };

  const renderCreateLeadButton = () => {
    if (isUserMember()) {
      if (projectList.length !== 0) {
        return (
          <Grid item>
            <Button
              onClick={() => {
                setOpenCreateLead(true);
              }}
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                color: "white",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                columnGap: "6px",
              }}
            >
              <Add /> Create Lead
            </Button>
          </Grid>
        );
      }
    } else {
      return (
        <Grid item>
          <Button
            onClick={() => {
              setOpenCreateLead(true);
            }}
            variant="contained"
            style={{
              backgroundColor: "#185DD2",
              color: "white",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              columnGap: "6px",
            }}
          >
            <Add /> Create Lead
          </Button>
        </Grid>
      );
    }
  };

  return !loading ? (
    isCustomerFromCurrentAccount() ? (
      <>
        <ParentContainer>
          <Container>
            <Row>
              <TopWrapper>
                <Header
                  style={{ color: "#185DD2", cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Customers
                </Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>{id}</Header>
              </TopWrapper>
            </Row>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Tabs
                  value={rootStore.customerStore.detailsCurrentTabID}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    rootStore.customerStore.updateDetailsCurrentTabID(newValue);
                  }}
                >
                  {tabs.map((tab, index) => {
                    return (
                      <CustomTab
                        value={tab["id"]}
                        label={tab["name"]}
                        key={index}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              {renderCreateLeadButton()}
            </Grid>

            {(() => {
              if (rootStore.customerStore.detailsCurrentTabID === "leads") {
                return <LeadsTab id={id} />;
              } else if (
                rootStore.customerStore.detailsCurrentTabID === "activities"
              ) {
                return <ActitivitiesTab customerID={id} />;
              } else if (
                rootStore.customerStore.detailsCurrentTabID === "history"
              ) {
                return <HistoryTab id={id} />;
              } else if (
                rootStore.customerStore.detailsCurrentTabID === "files"
              ) {
                return <FilesTab id={id} />;
              } else {
                let index = tabs.findIndex(
                  (tab) =>
                    tab["id"] === rootStore.customerStore.detailsCurrentTabID
                );
                if (index !== -1) {
                  let tabName = tabs[index]["name"];
                  console.log("tabname::::::", tabName);
                  if (tabName.toLowerCase() === "basic details") {
                    return (
                      <BasicInfoTab
                        loading={loadingCustomerDetails}
                        customerData={customerData}
                        groups={
                          tabsToGroups[
                            rootStore.customerStore.detailsCurrentTabID
                          ]
                        }
                        groupsToColumns={groupsToColumns}
                        setRefresh={setRefreshCustomerDetails}
                        customerID={id}
                      />
                    );
                  } else {
                    return (
                      <CustomInfoTab
                        customerID={id}
                        setRefresh={setRefreshCustomerDetails}
                        loading={loadingCustomerDetails}
                        customerData={customerData}
                        groups={
                          tabsToGroups[
                            rootStore.customerStore.detailsCurrentTabID
                          ]
                        }
                        groupsToColumns={groupsToColumns}
                      />
                    );
                  }
                }
              }
            })()}
          </Container>
        </ParentContainer>
        <Footer />
        {openWhatsAppBusinessChat && (
          <Drawer
            disableEnforceFocus
            anchor={"right"}
            open={openWhatsAppBusinessChat}
            onClose={() => {
              setOpenWhatsAppBusinessChat(false);
            }}
          >
            <CustomerDetailsWABAChatSidepanel
              setOpen={setOpenWhatsAppBusinessChat}
              open={openWhatsAppBusinessChat}
              customerData={customerData}
            />
          </Drawer>
        )}
        {openCreateLead && (
          <CreateLeadFromCustomerModal
            setOpen={setOpenCreateLead}
            open={openCreateLead}
            customerData={customerData}
            createLeadCallback={(leadData) => {
              setOpenCreateLeadPanel(true);
              setCreateLeadData(leadData);
            }}
          />
        )}
        <Drawer
          anchor={"right"}
          open={openCreateLeadPanel}
          onClose={() => {
            setOpenCreateLeadPanel(false);
          }}
        >
          {
            <CreateLeadComponentWithSections
              open={openCreateLeadPanel}
              setOpen={setOpenCreateLeadPanel}
              refresh={refreshCustomerDetails}
              setRefresh={setRefreshCustomerDetails}
              scope={"customer"}
              xdata={createLeadData}
            />
          }
        </Drawer>
        <CustomerDetailsStickyIconSidebar handleWhatsappBusiness={handleWhatsappBusiness}/>
      </>
    ) : (
      <Box
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MessageText>Some error occurred! Error code:1018</MessageText>
      </Box>
    )
  ) : (
    <Box
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CustomerDetailsPage);

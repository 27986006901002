import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Edit, Visibility, Delete } from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getLocalizedText } from "../../../Functions";

const TableHeaderCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
`;
const TableDataCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
  position: relative;
`;

export default function TableComponent({
  tableData = [],
  setSelectedProductData,
  setOpenEditProductModal,
  setOpenViewProductModal,
  handleDeleteProduct,
  isFormDisabled,
  handleCloneItem,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(0);

  const Open = Boolean(anchorEl);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedProductData({});
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        height: "calc(100vh - 494px)",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px", // Customize scrollbar width
          height: "6px", // Customize scrollbar height for horizontal scroll
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Color of the scrollbar thumb
          borderRadius: "10px", // Roundness of the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Color of the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
        },
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Item</TableHeaderCell>
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            <TableHeaderCell align="right">Qty</TableHeaderCell>
            <TableHeaderCell align="right">Tax (%)</TableHeaderCell>
            <TableHeaderCell align="right">Discount (%)</TableHeaderCell>
            <TableHeaderCell align="right">Amount</TableHeaderCell>
            <TableHeaderCell align="right">{""}</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  {row["item"]["name"]}
                </TableDataCell>
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                <TableDataCell align="right">{row["tax"]}</TableDataCell>
                <TableDataCell align="right">{row["discount"]}</TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
                <TableDataCell align="right">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, i)}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    style={{ display: "flex", flexDirection: "column" }}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={Open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "fit-content",
                      },
                    }}
                  >
                    <MenuItem
                      disabled={isFormDisabled}
                      onClick={() => {
                        handleClose();

                        if (!isFormDisabled) {
                          setSelectedProductData(tableData[selectedRowIndex]);
                          setOpenViewProductModal(true);
                        }
                      }}
                    >
                      <Visibility
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="primary"
                      />
                      {getLocalizedText("view_item")}
                    </MenuItem>
                    <MenuItem
                      disabled={isFormDisabled}
                      onClick={() => {
                        handleClose();

                        if (!isFormDisabled) {
                          setSelectedProductData(tableData[selectedRowIndex]);
                          setOpenEditProductModal(true);
                        }
                      }}
                    >
                      <Edit
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="primary"
                      />
                      {getLocalizedText("edit_item")}
                    </MenuItem>
                    <MenuItem
                      disabled={isFormDisabled}
                      onClick={() => {
                        handleClose();

                        if (!isFormDisabled) {
                          handleDeleteProduct(selectedRowIndex);
                        }
                      }}
                    >
                      <Delete
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="error"
                      />
                      {getLocalizedText("delete_item")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();

                        handleCloneItem(tableData[selectedRowIndex]);
                      }}
                    >
                      <ContentCopyIcon
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="primary"
                      />
                      {getLocalizedText("clone_item")}
                    </MenuItem>
                  </Menu>
                </TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell
                style={{ textAlign: "center", height: "calc(100vh - 552px)" }}
                colSpan={7}
              >
                no items.
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Dialog, Tooltip, Typography, Zoom } from "@mui/material";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { startDayApi } from "../../Api";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { getLocalizedText } from "../../Functions";

const Container = styled(Box)`
  background-color: white;
  display: flex;
  height: 254px;
  width: 400px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
`;

const BottomWrapper = styled(Box)`
  background-color: white;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 8;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
  row-gap: 20px;
`;
const TopWrapper = styled(Box)`
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  background-color: #1976d2;
`;
const Text1 = styled(Typography)`
  font: normal normal 600 24px Open Sans;
  color: white;
  text-align: center;
`;
const Text2 = styled(Typography)`
  font: normal normal 600 24px Open Sans;
  color: #4d4e4f;
`;
const Text3 = styled(Typography)`
  font: normal normal 400 14px Open Sans;
  color: #4d4e4f;
`;
const Text4 = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={500} ref={ref} {...props} />;
});
const QuickStartDialog = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const init = async () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    setOpen(true);
  };
  useEffect(() => {
    init();
  }, []);

  const getGreetingMessage = () => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour <= 16) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const handleStart = async () => {
    rootStore.authStore.fetchUserStartDayApiCall();
    setOpen(false); //directly closing the dialog after api call (false or true), else the user will get a blocker (if api fails)
  };

  function truncate(str, n = 22) {
    return (
      <Tooltip title={str}>
        <Text1>{str.length > n ? str.slice(0, n - 1) + "..." : str}</Text1>
      </Tooltip>
    );
  }
  return (
    <Dialog
      open={open}
      sx={{
        backdropFilter: "blur(5px)",
      }}
      TransitionComponent={Transition}
    >
      <Container>
        <TopWrapper>
          {rootStore.userStore.UserData !== null &&
          rootStore.userStore.UserData.hasOwnProperty("name") &&
          rootStore.userStore.UserData["name"] !== "" &&
          rootStore.userStore.UserData["name"] !== null ? (
            <>
              <Text1>{getGreetingMessage()},</Text1>
              {truncate(rootStore.userStore.UserData["name"])}
            </>
          ) : (
            <Text1>{getGreetingMessage()}!</Text1>
          )}
        </TopWrapper>
        <BottomWrapper>
          <TextWrapper>
            {isLoading ? (
              <>
                <Text2>Get set!</Text2>
                <Text3>
                  {getLocalizedText("leads_for_today_are_loading", "project")}
                </Text3>
              </>
            ) : (
              <>
                <Text4>
                  {getLocalizedText(
                    "leads_unleashed_lets_rock_and_convert",
                    "project"
                  )}
                </Text4>
              </>
            )}
          </TextWrapper>
          {isLoading ? (
            <LoadingButton
              loading
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                margin: "auto",
              }}
            >
              <span>Start</span>
            </LoadingButton>
          ) : (
            <Button
              onClick={handleStart}
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                margin: "auto",
              }}
            >
              Start
            </Button>
          )}
        </BottomWrapper>
      </Container>
    </Dialog>
  );
};

export default observer(QuickStartDialog);

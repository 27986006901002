import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Drawer,
  IconButton,
  Switch,
} from "@material-ui/core";
import {
  Add,
  ChevronRight,
  Edit,
  History,
  Search,
  Timeline,
} from "@material-ui/icons";
import { NewUSerSettingColumns } from "../../Db";
import {
  disableAdminUserApi,
  enableAdminUserApi,
  getAdminMembersApi,
  getAdminUsersApi,
  getTeamApi,
} from "../../Api";
import AddUserModal from "../../components/admin_setting_modals/AddUserModal";
import {
  IsSameUser,
  getCurrentEpochTime,
  isMoreThanXDaysAgo,
  validateDisabledFeature,
} from "../../Functions";
import {
  Backdrop,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Tooltip,
} from "@mui/material";
import rootStore from "../../stores/RootStore";
import analytics from "../../Analytics";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled as muiStyled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CsvDownloader from "react-csv-downloader";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import SuccessMessageDialog from "../../components/alert_dialogue/SuccessMessageDialog";
import CustomErrorAlertWithContinue from "../../components/alert_dialogue/CustomErrorAlertWithContinue";
import AddUserSidepanel from "../../components/admin_setting_modals/AddUserSidepanel";
import ViewAdminUserSidepanel from "../../components/view_adminuser_panel/ViewAdminUserSidepanel";
import CustomMessageDialog from "../../components/alert_dialogue/CustomMessageDialog";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";

const ChipsWrapper = muiStyled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const SpinnerWrapper = muiStyled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "auto",
  alignItems: "center",
}));
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #efeff4;
  height: 40px;
  width: 100%;
  padding: 8px 20px;
  border-radius: 8px;
`;
const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`;
const SearchInput = styled("input")(({ theme }) => ({
  height: "40px",
  border: "none",
  outline: "none",
  width: "100%",
  color: "#4D4E4F",
  font: "normal normal 500 14px Open Sans",
  padding: "2px",
  backgroundColor: "#efeff4",
  borderRadius: "8px",
  "&::placeholder": {
    fontSize: "14px",
    color: "#2B2B2B",
  },
}));
const TopBar = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
`;

const ITEM_HEIGHT = 48;

const MemberSetting = () => {
  const [row, setRow] = useState([]);
  const [filteredRows, setFilteredRows] = useState();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editableData, setEditableData] = useState({});
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState("");
  const [showConfirmAlertDialog, setShowConfirmAlertDialog] = useState(false);
  const [viewUserInfoPanel, setViewUserInfoPanel] = useState(false);
  const chipData = [
    { key: 0, label: "All" },
    { key: 1, label: "Active" },
    { key: 2, label: "Inactive" },
    { key: 3, label: "Not Used for 3+ days" },
    { key: 4, label: "Not Used for 7+ days" },
  ];

  const [selectedChipIndex, setSelectedChipIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const [openAlertMessageDialog, setOpenAlertMessageDialog] = useState(false);
  let navigate = useNavigate();
  let presetOrder = ["owner", "admin", "member"];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const enableUser = async (id) => {
    let response = await enableAdminUserApi(id);
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setShowAlert(true);
    }
  };
  const disableUser = async (id) => {
    let response = await disableAdminUserApi({
      userID: id,
      queryParams: { force: "false" },
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowConfirmAlertDialog(true);
    } else {
      setSuccessMessage("User is disabled successfully!");
      setShowSuccessDialog(true);
      setRefresh(true);
    }
  };
  const handleUserActive = ({ flag, id }) => {
    setSelectedUserID(id);
    if (flag) {
      disableUser(id);
    } else {
      enableUser(id);
    }
  };

  const handleConfirm = async () => {
    let response = await disableAdminUserApi({
      userID: selectedUserID,
      queryParams: { force: "true" },
    });
    setShowConfirmAlertDialog(false);
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setSuccessMessage("User is disabled successfully!");
      setShowSuccessDialog(true);
      setRefresh(true);
    }
  };

  const getMembersList = async () => {
    setRow([]);
    try {
      let response = await getAdminUsersApi();
      let newList = [];
      if (response.data.length > 0) {
        newList = response.data;
      }
      const map = new Map();
      presetOrder.forEach((x, i) => map.set(x, i));
      newList.sort((x, y) => map.get(x.role) - map.get(y.role));
      setRow(newList);
      filter(newList, query, selectedChipIndex);
    } catch (error) {
      setRow([]);
      console.log(error);
    }
  };
  const init = async () => {
    await getMembersList();
    setLoading(false);
  };

  const refetchMembers = async () => {
    await getMembersList();
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetchMembers();
    }
  }, [refresh]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000117,
      "admin_users_page_load",
      "admin_users_page",
      "",
      {}
    );
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const filter = (row, query, index) => {
    let data = [...row];
    if (index === 0) {
      data = [...row];
    }
    if (index === 1) {
      data = data.filter((element) => element["active"]);
    }
    if (index === 2) {
      data = data.filter((element) => element["active"] === false);
    }
    if (index === 3) {
      data = data.filter(
        (element) =>
          element["active"] &&
          isMoreThanXDaysAgo(3, element.last_action_at ?? element.updated_at)
      );
    }
    if (index === 4) {
      data = data.filter(
        (element) =>
          element["active"] &&
          isMoreThanXDaysAgo(7, element.last_action_at ?? element.updated_at)
      );
    }
    if (query === "") {
      setFilteredRows(data);
    } else {
      if (query.includes("phone:")) {
        let phone = query.split(":")[1].trim();
        data = data.filter((element) => element.phone.includes(phone));
      } else if (query.includes("group:")) {
        let group = query.split(":")[1].trim().toLowerCase();

        data = data.filter((element) => {
          let userGroup = element.group ?? "";
          return userGroup.toLowerCase().includes(group);
        });
      } else {
        data = data.filter((element) =>
          element.name.toLowerCase().includes(query.toLowerCase())
        );
      }

      setFilteredRows(data);
    }
  };

  const actionColumn = [
    {
      field: "active",
      headerName: "Active",
      sortable: false,

      width: 100,
      cellRenderer: (params) => {
        return (
          <>
            <Switch
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleUserActive({
                  flag: params.data.active,
                  id: params.data.id,
                });
              }}
              checked={params.data.active}
              disabled={
                IsSameUser(params.data.id) ||
                params.data.role === "owner" ||
                params.data.name === "system"
              }
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      minWidth: 100,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Edit"}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  analytics.triggerEvent(
                    4625000122,
                    "edit_button_tap",
                    "admin_users_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.data);
                  setIsEdit(true);
                  setOpen(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Checkin History"}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  analytics.triggerEvent(
                    4625000123,
                    "checkins_button_tap",
                    "admin_users_page",
                    "checkins_button",
                    {}
                  );
                  navigate(`/admin/checkin/${params.data.id}`);
                }}
              >
                <History
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Login History"}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate(`/admin/users-login-history/${params.data.id}`);
                }}
              >
                <Timeline
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const generateProjectUserData = async () => {
    setIsCsvDataLoading(true);
    let data = [];
    for (const project of rootStore.authStore.projectList) {
      let response = await getAdminMembersApi(project["id"]);
      for (const member of response.data) {
        data.push({
          name: member["name"],
          project: project["name"],
          team: member["team"]["name"],
          role: member["role"],
        });
      }
    }
    data.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setIsCsvDataLoading(false);
    return Promise.resolve(data);
  };
  const generateProjectData = async () => {
    setIsCsvDataLoading(true);
    let data = [];
    for (const project of rootStore.authStore.projectList) {
      let response = await getAdminMembersApi(project["id"]);
      let teamsResponse = await getTeamApi(project["id"]);
      for (const team of teamsResponse) {
        let filteredMembers = response.data.filter(
          (element) => element["team"]["id"] === team["id"]
        );

        data.push({
          project: project["name"],
          team: team["name"],
          no_of_users: filteredMembers.length,
        });
      }
    }
    setIsCsvDataLoading(false);
    return Promise.resolve(data);
  };
  const generateUserData = async () => {
    setIsCsvDataLoading(true);
    let data = [];
    row.forEach((element) => {
      data.push({
        name: element["name"],
        email: element["email"],
        phone: element["phone"],
        role: element["role"],
        active: element["active"],
      });
    });
    setIsCsvDataLoading(false);
    return Promise.resolve(data);
  };

  const getNumberOfActiveUsers = () => {
    return row.filter((user) => user["active"] === true).length;
  };
  return loading ? (
    <SpinnerWrapper>
      <LoadingAnimationComponent size={"small"} />
    </SpinnerWrapper>
  ) : (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Admin</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>Users</Header>
            </TopWrapper>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={Open ? "long-menu" : undefined}
              aria-expanded={Open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              anchorEl={anchorEl}
              open={Open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                },
              }}
            >
              <CsvDownloader
                filename={`user_report_${getCurrentEpochTime()}`}
                datas={generateUserData}
                columns={[
                  { displayName: "Name", id: "name" },
                  { displayName: "email", id: "email" },
                  { displayName: "Phone", id: "phone" },
                  { displayName: "Role", id: "role" },
                  { displayName: "Active", id: "active" },
                ]}
                handleError={(error) => {
                  console.log(error);
                }}
              >
                <MenuItem key={"user_report"} style={{ width: "100%" }}>
                  <span style={{ color: "#4d4e4f" }}>User Level Report</span>
                </MenuItem>
              </CsvDownloader>
              <CsvDownloader
                filename={`project_report_${getCurrentEpochTime()}`}
                datas={generateProjectData}
                columns={[
                  { displayName: "Project", id: "project" },
                  { displayName: "Team", id: "team" },
                  { displayName: "No. of Users", id: "no_of_users" },
                ]}
              >
                <MenuItem key={"project_report"}>
                  <span style={{ color: "#4d4e4f" }}>Project Level Report</span>
                </MenuItem>
              </CsvDownloader>
              <CsvDownloader
                filename={`project_user_report_${getCurrentEpochTime()}`}
                datas={generateProjectUserData}
                columns={[
                  { displayName: "Name", id: "name" },
                  { displayName: "Project", id: "project" },
                  { displayName: "Team", id: "team" },
                  { displayName: "Role", id: "role" },
                ]}
              >
                <MenuItem key={"project_user_report"}>
                  <span style={{ color: "#4d4e4f" }}>Project User Report</span>
                </MenuItem>
              </CsvDownloader>
            </Menu>
          </Row>
          <TopBar>
            <SearchWrapper>
              <Search
                style={{
                  width: "22px",
                  height: "22px",
                  color: "#4D4E4F",
                  marginRight: "4px",
                }}
              />
              <SearchInput
                placeholder="Search"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  filter(row, e.target.value, selectedChipIndex);
                }}
              />
            </SearchWrapper>
            <Button
              onClick={() => {
                analytics.triggerEvent(
                  4625000118,
                  "add_user_button_tap",
                  "admin_users_page",
                  "add_user_button",
                  {}
                );
                if (
                  rootStore.subscriptionState.hasReachedMaxUserLimit({
                    totalUsers: getNumberOfActiveUsers(),
                  }) === false
                ) {
                  setOpen(true);
                } else {
                  setOpenAlertMessageDialog(true);
                }
              }}
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "160px",
                marginLeft: "20px",
                height: "40px",
              }}
            >
              <Add style={{ width: "18px", height: "18px" }} /> Add User
            </Button>
          </TopBar>
          <ChipsWrapper>
            {chipData.map((data) => {
              return (
                <Chip
                  key={data.key}
                  color={selectedChipIndex === data.key ? "primary" : "default"}
                  label={data.label}
                  onClick={() => {
                    setSelectedChipIndex(data.key);
                    filter(row, query, data.key);
                  }}
                />
              );
            })}
          </ChipsWrapper>
          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={filteredRows}
                  defaultColDef={defaultColDef}
                  columnDefs={NewUSerSettingColumns.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  suppressColumnVirtualisation={true}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setEditableData(row.data);
                    setViewUserInfoPanel(true);
                  }}
                />
              </div>
            ) : (
              <ProgressWrapper>
                <LoadingAnimationComponent size={"small"} />
              </ProgressWrapper>
            )}
          </TableWrapper>
        </Container>
      </ParentContainer>
      <Footer />

      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
            setOpen(false);
            setIsEdit(false);
          }}
        >
          <AddUserSidepanel
            setOpen={setOpen}
            open={open}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={editableData}
            refresh={refresh}
            setRefresh={setRefresh}
            userList={row}
          />
        </Drawer>
      )}
      {viewUserInfoPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={viewUserInfoPanel}
          onClose={() => {
            setViewUserInfoPanel(false);
          }}
        >
          <ViewAdminUserSidepanel
            setOpen={setViewUserInfoPanel}
            open={viewUserInfoPanel}
            data={editableData}
            handleEdit={() => {
              setIsEdit(true);
              setOpen(true);
              setViewUserInfoPanel(false);
            }}
            handleUserActive={handleUserActive}
            list={row}
            refresh={refresh}
          />
        </Drawer>
      )}

      <Dialog
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            style={{ fontWeight: "bold", fontSize: "18px" }}
            id="alert-dialog-description"
          >
            User is enabled! Please add the user to a project and team.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginTop: "-20px" }}>
          <Button
            style={{ margin: "auto" }}
            onClick={() => {
              setShowAlert(false);
              setRefresh(true);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        open={isCsvDataLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <LoadingAnimationComponent size={"small"} />
      </Backdrop>

      {showErrorDialog && (
        <CustomErrorMessageDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          message={errorMessage}
        />
      )}
      {showSuccessDialog && (
        <SuccessMessageDialog
          open={showSuccessDialog}
          setOpen={setShowSuccessDialog}
          message={successMessage}
        />
      )}
      {showConfirmAlertDialog && (
        <CustomErrorAlertWithContinue
          open={showConfirmAlertDialog}
          setOpen={setShowConfirmAlertDialog}
          errorMessage={errorMessage}
          confirmMessage={""}
          handleConfirm={() => {
            handleConfirm();
          }}
        />
      )}

      {openAlertMessageDialog && (
        <CustomMessageDialog
          open={openAlertMessageDialog}
          setOpen={setOpenAlertMessageDialog}
          message={
            "You've reached the maximum number of users allowed for your account. Please get in touch with support for assistance or to upgrade your plan."
          }
        />
      )}
    </>
  );
};

export default observer(MemberSetting);

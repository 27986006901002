import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  TextField,
  Menu,
  MenuItem,
  CardActionArea,
  Select,
  Backdrop,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { getLocalizedText, consoleLogger, dataRenderer } from "../../Functions";
import moment from "moment";
import { Close } from "@material-ui/icons";
import WhatsappChatMessageComponent from "./WhatsappChatMessageComponent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import SendIcon from "@mui/icons-material/Send";
import rootStore from "../../stores/RootStore";
import TemplateMessageSendPopupComponent from "./TemplateMessageSendPopupComponent";
import {
  getWhatsappContactChatMessagesApi,
  getWhatsappMetaWabaProvidersListApi,
  sendContactWhatsAppBusinessChatApi,
  sendContactWhatsAppBusinessChatTextApi,
  sendContactWhatsAppBusinessChatImageApi,
  sendContactWhatsAppBusinessChatVideoApi,
  uploadWbMediaContact,
} from "../../Api";
import WhatsappChatImageSendScreen from "./WhatsappChatImageSendScreen";
import { Refresh } from "@mui/icons-material";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const FilterWrapper = styled(Box)(({ theme }) => ({
  margin: "auto",
  display: "flex",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  width: "100%",
  font: "normal normal 600 18px Open Sans",
}));

const ChatBoxTextField = styled(TextField)(({ theme }) => ({
  width: "95%",
  margin: "auto",
  backgroundColor: "white",
  borderRadius: "8px",
  "& .MuiInputBase-root": {
    maxHeight: "150px",
    overflowY: "auto",
    padding: "8px",
  },
  "& textarea": {
    maxHeight: "150px",
    overflowY: "auto",
    resize: "none",
  },
}));

const ChatBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "fit-content",
  minHeight: "8%",
  padding: "8px",
  overflowY: "auto",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  flex: 1,
  padding: "15px",
  position: "relative",
  overflow: "auto",
  boxSizing: "border-box",
  scrollBehavior: "auto",
}));
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomerDetailsWABAChatSidepanel = (props) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const anchorRef = useRef(null);
  const messageContainerRef = useRef(null);
  const templateList = [...rootStore.authStore.metaWabaBusinessTemplateList];
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openTemplateSelect, setOpenTemplateSelect] = useState(false);
  const [openImageSendView, setOpenImageSendView] = useState(false);
  const [showTopLoader, setShowTopLoader] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [message, setMessage] = useState("");
  const [caption, setCaption] = useState(""); //image caption
  const [infoBubbleMessage, setInfoBubbleMessage] = useState("");
  const [chatTextDisabled, setChatTextDisabled] = useState(true);
  const [templateSendDisabled, setTemplateSendDisabled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [chatDetails, setChatDetails] = useState([]);
  const [nextCursor, setNextCursor] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [providerData, setProviderData] = useState({});
  const [currentFilter, setCurrentFilter] = useState(
    rootStore.authStore.projectId
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);

  const chatFilters = [{ value: "test", label: "test" }];

  const handleTemplateMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStartInfoClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const mergeChatDetails = (chatDetails, newList) => {
    const groupedChatDetails = chatDetails.reduce((groups, group) => {
      groups[group.headerName] = group.messages;
      return groups;
    }, {});

    // Iterate over newList and add each message to the corresponding date group in groupedChatDetails
    newList.forEach((message) => {
      const messageDate = moment(getMessageTime(message)).format("YYYY-MM-DD");

      if (groupedChatDetails[messageDate]) {
        // If the date exists, push the message into that day's array
        groupedChatDetails[messageDate].push(message);
      } else {
        // If the date does not exist, create a new array for that date and add the message
        groupedChatDetails[messageDate] = [message];
      }
    });

    // Sort the dates in ascending order (newest at the bottom)
    const sortedDates = Object.keys(groupedChatDetails).sort();

    // Convert groupedChatDetails back into an array of objects with the position key
    return sortedDates.map((date, index) => ({
      headerName: date,
      messages: sortMessages(groupedChatDetails[date]),
      position: index, // Add the position key
    }));
  };

  const handleScroll = async () => {
    if (messageContainerRef.current) {
      if (messageContainerRef.current.scrollTop === 0) {
        if (!noMoreMessages) {
          console.log("load more messages");

          setShowTopLoader(true); // User has reached the top
          const newList = await getNextCheckpointMessages();
          if (newList.length === 0) {
            //set flag to never check again if list is empty
            setNoMoreMessages(true);
            setShowTopLoader(false);
          } else {
            //if new messages are there after fetch, append to current list and sort
            let mergedChatDetails = mergeChatDetails(chatDetails, newList);
            //consoleLogger("merged:: ", mergeChatDetails);
            setChatDetails(mergedChatDetails);
            setShowTopLoader(false);
          }
        }
      }
    }
  };

  const handleWheel = async (event) => {
    if (messageContainerRef.current) {
      const isScrollable =
        messageContainerRef.current.scrollHeight >
        messageContainerRef.current.clientHeight;

      if (
        !isScrollable && // only when container is not scrollable
        event.deltaY < 0 &&
        !noMoreMessages &&
        !showTopLoader
      ) {
        console.log("load more messages on wheel");

        setShowTopLoader(true); // User has reached the top
        const newList = await getNextCheckpointMessages();
        if (newList.length === 0) {
          //set flag to never check again if list is empty
          setNoMoreMessages(true);
          setShowTopLoader(false);
        } else {
          //if new messages are there after fetch, append to current list and sort
          let mergedChatDetails = mergeChatDetails(chatDetails, newList);
          //const completeList = newList.concat(chatDetails);
          //consoleLogger("merged:: ", mergeChatDetails);
          setChatDetails(mergedChatDetails);
          setShowTopLoader(false);
        }
      }
    }
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };
  const handleFilter = async (scope) => {
    setCurrentFilter(scope);
    const providerForProject = getSelectedProvider(providersList, scope);
    setProviderData(providerForProject);

    //set template list
    let templates = [];

    for (let i = 0; i < templateList.length; i++) {
      if (templateList[i].hasOwnProperty("whatsapp_provider")) {
        if (
          templateList[i]["whatsapp_provider"]["id"] !== null &&
          templateList[i]["whatsapp_provider"]["id"] !== ""
        ) {
          //checking provider mapped with project before pushing
          if (
            templateList[i]["whatsapp_provider"]["id"] ===
              providerForProject["id"] &&
            templateList[i]["status"] === "APPROVED"
          ) {
            templates.push(templateList[i]);
          }
        }
      }
    }
    setAvailableTemplates(templates);

    setRefresh(true);
  };
  const getDateHeaderText = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (moment(date).isSame(today, "day")) {
      return "TODAY";
    } else if (moment(date).isSame(yesterday, "day")) {
      return "YESTERDAY";
    } else if (moment(date)) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const getMessageTime = (messageData) => {
    return messageData.created_at;
  };

  const handlePhotoAdd = (event) => {
    setSelectedImage(event.target.files);
    setOpenImageSendView(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage({ templateData: null, variableDetails: {} });
    }
  };
  const getFullNameString = (details) => {
    let parts = [details.first_name, details.last_name].filter(Boolean);
    let fullName = parts.length ? parts.join(" ") : "-";
    return fullName;
  };

  const handleSendMessage = async ({
    templateData = null,
    variableDetails = {},
    templateFile = null,
  }) => {
    let sendFailed = true;
    let messageID = ""; //setting id empty initially
    if (templateData !== null) {
      let incoming_components = [];
      //adding header media if present
      if (templateFile !== null) {
        const mediaID = await uploadWABAMedia(templateFile[0]);
        const fileType = templateFile[0].type.split("/")[0]; // image or video

        incoming_components.push({
          type: "header",
          parameters: [{ type: fileType, [fileType]: { id: mediaID } }],
        });
      }

      //sending payload with template data
      let bodyPayload = [];

      bodyPayload = Object.values(variableDetails).map((text) => ({
        type: "text",
        text: text,
      }));

      incoming_components.push({
        type: "body",
        parameters: bodyPayload,
      });

      let payload = {
        wt_id: selectedTemplate.id,
        component_parameters: incoming_components,
      };
      consoleLogger("payload:: ", payload);

      const response = await sendContactWhatsAppBusinessChatApi({
        contactID: props.customerData.id,
        payload: payload,
      });

      if (!response.hasError()) {
        if (response.data.id !== "") {
          sendFailed = false;
          messageID = response.data.id; //setting id if it is there
        }
      }

      //setting data for chat message UI
      let newMessage = {
        id: messageID,
        billable: null,
        delivered_at: null,
        direction: "outbound",
        message: {
          text: message,
        },
        message_parameters: [
          {
            type: "body",
            parameters: bodyPayload,
          },
        ],
        message_type: null,
        read_at: null,
        sent_at: moment().toISOString(),
        status: "waiting",
        whatsapp_template_id: selectedTemplate.id,
        user: { name: rootStore.userStore.UserData.name },
      };
      setMessage("");
      setSelectedTemplate({});
      let mergedList = mergeChatDetails(chatDetails, [newMessage]);
      setChatDetails(mergedList);
    } else {
      if (message !== "") {
        let payload = {
          lead_id: props.customerData.id,
          wp_id: providerData.id,
          text: message,
        };
        consoleLogger("payload:: ", payload);

        const response = await sendContactWhatsAppBusinessChatTextApi({
          contactID: props.customerData.id,
          payload: payload,
        });

        if (!response.hasError()) {
          if (response.data.id !== "") {
            sendFailed = false;
            messageID = response.data.id; //setting id if it is there
          }
        }

        let newMessage = {
          id: messageID,
          delivered_at: null,
          direction: "outbound",
          message: {
            body: message,
          },
          message_parameters: null,
          message_type: "text",
          read_at: null,
          sent_at: moment().toISOString(),
          status: "waiting",
          whatsapp_template_id: null,
        };
        setMessage("");
        setSelectedTemplate({});
        let mergedList = mergeChatDetails(chatDetails, [newMessage]);
        setChatDetails(mergedList);
      }
    }
    consoleLogger("send fail:: ", sendFailed);
    if (!sendFailed) {
      setTimeout(() => {
        setRefresh(true);
      }, 1000);
    }
  };

  const uploadWABAMedia = async (file) => {
    const formData = new FormData();
    formData.append("wp_id", providerData.id);
    formData.append("file", file);

    const response = await uploadWbMediaContact({
      contactID: props.customerData.id,
      payload: formData,
    });

    if (!response.hasError()) {
      return response.data.media_id;
    } else {
      console.log(response.errorMessage);
      return "";
    }
  };

  const handleSendMedia = async (file) => {
    // const file = selectedImage[0];
    const fileType = file.type;

    const mediaID = await uploadWABAMedia(file);

    const messagePayload = {
      wp_id: providerData.id,
      media_id: mediaID,
    };

    if (fileType.startsWith("image/")) {
      // image send api
      const messagesResponse = await sendContactWhatsAppBusinessChatImageApi({
        contactID: props.customerData.id,
        payload: messagePayload,
      });
    } else if (fileType.startsWith("video/")) {
      // video send api
      const messagesResponse = await sendContactWhatsAppBusinessChatVideoApi({
        contactID: props.customerData.id,
        payload: messagePayload,
      });
    }

    setOpenImageSendView(false);
  };

  const getFreeTextFieldDisableStatus = () => {
    if (hasOutbound()) {
      if (isNewestMessageOlderThan24Hours()) {
        return false;
      }
      if (hasInbound()) {
        return false;
      }
    }
    return true;
  };

  function hasOutbound() {
    for (let i = 0; i < chatDetails.length; i++) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        for (let j = 0; j < messages.length; j++) {
          const message = messages[j];

          if (message.direction === "outbound") {
            return true;
          }
        }
      }
    }

    return false;
  }

  function hasInbound() {
    for (let i = 0; i < chatDetails.length; i++) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        for (let j = 0; j < messages.length; j++) {
          const message = messages[j];

          if (message.direction === "inbound") {
            return true;
          }
        }
      }
    }

    return false;
  }

  const handleTemplateSelect = (template) => {
    const templateJSON = { ...template };
    setSelectedTemplate(templateJSON);
    setChatTextDisabled(true);
    setOpenTemplateSelect(true);
  };

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  const getNextCheckpointMessages = async () => {
    let queryParams = {
      whatsapp_provider_id: providerData.id,
      next_cursor: nextCursor,
    };
    const response = await getWhatsappContactChatMessagesApi({
      contactID: props.customerData.id,
      queryParams: queryParams,
    });
    setNextCursor(response.next_cursor);
    return response.messages;
  };

  const refetchChatMessages = async () => {
    let queryParams = {
      whatsapp_provider_id: providerData.id,
    };
    const response = await getWhatsappContactChatMessagesApi({
      contactID: props.customerData.id,
      queryParams: queryParams,
    });
    let sortedMessagesResponse = sortMessages(response.messages);
    setNextCursor(response.next_cursor);
    let groupedMessages = groupMessagesByDate(sortedMessagesResponse);
    consoleLogger("groupedMessages:: ", groupedMessages);

    setChatDetails(groupedMessages);
    setRefresh(false);
    consoleLogger("res:: ", response);
  };

  const sortMessages = (messagesList) => {
    return messagesList.sort(
      (a, b) => new Date(getMessageTime(a)) - new Date(getMessageTime(b))
    );
  };

  const isNewestMessageOlderThan24Hours = () => {
    let newestMessageTimestamp = null;
    //checking if latest sent message is older than 24 hours
    for (let i = 0; i < chatDetails.length; i++) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        for (let j = 0; j < messages.length; j++) {
          const message = messages[j];

          if (
            message.direction == "outbound" &&
            message.created_at &&
            (!newestMessageTimestamp ||
              new Date(message.created_at) > new Date(newestMessageTimestamp))
          ) {
            newestMessageTimestamp = message.created_at;
          }
        }
      }
    }

    if (!newestMessageTimestamp) {
      return false;
    }

    const currentTime = new Date();
    const messageTime = new Date(newestMessageTimestamp);
    const timeDifferenceInHours =
      (currentTime - messageTime) / (1000 * 60 * 60);

    return timeDifferenceInHours > 24;
  };

  const groupMessagesByDate = (messages) => {
    const groupedMessages = messages.reduce((groups, message) => {
      const date = moment(getMessageTime(message)).format("YYYY-MM-DD");

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(message);
      return groups;
    }, {});

    // sort the dates in ascending order (newest at the bottom)
    const sortedDates = Object.keys(groupedMessages).sort();

    return sortedDates.map((date, index) => ({
      headerName: date,
      messages: groupedMessages[date],
      position: index,
    }));
  };

  const getSelectedProvider = (providerList, selectedProjectID) => {
    const provider = providerList.find(
      (provider) =>
        provider &&
        provider.project &&
        provider.project.id === selectedProjectID
    );
    return provider || {};
  };

  const init = async () => {
    //fetch provider data
    let providerResponse = await getWhatsappMetaWabaProvidersListApi();
    setProvidersList(providerResponse);

    //setting available projects for select filter
    let availableProjectsList = [];
    for (let i = 0; i < providerResponse.length; i++) {
      if (providerResponse[i].hasOwnProperty("project")) {
        const project = providerResponse[i]["project"];
        availableProjectsList.push(project);
      }
    }
    setAvailableProjects(availableProjectsList);

    const providerForProject = getSelectedProvider(
      providerResponse,
      currentFilter
    );
    setProviderData(providerForProject);

    //set template list
    let templates = [];

    for (let i = 0; i < templateList.length; i++) {
      if (templateList[i].hasOwnProperty("whatsapp_provider")) {
        if (
          templateList[i]["whatsapp_provider"]["id"] !== null &&
          templateList[i]["whatsapp_provider"]["id"] !== ""
        ) {
          //checking provider mapped with project before pushing
          if (
            templateList[i]["whatsapp_provider"]["id"] ===
              providerForProject["id"] &&
            templateList[i]["status"] === "APPROVED"
          ) {
            templates.push(templateList[i]);
          }
        }
      }
    }
    setAvailableTemplates(templates);

    //fetch messages
    const queryParams = {
      whatsapp_provider_id: providerForProject.id,
    };
    const response = await getWhatsappContactChatMessagesApi({
      contactID: props.customerData.id,
      queryParams: queryParams,
    });
    let sortedMessagesResponse = sortMessages(response.messages);
    setNextCursor(response.next_cursor);
    let groupedMessages = groupMessagesByDate(sortedMessagesResponse);
    consoleLogger("groupedMessages:: ", groupedMessages);

    setChatDetails(groupedMessages);

    consoleLogger("res:: ", sortedMessagesResponse);

    setLoading(false);
    scrollToBottom();
  };
  useEffect(() => {
    //set
    setChatResponseDisabled();
  }, [chatDetails]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (refresh) {
      refetchChatMessages();
    }
  }, [refresh]);

  function checkIfMultipleMessagesAreSent() {
    let outboundCount = 0;

    // Loop through chatDetails from last to first
    for (let i = chatDetails.length - 1; i >= 0; i--) {
      const messages = chatDetails[i].messages;

      if (Array.isArray(messages)) {
        // Loop through the messages array from last to first
        for (let j = messages.length - 1; j >= 0; j--) {
          const message = messages[j];

          if (message.direction === "outbound") {
            outboundCount++;
            if (outboundCount === 3) {
              return true; // If last three messages are "outbound", return true
            }
          } else {
            return false; // If a message is not "outbound", return false
          }
        }
      }
    }

    return false; // If less than three "outbound" messages are found
  }

  //set chat text field disable flag and info texts based on message inbound/outbound and other checks
  const setChatResponseDisabled = () => {
    if (chatDetails.length === 0) {
      setInfoBubbleMessage(
        getLocalizedText(
          "send_pre_approved_template_message_to_start_the_conversation"
        )
      );
      setTemplateSendDisabled(false);
      setChatTextDisabled(true);
      return;
    } else {
      const freeTextFieldDisabled = getFreeTextFieldDisableStatus();
      setChatTextDisabled(freeTextFieldDisabled); // setChatTextDisabled(false);
      if (freeTextFieldDisabled) {
        setInfoBubbleMessage(
          getLocalizedText(
            "free_text_messages_are_enabled_once_the_lead_replies_to_your_template"
          )
        );
        setTemplateSendDisabled(true);
        return;
      } else {
        if (checkIfMultipleMessagesAreSent()) {
          //don't restrict if the last message was more than 24 hrs old
          if (!isNewestMessageOlderThan24Hours()) {
            setInfoBubbleMessage(
              getLocalizedText(
                "you_have_sent_multiple_messages_please_wait_for_the_customer_to_respond"
              )
            );
            setTemplateSendDisabled(true);
            setChatTextDisabled(true);
            return;
          }
        }
      }
    }
    setTemplateSendDisabled(false);
    setChatTextDisabled(false);
    setInfoBubbleMessage("");
  };

  const RenderStartInfoTextComponent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          zIndex: 10,
          top: 0,
          marginTop: "8px",
        }}
      >
        <CardActionArea
          disabled={chatDetails.length !== 0}
          sx={{ width: "fit-content", borderRadius: "6px" }}
          onClick={handleStartInfoClick}
        >
          <Box
            style={{
              width: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffeecd",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              padding: "10px 14px",
              borderTop: "1px solid #ffeecd",
              borderBottom: "1px solid #ffeecd",
              borderRadius: "6px",
            }}
          >
            <Typography
              sx={{
                color: "#54656f",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {infoBubbleMessage}
            </Typography>
          </Box>
        </CardActionArea>
      </Box>
    );
  };

  const RenderTopFetchLoaderComponent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "12px",
        }}
      >
        <Box
          sx={{
            width: 44,
            height: 44,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F0F2F5",
          }}
        >
          <CircularProgress style={{ color: "#25D366" }} size="22px" />
        </Box>
      </Box>
    );
  };

  return !loading ? (
    <>
      <Box role="presentation" sx={{ height: "100%", overflow: "hidden" }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                width: "86%",
                display: "flex",
              }}
            >
              <ModelHeader>{getLocalizedText("whatsapp")}</ModelHeader>
              <FilterWrapper>
                <Select
                  classes={{
                    icon: classes.icon,
                  }}
                  name="project_filter"
                  disableUnderline
                  variant="outlined"
                  required
                  value={currentFilter}
                  onChange={(e) => {
                    handleFilter(e.target.value);
                  }}
                  style={{
                    height: "30px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "0px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {availableProjects.map((e) => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FilterWrapper>
            </Box>
            <IconButton
              onClick={() => {
                setRefresh(true);
              }}
              variant="contained"
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "50px",
                cursor: "pointer",
              }}
            >
              <Refresh />
            </IconButton>
            <IconButton
              onClick={handleDrawerClose}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <ModelWrapper
          sx={{
            height: "calc(100vh - 64px)",
            overflow: "hidden",
          }}
        >
          {!openImageSendView ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%", // or specific height
                  overflow: "hidden",
                  backgroundColor: "#e5ddd5",
                }}
              >
                <Backdrop
                  sx={(theme) => ({
                    color: "#fff",
                    zIndex: theme.zIndex.drawer + 1,
                  })}
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 100,
                  }}
                  open={refresh}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <MessageContainer
                  ref={messageContainerRef}
                  onScroll={handleScroll}
                  onWheel={handleWheel}
                >
                  {showTopLoader && <RenderTopFetchLoaderComponent />}
                  <Box sx={{ flex: 1 }}></Box>
                  {/* showing new chat info at the start of chat if chat is empty */}
                  {chatDetails
                    .sort((a, b) => a.position - b.position)
                    .map((group, i) => (
                      <Box key={i} sx={{ width: "100%" }}>
                        {/* Sticky Header */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            zIndex: 10,
                            top: 0,
                            marginBottom: "8px",
                          }}
                        >
                          <Box
                            style={{
                              width: "fit-content",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                              padding: "8px 12px",
                              borderTop: "1px solid #e0e0e0",
                              borderBottom: "1px solid #e0e0e0",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#4d4e4f",
                                textAlign: "center",
                                fontSize: "12px",
                              }}
                            >
                              {getDateHeaderText(group.headerName)}
                            </Typography>
                          </Box>
                        </Box>
                        {group.messages.map((message, index) => (
                          <WhatsappChatMessageComponent
                            key={index}
                            data={message}
                            templateList={templateList}
                            id={props.customerData.id}
                          />
                        ))}
                      </Box>
                    ))}
                  {infoBubbleMessage !== "" && <RenderStartInfoTextComponent />}
                </MessageContainer>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f0f2f5",
                  padding: "6px",
                }}
              >
                <IconButton
                  ref={anchorRef}
                  disabled={templateSendDisabled}
                  onClick={handleTemplateMenuClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <IconButton
                  disabled={chatTextDisabled}
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={inputRef}
                  // accept={acceptedFiles}
                  onClick={() => {
                    inputRef.current.value = "";
                  }}
                  onChange={(event) => handlePhotoAdd(event)}
                />
                <ChatBoxContainer>
                  <Menu
                    style={{ display: "flex", flexDirection: "column" }}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={Open}
                    onClose={handleClose}
                  >
                    {" "}
                    {availableTemplates.length > 0 ? (
                      availableTemplates.map((template) => (
                        <MenuItem
                          key={template.id}
                          onClick={() => {
                            handleTemplateSelect(template);
                            setAnchorEl(null);
                          }}
                        >
                          {template.template_title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>
                        {getLocalizedText("no_templates_found")}
                      </MenuItem>
                    )}
                  </Menu>
                  <ChatBoxTextField
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "14px",
                      },
                    }}
                    multiline
                    disabled={chatTextDisabled}
                    placeholder="Type a message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </ChatBoxContainer>
                <IconButton
                  disabled={chatTextDisabled}
                  onClick={handleSendMessage}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <WhatsappChatImageSendScreen
              caption={caption}
              setCaption={setCaption}
              setOpenImageSendView={setOpenImageSendView}
              fileURL={URL.createObjectURL(selectedImage[0])}
              handleSendMedia={() => {
                handleSendMedia(selectedImage[0]);
              }}
            />
          )}
        </ModelWrapper>
      </Box>
      {openTemplateSelect && (
        <TemplateMessageSendPopupComponent
          setOpen={setOpenTemplateSelect}
          open={openTemplateSelect}
          data={selectedTemplate}
          leadData={props.customerData}
          onSendCallback={(data, details, templateFile) => {
            handleSendMessage({
              templateData: data,
              variableDetails: details,
              templateFile: templateFile,
            });
          }}
          onCloseCallback={() => {
            setSelectedTemplate({});
          }}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CustomerDetailsWABAChatSidepanel);

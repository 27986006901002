import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Card, CardActionArea } from "@mui/material";
import rootStore from "../../stores/RootStore";
import businessIcon from "../../assets/images/whatsapp-business.svg";
import { hasAccessToWABAFeatures } from "../../Functions";

const Container = styled(Box)(({ theme }) => ({}));

const CustomerDetailsStickyIconSidebar = ({
  handleWhatsappBusiness,
}) => {
  const { authStore } = rootStore;

  return (
    <>
      <Container
        sx={{
          position: "fixed",
          top: "50vh",
          right: 0,
          height: "25vh",
          width: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          zIndex: 10,
        }}
      >
  
        {hasAccessToWABAFeatures() && (
          <Card
            sx={{
              display: "flex",
              width: "200px",
              height: "60px",
              transform: "translate(75px,0px)",
              alignItems: "center",
              margin: "2px",
              marginBottom: "6px",
              textDecoration: "none",
              transition: "transform 0.6s",
              "&:hover": {
                transform: "translate(-40px,0px)",
              },
            }}
          >
            <CardActionArea
              sx={{ width: "100%", height: "100%" }}
              onClick={(e) => {
                authStore.updateLastActiveTime();
                handleWhatsappBusiness();
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: "14px",
                  padding: "14px",
                }}
              >
                <img src={businessIcon} alt="Whatsapp Business" width="30px" />
                <Typography
                  variant="body1"
                  sx={{
                    width: "100%",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  WhatsApp Business
                </Typography>
              </Box>
            </CardActionArea>{" "}
          </Card>
        )}
      </Container>
    </>
  );
};

export default CustomerDetailsStickyIconSidebar;

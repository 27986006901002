import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import {
  AppBar,
  Backdrop,
  Box,
  OutlinedInput,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import {
  createCustomerApi,
  getListApi,
  searchContactByEmailApi,
  searchContactByPhoneApi,
  searchOrganizationsByNameApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";
import NumberField from "../custom_field_components/NumberField";
import DateField from "../custom_field_components/DateField";
import DateTimeField from "../custom_field_components/DateTimeField";
import SearchableSelectField from "../custom_field_components/SearchableSelectField";
import EntityDataPickerDialog from "./EntityDataPickerDialog";
import CustomTextField from "../custom_field_components/CustomTextField";
import { getSlug } from "../../Functions";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreateCustomerSidepanel = (props) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortedColumnsList, setSortedColumnsList] = useState([]);
  const columnsList = [...rootStore.customerStore.columnsList];
  const [listFieldInputMeta, setListFieldInputMeta] = useState({});
  const [selectedEntityByType, setSelectedEntityByType] = useState({});
  const [entityType, setEntityType] = useState("");
  const [entitiesListByType, setEntitiesListByType] = useState({});
  const [openEntityDataPickerDialog, setOpenEntityDataPickerDialog] =
    useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [payloadData, setPayloadData] = useState({});
  const [isOrgNameDirty, setIsOrgNameDirty] = useState(false);
  const [dialogContext, setDialogContext] = useState("off_focus"); //state variable to track from where dialog is evoked

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleSubmit = async () => {
    if (isOrgNameDirty) {
      let entityList = [];
      if (fieldValues["organization_name"] !== "") {
        const organizationResponse = await searchOrganizationsByNameApi({
          queryParams: { name: fieldValues["organization_name"] },
        });
        entityList = organizationResponse;
      }
      setIsOrgNameDirty(false);
      setDialogContext("form_submit");
      setEntityType(entityType);
      setEntitiesListByType({
        ...entitiesListByType,
        [entityType]: entityList,
      });
      if (entityList.length > 0) {
        setOpenEntityDataPickerDialog(true);
        return;
      }
    }

    let response = await createCustomerApi({
      payload: payloadData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const initFieldValues = (columnsList) => {
    let tempFieldValues = {};
    columnsList.forEach((entry) => {
      tempFieldValues[entry["column_name"]] = "";
    });
    setFieldValues(tempFieldValues);
  };

  const updatePayloadValue = (key, value) => {
    const columnIndex = sortedColumnsList.findIndex(
      (entry) => entry["column_name"] === key
    );

    if (columnIndex !== -1) {
      let fieldValue = value;
      const columnData = sortedColumnsList[columnIndex];

      // if filter type is list: field will be a dropdown where it stores value as {label:"",value:""}
      if (columnData["filter_type"] === "list") {
        fieldValue = value !== null && value !== "" ? value["value"] : "";
      }

      if (columnData["data_type"] === "integer") {
        fieldValue =
          fieldValue !== null && fieldValue !== "" ? parseInt(fieldValue) : "";
      }

      if (columnData.hasOwnProperty("is_array") && columnData["is_array"]) {
        if (value !== null && Array.isArray(value)) {
          let tempList = [];
          value.forEach((entry) => {
            tempList.push(entry["value"]);
          });
          fieldValue = tempList.join(",");
        }
      }

      let tempPayloadData = { ...payloadData };

      const entityType =
        columnData["entity"] !== null ? columnData["entity"] : "";

      if (entityType !== "") {
        const entityField =
          columnData["entity_field_name"] !== null
            ? columnData["entity_field_name"]
            : "";

        if (!tempPayloadData.hasOwnProperty("entities")) {
          tempPayloadData["entities"] = {};
        }

        if (!tempPayloadData["entities"].hasOwnProperty(entityType)) {
          tempPayloadData["entities"][entityType] = {};
        }

        if (
          columnData.hasOwnProperty("is_custom_param") &&
          columnData["is_custom_param"] === true
        ) {
          entityField = entityField.replace("custom_params_", "");
          let entityPayload = tempPayloadData["entities"][entityType];
          if (!entityPayload.hasOwnProperty("custom_params")) {
            entityPayload["custom_params"] = {};
          }
          tempPayloadData["entities"][entityType]["custom_params"][
            entityField
          ] = fieldValue;
        } else {
          tempPayloadData["entities"][entityType][entityField] = fieldValue;
        }
      } else {
        if (
          columnData.hasOwnProperty("is_custom_param") &&
          columnData["is_custom_param"] === true
        ) {
          key = key.replace("custom_params_", "");
          if (!tempPayloadData.hasOwnProperty("custom_params")) {
            tempPayloadData["custom_params"] = {};
          }
          tempPayloadData["custom_params"][key] = value;
        } else {
          tempPayloadData[key] = value;
        }
      }

      setPayloadData(tempPayloadData);
    }
  };

  function sortColumnFields() {
    let tempColumns = columnsList.filter(
      (columnData) =>
        columnData["show_in_create_form"] && columnData["is_visible"]
    );
    let requiredFields = tempColumns
      .filter((item) => item["required"])
      .map((item) => item)
      .sort((a, b) =>
        a["display_name"]
          .toLowerCase()
          .localeCompare(b["display_name"].toLowerCase())
      );
    let allFields = [...requiredFields];

    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["display_name"]
        .toLowerCase()
        .localeCompare(b["display_name"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });
    allFields.sort((a, b) => {
      if (a.is_multiline && !b.is_multiline) {
        return 1;
      } else if (!a.is_multiline && b.is_multiline) {
        return -1;
      } else {
        return 0;
      }
    });

    for (const item of allFields) {
      if (item.is_multiline) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }

    return allFields;
  }
  const init = async () => {
    let sortedFields = sortColumnFields();
    setSortedColumnsList(sortedFields);
    initFieldValues(sortedFields);
    let tempListFieldInputMeta = getListFieldInputMeta({
      columnsList: sortedFields,
    });
    setListFieldInputMeta(tempListFieldInputMeta);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = ({ key, value }) => {
    setIsSubmitFail(false);

    setFieldValues({ ...fieldValues, [key]: value });
    updatePayloadValue(key, value);
    if(key === "organization_name"){
      setIsOrgNameDirty(true);
    }
  };

  const getListDetailsToRender = async (columnMeta) => {
    const input = columnMeta["input"];
    let tempType = "list";
    let tempList = [];
    if (input.charAt(0) === "/" || input.startsWith("ep:")) {
      let slug = getSlug(input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (input.charAt(0) !== "/" && input !== "" && input !== null) {
      try {
        let parseJSON = JSON.parse(input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (input.length > 0) {
          let list = input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const getListFieldInputMeta = async ({ columnsList }) => {
    let tempList = {};
    for (let i = 0; i < columnsList.length; i++) {
      let columnMeta = columnsList[i];
      if (columnMeta["filter_type"] === "list") {
        let metaData = getListDetailsToRender(columnMeta);
        tempList[columnMeta["column_name"]] = metaData;
      }
    }
    return tempList;
  };

  const isColumnEditable = (columnMeta) => {
    const entity = columnMeta["entity"] !== null ? columnMeta["entity"] : "";
    const entityFieldName =
      columnMeta["entity_field_name"] !== null
        ? columnMeta["entity_field_name"]
        : "";
    if (!columnMeta["editable"]) {
      return false;
    } else {
      const entityData = selectedEntityByType.hasOwnProperty(entity)
        ? selectedEntityByType[entity]
        : {};
      if (Object.keys(entityData).length > 0) {
        if (entityFieldName !== "") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const getOptionListForSelectFieldToRender = (listFieldMeta) => {
    let tempOptionList = [];
    if (listFieldMeta["type"] === "list") {
      listFieldMeta["list"].forEach((entry) => {
        tempOptionList.push({ label: entry, value: entry });
      });
    } else {
      try {
        listFieldMeta["list"].forEach((entry) => {
          tempOptionList.push({ label: entry["name"], value: entry["value"] });
        });
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    return tempOptionList;
  };

  const handleEntity = async ({ key, value }) => {
    const columnIndex = sortedColumnsList.findIndex(
      (entry) => entry["column_name"] === key
    );
    if (columnIndex !== -1) {
      const columnMeta = sortedColumnsList[columnIndex];

      const entityType =
        columnMeta["entity"] !== null ? columnMeta["entity"] : "";

      const selectedEntity = selectedEntityByType.hasOwnProperty(entityType)
        ? selectedEntityByType[entityType]
        : {};

      if (value.length < 3 || Object.keys(selectedEntity).length > 0) {
        return;
      }

      let entityList = [];

      if (entityType === "organization") {
        if (key.toLowerCase() === "organization_name") {
          const organizationResponse = await searchOrganizationsByNameApi({
            queryParams: { name: value },
          });
          entityList = organizationResponse;
        }
      } else if (entityType === "contact") {
        if (key.toLowerCase() === "phone") {
          let contactResponse = await searchContactByPhoneApi({
            payload: { phone: value },
          });
          entityList = contactResponse;
        } else if (key.toLowerCase() === "email") {
          let contactResponse = await searchContactByEmailApi({
            payload: { email: value },
          });
          entityList = contactResponse;
        }
      }
      setIsOrgNameDirty(false);
      setEntityType(entityType);
      setEntitiesListByType({
        ...entitiesListByType,
        [entityType]: entityList,
      });
      if (entityList.length > 0) {
        setDialogContext("off_focus");
        setOpenEntityDataPickerDialog(true);
      }
    }
  };

  const postSelectEntityData = (entityType, entityData) => {
    let tempSelectedEntityByType = { ...selectedEntityByType };
    tempSelectedEntityByType[entityType] = entityData;
    setSelectedEntityByType(tempSelectedEntityByType);
    prefillEntityFields(entityType, entityData);
  };

  const prefillEntityFields = (entityType, entityData) => {
    let tempFieldValues = { ...fieldValues };
    sortedColumnsList.forEach((column) => {
      const entityField =
        column["entity_field_name"] !== null ? column["entity_field_name"] : "";
      if (column["entity"] === entityType && entityField !== "") {
        let value =
          entityData.hasOwnProperty(entityField) &&
          entityData[entityField] !== null
            ? entityData[entityField]
            : "";
        tempFieldValues[column["column_name"]] = value;
      }
    });
    setFieldValues(tempFieldValues);

    let tempPayloadData = { ...payloadData };
    if (!tempPayloadData.hasOwnProperty("entities")) {
      tempPayloadData["entities"] = {};
    }

    tempPayloadData["entities"][entityType] = {};
    let key = entityType + "_id";
    tempPayloadData[key] = entityData["id"];

    setPayloadData(tempPayloadData);
  };

  const RenderLabel = ({ item }) => {
    const isEditable = isColumnEditable(item);
    return (
      <Label style={{ color: isEditable ? "#4d4e4f" : "lightgray" }}>
        {item.display_name}
        {item.required && (
          <span style={{ color: isEditable ? "red" : "lightgray" }}>*</span>
        )}
      </Label>
    );
  };

  return !loading ? (
    <>
      {sortedColumnsList.length > 0 ? (
        <Box role="presentation" sx={{ position: "relative" }}>
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar>
              <ModelHeader>Create Customer</ModelHeader>
            </Toolbar>
          </AppBar>
          {!isSubmitSuccess ? (
            <ModelWrapper
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <InputContainer>
                <Grid container spacing={2}>
                  {sortedColumnsList.map((item) => {
                    if (item.input === "" || item.input === null) {
                      if (item.is_multiline) {
                        return (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <RenderLabel item={item} />
                              <OutlinedInput
                                multiline={true}
                                rows={3}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                required={item.required}
                                value={
                                  fieldValues.hasOwnProperty(item.column_name)
                                    ? fieldValues[item.column_name]
                                    : ""
                                }
                                disabled={!isColumnEditable(item)}
                                name={item.column_name}
                                type={"text"}
                                placeholder={`Enter ${item.display_name}`}
                                onBlur={(event) => {
                                  handleEntity({
                                    key: item.column_name,
                                    value: event.target.value,
                                  });
                                }}
                                onChange={(event) => {
                                  handleChange({
                                    key: item["column_name"],
                                    value: event.target.value,
                                  });
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        );
                      } else {
                        if (item["data_type"] === "integer") {
                          return (
                            <Grid item xs={item["width"] === 1 ? 6 : 12}>
                              <InputWrapper>
                                <RenderLabel item={item} />

                                <NumberField
                                  value={
                                    fieldValues.hasOwnProperty(item.column_name)
                                      ? fieldValues[item.column_name]
                                      : ""
                                  }
                                  name={item.column_name}
                                  placeholder={
                                    item.hint !== null && item.hint !== ""
                                      ? item.hint
                                      : `Enter ${item.display_name}`
                                  }
                                  required={item.required}
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity(
                                      `Please enter a valid ${item.display_name}`
                                    )
                                  }
                                  pattern={""}
                                  disabled={!isColumnEditable(item)}
                                  handleBlur={(event) => {
                                    handleEntity({
                                      key: item.column_name,
                                      value: event.target.value,
                                    });
                                  }}
                                  handleChange={(e) => {
                                    e.target.setCustomValidity("");

                                    handleChange({
                                      key: e.target.name,
                                      value: e.target.value,
                                    });
                                  }}
                                />
                              </InputWrapper>
                            </Grid>
                          );
                        } else if (item["data_type"] === "date") {
                          return (
                            <Grid item xs={item["width"] === 1 ? 6 : 12}>
                              <InputWrapper>
                                <RenderLabel item={item} />

                                <DateField
                                  disabled={!isColumnEditable(item)}
                                  value={
                                    fieldValues.hasOwnProperty(
                                      item.column_name
                                    ) && fieldValues[item.column_name] !== ""
                                      ? fieldValues[item.column_name]
                                      : null
                                  }
                                  onChange={(event) => {
                                    handleChange({
                                      key: item["column_name"],
                                      value: event,
                                    });
                                  }}
                                  required={item.required}
                                />
                              </InputWrapper>
                            </Grid>
                          );
                        } else if (item["data_type"] === "datetime") {
                          return (
                            <Grid item xs={item["width"] === 1 ? 6 : 12}>
                              <InputWrapper>
                                <RenderLabel item={item} />

                                <DateTimeField
                                  disabled={!isColumnEditable(item)}
                                  value={
                                    fieldValues.hasOwnProperty(
                                      item.column_name
                                    ) && fieldValues[item.column_name] !== ""
                                      ? fieldValues[item.column_name]
                                      : null
                                  }
                                  onChange={(event) => {
                                    handleChange({
                                      key: item["column_name"],
                                      value: event,
                                    });
                                  }}
                                  required={item.required}
                                />
                              </InputWrapper>
                            </Grid>
                          );
                        } else {
                          return (
                            <Grid item xs={item["width"] === 1 ? 6 : 12}>
                              <InputWrapper>
                                <RenderLabel item={item} />

                                <CustomTextField
                                  fieldType={
                                    item.data_type === "email"
                                      ? "email"
                                      : "text"
                                  }
                                  fieldName={item.column_name}
                                  hintText={`Enter ${item.display_name}`}
                                  isDiabled={!isColumnEditable(item)}
                                  isRequired={item.required}
                                  value={
                                    fieldValues.hasOwnProperty(item.column_name)
                                      ? fieldValues[item.column_name]
                                      : ""
                                  }
                                  handleBlur={(event) => {
                                    handleEntity({
                                      key: item.column_name,
                                      value: event.target.value,
                                    });
                                  }}
                                  handleChange={(event) => {
                                    handleChange({
                                      key: item["column_name"],
                                      value: event.target.value,
                                    });
                                  }}
                                />
                              </InputWrapper>
                            </Grid>
                          );
                        }
                      }
                    } else {
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <InputWrapper>
                            <RenderLabel item={item} />

                            <SearchableSelectField
                              value={
                                fieldValues.hasOwnProperty(item.column_name) &&
                                fieldValues[item.column_name] !== ""
                                  ? fieldValues[item.column_name]
                                  : null
                              }
                              handleChange={(value) => {
                                handleChange({
                                  key: item["column_name"],
                                  value: value,
                                });
                              }}
                              isDisabled={!isColumnEditable(item)}
                              isRequired={item.required}
                              optionsList={
                                listFieldInputMeta.hasOwnProperty(
                                  item.column_name
                                )
                                  ? getOptionListForSelectFieldToRender(
                                      listFieldInputMeta[item.column_name]
                                    )
                                  : []
                              }
                            />
                          </InputWrapper>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </InputContainer>

              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: 500 }}
              >
                <Toolbar style={{ width: 500, position: "relative" }}>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#185DD2",
                        color: "white",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </ModelWrapper>
          ) : (
            <MessageWrapper>
              <Message>Customer created successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                Close
              </Button>
            </MessageWrapper>
          )}

          {openEntityDataPickerDialog && (
            <EntityDataPickerDialog
              entityDataList={
                entitiesListByType.hasOwnProperty(entityType)
                  ? entitiesListByType[entityType]
                  : []
              }
              entityType={entityType}
              handlePostContinue={async (entityData) => {
                postSelectEntityData(entityType, entityData);
                setOpenEntityDataPickerDialog(false);
                if (dialogContext === "form_submit") {
                  handleSubmit();
                }
              }}
              open={openEntityDataPickerDialog}
              setOpen={setOpenEntityDataPickerDialog}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: 500,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper>
            <Message
              style={{
                color: "#4d4e4f",
              }}
            >
              No fields to show.
            </Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        </Box>
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CreateCustomerSidepanel);

import axios from "axios";
import moment from "moment";
import { app_id, app_version, device_os, features, pageSize } from "./Config";
import { isUnderWorkSchedule, updateViewList } from "./Functions";
import ApiResult from "./services/apiResult";

import rootStore from "./stores/RootStore";
const api_key = process.env.REACT_APP_API_KEY;
const lead_source = "Website";
const lpp_id = "eef36517-2b4d-4078-9c00-4ad567409aaa";
// const { rootStore.userStore, rootStore.authStore } = rootStore;
let url = process.env.REACT_APP_API_BASE_URL;

//**********landing page form related payload data generator */
const normalFieldList = ["name", "phone", "email", "city"];
const modifyPayloadData = (data) => {
  let normalList = {};
  let customList = {};
  Object.keys(data).map((item) => {
    if (normalFieldList.includes(item)) {
      normalList[item] = data[item];
    } else {
      customList[item] = data[item];
    }
  });
  return { normalList, customList };
};

//********end********************** */

//**********updated columns for filteredcolumns in search page************* */
const getUpdatedColumns = (response_columns) => {
  let allColumnList = rootStore.userStore.AllColumnsList;

  let newColumnsList = [];
  if (response_columns.length > 0 && allColumnList.length > 0) {
    response_columns.map((item) => {
      allColumnList.map((key) => {
        if (key["field"] === item["field"]) {
          newColumnsList.push(key);
        }
      });
    });
  }
  return newColumnsList;
};
//*************************END***************************************** */
const getCommonHeaders = () => {
  let device_id = localStorage.getItem("device_id");
  let headers = {};
  headers["X_TIMESTAMP"] = Math.round(new Date() / 1000);
  headers["X_FEATURES"] = features;
  headers["X_APPID"] = app_id;
  headers["X_APPVERSION"] = app_version;
  headers["X_DEVICEID"] = device_id;
  headers["X_DEVICEOS"] = device_os;
  return headers;
};
const getUserHeaders = () => {
  let headers = getCommonHeaders();
  headers["X_USERID"] = localStorage.getItem("uid");
  headers["X_TOKEN"] = localStorage.getItem("token");
  return headers;
};

const sendOtpPhoneApi = async (payload) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/sendotp`,
      headers: getCommonHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const sendOtpEmailApi = async (payload) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/sendotpemail`,
      headers: getCommonHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const testAPI = () => {
  return axios({
    method: "get",
    url: `http://3.111.11.76:3003/api/test`,
    headers: {
      ...getUserHeaders(),
    },
  });
};

const bookDemoApi = async (data) => {
  let lead_created_at = null;
  lead_created_at = moment().format("YYYY-MM-DD");
  let modifiedData = modifyPayloadData(data);
  try {
    let response = await axios({
      method: "post",
      url: `${url}external/projects/${lpp_id}/leads`,
      headers: {
        ...getCommonHeaders(),
        X_APIKEY: api_key,
      },
      data: {
        ...modifiedData.normalList,
        custom_params: modifiedData.customList,
        lead_source: lead_source,
        lead_created_at: lead_created_at,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
const getQuoteApi = async (data) => {
  let lead_created_at = null;
  lead_created_at = moment().format("YYYY-MM-DD");
  let modifiedData = modifyPayloadData(data);
  try {
    let response = await axios({
      method: "post",
      url: `${url}external/projects/${lpp_id}/leads`,
      headers: {
        ...getCommonHeaders(),
        X_APIKEY: api_key,
      },
      data: {
        ...modifiedData.normalList,
        custom_params: modifiedData.customList,
        lead_source: lead_source,
        lead_created_at: lead_created_at,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const submitExternalCampaignForm = async ({ campaign_id, data }) => {
  let lead_created_at = null;
  lead_created_at = moment().format("YYYY-MM-DD");
  let payload_data = {
    payload: {
      ...data,
      lead_source: lead_source,
      lead_created_at: lead_created_at,
    },
  };
  if (data.hasOwnProperty("email")) {
    payload_data["target"] = data["email"];
  } else if (data.hasOwnProperty("phone")) {
    payload_data["target"] = data["phone"];
  }
  try {
    let response = await axios({
      method: "post",
      url: `${url}external/campaigns/${campaign_id}/submit`,
      headers: {
        ...getCommonHeaders(),
        X_APIKEY: api_key,
      },
      data: payload_data,
    });
    return true;
  } catch (error) {
    return false;
  }
};
const userLoginPhoneApi = async (payload) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/login`,
      headers: getCommonHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const userLoginEmailApi = async (payload) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/login/email`,
      headers: getCommonHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProjectApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/my_projects`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getAdminProjectApi = async () => {
  return await axios({
    method: "get",
    url: `${url}admin/projects/`,
    headers: getUserHeaders(),
  });
};
const getAdminUsersApi = async () => {
  return await axios({
    method: "get",
    url: `${url}admin/users/`,
    headers: getUserHeaders(),
  });
};

const enableAdminUserApi = async (id) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/${id}/enable`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const disableAdminUserApi = async ({ userID, queryParams }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/${userID}/disable`,
      headers: getUserHeaders(),
      params: queryParams,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getUserLoginHistoryApi = async ({ id }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/${id}/login_history`,
      headers: {
        ...getUserHeaders(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getProjectListApi = async () => {
  return await axios({
    method: "get",
    url: `${url}projects/my_projects`,
    headers: getUserHeaders(),
  });
};

const getUserStatus = async (formData) => {
  let uid = localStorage.getItem("uid");

  return await axios({
    method: "post",
    url: `${url}users/${uid}/status/current`,
    headers: getUserHeaders(),
    data: formData,
  });
};

const getTabsApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/tabs/updated`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getViewGroupsApi = async ({ projectID }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/viewgroups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getTeamApi = async (pid) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/my_teams`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getRoleApi = async (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/me/v1`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRoleApiAdmin = (pid) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/me/v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
    },
  });
};

const getDataApi = async () => {
  let uid = localStorage.getItem("uid");
  let response = await axios({
    method: "get",
    url: `${url}users/${uid}/`,
    headers: getUserHeaders(),
  });
  rootStore.userStore.createUser({
    name: response.data.name,
    email: response.data.email,
    phone: response.data.phone,
    role: response.data.role,
  });
};
const getProfileDataApi = () => {
  let uid = localStorage.getItem("uid");
  return axios({
    method: "get",
    url: `${url}users/${uid}/`,
    headers: getUserHeaders(),
  });
};

const uploadAccountUserProfileImageApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let userID = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${userID}/update/profile_pic`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });

    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getStatusApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/lead_statuses/`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getStagesApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/lead_stages/details`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getTotalLeadCountApi = async (pid) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/count`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getStageStatusesApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/stage_statuses`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getListApi = async ({ slug }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}${slug}`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const getAllColsApi = (pid) => {
  return axios({
    method: "get",
    url: `${url}projects/${pid}/columns`,
    headers: getUserHeaders(),
  });
};

const getFilterColsApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/columns_for_filters`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    let updated_columns = getUpdatedColumns(response.data);
    rootStore.userStore.formatFilterColumnHeaders(updated_columns);
  } catch (error) {
    console.log(error);
  }
};
const getLeadsApi = async ({ page_size, page, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/tab/v2?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getLeadsApiV3 = async ({ page_size, page, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/tab/v3?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getLeadsItemCountApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/tab/count`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getFilteredLeadsApi = ({ page_size, page_no }) => {
  let filters = rootStore.userStore.globalFilters;
  let accId = rootStore.authStore.accountId;

  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/filter?offset=${
      page_no * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: filters,
  });
};

const uploadCsvApi = (formData) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/import_v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: formData,
  });
};
const submitLeadApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const editLeadApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/update`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const changeLeadstatusApi = async ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${id}/status/change`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const changeLeadSourceApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/source/change`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getSourcesListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/lead_sources/details`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getProjectMemberListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/members/details`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getSummaryApi = ({ start_date, end_date }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/summary`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      start_date: start_date !== null ? start_date : null,
      end_date: end_date !== null ? end_date : null,
    },
  });
};

const getPhoneApi = (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/getph`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getLeadProfileApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/show_v1`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getLeadActivityApi = async ({ leadID, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/activities?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getLeadCallsApi = async ({ lid, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${lid}/calldetails?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
const getLeadFilesApi = async ({ id, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${id}/files?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getLeadPredefinedFilesApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/files/predefined`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getLeadContactLinkedFilesApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/files/linked`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const uploadLeadProfileImageApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/update/profile_pic`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const submitCallApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${id}/submit_call_log`,
    headers: {
      "Content-Type": "multipart/form-data",
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const submitFileApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/files/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const uploadLeadFileApi = async ({ leadID, payload, fileID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/files/${fileID}/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getLeadNotesApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/notes`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getUsersToMentionInLeadNoteApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/users/mention`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
const createLeadNoteApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/notes`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const userLogoutApi = () => {
  let uid = localStorage.getItem("uid");
  return axios({
    method: "post",
    url: `${url}users/${uid}/logout`,
    headers: getUserHeaders(),
  });
};

const leadAssignApi = ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    // data: {
    //   lead_ids: ids,
    //   assign_to: member,
    //   erase_history: erase_history,
    // },
    data: payload,
  });
};

const leadTransferApi = async ({ payload, leadID }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/transfer`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteMultipleLeadsApi = async ({ idList }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: {
        lead_ids: idList,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteLeadApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const claimLeadApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/claim`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateLeadColorApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/color`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateMultipleLeadColorApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/color`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getMembersApi = () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  return axios({
    method: "get",
    url: `${url}projects/${pid}/userdetails_to_assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getMembersApiV2 = async ({ projectID, teamID }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/userdetails_to_assign/v1`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getMembersForTransfer = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/members_for_transfer`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getTargetMembersApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/userdetails_to_assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminMembersApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/members`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminSourcesApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/sources`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminStagesApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/stages`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getProjectAdministrationReOrederedStagesApi = async ({
  projectId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/stages/reorder`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getAdminStatusesApi = (pid) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/statuses`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminStatusToStageApi = async () => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${pid}/status_stages`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getAdminColumnsApi = ({ pid, page, page_size }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${pid}/columns?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getSummaryChartData = ({ start_date, end_date }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/status/summary`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      start_date: start_date !== null ? start_date : null,
      end_date: end_date !== null ? end_date : null,
    },
  });
};
const getSummaryBarData = ({ start_date, end_date }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/owner_status/summary`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      start_date: start_date !== null ? start_date : null,
      end_date: end_date !== null ? end_date : null,
    },
  });
};
const getImportHistoryApi = ({ page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/import/history?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const refreshImportRowApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/imports/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const cancelLeadImportApi = async ({ importID }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/imports/${importID}/cancel`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getTargetsApi = ({ page }) => {
  let pid = rootStore.authStore.projectId;
  let page_size = pageSize;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/targets?offset=${
      page * page_size
    }&limit=${page_size}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const sendTargetsApi = (data) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/targets`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const getNotificationsApi = async () => {
  let uid = localStorage.getItem("uid");
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}users/${uid}/notifications`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
const getNotificationsColumnsApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/columns_for_notifications`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getNotificationsDetailApi = async ({ page_size, page_no, payload }) => {
  let uid = localStorage.getItem("uid");
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/notifications/details?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
const updateUserDetailApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let userID = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${userID}/update/details`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });

    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editUsersApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users/${id}/edit`,
    headers: {
      "Content-Type": "multipart/form-data",
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addUsersApi = ({ data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users`,
    headers: {
      "Content-Type": "multipart/form-data",
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addNewProjectUserApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/users`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editProjectApi = ({ id, data }) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/edit`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};
const editProjectWorkScheduleApi = ({ id, data }) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/edit/schedule`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

const getAdminProjectDataApi = (id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}admin/projects/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getAdminTeamsApi = async ({ projectID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/teams`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const editTeamApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/teams/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addTeamApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/teams/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editSourceApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/sources/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addSourceApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/sources/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/stages/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/stages/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editStatusApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/statuses/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const addStatusApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/statuses/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const addStatusToStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/status_stages/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editStatusToStageApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/status_stages/edit`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const deleteStatusToStageApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${pid}/status_stages/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getAdminDetailsApi = () => {
  return axios({
    method: "get",
    url: `${url}admin/accounts/show`,
    headers: {
      ...getUserHeaders(),
    },
  });
};
const addAdminMemberApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/members/add`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const removeAdminMemberApi = async ({ id, data }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${id}/members/remove`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const editProjectMemberApi = ({ id, data }) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${id}/members/edit`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

const createAdminProjectMemberDialerConfigApi = async ({
  projectID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/dialercreds/create`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateAdminProjectMemberDialerConfigApi = async ({
  projectID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/dialercreds/update`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const removeAdminProjectMemberDialerConfigApi = async ({
  projectID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/dialercreds/remove`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProjectMemberDialerConfigApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${projectID}/dialercreds/get`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
        X_PROJECTID: projectID,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const getAdminProjectMemberDialerConfigApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/dialercreds/get`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const getPredefinedWidgetsApi = () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/predefined`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const addWidgetApi = ({ data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/widgets`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getPredefinedWidgetsApiv2 = () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/v2/predefined`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getPredefinedWidgetsForPrebuiltDashboradsApi = async () => {
  let pid = rootStore.authStore.projectId;
  try {
    let repponse = await axios({
      method: "post",
      url: `${url}dashboards/list/predefined`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
      },
    });
    return repponse;
  } catch (error) {
    return [];
  }
};

const addWidgetApiv2 = async ({ dashboard_id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/widgets/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...data, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addPredefinedWidgetApi = async ({ dashboard_id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/widgets/predefined/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...data, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addClientWidgetApi = async ({ dashboard_id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/widgets/predefined/client/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getWidgetDataApiv2 = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}widgets/${id}/v2/data`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      project_id: pid,
    },
  });
};

const getWidgetListApiv2 = async () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/widgets/v2`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getWidgetDataApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/${id}/data`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getWidgetListApi = () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const removeWidgetApiv2 = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/${id}/v2/remove`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const removeWidgetApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/widgets/${id}/remove`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const rearrangeWidgetListApi = async ({ dashboard_id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/widgets/sequences`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const addAdminColumnApi = async ({ id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${id}/columns/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const editAdminColumnApi = async ({ id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${id}/columns/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteAdminCustomColumnApi = async ({ payload }) => {
  let projectID = rootStore.authStore.projectId;
  let teamID =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accountID = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/columns/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accountID,
        X_TEAMID: teamID,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editAdminSystemColumnApi = async ({ id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${id}/columns/edit_system`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const submitReportApi = ({ data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editReportApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/reports/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};
const getReportsApi = async ({ page_size, page }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/reports?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getPredefinedReportsApi = async () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/predefined_reports/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
const getPredefinedReportDetailsApi = async ({
  reportID,
  payload,
  queryParams,
}) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/predefined_reports/${reportID}/data`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
const getReportDetailsApi = async ({ id, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/reports/${id}/data?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getReportFileApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/export`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const downloadReportFileApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/download`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const deleteReportApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}/delete`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const refreshReportRowApi = ({ id }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/reports/${id}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const getJobsApi = async ({ id, page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${id}/jobs?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
const createJobApi = ({ id, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${id}/jobs`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getJobStatusApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const getJobSpecificCountApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}/itemcount`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};
const startSpecificJobApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}/start`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

/////////*******************checkins related apis********************* */

const getMonthCheckInsApi = (date) => {
  let pid = rootStore.authStore.projectId;
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}users/${uid}/checkins/monthdata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};

const getDayCheckInsApi = (date) => {
  let pid = rootStore.authStore.projectId;
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}users/${uid}/checkins/daydata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};

const getUserMonthCheckInsApi = (date, pid, id) => {
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${pid}/checkins/monthdata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
      user_id: id,
    },
  });
};

const getUserDayCheckInsApi = (date, pid, id) => {
  let uid = localStorage.getItem("uid");
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${pid}/checkins/daydata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
      user_id: id,
    },
  });
};

const getAdminUserMonthCheckInsApi = (date, id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users/${id}/checkins/monthdata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};
const getAdminUserDayCheckInsApi = (date, id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/users/${id}/checkins/daydata`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      date: date,
    },
  });
};

const getLeadCheckInsApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/checkins/lead/get`,
      headers: {
        ...getUserHeaders(),
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/////////***************************END***************************************************//////// */

//////////*************************Marketing Section Api*********************************** */

const getSegmentsApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}segments?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const getSegmentDataApi = (id) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}segments/${id}`,
    headers: {
      ...getUserHeaders(),

      X_ACCOUNTID: accId,
    },
  });
};

const removeSegmentApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}segments/${id}/delete`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const submitSegmentApi = async ({ data }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}segments`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getCampaignTypeListApi = async () => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/types`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCampaignProviderListApi = async (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/providers`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//api to get template list
const getTemplateListApi = async (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/templates`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getAgentListApi = async (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/dialers/agents`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getDialerExportJobAgentListApi = async (payload) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/dialer_campaigns/dialers/agents`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createCampaignApi = (payload) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = axios({
      method: "post",
      url: `${url}campaigns/create`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getCampaignsListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/list?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignDetailsApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/show`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const startCampaignApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/start`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignItemCountApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/count`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignStatsApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/stats`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCampaignLeadsListApi = async ({
  campaign_id,
  page_size,
  page_no,
  scope,
  filter,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}campaigns/${campaign_id}/leads?scope=${scope}&filter=${filter}&offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createLeadForProject = async ({ pid, data }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const testCampaignApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}campaigns/${id}/test`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************add project member new flow apis************** */

const getMemberDetailByPhoneApi = (pid, phone) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}admin/projects/${pid}/userdetails`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      phone: phone,
    },
  });
};

//***************** project_type->B2B related apis ********************** */

const getBusinessContactListApi = (lid) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/contacts`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const addBusinessContactApi = ({ lid, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${lid}/contacts`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const editBusinessContactApi = ({ lid, cid, data }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/leads/${lid}/contacts/${cid}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

const getBusinessContactDetailsApi = ({ lid, cid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${lid}/contacts/${cid}`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const testWidgetApi = (data) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/widgets/53b2a33c-b3fb-41f2-805b-f06f213a4a88/data/v1`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: data,
  });
};

//********************************End************************************ */

//********************export_leads job related apis */
const getExportJobDownloadFileApi = ({ id, jid }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${id}/jobs/${jid}/artifact`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

//**********************************End********************************** */

//******************money view related apis*****************************************

const moneyViewCreateLeadApi = async (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${lid}/lenders/moneyview/lead/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const moneyViewCollectOffersApi = async (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${lid}/lenders/moneyview/offers`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const moneyViewFetchJourneyURLApi = async (lid) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${lid}/lenders/moneyview/journey_url`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

/*******************END*********************************** */

//************select columns for tab related apis************** */

const getColumnsForTabApi = (tabId) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/tabs/${tabId}/columns`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
  });
};

const setColumnsForTabApi = ({ tabId, data }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}projects/${pid}/tabs/${tabId}/columns`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
      X_TEAMID: tid,
    },
    data: {
      columns: data,
    },
  });
};
//**************************Call API************************************** */
const ozonetelCall = (leadID) => {
  let pid = rootStore.authStore.projectId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${leadID}/dialers/ozonetel/dial/manual`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
    },
  });
};
const getOzonetelCallStatus = (formData) => {
  return axios({
    method: "post",
    url: `${url}dialers/ozonetel/call_status`,
    headers: {
      ...getUserHeaders(),
    },
    data: formData,
  });
};
const ozonetelSetCallDisposition = async (leadID, formData) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/dialers/ozonetel/disposition/set`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
      },
      data: formData,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

/******************************* M-Cube  ************************************* */
const mcubeCall = (leadID) => {
  let pid = rootStore.authStore.projectId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/leads/${leadID}/dialers/mcube/dial/manual`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
    },
  });
};
const getMcubeCallStatus = (formData) => {
  return axios({
    method: "post",
    url: `${url}dialers/mcube/call_status`,
    headers: {
      ...getUserHeaders(),
    },
    data: formData,
  });
};

/********************************Techinfo************************************* */

const teckinfoClickToCall = async ({ queryParams }) => {
  try {
    let response = await axios({
      method: "get",
      url: `https://192.168.0.225:7001/api/crm/clicktocall`,

      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const teckinfoGetTokenApi = async ({ queryParams }) => {
  try {
    let response = await axios({
      method: "get",
      url: `https://192.168.0.225:9003/api/cti/GetAgentToken`,

      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const teckinfoCloseCallApi = async ({ queryParams }) => {
  try {
    let response = await axios({
      method: "get",
      url: `https://192.168.0.225:9003/api/cti/CloseCall`,
      params: queryParams,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const teckinfoLeadpixieCall = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/dialers/teckinfo/dial/manual/post_dial`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getTeckinfoCallStatus = async (formData) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}dialers/teckinfo/call_status`,
      headers: {
        ...getUserHeaders(),
      },
      data: formData,
    });
    return response.data;
  } catch (error) {}
};

//************************Filter v2 related apis*********************** */
const getFilteredLeadsApiV2 = async ({ page_size, page_no, payload }) => {
  let accId = rootStore.authStore.accountId;

  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/filter_v2?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getFilteredLeadsApiV3 = async ({ page_size, page_no, payload }) => {
  let accId = rootStore.authStore.accountId;

  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/filter_v3?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getFilterLeadsItemCountApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/filter/count`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getFilterColsApiV2 = () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    return axios({
      method: "get",
      url: `${url}projects/${pid}/columns_for_filters`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
//**************************End************************************** */
//*********************details section grouping related apis*************** */

const getColumnGroupsApi = async (pid) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/column_groups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getColumnPermissionsApi = async ({ pid, data }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/columns/permissions`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getColumnGroupsPermissionsApi = async ({ pid, data }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/column_groups/permissions`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//***********************************END********************************** */

//**********************Imports status get logs file related apis********************** */

const getImportLogUrlApi = async ({ id, scope }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/imports/${id}/${scope}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//**************************************END******************************************** */

//**********************raw leads page related apis********************** */

const getRawLeadsApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}raw_leads?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: { filters: payload },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRawLeadsColumnsApi = async () => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/columns`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getRawLeadsImportsApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/imports?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const importRawLeadsApi = (data) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}raw_leads/imports`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

const refetchRawLeadImportRowDataApi = ({ id }) => {
  let accId = rootStore.authStore.accountId;
  return axios({
    method: "get",
    url: `${url}raw_leads/imports/${id}`,
    headers: {
      ...getUserHeaders(),

      X_ACCOUNTID: accId,
    },
  });
};

const getRawLeadImportLogUrlApi = async ({ id, scope }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/imports/${id}/${scope}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getMembersToAssignByProject = (pid) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "get",
    url: `${url}projects/${pid}/users_to_assign`,
    headers: {
      ...getUserHeaders(),
      X_PROJECTID: pid,
      X_ACCOUNTID: accId,
    },
  });
};

const moveRawLeadsToProjectApi = (data) => {
  let accId = rootStore.authStore.accountId;

  return axios({
    method: "post",
    url: `${url}raw_leads/move`,
    headers: {
      ...getUserHeaders(),
      X_ACCOUNTID: accId,
    },
    data: data,
  });
};

//**************************************END******************************************** */

//************api to get import leads list**************** */
const getImportLeadListApi = async ({ id, page_no, page_size }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/imports/${id}/leads?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//***************end*********************** */

//******************get project columns for mapping api******************** */
const getColumnsForMapping = async (pid) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/columns/formapping`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//***************************END*************************************** */

//******************admin whitelist page apis ******************/

const getUserNetWorkInfoApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: "https://api.ipify.org/?format=json",
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getWhiteListedIpsApi = async () => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "get",
      url: `${url}users/${uid}/whitelisted_ips`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const addNewIpAddressApi = async (payload) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/add`,
      headers: getUserHeaders(),
      data: payload,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const removeIpAddressApi = async (id) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/${id}/remove`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const disableIpAddressApi = async (id) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/${id}/disable`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const enableIpAddressApi = async (id) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/whitelisted_ips/${id}/enable`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//*************************END*********************** */

//************Quick start api ***************** */

const startDayApi = async () => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/udl/startday`,
      headers: getUserHeaders(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//***************END************************ */

//************Send user active status api ***************** */

const sendUserActiveStatus = async (payload) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/udl/markstatus`,
      headers: getUserHeaders(),
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//***************END************************ */

//***************Raw lead export tab related apis*************** */
const getRawLeadJobsApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/get_jobs?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const createRawLeadJobApi = async ({ data }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}raw_leads/jobs/create_job`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getRawLeadJobStatusApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/get_job`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
const getRawLeadJobCountApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/itemcount`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const startRawLeadJobApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/start_job`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRawLeadExportJobDownloadFileApi = async ({ jid }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}raw_leads/jobs/${jid}/artifact`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//**************************END********************************** */

//**************************Search api to get leads in search page after doing query in searchbar *************/
const searchLeadsByQueryApi = async ({ page_size, page_no, query_params }) => {
  let accId = rootStore.authStore.accountId;
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/search?offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: query_params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//************************************END************************************************* */

//************************multi dashboard related apis**************************************
const getDashboardListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: {
        project_id: pid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getWidgetListByDashboardApi = async (dashboard_id) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}dashboards/${dashboard_id}/widgets`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const createPrebuildDashboardApi = async (payload) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/predefined/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const createDashboardApi = async (payload) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...payload, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editDashboardApi = async ({ dashboard_id, payload }) => {
  let pid = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}dashboards/${dashboard_id}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: { ...payload, project_id: pid },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//*******************************END*********************************** */

//********************create web forms related apis***********************************
const getWebFormsListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}webforms?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createWebFormApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}webforms/create`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createWebFormWithLandingPageApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}webforms/create/landing_page/template`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateWebFormApi = async ({ formID, payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}webforms/${formID}/save`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createExternalWebFormApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}webforms/create_external`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getWebFormDataApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}webforms/${id}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

//*****************************END************************************************** */

//***********************Smart Views page related apis************************** */
const getAllProjectAdministrationViewsApi = async ({ projectRole }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  let projectId = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectId}/views/all?role=${projectRole}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getAssignedProjectAdministrationViewsApi = async ({ projectRole }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  let projectId = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectId}/views/assigned?role=${projectRole}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const addProjectAdministrationViewApi = async ({ tabID, payload }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  let projectId = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/${tabID}/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const getProjectAdministrationReOrederedViewsApi = async ({
  projectId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/reorder`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deleteProjectAdministrationViewApi = async ({ tabID, payload }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  let projectId = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/${tabID}/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editProjectAdministrationViewApi = async ({
  projectId,
  itemId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/${itemId}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createProjectAdministrationViewApi = async ({ projectId, payload }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editProjectAdministrationViewSelectColumnApi = async ({
  itemId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  let projectId = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/views/${itemId}/selected_columns`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const getAdminFilterColumns = async () => {
  let pid = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${pid}/columns/filter`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
const updateFilterColumns = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${pid}/columns/filter`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return true;
  } catch (error) {
    return false;
  }
};
const getAdminNotificationColumns = async () => {
  let pid = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${pid}/columns/notifications`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
const updateNotificationColumns = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${pid}/columns/notifications`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return true;
  } catch (error) {
    return false;
  }
};
//********************************END********************************** */

//***********************View Groups page related apis************************** */
const getProjectAdministrationViewGroupsApi = async ({ projectId }) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectId}/viewgroups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deleteProjectAdministrationViewGroupApi = async ({
  projectId,
  viewGroupID,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/viewgroups/${viewGroupID}/remove`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createProjectAdministrationViewGroupApi = async ({
  projectId,
  payload,
}) => {
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/viewgroups/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//********************************END********************************** */

//********************Deals tab apis************ */
const getLeadActiveDealApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/deals/active`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getLeadDealListApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/deals/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deactivateLeadDealApi = async ({ leadID, dealID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/deals/${dealID}/deactivate`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createLeadDealApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/deals/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//********************************END********************************** */

//********************Quotes tab apis************ */
const getLeadQuoteListApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getLeadInvoiceListApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/sales_invoices/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createLeadQuotationApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editLeadQuotationApi = async ({ leadID, payload, quoteID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/${quoteID}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const reviseLeadQuotationApi = async ({ leadID, payload, quoteID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/${quoteID}/revise`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const markLeadQuotationAsSentApi = async ({ leadID, quoteID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/${quoteID}/mark/sent`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const markLeadQuotationAsInvoicedApi = async ({ leadID, quoteID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/${quoteID}/mark/invoiced`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getLeadQuotationDataApi = async ({ leadID, quoteID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/quotations/${quoteID}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getQuoteTemplateListApi = async () => {
  let projectID = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let teamID =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/quotations/templates`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getQuotationNumberApi = async () => {
  let projectID = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let teamID =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/quotations/sequence`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getInvoiceNumberApi = async () => {
  let projectID = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let teamID =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/sales_invoices/sequence`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getLeadInvoiceDataApi = async ({ invoiceID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/sales_invoices/${invoiceID}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const createLeadInvoiceApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/sales_invoices/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editLeadInvoiceApi = async ({ leadID, payload, invoiceID }) => {
  payload["lead_id"] = leadID;
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/sales_invoices/${invoiceID}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const markLeadInvoiceAsSentApi = async ({ invoiceID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/sales_invoices/${invoiceID}/status/sent`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const invoiceRecordPaymentsApi = async ({ payload, invoiceID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/sales_invoices/${invoiceID}/payments`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************END****************** */

//********************contacts page apis********************** */

const getContactsListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getFilteredContactListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/filter?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getFilteredContactItemCountApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/filter/count`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createContactApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getContactDetailsApi = async ({ contactID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/${contactID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const uploadContactProfileImageApi = async ({ contactID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/${contactID}/update/profile_pic`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });

    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editContactApi = async ({ contactID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/${contactID}/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getContactLeadsListApi = async ({ contactID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/${contactID}/leads?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getContactFilesListApi = async ({ contactID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/${contactID}/files?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const uploadContactFileApi = async ({ contactID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/${contactID}/upload_file`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateContactFileApi = async ({ contactID, fileID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}contacts/${contactID}/files/${fileID}/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//************************END********************** */

//********************Customers page apis************** */

const createCustomerApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const assignCustomerApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/assign`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getCustomerStagesListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/stages/list`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerPurchaseCategoryListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/purchase_categories/list`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomersListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getFilteredCustomerListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/filter?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getFilteredCustomerItemCountApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/filter/count`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCustomerDetailsApi = async ({ customerID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const editCustomerApi = async ({ customerID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getCustomerActivityListApi = async ({
  customerID,
  page,
  page_size,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}/activities?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const uploadCustomerProfileImageApi = async ({ customerID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/update/profile_pic`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });

    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getCustomerLeadsListApi = async ({
  customerID,
  page,
  page_size,
  queryParams,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/leads/v2?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      params: queryParams,
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCustomerHistoryListApi = async ({ customerID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}/history?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getCustomerFilesListApi = async ({ customerID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/${customerID}/files?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const uploadCustomerFileApi = async ({ customerID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/upload_file`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateCustomerFileApi = async ({ customerID, fileID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/files/${fileID}/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateCustomerStageApi = async ({ customerID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/customer_stages/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });

    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateCustomerPurchaseCategoryApi = async ({ customerID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}customers/${customerID}/purchase_categories/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });

    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//************************END************************ */

//*******************Organizations page apis******************** */
const getOrganizationsListApi = async ({ page, page_size, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}organizations?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const searchOrganizationsByNameApi = async ({ queryParams }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/search/name`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createOrganizationApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}organizations/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getOrganizationDetailsApi = async ({ organizationID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/${organizationID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const editOrganizationApi = async ({ organizationID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}organizations/${organizationID}/update`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getOrganizationContactsListApi = async ({
  organizationID,
  page,
  page_size,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/${organizationID}/contacts?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getOrganizationLeadsListApi = async ({
  organizationID,
  page,
  page_size,
  queryParams,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/${organizationID}/leads?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getOrganizationSummaryApi = async ({ organizationID }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/${organizationID}/summary`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//**********************END********************************** */

//********************Products page apis********************** */

const getProductsListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}products?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createProductApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}products/create`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProductDetailsApi = async ({ productID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}products/${productID}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const editProductApi = async ({ productID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}products/${productID}/update`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//************************END********************** */

//*********************Deals page apis****************** */
const getDealsListApi = async ({ page, page_size, payload, queryParams }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/deals/list?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//*********************END**************************** */

//*************Search contact by phone and email apis******************** */
const searchContactByPhoneApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/contacts/search/phone`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
const searchContactByEmailApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/contacts/search/email`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
//******************END******************************* */

// mark or unmark lead as fresh lead api***********************
const markUnmarkLeadAsFreshLeadApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/fresh/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const markUnmarkMultipleLeadAsFreshLeadApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/fresh/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//****************END******************************* */

//******************mark lead as priority apis**************** */

const markUnmarkLeadAsPriorityApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/priority/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const markUnmarkMultipleLeadAsPriorityApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/priority/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//*********************END********************************* */

//***********************Column Groups page related apis************************** */

const getProjectAdministrationColumnGroupsApi = async () => {
  let projectId = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectId}/columngroups`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deleteProjectAdministrationColumnGroupApi = async ({ groupID }) => {
  let projectId = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/columngroups/${groupID}/remove`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createProjectAdministrationColumnGroupApi = async ({ payload }) => {
  let projectId = rootStore.authStore.projectId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/columngroups/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateProjectAdministrationColumnGroupApi = async ({
  columnGroupID,
  payload,
}) => {
  let projectId = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/columngroups/${columnGroupID}/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const getProjectAdministrationReOrederedColumnGroupsApi = async ({
  payload,
}) => {
  let projectId = rootStore.authStore.projectId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectId}/columngroups/reorder`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectId,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//********************************END********************************** */

//*************************columns apis************ */

const getProductColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}products/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getContactColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getContactColumnGroupsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/column_groups`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getContactDetailsTabsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}contacts/detail_tabs`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerColumnGroupsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/column_groups`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getCustomerDetailsTabsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}customers/detail_tabs`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getOrganizationColumnsListApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}organizations/columns`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//**************************END*************** */

//**********************************Admin predefined deals page apis***************************** */

const getAdminPredefinedDealsListApi = async ({ projectID, teamID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/predefined_deals`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const createAdminPredefinedDealApi = async ({ projectID, teamID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/predefined_deals/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//*****************************************END********************************************** */

//**********************************Admin Quotations page apis***************************** */

const getAdminQuotationsListApi = async ({ projectID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/quotations/templates`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const createAdminQuotationApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quotations/templates/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deactivateQuotationTemplate = async ({
  projectID,
  quotationTemplateID,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quotations/templates/${quotationTemplateID}/deactivate`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const activateQuotationTemplate = async ({
  projectID,
  quotationTemplateID,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quotations/templates/${quotationTemplateID}/activate`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//*****************************************END********************************************** */

//******************************predefined deals list api **********************/

const getPredefinedDealsApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/predefined_deals`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//**********************************END******************** */

//*********************Link contact api********************** */
const linkContactApi = async ({ projectID, teamID, leadID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${projectID}/leads/${leadID}/link_contact/`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
        X_TEAMID: teamID,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//***************************END***************************** */

//*****************************Update stage api******************** */
const updateLeadStageApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/stage/change`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateMultipleLeadStageApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/stage/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************************END*********************** */

const updateMultipleLeadFieldApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/fields/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateMultipleLeadSourceApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/source/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateMultipleLeadStatusApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/status/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//******************************Files page related apis********** */

//******************************predefined deals list api **********************/

const getFoldersListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}folders/list?offset=${page * page_size}&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createFolderApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/create`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getFolderFileListApi = async ({ folderID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}folders/${folderID}/documents/list?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getFolderPermissionListApi = async ({ folderID, page, page_size }) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}folders/${folderID}/permissions?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createFileApi = async ({ folderID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/documents/add`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateDocumentApi = async ({ folderID, documentID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/documents/${documentID}/update`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteDocumentApi = async ({ folderID, documentID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/documents/${documentID}/delete`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteFolderApi = async ({ folderID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/delete`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addFolderPermissionApi = async ({ folderID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/permissions/add`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const removeFolderPermissionApi = async ({ folderID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}folders/${folderID}/permissions/remove`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**********************************END******************** */
//***********************************END********************* */

//*****************************lead submit api******************** */
const leadSubmitApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/submit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//**********************************END*********************** */

//********************Billing page apis*************** */
const getInvoiceListApi = async ({ page, page_size }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}billing/invoices?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getSubscriptionDetailsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}billing/subscription/active`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//************************END************************* */

const getBannerListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}banners/list`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//**************api to get hot leads *************** */
const getHotLeadApi = async () => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/leads/hotlead`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
//***********************END********************* */

//*********************Webform stats page apis******************** */
const getWebformStatsApi = async (id) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}webforms/${id}/stats`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getWebformLeadsListApi = async ({
  webform_id,
  filter,
  page_size,
  page_no,
}) => {
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}webforms/${webform_id}/data?filter=${filter}&offset=${
        page_no * page_size
      }&limit=${page_size}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const markWebformLeads = async ({ webform_id, pid, data }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}webforms/${webform_id}/data/mark`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: data,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**************************END********************************* */

//*************Get project members api*********** */
const getAccountMembersApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/members`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getUserProjectsApi = async ({ user_id }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/users/${user_id}/projects`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getAccountUserDetailsApi = async ({ userID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/users/${userID}`,
      headers: {
        ...getUserHeaders(),
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
//*********************END******************* */

//*************lead forms related api*************
const getLeadFormDetailsApi = async () => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/leadforms/primary`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};
//*********************END********************** */ */

//******************Quality page under project administration related apis****************** */
const getProjectAdministrationQualityListApi = async () => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/quality_scores/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const deleteProjectAdministrationQualityApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quality_scores/delete`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const addProjectAdministrationQualityApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quality_scores/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const editProjectAdministrationQualityApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quality_scores/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const publishProjectAdministrationQualityApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quality_scores/publish`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const unPublishProjectAdministrationQualityApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/quality_scores/unpublish`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//*************************************END********************************************* */

//************************reset nextfollowup for lead api */
const resetNextFollowupForLeadsApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/followup/reset`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//************************END************************ */

//**************get terms of services details apis*************************** */
const getUnacceptedTermsOfServicesDetailsApi = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/tos/unaccepted`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const acceptTermsOfServicesApi = async ({ tosID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}accounts/tos/${tosID}/accept`,
      headers: {
        ...getUserHeaders(),

        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//*******************************END****************************************** */

//**************************Get lead whatsapp template list api*************** */
const getAdminAccountWhatsAppBusinessTemplateListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/whatsapp/templates/business`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const syncAdminAccountWhatsAppBusinessApi = async () => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/whatsapp/templates/business/meta_waba/sync`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendAdminAccountWhatsAppBusinessApi = async ({ templateID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/whatsapp/templates/business/meta_waba/${templateID}/test`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendLeadWhatsAppBusinessChatTextApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/send/text`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendLeadWhatsAppBusinessChatImageApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/send/media/image`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendContactWhatsAppBusinessChatVideoApi = async ({
  contactID,
  payload,
}) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}accounts/contacts/${contactID}/whatsapp/metawaba/send/media/video`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendLeadWhatsAppBusinessChatVideoApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/send/media/video`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendContactWhatsAppBusinessChatImageApi = async ({
  contactID,
  payload,
}) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}accounts/contacts/${contactID}/whatsapp/metawaba/send/media/image`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendContactWhatsAppBusinessChatTextApi = async ({
  contactID,
  payload,
}) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}accounts/contacts/${contactID}/whatsapp/metawaba/send/text`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendLeadWhatsAppBusinessChatApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/send/template`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendContactWhatsAppBusinessChatApi = async ({ contactID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}accounts/contacts/${contactID}/whatsapp/metawaba/send/template`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const uploadLeadWhatsAppBusinessMediaApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/media/upload`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const uploadWbMediaContact = async ({ contactID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}accounts/contacts/${contactID}/whatsapp/metawaba/media/upload`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getWhatsappLeadChatMediaApi = async ({ leadID, queryParams }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/media/get`,
      responseType: "arraybuffer", //for rendering binary image directly in chat message component
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const getWhatsappChatMessagesApi = async ({ leadID, queryParams }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/whatsapp/metawaba/messages`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getWhatsappContactChatMessagesApi = async ({
  contactID,
  queryParams,
}) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/contacts/${contactID}/whatsapp/metawaba/messages`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const createAdminAccountMetaWabaTemplateApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/whatsapp/templates/business/meta_waba/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const deleteAdminAccountMetaWabaTemplateApi = async ({ templateID }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/whatsapp/templates/business/meta_waba/${templateID}/delete`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getAdminAccountWhatsAppPersonalTemplateListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/whatsapp/templates/personal`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getWhatsAppTemplateListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      //url: `${url}projects/${pid}/whatsapp/templates/list`,     // old - includes both personal and business templates
      url: `${url}projects/${pid}/whatsapp/templates/personal/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getWhatsAppTemplateMetaWabaListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/whatsapp/templates/metawaba/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getProjectAdminWhatsAppTemplateListApi = async () => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${pid}/whatsapp/templates/personal`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const createWhatsAppPersonalTemplateApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/whatsapp/templates/personal/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createProjectWhatsAppPersonalTemplateApi = async ({ payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  payload["project_id"] = pid;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${pid}/whatsapp/templates/personal/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateWhatsAppPersonalTemplateApi = async ({ templateID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/whatsapp/templates/personal/${templateID}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateProjectWhatsAppPersonalTemplateApi = async ({
  templateID,
  payload,
}) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  payload["project_id"] = pid;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${pid}/whatsapp/templates/personal/${templateID}/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//************************************END******************************** */

//***********************integrations page apis************************* */
const sendFbLoginToken = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/fbleads/conf/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createFbPageConfig = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/fbleads/pages/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getFacebookLeadsConfigData = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/fbleads/conf`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getFacebookAllPagesData = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/fbleads/pages/all`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getFacebookConfiguredPagesData = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/fbleads/pages/configured`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getFacebookPageAllFormData = async ({ pageID }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/fbleads/pages/${pageID}/forms/all`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const getFacebookPageConfiguredFormData = async ({ pageID }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/fbleads/pages/${pageID}/forms/configured`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getFacebookPageFormQuestionsList = async ({ pageID, formID }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/fbleads/pages/${pageID}/forms/${formID}/questions`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const saveFacebookPageFormApi = async ({ pageID, formID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/fbleads/pages/${pageID}/forms/${formID}/configure`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//**************************************END*************************** */

//******************Integrations Indiamart apis***************** */

const getIndiamartAllConfigApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/indiamart/conf/list`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getIndiamartConfigApi = async ({ id }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/indiamart/conf/${id}/get`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createIndiamartConfigApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/indiamart/conf/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateIndiamartConfigApi = async ({ payload, id }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/indiamart/conf/${id}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getIndiamartConfigApiKeyApi = async ({ id }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/indiamart/conf/${id}/apikey`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//**************************************END*************************** */

//******************Integrations Aajjo apis***************** */

const getAajjoConfigApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/aajjo/conf`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createAajjoConfigApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/aajjo/conf/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateAajjoConfigApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/aajjo/conf/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getWhatsappBAListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/whatsapp/list`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getWhatsappMetaWabaProvidersListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/whatsapp/metawaba/providers`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createWhatsappBAApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/whatsapp/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateWhatsappBAApi = async ({ wabaID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/whatsapp/${wabaID}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//**************************************END*************************** */

//---------------------Housing integration apis--------------------------
const getHousingIntegrationConfigApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/housing/conf`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createHousingConfigApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/housing/conf/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateHousingConfigApi = async ({ payload, configID }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/housing/conf/${configID}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//---------------------------END-----------------------------------------

//--------------------------99Acres Integration apis-------------------------
const get99AcresIntegrationConfigApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/99acres/conf`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const create99AcresConfigApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/99acres/conf/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const update99AcresConfigApi = async ({ payload, configID }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/99acres/conf/${configID}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//------------------------------END--------------------------------------

//----------------------Integrations page external webform apis--------------------
const getExternalWebformListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/webhooks/list`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createIntegrationsExternalWebFormApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/webhooks/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateIntegrationsExternalWebFormApi = async ({ formID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/integrations/webhooks/${formID}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getExternalWebformApiKeyApi = async ({ webhookID }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/integrations/webhooks/${webhookID}/apikey`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//-----------------------------End----------------------------------------------

//******************Project Admin Workday template page apis***************** */
const createProjectAdminWorkdayTemplateApi = async ({ projectID, payload }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/worktimings/templates/add`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
const updateProjectAdminWorkdayTemplateApi = async ({
  templateID,
  projectID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/worktimings/templates/${templateID}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getProjectAdminWorkTimingTemplateListApi = async ({ projectID }) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/worktimings/templates`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const updateCurrentProjectWorkdayTemplateApi = async ({
  projectID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/worktimings/update`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//****************************END****************************************** */

//********************************Branch locations related apis***************************** */
const getBranchLocationsListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/locations`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createBranchLocationApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/locations`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//************************************END*********************************************/

//********************************Attendance Settings related apis***************************** */
const getAttendanceSettingListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/attendance_settings`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const addUserAttendanceSettingApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/attendance_settings`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editUserAttendanceSettingApi = async ({
  attendanceSettingID,
  payload,
}) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/attendance_settings/${attendanceSettingID}/edit`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const removeUserAttendanceSettingApi = async ({ attendanceSettingID }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/attendance_settings/${attendanceSettingID}/remove`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getAttendanceUserSettingApi = async () => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/attendances/setting`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const markInUserAttendanceApi = async ({ payload }) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/attendances/markin`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const markOutUserAttendanceApi = async ({ payload }) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/attendances/markout`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const getAttendanceDayData = async ({ payload }) => {
  let uid = localStorage.getItem("uid");
  try {
    let response = await axios({
      method: "post",
      url: `${url}users/${uid}/attendances/list`,
      headers: getUserHeaders(),
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//************************************END*********************************************/

//*******************************attendance history page apis********************* */
const getUsersAttendanceListApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/attendances`,
      headers: getUserHeaders(),
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const downloadUsersAttendanceApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/users/attendances/download`,
      headers: getUserHeaders(),
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//**************************************END*************************************** */

//****************************attendance details page apis****************** */
const getUsersAttendanceDataApi = async ({ payload }) => {
  // try {
  //   let response = await axios({
  //     method: "post",
  //     url: `${url}admin/users/attendances`,
  //     headers: getUserHeaders(),
  //     data: payload,
  //   });
  //   return response.data;
  // } catch (error) {
  //   console.log(error);
  //   return [];
  // }
  return [
    {
      id: "952e85d0-ed21-4c01-9d91-e27a0f923849",
      attendance_date: "2024-07-29T18:30:00Z",
      attendance_in_latlong: "13.0298654,77.5714031",
      attendance_in_time: "2024-07-30T04:28:32Z",
      attendance_out_latlong: "13.0298662,77.5714005",
      attendance_out_time: "2024-07-30T04:28:50Z",
      total_in_time: null,
      user: {
        id: "8c9a5c4f-d404-4acf-a880-8fe5f960375d",
        name: "Shreyas",
      },
    },
    {
      id: "65af8faf-eb45-4e85-b0c0-eaeff40f10e5",
      attendance_date: "2024-07-29T18:30:00Z",
      attendance_in_latlong: "13.0298688,77.5714015",
      attendance_in_time: "2024-07-30T05:41:21Z",
      attendance_out_latlong: "13.0298621,77.5714013",
      attendance_out_time: "2024-07-30T05:41:49Z",
      total_in_time: 0,
      user: {
        id: "8c9a5c4f-d404-4acf-a880-8fe5f960375d",
        name: "Shreyas",
      },
    },
    {
      id: "8ca950b3-11d6-44bb-ac91-c91f1d192c9f",
      attendance_date: "2024-07-29T18:30:00Z",
      attendance_in_latlong: "13.0298739,77.5714008",
      attendance_in_time: "2024-07-30T08:10:46Z",
      attendance_out_latlong: "13.0298859,77.5713857",
      attendance_out_time: "2024-07-30T08:11:10Z",
      total_in_time: 0,
      user: {
        id: "8c9a5c4f-d404-4acf-a880-8fe5f960375d",
        name: "Shreyas",
      },
    },
    {
      id: "7a9a15b6-0d82-4daf-99ff-3c6536ac5413",
      attendance_date: "2024-07-29T18:30:00Z",
      attendance_in_latlong: "13.0298731,77.5714015",
      attendance_in_time: "2024-07-30T08:11:30Z",
      attendance_out_latlong: "13.0298906,77.5713841",
      attendance_out_time: "2024-07-30T08:29:11Z",
      total_in_time: 17,
      user: {
        id: "8c9a5c4f-d404-4acf-a880-8fe5f960375d",
        name: "Shreyas",
      },
    },
  ];
};
//*************************************END**************************** */

//**************************Invoices page apis*********************** */

const getInvoicesListApi = async ({ page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/sales_invoices/list?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//*************************************END**************************** */

//**************************Quotes page apis*********************** */
const getQuotesListApi = async ({ page, page_size }) => {
  let pid = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/quotations/list?offset=${
        page * page_size
      }&limit=${page_size}`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getQuotationApi = async (quotationID) => {
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/quotations/${quotationID}/details`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//*************************************END**************************** */

//**************************Task types page apis*********************** */
const getAdminTaskTypeListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/task_types`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//********************************END*********************************** */

//**************************Tasks page apis*********************** */
const getTasksListApi = async ({ queryParams }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}tasks`,
      headers: getUserHeaders(),
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createTaskApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}tasks/create`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const editTaskApi = async ({ taskID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}tasks/${taskID}/update`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const closeTaskApi = async ({ taskID, payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}tasks/${taskID}/close`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//********************************END*********************************** */

//**************************Tasks Tab in lead details page apis*********************** */
const getLeadTasksListApi = async ({ leadID }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;
  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/tasks/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
//********************************END*********************************** */

//************************Api to get tasktype list for user*************** */
const getTaskTypeListApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}tasks/types/list`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//**************************END *****************************************/

//***********************Interactions related apis****************** */
const getLeadInteractionLogsListApi = async ({ leadID, queryParams }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${pid}/leads/${leadID}/lead_interaction_logs/list`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createLeadInteractionLogApi = async ({ leadID, payload }) => {
  let pid = rootStore.authStore.projectId;
  let accId = rootStore.authStore.accountId;

  let tid =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  try {
    let response = await axios({
      method: "post",
      url: `${url}projects/${pid}/leads/${leadID}/lead_interaction_logs/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: pid,
        X_ACCOUNTID: accId,
        X_TEAMID: tid,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//*****************************END************************************ */

//**************Security page related apis**************** */
const getAdminSecurityLoginLocationSettings = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/accounts/security/loginlocationsettings`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getSecurityLoginLocationSettings = async () => {
  let accId = rootStore.authStore.accountId;

  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/${accId}/security/loginlocationsettings`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const createAdminSecurityLoginLocationSettings = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/security/loginlocationsettings`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const enableAdminSecurityLoginLocation = async ({ id }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/security/loginlocationsettings/${id}/enable`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const disableAdminSecurityLoginLocation = async ({ id }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/accounts/security/loginlocationsettings/${id}/disable`,
      headers: getUserHeaders(),
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//*****************************END************************************ */

//**************Project Admin lead form related apis**************** */
const getProjectAdminLeadFormDetailsApi = async () => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/projects/${projectID}/leadforms/primary`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

const createProjectAdminLeadFormApi = async ({ payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateProjectAdminLeadFormApi = async ({ formID, payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createLeadFormSectionApi = async ({ formID, payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/leadform_sections/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateLeadFormSectionApi = async ({ formID, sectionID, payload }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/leadform_sections/${sectionID}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const removeLeadFormSectionApi = async ({ formID, sectionID }) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/leadform_sections/${sectionID}/remove`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const createLeadFormSectionQuestionApi = async ({
  formID,
  sectionID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/leadform_sections/${sectionID}/leadform_questions/create`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const updateLeadFormSectionQuestionApi = async ({
  formID,
  sectionID,
  questionID,
  payload,
}) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/leadform_sections/${sectionID}/leadform_questions/${questionID}/edit`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const removeLeadFormSectionQuestionApi = async ({
  formID,
  sectionID,
  questionID,
}) => {
  let accId = rootStore.authStore.accountId;
  let projectID = rootStore.authStore.projectId;

  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/projects/${projectID}/leadforms/${formID}/leadform_sections/${sectionID}/leadform_questions/${questionID}/remove`,
      headers: {
        ...getUserHeaders(),
        X_PROJECTID: projectID,
        X_ACCOUNTID: accId,
      },
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    return new ApiResult("error", error.response.data, error.response.status);
  }
};
//****************************END******************************** */

const getAdminSystemUsersApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}admin/users/system`,
      headers: getUserHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

//**************Branding image related apis**************** */

const sendBrandingTextLogoApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/branding/logos/textlogo`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendBrandingCircularLogoApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/branding/logos/circularlogo`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendBrandingIconApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/branding/logos/icon`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

const sendBrandingSquareLogoApi = async ({ payload }) => {
  try {
    let response = await axios({
      method: "post",
      url: `${url}admin/branding/logos/squarelogo`,
      headers: getUserHeaders(),
      data: payload,
    });
    return new ApiResult("success", response.data, response.status);
  } catch (error) {
    console.log(error);
    return new ApiResult("error", error.response.data, error.response.status);
  }
};

//****************************END******************************** */

//------------------------app configs api------------------- */
const getAppConfigsApi = async () => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}accounts/app_configs`,
      headers: {
        ...getUserHeaders(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//-----------------------------end--------------------------

const getCreateLeadConfigDataApi = async ({ projectID, queryParams }) => {
  try {
    let response = await axios({
      method: "get",
      url: `${url}projects/${projectID}/leads/create/config`,
      headers: getUserHeaders(),
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export {
  getQuoteApi,
  bookDemoApi,
  submitExternalCampaignForm,
  testAPI,
  sendOtpEmailApi,
  sendOtpPhoneApi,
  userLoginPhoneApi,
  userLoginEmailApi,
  getProjectApi,
  getProjectListApi,
  getUserStatus,
  getDataApi,
  getProfileDataApi,
  uploadAccountUserProfileImageApi,
  getRoleApi,
  getTabsApi,
  getTeamApi,
  getStagesApi,
  getTotalLeadCountApi,
  getStageStatusesApi,
  getStatusApi,
  getAllColsApi,
  getLeadsApi,
  getLeadsApiV3,
  getLeadsItemCountApi,
  getFilteredLeadsApi,
  getListApi,
  submitLeadApi,
  editLeadApi,
  changeLeadstatusApi,
  changeLeadSourceApi,
  getSourcesListApi,
  getProjectMemberListApi,
  getSummaryApi,
  getPhoneApi,
  getLeadProfileApi,
  uploadLeadProfileImageApi,
  getLeadActivityApi,
  getLeadCallsApi,
  submitCallApi,
  userLogoutApi,
  leadAssignApi,
  leadTransferApi,
  deleteMultipleLeadsApi,
  deleteLeadApi,
  claimLeadApi,
  updateLeadColorApi,
  updateMultipleLeadColorApi,
  getMembersApi,
  getMembersApiV2,
  getMembersForTransfer,
  getFilterColsApi,
  getSummaryChartData,
  getSummaryBarData,
  getImportHistoryApi,
  getTargetsApi,
  sendTargetsApi,
  uploadCsvApi,
  getNotificationsApi,
  getNotificationsColumnsApi,
  getTargetMembersApi,
  getLeadFilesApi,
  getLeadPredefinedFilesApi,
  getLeadContactLinkedFilesApi,
  submitFileApi,
  uploadLeadFileApi,
  getLeadNotesApi,
  getUsersToMentionInLeadNoteApi,
  createLeadNoteApi,
  getNotificationsDetailApi,
  getAdminProjectApi,
  getAdminUsersApi,
  getAdminMembersApi,
  getAdminSourcesApi,
  getAdminStagesApi,
  getProjectAdministrationReOrederedStagesApi,
  getAdminStatusesApi,
  updateUserDetailApi,
  editUsersApi,
  editProjectApi,
  editProjectWorkScheduleApi,
  getAdminProjectDataApi,
  editTeamApi,
  addTeamApi,
  getAdminTeamsApi,
  editSourceApi,
  editStageApi,
  editStatusApi,
  getAdminDetailsApi,
  addSourceApi,
  addStageApi,
  addStatusApi,
  refreshImportRowApi,
  cancelLeadImportApi,
  addAdminMemberApi,
  getAdminStatusToStageApi,
  addStatusToStageApi,
  editStatusToStageApi,
  deleteStatusToStageApi,
  addUsersApi,
  removeAdminMemberApi,
  editProjectMemberApi,
  createAdminProjectMemberDialerConfigApi,
  updateAdminProjectMemberDialerConfigApi,
  removeAdminProjectMemberDialerConfigApi,
  getProjectMemberDialerConfigApi,
  getAdminProjectMemberDialerConfigApi,
  getPredefinedWidgetsApi,
  getPredefinedWidgetsApiv2,
  getPredefinedWidgetsForPrebuiltDashboradsApi,
  addWidgetApiv2,
  getWidgetDataApiv2,
  getWidgetListApiv2,
  addWidgetApi,
  getWidgetDataApi,
  getWidgetListApi,
  removeWidgetApi,
  rearrangeWidgetListApi,
  removeWidgetApiv2,
  getAdminColumnsApi,
  addAdminColumnApi,
  editAdminColumnApi,
  deleteAdminCustomColumnApi,
  editAdminSystemColumnApi,
  submitReportApi,
  getReportsApi,
  getPredefinedReportsApi,
  editReportApi,
  getReportDetailsApi,
  getPredefinedReportDetailsApi,
  getReportFileApi,
  downloadReportFileApi,
  deleteReportApi,
  refreshReportRowApi,
  getJobsApi,
  createJobApi,
  getJobStatusApi,
  getJobSpecificCountApi,
  getDialerExportJobAgentListApi,
  startSpecificJobApi,
  getRoleApiAdmin,
  getMonthCheckInsApi,
  getSegmentsApi,
  getCampaignTypeListApi,
  getCampaignProviderListApi,
  getTemplateListApi,
  getAgentListApi,
  submitSegmentApi,
  createCampaignApi,
  getCampaignDetailsApi,
  getCampaignStatsApi,
  testCampaignApi,
  getCampaignsListApi,
  getSegmentDataApi,
  removeSegmentApi,
  getLeadCheckInsApi,
  getDayCheckInsApi,
  getUserDayCheckInsApi,
  getUserMonthCheckInsApi,
  getMemberDetailByPhoneApi,
  addNewProjectUserApi,
  enableAdminUserApi,
  disableAdminUserApi,
  getUserLoginHistoryApi,
  getAdminUserDayCheckInsApi,
  getAdminUserMonthCheckInsApi,
  getBusinessContactListApi,
  getBusinessContactDetailsApi,
  addBusinessContactApi,
  editBusinessContactApi,
  testWidgetApi,
  getExportJobDownloadFileApi,

  //tabs select columns apis exports
  getColumnsForTabApi,
  setColumnsForTabApi,

  //filter v2 related api exports
  getFilteredLeadsApiV2,
  getFilteredLeadsApiV3,
  getFilterLeadsItemCountApi,
  getFilterColsApiV2,
  // call api
  ozonetelCall,
  getOzonetelCallStatus,
  ozonetelSetCallDisposition,
  mcubeCall,
  getMcubeCallStatus,
  teckinfoLeadpixieCall,
  getTeckinfoCallStatus,
  teckinfoClickToCall,
  teckinfoGetTokenApi,
  teckinfoCloseCallApi,

  //moneyview related api exports
  moneyViewCreateLeadApi,
  moneyViewCollectOffersApi,
  moneyViewFetchJourneyURLApi,

  //group order related api exports
  getColumnGroupsApi,
  getColumnPermissionsApi,
  getColumnGroupsPermissionsApi,
  //import log urls related api
  getImportLogUrlApi,

  //raw leads page related api exports
  getRawLeadsApi,
  getRawLeadsColumnsApi,
  getRawLeadsImportsApi,
  importRawLeadsApi,
  refetchRawLeadImportRowDataApi,
  getRawLeadImportLogUrlApi,
  getMembersToAssignByProject,
  moveRawLeadsToProjectApi,
  startCampaignApi,
  getCampaignItemCountApi,
  getImportLeadListApi,

  //get columns for mapping api
  getColumnsForMapping,

  //add predefinedWidget api
  addPredefinedWidgetApi,
  addClientWidgetApi,

  //get network info api
  getUserNetWorkInfoApi,
  getWhiteListedIpsApi,
  addNewIpAddressApi,
  removeIpAddressApi,
  disableIpAddressApi,
  enableIpAddressApi,

  //get started dialog api
  startDayApi,

  //send user active status api
  sendUserActiveStatus,

  //raw leads export tab apis
  getRawLeadJobsApi,
  getRawLeadJobStatusApi,
  getRawLeadExportJobDownloadFileApi,
  getRawLeadJobCountApi,
  startRawLeadJobApi,
  createRawLeadJobApi,

  //query seach api for search page
  searchLeadsByQueryApi,

  //multi dashboard apis
  getDashboardListApi,
  getWidgetListByDashboardApi,
  createPrebuildDashboardApi,
  createDashboardApi,
  editDashboardApi,

  //campaign stats page apis
  getCampaignLeadsListApi,
  createLeadForProject,

  //webforms apis
  getWebFormsListApi,
  createWebFormApi,
  createWebFormWithLandingPageApi,
  updateWebFormApi,
  createExternalWebFormApi,
  getWebFormDataApi,

  //smartviews page apis
  getAllProjectAdministrationViewsApi,
  getAssignedProjectAdministrationViewsApi,
  addProjectAdministrationViewApi,
  getProjectAdministrationReOrederedViewsApi,
  deleteProjectAdministrationViewApi,
  editProjectAdministrationViewApi,
  createProjectAdministrationViewApi,
  editProjectAdministrationViewSelectColumnApi,
  getAdminFilterColumns,
  updateFilterColumns,
  getAdminNotificationColumns,
  updateNotificationColumns,

  // deals tab apis
  getLeadActiveDealApi,
  getLeadDealListApi,
  deactivateLeadDealApi,
  createLeadDealApi,

  //quotes tab apis
  getLeadQuoteListApi,
  getLeadInvoiceListApi,
  getQuoteTemplateListApi,
  getQuotationNumberApi,
  getInvoiceNumberApi,
  getLeadInvoiceDataApi,
  createLeadInvoiceApi,
  markLeadInvoiceAsSentApi,
  editLeadInvoiceApi,
  invoiceRecordPaymentsApi,
  createLeadQuotationApi,
  editLeadQuotationApi,
  reviseLeadQuotationApi,
  markLeadQuotationAsInvoicedApi,
  markLeadQuotationAsSentApi,
  getLeadQuotationDataApi,

  //contacts page apis
  getContactsListApi,
  getFilteredContactItemCountApi,
  getFilteredContactListApi,
  createContactApi,
  getContactDetailsApi,
  uploadContactProfileImageApi,
  editContactApi,
  getContactLeadsListApi,
  getContactFilesListApi,
  uploadContactFileApi,
  updateContactFileApi,

  //products page apis
  getProductsListApi,
  createProductApi,
  getProductDetailsApi,
  editProductApi,

  //search contact apis
  searchContactByEmailApi,
  searchContactByPhoneApi,

  //deals page apis
  getDealsListApi,

  //organizations page apis
  getOrganizationsListApi,
  searchOrganizationsByNameApi,
  createOrganizationApi,
  getOrganizationDetailsApi,
  editOrganizationApi,
  getOrganizationContactsListApi,
  getOrganizationLeadsListApi,
  getOrganizationSummaryApi,

  //mark unmark lead apis
  markUnmarkLeadAsFreshLeadApi,
  markUnmarkMultipleLeadAsFreshLeadApi,

  //view groups page apis
  getProjectAdministrationViewGroupsApi,
  deleteProjectAdministrationViewGroupApi,
  createProjectAdministrationViewGroupApi,

  //customers page apis
  createCustomerApi,
  assignCustomerApi,
  getCustomerStagesListApi,
  getCustomerPurchaseCategoryListApi,
  getCustomersListApi,
  getFilteredCustomerListApi,
  getFilteredCustomerItemCountApi,
  getCustomerDetailsApi,
  editCustomerApi,
  getCustomerActivityListApi,
  uploadCustomerProfileImageApi,
  getCustomerLeadsListApi,
  getCustomerHistoryListApi,
  getCustomerFilesListApi,
  uploadCustomerFileApi,
  updateCustomerFileApi,
  updateCustomerStageApi,
  updateCustomerPurchaseCategoryApi,

  //viewgroups api
  getViewGroupsApi,

  // column group api
  getProjectAdministrationColumnGroupsApi,
  deleteProjectAdministrationColumnGroupApi,
  createProjectAdministrationColumnGroupApi,
  updateProjectAdministrationColumnGroupApi,
  getProjectAdministrationReOrederedColumnGroupsApi,

  //columns apis
  getProductColumnsListApi,
  getContactColumnsListApi,
  getContactColumnGroupsApi,
  getContactDetailsTabsApi,
  getOrganizationColumnsListApi,
  getCustomerColumnsListApi,
  getCustomerColumnGroupsApi,
  getCustomerDetailsTabsApi,

  //Admin predefined deals apis
  getAdminPredefinedDealsListApi,
  createAdminPredefinedDealApi,

  ///// predeined deals api
  getPredefinedDealsApi,

  //link contact api
  linkContactApi,

  //update lead stage api
  updateLeadStageApi,
  updateMultipleLeadStageApi,

  //update multi lead field api
  updateMultipleLeadFieldApi,

  //update multi lead source api
  updateMultipleLeadSourceApi,

  //update multi lead status api
  updateMultipleLeadStatusApi,

  //files page apis
  createFolderApi,
  getFoldersListApi,
  getFolderFileListApi,
  createFileApi,
  updateDocumentApi,
  deleteDocumentApi,
  deleteFolderApi,
  leadSubmitApi,
  getFolderPermissionListApi,
  addFolderPermissionApi,
  removeFolderPermissionApi,
  getInvoiceListApi,
  getBannerListApi,

  //lead as priority apis
  markUnmarkLeadAsPriorityApi,
  markUnmarkMultipleLeadAsPriorityApi,

  //******hot lead api */
  getHotLeadApi,

  //******webform stats page apis**** */
  getWebformLeadsListApi,
  getWebformStatsApi,
  markWebformLeads,

  //billing page apis
  getSubscriptionDetailsApi,

  //acount member related api
  getAccountMembersApi,
  getUserProjectsApi,
  getAccountUserDetailsApi,

  //lead forms api
  getLeadFormDetailsApi,

  //project admin quality page apis
  getProjectAdministrationQualityListApi,
  deleteProjectAdministrationQualityApi,
  addProjectAdministrationQualityApi,
  editProjectAdministrationQualityApi,
  publishProjectAdministrationQualityApi,
  unPublishProjectAdministrationQualityApi,

  //reset next followup api
  resetNextFollowupForLeadsApi,

  //admin quotation page apis
  getAdminQuotationsListApi,
  createAdminQuotationApi,
  deactivateQuotationTemplate,
  activateQuotationTemplate,

  //terms of services api
  getUnacceptedTermsOfServicesDetailsApi,
  acceptTermsOfServicesApi,

  //lead whatsapp template list api
  getAdminAccountWhatsAppBusinessTemplateListApi,
  uploadLeadWhatsAppBusinessMediaApi,
  uploadWbMediaContact,
  getWhatsappLeadChatMediaApi,
  getWhatsappChatMessagesApi,
  getWhatsappContactChatMessagesApi,
  syncAdminAccountWhatsAppBusinessApi,
  sendAdminAccountWhatsAppBusinessApi,
  sendLeadWhatsAppBusinessChatTextApi,
  sendLeadWhatsAppBusinessChatImageApi,
  sendContactWhatsAppBusinessChatVideoApi,
  sendLeadWhatsAppBusinessChatVideoApi,
  sendContactWhatsAppBusinessChatImageApi,
  sendContactWhatsAppBusinessChatTextApi,
  sendLeadWhatsAppBusinessChatApi,
  sendContactWhatsAppBusinessChatApi,
  createAdminAccountMetaWabaTemplateApi,
  deleteAdminAccountMetaWabaTemplateApi,
  //account level personal
  getAdminAccountWhatsAppPersonalTemplateListApi,
  createWhatsAppPersonalTemplateApi,
  createProjectWhatsAppPersonalTemplateApi,
  updateWhatsAppPersonalTemplateApi,
  updateProjectWhatsAppPersonalTemplateApi,
  getWhatsAppTemplateListApi,
  getWhatsAppTemplateMetaWabaListApi,
  //project admin whatsapp template apis
  getProjectAdminWhatsAppTemplateListApi,

  //integrations page apis
  sendFbLoginToken,
  createFbPageConfig,
  getFacebookLeadsConfigData,
  getFacebookAllPagesData,
  getFacebookConfiguredPagesData,
  getFacebookPageAllFormData,
  getFacebookPageConfiguredFormData,
  getFacebookPageFormQuestionsList,
  saveFacebookPageFormApi,
  getIndiamartAllConfigApi,
  getIndiamartConfigApi,
  createIndiamartConfigApi,
  updateIndiamartConfigApi,
  getIndiamartConfigApiKeyApi,
  getAajjoConfigApi,
  createAajjoConfigApi,
  updateAajjoConfigApi,
  getHousingIntegrationConfigApi,
  createHousingConfigApi,
  updateHousingConfigApi,
  get99AcresIntegrationConfigApi,
  create99AcresConfigApi,
  update99AcresConfigApi,
  getWhatsappBAListApi,
  getWhatsappMetaWabaProvidersListApi,
  createWhatsappBAApi,
  updateWhatsappBAApi,

  //project admin workday template apis
  createProjectAdminWorkdayTemplateApi,
  updateProjectAdminWorkdayTemplateApi,
  getProjectAdminWorkTimingTemplateListApi,
  updateCurrentProjectWorkdayTemplateApi,

  //branch locations apis
  getBranchLocationsListApi,
  createBranchLocationApi,

  //attendance settings apis
  getAttendanceSettingListApi,
  addUserAttendanceSettingApi,
  editUserAttendanceSettingApi,
  removeUserAttendanceSettingApi,
  getAttendanceUserSettingApi,
  markInUserAttendanceApi,
  markOutUserAttendanceApi,
  getAttendanceDayData,

  //attendance history apis
  getUsersAttendanceListApi,
  downloadUsersAttendanceApi,
  getUsersAttendanceDataApi,

  //task types page apis
  getAdminTaskTypeListApi,

  //invoices page apis
  getInvoicesListApi,

  //quotes page apis
  getQuotesListApi,
  getQuotationApi,

  //tasks page apis
  getTasksListApi,
  createTaskApi,
  editTaskApi,
  closeTaskApi,

  //tasks tab apis
  getLeadTasksListApi,
  getTaskTypeListApi,

  //Interactions apis
  getLeadInteractionLogsListApi,
  createLeadInteractionLogApi,

  //Security apis
  getAdminSecurityLoginLocationSettings,
  getSecurityLoginLocationSettings,
  createAdminSecurityLoginLocationSettings,
  enableAdminSecurityLoginLocation,
  disableAdminSecurityLoginLocation,

  //project admin lead form apis
  getProjectAdminLeadFormDetailsApi,
  createProjectAdminLeadFormApi,
  updateProjectAdminLeadFormApi,
  createLeadFormSectionApi,
  updateLeadFormSectionApi,
  createLeadFormSectionQuestionApi,
  updateLeadFormSectionQuestionApi,
  removeLeadFormSectionApi,
  removeLeadFormSectionQuestionApi,
  getAdminSystemUsersApi,

  //branding image apis
  sendBrandingTextLogoApi,
  sendBrandingCircularLogoApi,
  sendBrandingIconApi,
  sendBrandingSquareLogoApi,

  //app configs api
  getAppConfigsApi,

  //external webforms integrations page apis
  getExternalWebformListApi,
  createIntegrationsExternalWebFormApi,
  updateIntegrationsExternalWebFormApi,
  getExternalWebformApiKeyApi,

  //get create lead config
  getCreateLeadConfigDataApi,
};

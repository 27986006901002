import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { getRawLeadsColumnsApi, importRawLeadsApi } from "../../Api";
import { MultiSelect } from "react-multi-select-component";
import { CSVLink } from "react-csv";
import "./upload.css";
import { myTheme } from "../../themeUtils";
import { consoleLogger, getLocalizedText } from "../../Functions";
import { observer } from "mobx-react-lite";
import analytics from "../../Analytics";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const Message = styled.p`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  margin-bottom: 12px;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
`;
const SubHeader = styled.p`
  margin: 0px;
  margin-bottom: 20px;
  font: normal normal 500 14px Open Sans;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  width: 100%;
  height: 35px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 600 16px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;

const ImportRawLeadModal = (props) => {
  const { authStore, userStore } = rootStore;
  const [file, setFile] = useState();
  const [selected, setSelected] = useState([]);
  const [selectVal, setSelectedVal] = useState([]);
  const [list, setList] = useState([]);
  const [defaultList, setDefaultList] = useState([]);
  const [check, setCheck] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  let pid = authStore.projectId;

  const handleFlag = (e) => {
    setFlag(e.target.checked);
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("import_type", "raw_leads");
    if (userStore.UserData !== null && userStore.UserData.role === "owner") {
      formData.append("visible_to_admin", flag);
    } else {
      formData.append("visible_to_admin", true);
    }
    const sendCSV = async () => {
      try {
        let response = await importRawLeadsApi(formData);
        setLoading(false);
        setCheck(true);
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
    };
    sendCSV();
  };

  const updateList = (val) => {
    let temp_list = [];
    let default_field_list = [];
    val.map((e) => {
      let newObj = {
        label: e.display_name,
        value: e.display_name,
      };
      temp_list.push(newObj);
      default_field_list.push(e.display_name);
    });
    return { temp_list, default_field_list };
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000146,
      "import_raw_leads_form_load",
      "imports_tab",
      "",
      {}
    );
    const getColumns = async () => {
      let response = await getRawLeadsColumnsApi(pid);
      let updated_list = updateList(response);
      consoleLogger(updated_list);
      setDefaultList(updated_list.default_field_list);
      setList(updated_list.temp_list);
    };
    getColumns();
  }, []);

  const canShowVisibleToAdministratorCheckbox = () => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    return accountRole === "owner";
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <IconButton
              onClick={() => {
                analytics.triggerEvent(
                  4625000149,
                  "close_icon_tap",
                  "import_raw_leads_form",
                  "close_icon",
                  {}
                );
                props.setOpen(false);
              }}
              style={{
                zIndex: "9999",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>
            {!check ? (
              <ModelWrapper>
                <Header>{getLocalizedText("import_raw_leads","project")}</Header>
                <SubHeader>
                  {getLocalizedText(
                    "upload_the_data_in_the_same_format_as_the_sample_csv_file"
                  )}
                </SubHeader>
                <Column>
                  <Input
                    type="file"
                    style={{
                      fontSize: "12px",
                      padding: "4px",
                      backgroundColor: "#EFEFF4",
                      border: "none",
                      borderRadius: "6px",
                      marginBottom: "10px",
                    }}
                    accept={".csv"}
                    onChange={handleChange}
                  />
                  {canShowVisibleToAdministratorCheckbox() && (
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label={
                        <FlagText>
                          {getLocalizedText("visible_to_administrators")}
                        </FlagText>
                      }
                      onChange={handleFlag}
                      style={{ marginBottom: "10px" }}
                    />
                  )}

                  <Button
                    disabled={file === undefined || loading}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000147,
                        "import_raw_leads_button_tap",
                        "import_raw_leads_form",
                        "import_raw_leads_button",
                        {}
                      );
                      handleUpload();
                    }}
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "100%",
                      textTransform: "none",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : getLocalizedText("import_raw_leads","project")}
                  </Button>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    {list.length > 0 && (
                      <>
                        <InputWrapper>
                          <MultiSelect
                            options={list}
                            value={selected}
                            className="upload_multi_select"
                            onChange={(e) => {
                              setSelected(e);
                              let newList = [];
                              e.map((val) => {
                                newList.push(val.value);
                              });
                              setSelectedVal(newList);
                            }}
                            labelledBy="Select Columns"
                          />
                        </InputWrapper>

                        {selectVal.length === 0 ? (
                          <CSVLink
                            filename="sample"
                            style={{ display: "flex", alignItems: "center" }}
                            data={[defaultList]}
                          >
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000148,
                                  "download_sample_csv_button_tap",
                                  "import_raw_leads_form",
                                  "download_sample_csv_button",
                                  {}
                                );
                              }}
                              style={{
                                font: "normal normal 600 12px Open Sans",
                                textTransform: "none",
                              }}
                            >
                              {getLocalizedText("download_sample_csv")}
                            </Button>
                          </CSVLink>
                        ) : (
                          <CSVLink
                            filename="sample"
                            style={{ display: "flex", alignItems: "center" }}
                            data={[selectVal]}
                          >
                            <Button
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000148,
                                  "download_sample_csv_button_tap",
                                  "import_raw_leads_form",
                                  "download_sample_csv_button",
                                  {}
                                );
                              }}
                              variant="text"
                              color="primary"
                              style={{
                                font: "normal normal 600 12px Open Sans",
                                textTransform: "none",
                              }}
                            >
                              {getLocalizedText("download_sample_csv")}
                            </Button>
                          </CSVLink>
                        )}
                      </>
                    )}
                  </Row>
                </Column>
              </ModelWrapper>
            ) : !isError ? (
              <ModelWrapper>
                <MessageWrapper>
                  <Message style={{ textAlign: "center" }}>
                    {getLocalizedText(
                      "raw_leads_are_being_created_please_check_the_status_in_the_listing","project"
                    )}
                  </Message>
                  <Button
                    variant="text"
                    onClick={() => {
                      props.setRefresh(!props.refresh);
                      props.setOpen(false);
                    }}
                    style={{
                      width: "fit-content",
                      backgroundColor: "#185DD2",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    {getLocalizedText("ok")}
                  </Button>
                </MessageWrapper>
              </ModelWrapper>
            ) : (
              <ModelWrapper>
                <MessageWrapper>
                  <Message style={{ textAlign: "center" }}>
                    {getLocalizedText(
                      "some_error_occured_please_retry_or_contact_your_administrator"
                    )}
                  </Message>
                  <Button
                    variant="text"
                    onClick={() => {
                      props.setRefresh(!props.refresh);
                      props.setOpen(false);
                    }}
                    style={{
                      width: "fit-content",
                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "auto",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("ok")}
                  </Button>
                </MessageWrapper>
              </ModelWrapper>
            )}
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ImportRawLeadModal);

import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import "./rule.css";
import { Alert, CircularProgress, MenuItem } from "@mui/material";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import analytics from "../../../Analytics";
import { getLocalizedText } from "../../../Functions";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  width: 398px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;

const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Label = styled.span`
  font: normal normal normal 10px Open Sans;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  column-gap: 10px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const CreateLeadRulesModal = (props) => {
  const classes = useStyles();
  const [createLeadRule, setSelectedCreateLeadRule] = useState({});
  const [columnsList, setColumnsList] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    setSelectedCreateLeadRule(props.selectedCreateLeadRule);

    let filteredColumns = [...props.allColumnList].filter(
      (item) =>
        item.derived === false &&
        item.system === false &&
        (item.contact_column === null || item.contact_column === "") &&
        item.data_type !== "date" &&
        item.data_type !== "datetime" &&
        item.parent === false &&
        item.is_array === false &&
        item.child_of === null
    );
    filteredColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnsList(filteredColumns);
    setSelectedColumn(props.selectedColumn);
    setLoading(false);
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    if (createLeadRule.slug === "option_allow_duplicates_unique_column") {
      if (Object.keys(selectedColumn).length === 0) {
        setShowAlert(true);
        setAlertMessage(getLocalizedText("please_select_a_column"));
      } else {
        props.setSelectedColumn(selectedColumn);
        props.setSelectedCreateLeadRule(createLeadRule);
        props.setOpen(false);
      }
    } else {
      props.setSelectedCreateLeadRule(createLeadRule);
      props.setOpen(false);
      props.setSelectedColumn({});
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {!loading ? (
            <Box>
              <IconButton
                onClick={() => {
                  analytics.triggerEvent(
                    4625000105,
                    "close_icon_tap",
                    "assignment_rules_form",
                    "close_icon",
                    {}
                  );
                  props.setOpen(false);
                }}
                style={{
                  zIndex: "9999",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <Close />
              </IconButton>

              <ModelWrapper>
                <Header>{getLocalizedText("create_lead_rule","project")}</Header>

                <SubHeader>
                  {getLocalizedText("rules_for_creating_leads_on_import","project")}
                </SubHeader>
                <Divider style={{ margin: "10px 0px" }} />
                <Wrapper onSubmit={handleSave}>
                  <Column>
                    <InputWrapper>
                      <Label>{getLocalizedText("create_lead_rule","project")}</Label>
                      <FormControl
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          name="create_lead_rule"
                          onChange={(e) => {
                            setShowAlert(false);
                            setAlertMessage("");
                            setSelectedCreateLeadRule(e.target.value);
                            setSelectedColumn({});
                          }}
                          required
                          value={createLeadRule}
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          style={{
                            marginBottom: "10px",
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {props.createLeadRulesList.map((e, index) => {
                            return (
                              <MenuItem
                                style={{ fontSize: "14px" }}
                                id={index}
                                value={e}
                              >
                                {e.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>

                    {createLeadRule.slug ===
                      "option_allow_duplicates_unique_column" && (
                      <InputWrapper>
                        <Label>{getLocalizedText("select_column")}</Label>
                        <FormControl
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="column"
                            required
                            displayEmpty
                            variant="outlined"
                            value={selectedColumn}
                            onChange={(e) => {
                              setSelectedColumn(e.target.value);
                            }}
                            style={{
                              marginBottom: "10px",
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {columnsList.map((item, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  id={i}
                                  value={item}
                                  style={{ fontSize: "12px" }}
                                >
                                  {item.headerName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    )}

                    <Column>
                      <Label
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginRight: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Description:
                      </Label>
                      <Box
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {createLeadRule["desc"].map((e) => {
                          return (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Label
                                style={{
                                  fontSize: "12px",
                                  marginRight: "8px",
                                }}
                              >
                                {"•"}
                              </Label>
                              <Label style={{ fontSize: "12px" }}>{e}</Label>
                            </Box>
                          );
                        })}
                      </Box>
                    </Column>
                  </Column>

                  {showAlert && (
                    <Alert
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        padding: "0px 20px",
                      }}
                      severity="warning"
                    >
                      {alertMessage}
                    </Alert>
                  )}
                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      style={{
                        font: "normal normal 600 12px Open Sans",
                        width: "130px",
                        textTransform: "none",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      onClick={() => {}}
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        font: "normal normal 600 12px Open Sans",
                        width: "130px",
                        textTransform: "none",
                      }}
                    >
                      {getLocalizedText("save")}
                    </Button>
                  </ButtonWrapper>
                </Wrapper>
              </ModelWrapper>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "350px",
                width: "398px",
              }}
            >
              <CircularProgress />
            </Box>
          )}{" "}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(CreateLeadRulesModal);

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { dataRenderer, getLocalizedText } from "../../../Functions";
import { Box, Typography } from "@mui/material";
import { Divider, Drawer, Grid } from "@material-ui/core";
import ViewDealProductSidepanel from "../../view_product_sidepanel/ViewDealProductSidepanel";
import ProductsTab from "./deals_sub_tabs/ProductsTab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HistoryTab from "./deals_sub_tabs/HistoryTab";
import ViewLeadDealSidepanel from "../../view_deal_sidepanel/ViewLeadDealSidepanel";
import { PriorityHigh } from "@material-ui/icons";

const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
`;

const InputWrapper = styled(Box)`
  background: #f9f9fc 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  height: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
`;

const DealDeatilsWithHistoryWidget = ({ details, dealsHistoryList }) => {
  const [openViewProductPanel, setOpenViewProductPanel] = useState(false);
  const [openViewDealPanel, setOpenViewDealPanel] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedDealData, setSelectedDealData] = useState({});

  const RenderDealPriorityIcon = () => {
    if (details.priority.toLowerCase() === "low") {
      return (
        <PriorityHigh
          color="inherit"
          style={{ width: "20px", height: "20px" }}
        />
      );
    } else if (details.priority.toLowerCase() === "medium") {
      return (
        <PriorityHigh
          color="primary"
          style={{ width: "20px", height: "20px" }}
        />
      );
    } else if (details.priority.toLowerCase() === "high") {
      return (
        <PriorityHigh color="error" style={{ width: "20px", height: "20px" }} />
      );
    }
  };

  const RenderValueWithCurrency = ({ value }) => {
    if (value !== "-") {
      if (
        details.hasOwnProperty("deal_value_currency") &&
        details["deal_value_currency"] !== null &&
        details["deal_value_currency"] !== ""
      ) {
        return <Detail>{`${details["deal_value_currency"]} ${value}`}</Detail>;
      }
    }
    return <Detail>{value}</Detail>;
  };
  return (
    <>
      <Container>
        <SectionWrapper style={{ marginBottom: "16px" }}>
          <SectionHeader>{getLocalizedText("deal_information")}</SectionHeader>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputWrapper>
                <Label>{getLocalizedText("deal_name")}</Label>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Detail>{dataRenderer(details.name)}</Detail>
                  {details.hasOwnProperty("priority") &&
                    details["priority"] !== null &&
                    details["priority"] !== "" && <RenderDealPriorityIcon />}
                </Box>
              </InputWrapper>
            </Grid>
            <Grid item xs={3}>
              <InputWrapper>
                <Label>{getLocalizedText("final_deal_value")}</Label>
                <RenderValueWithCurrency
                  value={dataRenderer(details["deal_value"])}
                />
              </InputWrapper>
            </Grid>

            <Grid item xs={3}>
              <InputWrapper>
                <Label>{getLocalizedText("discount")}</Label>
                {details.hasOwnProperty("discount") &&
                details.hasOwnProperty("discount_in_price") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <RenderValueWithCurrency
                      value={dataRenderer(details["discount_in_price"])}
                    />
                    {details.hasOwnProperty("discount") &&
                      details["discount"] !== null &&
                      details["discount"] !== "" && (
                        <Detail>{`(${dataRenderer(
                          details["discount"]
                        )}%)`}</Detail>
                      )}
                  </Box>
                ) : (
                  <Detail>-</Detail>
                )}
              </InputWrapper>
            </Grid>
            <Grid item xs={3}>
              <InputWrapper>
                <Label>{getLocalizedText("margin")}</Label>

                <Detail>
                  {details.hasOwnProperty("margin") &&
                  details["margin"] !== null &&
                  details["margin"] !== ""
                    ? `${details["margin"]}%`
                    : "-"}
                </Detail>
              </InputWrapper>
            </Grid>
          </Grid>
        </SectionWrapper>

        <Divider style={{ marginBottom: "16px" }} />
        <SectionWrapper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
              variant="scrollable"
              value={tabValue}
              textColor="primary"
              indicatorColor="primary"
              scrollButtons={"auto"}
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "capitalize",
                  },

                  backgroundColor: "white",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                }}
                label={"Products"}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#E7EEFA",
                    color: "#4079DA",
                    font: "normal normal 600 14px Open Sans",
                    textTransform: "capitalize",
                  },

                  backgroundColor: "white",
                  color: "#4D4E4F",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                }}
                label={"Deals History"}
              />
            </Tabs>
          </Box>
          {(() => {
            if (tabValue === 0) {
              return (
                <ProductsTab
                  tableData={
                    details.hasOwnProperty("deal_items") &&
                    details.deal_items !== null
                      ? details.deal_items
                      : []
                  }
                  setOpenViewProductPanel={setOpenViewProductPanel}
                  setSelectedRowID={setSelectedRowID}
                />
              );
            } else {
              return (
                <HistoryTab
                  tableData={dealsHistoryList}
                  setSelectedDealData={setSelectedDealData}
                  setOpenViewDealPanel={setOpenViewDealPanel}
                />
              );
            }
          })()}
        </SectionWrapper>
      </Container>
      {openViewProductPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewProductPanel}
          onClose={() => {
            setSelectedRowID("");
            setOpenViewProductPanel(false);
          }}
        >
          <ViewDealProductSidepanel
            productID={selectedRowID}
            setOpen={setOpenViewProductPanel}
            open={openViewProductPanel}
          />
        </Drawer>
      )}

      {openViewDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewDealPanel}
          onClose={() => {
            setSelectedDealData({});
            setOpenViewDealPanel(false);
          }}
        >
          <ViewLeadDealSidepanel details={selectedDealData} />
        </Drawer>
      )}
    </>
  );
};

export default DealDeatilsWithHistoryWidget;

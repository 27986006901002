import React from "react";
import { styled } from "@mui/material/styles";

import { observer } from "mobx-react-lite";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  getLocalizedText,
  dataRenderer,
  getFormattedMultilineText,
} from "../../../Functions";
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  textAlign: 'left',
  margin: 'auto',
  backgroundColor: 'white',
  height: 'fit-content',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0.2em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px #ffffff',
  },
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  height: 'fit-content',
  width: 'fit-content',
  border: 'none',
  outline: 'none',
  backgroundColor: 'white',
  boxShadow: '0px 3px 6px #0000000d',
  borderRadius: '8px',
  padding: '30px',
  border: '1px solid #c5d7f1',
  boxShadow: '0px 3px 20px #185dd21f',
}));

const Header = styled(Typography)(({ theme }) => ({
  font: 'normal normal 600 18px Open Sans',
  color: '#4d4e4f',
}));

const Form = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '20px',
  width: '320px',
  columnGap: '20px',
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '150px',
}));

const Label = styled(Typography)(({ theme }) => ({
  font: 'normal normal 500 10px Open Sans',
  color: '#4d4e4f',
  marginBottom: '6px',
}));

const Detail = styled(Typography)(({ theme }) => ({
  font: 'normal normal 600 14px Open Sans',
  color: '#4d4e4f',
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
  marginBottom: '30px',
}));

const ViewAreaBasedProductModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
    props.setData({});
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Row>
                <Info color="primary" />
                <Header>{getLocalizedText("info")}</Header>
              </Row>
              <Form>
                <InputContainer>
                  <InputWrapper>
                    <Label>Item Name</Label>
                    <Detail>{dataRenderer(props.data.item.name)}</Detail>
                  </InputWrapper>
                  <InputWrapper style={{ width: "100%" }}>
                    <Label>Description</Label>
                    {props.data.hasOwnProperty("desc") &&
                    props.data["desc"] !== null &&
                    props.data["desc"] !== "" ? (
                      <Detail
                        component="div"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {getFormattedMultilineText(props.data["desc"])}
                      </Detail>
                    ) : (
                      <Detail>-</Detail>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Width</Label>
                    <Detail>{dataRenderer(props.data.width)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Height</Label>
                    <Detail>{dataRenderer(props.data.height)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>In Units</Label>
                    <Detail>{dataRenderer(props.data.in_unit)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Quantity</Label>
                    <Detail>{dataRenderer(props.data.quantity)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Total Units</Label>
                    <Detail>{dataRenderer(props.data.total_unit)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Rate</Label>
                    <Detail>{dataRenderer(props.data.rate)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Amount</Label>
                    <Detail>{dataRenderer(props.data.amount)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Tax (%)</Label>
                    <Detail>{dataRenderer(props.data.tax)}</Detail>
                  </InputWrapper>
                </InputContainer>

                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{
                      width: "120px",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("close")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ViewAreaBasedProductModal);

import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import WhatsappSendTemplateMessagePreview from "./WhatsappSendTemplateMessagePreview";
import {
  consoleLogger,
  getLeadVariableValue,
  getLocalizedText,
  getSystemVariableValue,
} from "../../Functions";
import rootStore from "../../stores/RootStore";

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "16px",
  width: "100%",
  maxWidth: "560px",
  maxHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));
const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  boxSizing: "border-box",
  overflow: "auto",
  maxHeight: "60vh",
  flexShrink: 0,
}));
const FieldsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: "8px",
  overflowY: "auto",
  maxHeight: "calc(95vh - 200px)",
  boxSizing: "border-box",
  width: "100%",
  padding: "8px",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: "8px",
}));

const Label = styled(Typography)(({ theme }) => ({
  width: "25%",
  font: "normal normal 500 12px Open Sans",
  color: "#4d4e4f",
}));

const PreviewHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "20px",
  },
}));

function TemplateMessageSendPopupComponent(props) {
  consoleLogger("data::", props.data);
  const toolbarStyle = useStyles();
  const [headerVariableField, setHeaderVariableField] = useState({});
  const [variableFields, setVariableFields] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const contactColumns = [...rootStore.contactStore.columnsList];

  const checkIfVariableHasComponentMapping = (varId, mapping) => {
    return mapping.hasOwnProperty(varId);
  };

  const handleSubmit = () => {
    let newBodyData = [...props.data.components];
    const index = newBodyData.findIndex((x) => x.type.toLowerCase() === "body");
    let bodyData = [];
    if (props.data.components.length > 0) {
      bodyData = props.data["components"].find(
        (x) => x.type.toLowerCase() === "body"
      );
    }

    let finalText = bodyData["text"];
    for (let key in details) {
      finalText = replacePlaceholder(
        finalText,
        `{{` + key + `}}`,
        details[key]
      );
    }
    newBodyData[index]["text"] = finalText;

    props.onSendCallback(newBodyData, details, selectedFile);
    handleClose();
  };

  const handleClose = () => {
    props.onCloseCallback();
    props.setOpen(false);
  };

  const getComponentByType = (type) => {
    const component = props.data["components"].find(
      (x) => x.type.toLowerCase() === type
    );
    return component || null;
  };

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    //header var handling
    const headerData = getComponentByType("header");
    if (
      headerData !== null &&
      headerData.hasOwnProperty("format") &&
      headerData.format !== null
    ) {
      if (
        headerData.format.toLowerCase() === "image" ||
        headerData.format.toLowerCase() === "video"
      ) {
        setHeaderVariableField({
          name: headerData.format.toLowerCase(),
          id: -1,
        });
      }
    }
    let bodyData = [];

    if (props.data.components.length > 0) {
      bodyData = props.data["components"].find(
        (x) => x.type.toLowerCase() === "body"
      );
    }

    let componentVariableMapping = {};
    let variablesWithType = [];

    if (props.data.hasOwnProperty("component_variable_mapping")) {
      componentVariableMapping = props.data["component_variable_mapping"];
      for (const key in componentVariableMapping) {
        const value = componentVariableMapping[key];

        const index = contactColumns.findIndex((column) => column.id === value);
        if (index !== 1) {
          const mappingKey = parseInt(key);
          variablesWithType.push({
            variable_name: contactColumns[index]["display_name"],
            variable_type: "lead_variable",
          });
        }
        //prefill lead variable in details
        const leadVariableValues = getLeadVariableValuesForMessage({
          variablesWithType: variablesWithType,
          leadData: props.leadData,
        });

        setDetails(leadVariableValues);
      }

      if (
        bodyData.hasOwnProperty("example") &&
        bodyData["example"].hasOwnProperty("body_text")
      ) {
        const varIds = bodyData["example"]["body_text"][0].map(
          (_, i) => `{{` + (i + 1) + `}}`
        );
        let varFields = [];
        for (let i = 0; i < varIds.length; i++) {
          if (
            !checkIfVariableHasComponentMapping(
              varIds[i],
              componentVariableMapping
            )
          ) {
            varFields.push({ name: varIds[i], id: i });
          }
        }
        setVariableFields(varFields);
      }
    } else {
      if (
        bodyData.hasOwnProperty("example") &&
        bodyData["example"].hasOwnProperty("body_text")
      ) {
        const varIds = bodyData["example"]["body_text"][0].map(
          (_, i) => `{{` + (i + 1) + `}}`
        );
        let varFields = [];
        for (let i = 0; i < varIds.length; i++) {
          varFields.push({ name: varIds[i], id: i });
        }
        setVariableFields(varFields);
      }
    }
  };

  function replacePlaceholder(message, placeholderString, replacementValue) {
    if (replacementValue !== "") {
      return message.replace(placeholderString, replacementValue);
    }
    return message;
  }

  //used on init to prefill contact column mapping from template
  const getLeadVariableValuesForMessage = ({ variablesWithType, leadData }) => {
    const columnsList = [...rootStore.userStore.AllColumnsList];

    let tempVariableValueMapping = {};
    variablesWithType.forEach((variableData, i) => {
      if (variableData["variable_type"] === "lead_variable") {
        let index = columnsList.findIndex(
          (column) => column["headerName"] === variableData["variable_name"]
        );
        if (index !== -1) {
          let variableValue = getLeadVariableValue({
            columnMeta: columnsList[index],
            leadData: leadData,
          });
          tempVariableValueMapping[i + 1] = variableValue;
        } else {
          tempVariableValueMapping[i + 1] = null;
        }
      } else {
        let variableValue = getSystemVariableValue({
          variable: variableData["variable_name"],
        });
        tempVariableValueMapping[variableData["variable_name"]] = variableValue;
      }
    });
    return tempVariableValueMapping;
  };

  //used on input change to map with entered lead variables
  const getLeadVariableForPlaceholder = ({ leadVariableName, leadData }) => {
    const columnsList = [...rootStore.userStore.AllColumnsList];
    let index = columnsList.findIndex(
      (column) => column["headerName"] === leadVariableName
    );
    if (index !== -1) {
      let variableValue = getLeadVariableValue({
        columnMeta: columnsList[index],
        leadData: leadData,
      });
      return variableValue;
    } else {
      return null;
    }
  };

  const handlePhotoAdd = (event) => {
    setSelectedFile(event.target.files);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            top: "-16px",
            bottom: "auto",
            width: "100%",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ height: "50px" }}
            >
              <PreviewHeader>Send template</PreviewHeader>
            </Grid>
          </Toolbar>
        </AppBar>
        <Wrapper
          component={"form"}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <PreviewWrapper>
            <WhatsappSendTemplateMessagePreview
              data={props.data.components}
              variableDetails={details}
              headerVariableField={headerVariableField}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              handlePhotoAdd={handlePhotoAdd}
            />
          </PreviewWrapper>
          <FieldsWrapper>
            {variableFields.map((field) => (
              <InputWrapper key={field.id}>
                <Label>{field.name}</Label>
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    required
                    name={`body_text_${field.id}`}
                    onChange={(e) => {
                      const { value } = e.target;
                      let newDetails = { ...details };

                      const leadPattern = /\{\{([^{}]+)\}\}/g;
                      const foundMatches = [...value.matchAll(leadPattern)].map(
                        (match) => match[1]
                      );

                      if (foundMatches.length > 0) {
                        const leadVariable = getLeadVariableForPlaceholder({
                          leadVariableName: foundMatches[0], // only pick the first match
                          leadData: props.leadData,
                        });

                        newDetails[field.id + 1] = leadVariable;
                      } else {
                        newDetails[field.id + 1] = value;
                      }

                      setDetails(newDetails);
                    }}
                    value={details[field.id + 1] || ""}
                    style={{ height: "40px", width: "100%" }}
                    inputProps={{
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                  />
                </FormControl>
              </InputWrapper>
            ))}
          </FieldsWrapper>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ bottom: 0, width: "100%", height: 60 }}
          >
            <Toolbar
              style={{
                padding: "0px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Wrapper>
      </ModelContainer>
    </Modal>
  );
}

export default TemplateMessageSendPopupComponent;

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Typography } from "@mui/material";
import ItemActionComponent from "./ItemActionComponent";
import { dataRenderer, getLocalizedText } from "../../../Functions";
import { Visibility } from "@material-ui/icons";

const TableHeaderCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
`;
const TableDataCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
`;
const MenuText = styled(Typography)`
  font: normal normal 500 12px Open Sans;
`;
export default function AddCustomDealTableComponent({
  tableData,
  handleView,
  handleDelete,
  handleEdit,
  handleAddProduct,
  handleAddCustomItem,
  canEdit = true,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        zIndex: "0",
        width: "100%",
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              {getLocalizedText("name_of_item")}
            </TableHeaderCell>
            <TableHeaderCell align="right">
              {getLocalizedText("rate")}
            </TableHeaderCell>
            <TableHeaderCell align="right">
              {getLocalizedText("quantity")}
            </TableHeaderCell>
            <TableHeaderCell align="right">{`${getLocalizedText(
              "discount"
            )}(%)`}</TableHeaderCell>
            <TableHeaderCell align="right">
              {getLocalizedText("amount")}
            </TableHeaderCell>
            <TableHeaderCell align="right">
              {canEdit && (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                style={{ display: "flex", flexDirection: "column" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "fit-content",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleAddProduct();
                    setAnchorEl(null);
                  }}
                >
                  <MenuText>{getLocalizedText("add_product")}</MenuText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleAddCustomItem();
                    setAnchorEl(null);
                  }}
                >
                  <MenuText>{getLocalizedText("add_custom_item")}</MenuText>
                </MenuItem>
              </Menu>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  {row["product"]["name"]}
                </TableDataCell>
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                <TableDataCell align="right">
                  {dataRenderer(row["discount"])}
                </TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
                <TableDataCell align="right">
                  {canEdit ? (
                    <ItemActionComponent
                      handleDelete={() => handleDelete(row)}
                      handleEdit={() => handleEdit(row)}
                      handleView={() => handleView(row)}
                    />
                  ) : (
                    <IconButton
                      style={{ padding: "0px " }}
                      onClick={() => handleView(row)}
                    >
                      <Visibility
                        color="primary"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </IconButton>
                  )}
                </TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableHeaderCell colSpan={6} style={{ textAlign: "center" }}>
                {getLocalizedText("no_items")}
              </TableHeaderCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import "./rule.css";
import { Alert, MenuItem } from "@mui/material";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { observer } from "mobx-react-lite";
import analytics from "../../../Analytics";
import { getLocalizedText } from "../../../Functions";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  width: 398px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;

const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Label = styled.span`
  font: normal normal normal 10px Open Sans;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  column-gap: 10px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AssignmentRulesModal = (props) => {
  const classes = useStyles();
  const [assignmentRule, setAssignmentRule] = useState({});
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [list, setList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const updateList = (list) => {
    let newList = [];
    list.map((e) => {
      let newObj = {
        label: e.name,
        value: e.id,
      };
      newList.push(newObj);
    });
    return newList;
  };
  const setUpMemberListForMultiSelect = async () => {
    let modifiedList = updateList(props.memberList);
    setSelectedMembers(props.selectedMembers);
    setList(modifiedList);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000102,
      "assignment_rules_form_load",
      "imports_create_leads_form",
      "",
      {}
    );
    setAssignmentRule(props.selectedAssignmentRule);
    setUpMemberListForMultiSelect();
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    if (assignmentRule.slug === "project_member_equally") {
      if (selectedMembers.length === 0) {
        setShowAlert(true);
        setAlertMessage(getLocalizedText("please_select_atleast_one_member"));
      } else {
        props.setSelectedMembers(selectedMembers);
        props.setSelectedAssignmentRule(assignmentRule);
        props.setOpen(false);
      }
    } else {
      props.setSelectedAssignmentRule(assignmentRule);
      props.setOpen(false);
      props.setSelectedMembers([]);
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <IconButton
              onClick={() => {
                analytics.triggerEvent(
                  4625000105,
                  "close_icon_tap",
                  "assignment_rules_form",
                  "close_icon",
                  {}
                );
                props.setOpen(false);
              }}
              style={{
                zIndex: "9999",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>

            <ModelWrapper>
              <Header>
                {getLocalizedText("lead_assignment_rule", "project")}
              </Header>

              <SubHeader>
                {getLocalizedText("rules_for_assigning_leads_on_import","project")}
              </SubHeader>
              <Divider style={{ margin: "10px 0px" }} />
              <Wrapper onSubmit={handleSave}>
                <Column>
                  <InputWrapper>
                    <Label>{getLocalizedText("assignment_rule")}</Label>
                    <FormControl
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        name="assignment_rule"
                        onChange={(e) => {
                          setShowAlert(false);
                          setAlertMessage("");
                          setAssignmentRule(e.target.value);
                        }}
                        required
                        value={assignmentRule}
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        style={{
                          marginBottom: "20px",
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {props.assignmentRuleList.map((e, index) => {
                          return (
                            <MenuItem
                              style={{ fontSize: "14px" }}
                              id={index}
                              value={e}
                            >
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>

                  {assignmentRule.slug === "project_member_equally" && (
                    <InputWrapper>
                      <Label>{getLocalizedText("select_member")}</Label>
                      <MultiSelect
                        hasSelectAll={true}
                        options={list}
                        value={selectedMembers}
                        className="rule_multi_select"
                        onChange={(e) => {
                          setShowAlert(false);
                          setAlertMessage("");
                          setSelectedMembers(e);
                        }}
                        labelledBy="Select Member"
                      />
                    </InputWrapper>
                  )}
                </Column>
                {assignmentRule.desc !== "" && (
                  <InputWrapper
                    style={{
                      height: "100%",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Alert
                      style={{
                        padding: "0px 10px",
                        fontSize: "12px",
                        borderRadius: "0px 12px 12px 12px",
                      }}
                      severity="info"
                    >
                      {assignmentRule.desc}
                    </Alert>
                  </InputWrapper>
                )}
                {showAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      padding: "0px 20px",
                    }}
                    severity="warning"
                  >
                    {alertMessage}
                  </Alert>
                )}
                <ButtonWrapper>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000103,
                        "cancel_button_tap",
                        "assignment_rules_form",
                        "cancel_button",
                        {}
                      );
                      props.setOpen(false);
                    }}
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "130px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000104,
                        "save_button_tap",
                        "assignment_rules_form",
                        "save_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "130px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("save")}
                  </Button>
                </ButtonWrapper>
              </Wrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AssignmentRulesModal);

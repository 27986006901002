import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { CircularProgress, Typography } from "@mui/material";
import EntityDetailCardWidget from "./card_widgets/EntityDetailCardWidget";
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: ${(e) => `${e.rightShift}px`};
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const EntityDataPickerDialog = ({
  entityType,
  entityDataList,
  open,
  setOpen,
  handlePostContinue,
  scope = "create_customer",
}) => {
  const [selectedEntity, setSelectedEntity] = useState({});
  const [loading, setLoading] = useState(true);

  const handleContinue = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let index = entityDataList.findIndex(
      (entry) => entry["id"] === selectedEntity["id"]
    );
    if (index !== -1) {
      handlePostContinue(entityDataList[index]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDialogRightShifMargin = () => {
    if (scope === "create_lead") {
      const fiftyVwInPixels = window.innerWidth * 0.5;
      return (fiftyVwInPixels - dialogWidth) / 2;
    }
    return 40;
  };

  useEffect(() => {
    setTimeout(() => {
      if (entityDataList.length === 1) {
        handlePostContinue(entityDataList[0]);
      } else {
        setLoading(false);
      }
    }, 2000);
  }, []);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer rightShift={getDialogRightShifMargin()}>
          {!loading ? (
            <Box component={"form"} onSubmit={handleContinue}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>{`Select ${entityType}`}</Header>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Grid container spacing={2}>
                    {entityDataList.map((entityData, index) => {
                      return (
                        <Grid item xs={12}>
                          <EntityDetailCardWidget
                            entityData={entityData}
                            entityType={entityType}
                            key={index}
                            isSelected={
                              selectedEntity.hasOwnProperty("id") &&
                              selectedEntity["id"] === entityData["id"]
                            }
                            handleClick={() => {
                              setSelectedEntity(entityData);
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          color="default"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          disabled={Object.keys(selectedEntity).length === 0}
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                          }}
                        >
                          Continue
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          ) : (
            <Box
              style={{
                padding: "16px",
                width: dialogWidth,
                alignItems: "center",
                justifyContent: "center",

                display: "flex",
                height: "200px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                <Typography>Fetching data please wait...</Typography>
              </Box>
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default EntityDataPickerDialog;

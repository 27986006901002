import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Tab, Tabs, withStyles } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Typography, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import BasicInfoTab from "../../components/organization_page_components/OrganizationBasicInfoTab";
import OrganizationContactsTab from "../../components/organization_page_components/OrganizationContactsTab";
import OrganizationLeadsTab from "../../components/organization_page_components/OrganizationLeadsTab";
import { getOrganizationSummaryApi } from "../../Api";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);

const OrganizationsDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [summaryData, setSummaryData] = useState({});
  const [loading, setLoading] = useState(true);
  const tabsToGroups = rootStore.organizationStore.tabsToGroups;
  const groupsToColumns = rootStore.organizationStore.groupsToColumns;

  useEffect(() => {
    init();

    rootStore.organizationStore.updateDetailsCurrentTabID(0); //hardcoding tab id to 0 to set first tab
    rootStore.authStore.updateLeadDetailStatus();
    
    return () => {
      rootStore.organizationStore.updateOrganizationLeadsScope("open");
    };
  }, []);

  const init = async () => {
    await getOrganizationSummaryData();
    setLoading(false);
  };

  const getOrganizationSummaryData = async () => {
    let response = await getOrganizationSummaryApi({ organizationID: id });
    if (Object.keys(response).length > 0) {
      setSummaryData(response);
    }
  };

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header
                style={{ color: "#185DD2", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Organizations
              </Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>{id}</Header>
            </TopWrapper>
          </Row>
          <Tabs
            value={rootStore.organizationStore.detailsCurrentTabID}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              rootStore.organizationStore.updateDetailsCurrentTabID(newValue);
            }}
          >
            <CustomTab label={"Basic Information"} />
            <CustomTab label={"Contacts"} />
            <CustomTab label={"Leads"} />
          </Tabs>
          {(() => {
            if (rootStore.organizationStore.detailsCurrentTabID === 0) {
              return (
                <BasicInfoTab
                  id={id}
                  groups={
                    tabsToGroups[
                      rootStore.organizationStore.detailsCurrentTabID
                    ]
                  }
                  groupsToColumns={groupsToColumns}
                  summaryData={summaryData}
                />
              );
            } else if (rootStore.organizationStore.detailsCurrentTabID === 1) {
              return <OrganizationContactsTab id={id} />;
            } else if (rootStore.organizationStore.detailsCurrentTabID === 2) {
              return <OrganizationLeadsTab id={id} />;
            }
          })()}
        </Container>
      </ParentContainer>
      <Footer />
    </>
  ) : (
    <Box
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(OrganizationsDetailsPage);

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  dataRenderer,
  getLocalizedText,
} from "../../../Functions";
import { Box, IconButton, Typography } from "@mui/material";
import { Divider, Grid, Tooltip } from "@material-ui/core";
import { CorporateFare } from "@mui/icons-material";
import {
  Edit,
  Email,
  Facebook,
  LinkedIn,
  Phone,
  Twitter,
  Work,
} from "@material-ui/icons";
import { useEffect } from "react";
import rootStore from "../../../stores/RootStore";
import { uploadCustomerProfileImageApi } from "../../../Api";
import MessageWithLoadingStateCustomDialog from "../../alert_dialogue/MessageWithLoadingStateCustomDialog";
import ImageWithUploadComponent from "../../file_upload_component/ImageWithUploadComponent";
import PreviewImageDialog from "../../files_page_components/PreviewImageDialog";
import CustomerAndEngagementScoreWidget from "../../customer_page_components/CustomerAndEngagementScoreWidget";
import CustomerCurrentStatusInfoWidget from "./customer_current_status_info_widget/CustomerCurrentStatusInfoWidget";
import UpdateCustomerStageDialog from "./customer_current_status_info_widget/dialogs/UpdateCustomerStageDialog";
import UpdateCustomerPurchaseCategoryDialog from "./customer_current_status_info_widget/dialogs/UpdateCustomerPurchaseCategoryDialog";

const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const DetailLg = styled(Box)`
  font: normal normal 600 24px Open Sans;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 276px);`};
  display: flex;
  width: 100%;
  column-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled(Box)`
  padding: 16px;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin-bottom: 20px;
  color: #979797;
`;
const Message = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
`;
const LeftWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 3;
`;
const RightWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 7;
  gap: 20px;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;

  color: #979797;
`;
const primaryFields = [
  "salutation",
  "first_name",
  "last_name",
  "phone",
  "email",
  "organization",
  "title",
  "facebook_profile",
  "linkedin_url",
  "twitter_handle",
];
const secondaryInfoFieldOrder = ["city", "state", "country"];
const DetailsWidget = ({
  details,
  groups,
  groupsToColumns,
  setRefresh,
  setColumnIds,
  setOpenEditPanel,
  setShowOrganizationField,
}) => {
  const [groupsDetails, setGroupsDetails] = useState({});
  //******state variables related profile image upload *******************/
  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [openUpdateCustomerStageDialog, setOpenUpdateCustomerStageDialog] =
    useState(false);
  const [
    openUpdatePurchaseCategoryDialog,
    setOpenUpdatePurchaseCategoryDialog,
  ] = useState(false);

  //***********************END************************************ */

  const columnsList = rootStore.customerStore.columnsList;
  const getFullNameString = () => {
    let parts = [
      details.salutation,
      details.first_name,
      details.last_name,
    ].filter(Boolean);
    let fullName = parts.length ? parts.join(" ") : "-";
    return fullName;
  };

  const getColumnValue = (field, data_type) => {
    let data = details;

    if (data.hasOwnProperty(field)) {
      let value = data[field];
      if (value !== null && value !== "") {
        if (data_type === "date") {
          value = IsoToLocalDate(value);
        } else if (data_type === "datetime") {
          value = IsoToLocalTime(value);
        }
        if (field === "creator") {
          return value !== null ? value["name"] : "";
        }
        if (field === "owner") {
          return value !== null ? value["name"] : "";
        }
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    setupGroupDetails();
  }, [details]);

  //***********************sections division based on group name function */
  const setupGroupDetails = () => {
    let tempGroupsDetails = {};
    groups.forEach((group) => {
      let groupDetails = [];
      let columnIds = groupsToColumns[group];
      columnIds.forEach((id) => {
        let index = columnsList.findIndex((element) => element["id"] === id);
        if (index !== -1) {
          let item = {
            display_name: columnsList[index]["display_name"],
            value: getColumnValue(
              columnsList[index]["column_name"],
              columnsList[index]["data_type"]
            ),
            column_name: columnsList[index]["column_name"],
          };
          groupDetails.push(item);
        }
      });
      groupDetails.sort((a, b) => {
        let A = a.display_name.toLowerCase();
        let B = b.display_name.toLowerCase();
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      });
      tempGroupsDetails[group] = groupDetails;
    });
    console.log(
      "temp group details:::::",
      JSON.stringify(tempGroupsDetails),
      "groups:::",
      JSON.stringify(groups)
    );
    setGroupsDetails(tempGroupsDetails);
  };
  //*********************************END******************************** */

  const handleProfileImageUpload = async (event) => {
    let file = event.target.files[0];
    const uploadFile = async () => {
      setFetchingApi(true);
      setOpenSubmitResponseDialog(true);
      let formData = new FormData();
      formData.append("file", file);
      let response = await uploadCustomerProfileImageApi({
        customerID: details["id"],
        payload: formData,
      });
      if (!response.hasError()) {
        setIsSubmitFail(false);
        setRefresh(true);
        setSuccessMessage("Image uploaded successfully!");
        setFetchingApi(false);
      } else {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setFetchingApi(false);
      }
    };
    if (file !== null && file !== undefined) {
      uploadFile();
    }
  };

  const getProfileImageUrl = () => {
    if (
      details.hasOwnProperty("profile_pic") &&
      details["profile_pic"] !== null &&
      details["profile_pic"] !== ""
    ) {
      return details["profile_pic"];
    } else {
      return null;
    }
  };

  const handleSocialNetworkIconClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Container>
        <LeftWrapper>
          {groupsDetails.hasOwnProperty("Primary Information") && (
            <SectionWrapper
              style={{
                backgroundColor: "#003B7C",
                flexGrow: 0,
                padding: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "20px",
                  paddingBottom: "0px",
                }}
              >
                <DetailsHeader style={{ color: "white" }}>
                  {getLocalizedText("primary_information")}
                </DetailsHeader>

                {rootStore.customerStore.isGroupEditable(
                  "Primary Information"
                ) && (
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setColumnIds(groupsToColumns["Primary Information"]);
                      setShowOrganizationField(true);
                      setOpenEditPanel(true);
                    }}
                  >
                    <Edit style={{ color: "#DBE9FA" }} />
                  </IconButton>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  padding: "20px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                      <Grid container spacing={3} alignItems={"center"}>
                        <Grid item xs={3}>
                          <ImageWithUploadComponent
                            handleUpload={(event) =>
                              handleProfileImageUpload(event)
                            }
                            url={getProfileImageUrl()}
                            handleImageClick={() => {
                              setViewImageDialog(true);
                            }}
                            canShowUpload={true}
                          />
                        </Grid>

                        <Grid item xs={9}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "8px",
                              width: "100%",
                            }}
                          >
                            <Tooltip
                              title={getFullNameString()}
                              placement="bottom-start"
                            >
                              <DetailLg>{getFullNameString()}</DetailLg>
                            </Tooltip>
                            <IconTextWrapper style={{ width: "100%" }}>
                              <Tooltip title={"Organization"}>
                                <CorporateFare
                                  style={{
                                    color: "#DBE9FA",
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                title={
                                  details.hasOwnProperty("organization") &&
                                  details["organization"] !== null
                                    ? dataRenderer(
                                        details["organization"]["name"]
                                      )
                                    : "-"
                                }
                                placement="bottom-start"
                              >
                                <DetailLg
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {details.hasOwnProperty("organization") &&
                                  details["organization"] !== null
                                    ? dataRenderer(
                                        details["organization"]["name"]
                                      )
                                    : "-"}
                                </DetailLg>
                              </Tooltip>
                            </IconTextWrapper>
                            <IconTextWrapper style={{ width: "100%" }}>
                              <Tooltip title={"Job Title"}>
                                <Work
                                  style={{
                                    color: "#DBE9FA",
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                title={dataRenderer(details.title)}
                                placement="bottom-start"
                              >
                                <DetailLg
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {dataRenderer(details.title)}
                                </DetailLg>
                              </Tooltip>
                            </IconTextWrapper>
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider
                        orientation="horizontal"
                        style={{
                          backgroundColor: "#DBE9FA",
                          width: "100%",
                          marginTop: "24px",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <IconTextWrapper style={{ width: "100%" }}>
                      <Phone
                        style={{
                          color: "#DBE9FA",
                        }}
                      />
                      <Detail
                        style={{
                          color: "white",
                        }}
                      >
                        {dataRenderer(details.phone)}
                      </Detail>
                    </IconTextWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <IconTextWrapper style={{ width: "100%" }}>
                      <Email
                        style={{
                          color: "#DBE9FA",
                        }}
                      />
                      <Detail
                        style={{
                          color: "white",
                        }}
                      >
                        {dataRenderer(details.email)}
                      </Detail>
                    </IconTextWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-start",
                        gap: "8px",
                      }}
                    >
                      <IconButton
                        style={{ padding: "0px" }}
                        disabled={
                          details.facebook_profile === null ||
                          details.facebook_profile === ""
                        }
                        onClick={() => {
                          handleSocialNetworkIconClick(
                            details.facebook_profile
                          );
                        }}
                      >
                        <Facebook
                          style={{
                            color:
                              details.facebook_profile !== null &&
                              details.facebook_profile !== ""
                                ? "#DBE9FA"
                                : "#BDBDBD",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        style={{ padding: "0px" }}
                        disabled={
                          details.twitter_handle === null ||
                          details.twitter_handle === ""
                        }
                        onClick={() => {
                          handleSocialNetworkIconClick(details.twitter_handle);
                        }}
                      >
                        <Twitter
                          style={{
                            color:
                              details.twitter_handle !== null &&
                              details.twitter_handle !== ""
                                ? "#DBE9FA"
                                : "#BDBDBD",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        style={{ padding: "0px" }}
                        disabled={
                          details.linkedin_url === null ||
                          details.linkedin_url === ""
                        }
                        onClick={() => {
                          handleSocialNetworkIconClick(details.linkedin_url);
                        }}
                      >
                        <LinkedIn
                          style={{
                            color:
                              details.linkedin_url !== null &&
                              details.linkedin_url !== ""
                                ? "#DBE9FA"
                                : "#BDBDBD",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                  {groupsDetails["Primary Information"].length > 0 &&
                    groupsDetails["Primary Information"].map((item) => {
                      if (!primaryFields.includes(item["column_name"])) {
                        return (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <Label style={{ color: "#DBE9FA" }}>
                                {item["display_name"]}
                              </Label>
                              <Detail style={{ color: "white" }}>
                                {item["value"] !== "" ? item["value"] : "-"}
                              </Detail>
                            </InputWrapper>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Box>
              <CustomerAndEngagementScoreWidget
                customerScore={details["customer_score"]}
                engagementScore={details["engagement_score"]}
              />
            </SectionWrapper>
          )}

          {groupsDetails.hasOwnProperty("Secondary Information") &&
            (groupsDetails["Secondary Information"].length > 0 ? (
              <SectionWrapper>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <SectionHeader style={{ marginBottom: "0px" }}>
                    Secondary Information
                  </SectionHeader>

                  {rootStore.customerStore.isGroupEditable(
                    "Secondary Information"
                  ) && (
                    <IconButton
                      style={{ padding: "0px" }}
                      color="primary"
                      onClick={() => {
                        setColumnIds(groupsToColumns["Secondary Information"]);

                        setOpenEditPanel(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Box>
                <Grid container spacing={3}>
                  {groupsDetails["Secondary Information"]
                    .sort(
                      (a, b) =>
                        secondaryInfoFieldOrder.indexOf(a.column_name) -
                        secondaryInfoFieldOrder.indexOf(b.column_name)
                    )
                    .map((item) => {
                      return (
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>{item["display_name"]}</Label>
                            <Detail>
                              {item["value"] !== "" ? item["value"] : "-"}
                            </Detail>
                          </InputWrapper>
                        </Grid>
                      );
                    })}
                </Grid>
              </SectionWrapper>
            ) : (
              <SectionWrapper
                style={{
                  flexDirection: "column",
                  flexWrap: "nowrap",
                }}
              >
                <SectionHeader>Secondary Information</SectionHeader>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Message>No data.</Message>
                </Box>
              </SectionWrapper>
            ))}
        </LeftWrapper>
        <RightWrapper>
          <CustomerCurrentStatusInfoWidget
            details={details}
            setOpenUpdateCustomerStageDialog={setOpenUpdateCustomerStageDialog}
            setOpenUpdatePurchaseCategoryDialog={
              setOpenUpdatePurchaseCategoryDialog
            }
          />
          {Object.keys(groupsDetails).length > 0 &&
            Object.keys(groupsDetails).map((key) => {
              let details = groupsDetails[key];
              if (
                key !== "Primary Information" &&
                key !== "Secondary Information"
              )
                return details.length > 0 ? (
                  <SectionWrapper>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <SectionHeader style={{ marginBottom: "0px" }}>
                        {key}
                      </SectionHeader>

                      {rootStore.customerStore.isGroupEditable(key) && (
                        <IconButton
                          style={{ padding: "0px" }}
                          color="primary"
                          onClick={() => {
                            setColumnIds(groupsToColumns[key]);
                            setOpenEditPanel(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      )}
                    </Box>
                    <Grid container spacing={3}>
                      {details.map((data, index) => {
                        return (
                          <Grid item xs={12} md={4} lg={3} xl={2} key={index}>
                            <InputWrapper>
                              <Label>{data["display_name"]}</Label>

                              <Detail>
                                {data["value"] !== "" ? data["value"] : "-"}
                              </Detail>
                            </InputWrapper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </SectionWrapper>
                ) : (
                  <SectionWrapper
                    style={{
                      flexDirection: "column",
                      flexWrap: "nowrap",
                    }}
                  >
                    <SectionHeader>{key}</SectionHeader>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Message>No data.</Message>
                    </Box>
                  </SectionWrapper>
                );
            })}
        </RightWrapper>
      </Container>
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
      {openUpdateCustomerStageDialog && (
        <UpdateCustomerStageDialog
          details={details}
          open={openUpdateCustomerStageDialog}
          setOpen={setOpenUpdateCustomerStageDialog}
          setRefresh={setRefresh}
        />
      )}
      {openUpdatePurchaseCategoryDialog && (
        <UpdateCustomerPurchaseCategoryDialog
          details={details}
          open={openUpdatePurchaseCategoryDialog}
          setOpen={setOpenUpdatePurchaseCategoryDialog}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default DetailsWidget;

import { Drawer } from "@material-ui/core";
import { LocationOn, Map } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAdminUserDayCheckInsApi } from "../../../Api";
import { CheckInColumns } from "../../../Db";
import ViewUserCheckinModal from "../../check_in_modal/ViewUserCheckinModal";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, styled, Tooltip, Typography } from "@mui/material";
import SwitchProjectToRedirectDialog from "../../alert_dialogue/SwitchProjectToRedirectDialog";
import { dataRenderer, getLocalizedText } from "../../../Functions";
import rootStore from "../../../stores/RootStore";
const Container = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 14px;
  top: 14px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const AdminUserCheckinTable = (props) => {
  const navigate = useNavigate();
  const [row, setRow] = useState();
  const [currData, setCurrData] = useState(null);
  const [viewLog, setViewLog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wayPoint, setWayPoint] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [switchingProject, setSwitchingProject] = useState(false);
  const userID = localStorage.getItem("uid");
  const latLongGenerator = (value) => {
    let a = value;
    let array = a.split(",");
    let b = array[0];
    let c = array[1];
    return { start: b, end: c };
  };
  const wayPointGenerator = (val) => {
    let value = [...val];
    if (value.length > 2) {
      let tempList = value;
      let result = "https://www.google.com/maps/dir/?api=1";
      let start = tempList.pop();
      start = latLongGenerator(start.latlong);
      let dest = tempList.shift();
      dest = latLongGenerator(dest.latlong);
      tempList = tempList.reverse();
      result += `&origin=${start.start},${start.end}&destination=${dest.start},${dest.end}&waypoints=`;
      if (tempList.length > 0) {
        let str = "";
        tempList.map((item) => {
          let src = latLongGenerator(item.latlong);
          str += `${src.start},${src.end}|`;
        });
        result += str;
      }
      result = result.slice(0, -1);
      setWayPoint(result);
    } else if (value.length === 2) {
      let tempList = value;
      let result = "https://www.google.com/maps/dir/?api=1";
      let start = tempList.pop();
      start = latLongGenerator(start.latlong);
      let dest = tempList.shift();
      dest = latLongGenerator(dest.latlong);
      result += `&origin=${start.start},${start.end}&destination=${dest.start},${dest.end}`;
      setWayPoint(result);
    } else if (value.length === 1) {
      let tempList = value;
      let start = tempList.pop();
      start = latLongGenerator(start.latlong);
      let result = `https://www.google.com/maps/?q=${start.start},${start.end}`;
      setWayPoint(result);
    }
  };
  useEffect(() => {
    setWayPoint(null);
    setRow([]);
    setLoading(true);

    const getUserCheckinHistory = async () => {
      try {
        let response = await getAdminUserDayCheckInsApi(
          props.checkinDate,
          props.id
        );
        setRow(response.data);
        wayPointGenerator(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getUserCheckinHistory();
  }, [props.checkinDate]);
  const handleWayPoint = () => {
    window.open(wayPoint, "_blank");
  };

  //------------------lead id and action column related functions  ---------

  const handleLocation = (value) => {
    if (value !== null) {
      let a = value;
      let array = a.split(",");
      let b = array[0];
      let c = array[1];
      window.open(`https://www.google.com/maps/?q=${b},${c}`, "_blank");
    }
  };

  const actionColumn = [
    {
      field: "flid",
      headerName: <Text>{getLocalizedText("associated_lead")}</Text>,
      sortable: false,
      width: 130,
      renderCell: (params) => {
        let value =
          params.row.lead !== null ? dataRenderer(params.row.lead.flid) : "-";
        return (
          <Tooltip title={value}>
            <TextHighlight
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (
                  params.row.lead !== null &&
                  params.row.lead.hasOwnProperty("flid")
                ) {
                  handleLeadIDTap(params.row);
                }
              }}
            >
              {value}
            </TextHighlight>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: <Text>{getLocalizedText("actions")}</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={params.row.latlong === null}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleLocation(params.row.latlong);
            }}
            style={{ padding: "0px" }}
          >
            <LocationOn color="error" />
          </IconButton>
        );
      },
    },
  ];

  const handleLeadIDTap = (rowData) => {
    let currentProjectID = rootStore.authStore.projectId;
    if (rowData["project_id"] === currentProjectID) {
      navigate(`/users/${userID}/leads/${rowData["lead"]["id"]}`, {
        state: {
          lead_status: null,
          scope: null,
        },
      });
    } else {
      setSelectedRowData(rowData);
      setOpenAlertDialog(true);
    }
  };
  const handleSwitchToNewProject = async () => {
    setSwitchingProject(true);
    await rootStore.authStore.updateOnProjectChange(
      selectedRowData["project_id"]
    );
    setSwitchingProject(false);
    navigate(`/users/${userID}/leads/${selectedRowData["lead"]["id"]}`, {
      state: {
        lead_status: null,
        scope: null,
      },
    });
    setOpenAlertDialog(false);
  };

  //--------------------END------------------------

  return (
    <>
      <Container>
        <AddBtnWrapper>
          <Map
            color={wayPoint !== null ? "primary" : "disabled"}
            onClick={wayPoint !== null ? handleWayPoint : null}
            style={{
              width: "28px",
              height: "28px",
            }}
          />
        </AddBtnWrapper>
        {row && (
          <DataGrid
            rows={row}
            columns={CheckInColumns.concat(actionColumn)}
            checkboxSelection={false}
            style={{ cursor: "pointer" }}
            hideFooter={true}
            loading={loading}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            onRowClick={(params) => {
              setCurrData(params.row);
              setViewLog(true);
            }}
          />
        )}
      </Container>
      <Drawer
        anchor={"right"}
        open={viewLog}
        onClose={() => {
          setViewLog(false);
        }}
      >
        {<ViewUserCheckinModal data={currData} setData={setCurrData} />}
      </Drawer>
      {openAlertDialog && (
        <SwitchProjectToRedirectDialog
          isLoading={switchingProject}
          open={openAlertDialog}
          setOpen={setOpenAlertDialog}
          handleCancel={() => {
            setSelectedRowData({});
            setOpenAlertDialog(false);
          }}
          handleContinue={() => {
            handleSwitchToNewProject();
          }}
        />
      )}
    </>
  );
};

export default AdminUserCheckinTable;

import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Card, CardActionArea, Radio, Typography } from "@mui/material";

const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  row-gap: 16px;
`;
const DetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: 100%;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const EntityDetailCardWidget = ({
  entityData,
  entityType,
  isSelected,
  handleClick,
}) => {
  const renderDataBasedOnEntity = () => {
    if (entityType === "contact") {
      let phoneNumber =
        entityData.hasOwnProperty("phone") && entityData["phone"] !== null
          ? entityData["phone"]
          : "";
      let email =
        entityData.hasOwnProperty("email") && entityData["email"] !== null
          ? entityData["email"]
          : "";
      return (
        <CardActionArea
          sx={{
            display: "flex",
            width: "100%",
            padding: "8px",
            rowGap: "4px",
            alignItems: "center",
          }}
          onClick={handleClick}
        >
          <Radio checked={isSelected} color="primary" />
          <DetailsWrapper>
            <Text>{phoneNumber !== "" ? phoneNumber : "-"}</Text>

            <Text>{email !== "" ? email : "-"}</Text>
          </DetailsWrapper>
        </CardActionArea>
      );
    } else {
      let name =
        entityData.hasOwnProperty("name") && entityData["name"] !== null
          ? entityData["name"]
          : "";
      return (
        <CardActionArea
          sx={{
            display: "flex",
            padding: "8px",
            rowGap: "4px",
            alignItems: "center",
          }}
          onClick={handleClick}
        >
          <Radio checked={isSelected} color="primary" />
          <DetailsWrapper>
            <Text>{name !== "" ? name : "-"}</Text>
          </DetailsWrapper>
        </CardActionArea>
      );
    }
  };
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        border: "1px solid #f9f9fc",
        bgcolor: "#f9f9fc",
      }}
    >
      <Container>{renderDataBasedOnEntity()}</Container>
    </Card>
  );
};

export default EntityDetailCardWidget;

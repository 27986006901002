import { Box, LinearProgress, lighten, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import moment from "moment";

const Container = styled(Box)`
  width: 100%;
  height: fit-content;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
`;
const IndicatorTextTop = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const IndicatorText = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const IndicatorWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
const DateText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;

const AttendanceInfoDropdownComponent = ({ value, details, totalMinutes }) => {
  const formatTimeFromMinutes = (totalMinutes) => {
    const duration = moment.duration(totalMinutes, "minutes");
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss") + " hrs";
  };

  function getFormattedDate() {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const today = new Date();

    return today.toLocaleDateString("en-US", options);
  }

  const CustomProgressBar = styled(LinearProgress)(({ value }) => {
    return {
      width: "100%",
      height: 6,
      borderRadius: 5,
      backgroundColor: "#efeff4"
    };
  });
  const formatTime = (time) => {
    return time ? moment(time).format("HH:mm A") : "00:00";
  };

  return (
    <Container>
      <IndicatorWrapper
        sx={{ justifyContent: "space-between", marginBottom: "5px" }}
      >
        <DateText>{getFormattedDate()}</DateText>
        <IndicatorTextTop>
          {formatTimeFromMinutes(totalMinutes)}
        </IndicatorTextTop>
      </IndicatorWrapper>
      <CustomProgressBar variant="determinate" value={value} color="success" />
      <IndicatorWrapper>
        <IndicatorText
          sx={{
            color:
              details["min_time"] !== null
                ? "#465B63"
                : "rgba(70, 91, 99, 0.5)",
          }}
        >
          {" "}
          {details["min_time"] !== null
            ? formatTime(details["min_time"])
            : "0:00"}
        </IndicatorText>
        <IndicatorText
          sx={{
            color: rootStore.userStore.isLatestAttendanceEmpty()
              ? details["max_time"] !== null
                ? "#465B63"
                : "rgba(70, 91, 99, 0.5)"
              : "rgba(70, 91, 99, 0.5)",
          }}
        >
          {" "}
          {rootStore.userStore.isLatestAttendanceEmpty()
            ? formatTime(details["max_time"])
            : "0:00"}
        </IndicatorText>
      </IndicatorWrapper>
    </Container>
  );
};

export default observer(AttendanceInfoDropdownComponent);

import React, { useEffect, useState } from "react";
import { Button, Modal } from "@material-ui/core";

import { Box, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Close } from "@material-ui/icons";
import { Transfer } from "antd";
import "antd/dist/antd.css";
import "../../styles/multiselect.css";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
import rootStore from "../../stores/RootStore";
import { styled as muiStyled } from "@mui/material/styles";
import { submitLeadApi } from "../../Api";
import { myTheme } from "../../themeUtils";

const ModelContainer = muiStyled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 600px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 700px) {
    height: 80vh;
    overflow-y: scroll;
  }
`;
const ModelWrapper = muiStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
}));

const ModalHeader = muiStyled(Typography)(({ theme }) => ({
  font: "normal normal 700 24px Open Sans",
  marginBottom: "20px",
}));
const MessageWrapper = muiStyled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = muiStyled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const ButtonWrapper = muiStyled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-end;
`;

const CloneLeadModal = (props) => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [newLeadData, setNewLeadData] = useState({});

  let uid = localStorage.getItem("uid");

  const handleClose = () => {
    props.setOpen(false);
  };

  const goToLead = () => {
    props.setOpen(false);
    navigate(`/users/${uid}/leads/${newLeadData.id}`, {
      state: {
        lead_status: null,
        scope: "clone_lead",
      },
    });
  };

  const getSortedTargetKeys = (targetKeys) => {
    const sortedList = [...targetKeys].sort((a, b) => {
      const indexOfA = columnsList.findIndex((column) => column["field"] === a);
      const indexOfB = columnsList.findIndex((column) => column["field"] === b);
      return columnsList[indexOfA]["headerName"]
        .toLowerCase()
        .localeCompare(columnsList[indexOfB]["headerName"].toLowerCase());
    });
    return sortedList;
  };

  useEffect(() => {
    let newData = [];
    let keys = [];
    columnsList.forEach((e) => {
      if (e["derived"] === false && rootStore.userStore.isColumnVisible(e)) {
        let disabled = false;
        let required = false;
        if (e["required"] && e["enable_for_create"]) {
          disabled = true;
          required = true;
        } else if (e["contact_column"] !== null && e["contact_column"] !== "") {
          disabled = true;
        }
        let newObj = {
          key: e.field,
          title: e.headerName,
          disabled: disabled,
          required: required,
        };
        newData.push(newObj);
        keys.push(e.field);
      }
    });

    setTargetKeys(keys);
    setData(newData);
  }, []);

  const handleKeys = (keys) => {
    setShowAlert(false);
    setErrorMessage("");
    setTargetKeys(Array.from(new Set(keys)));
  };

  const handleSubmit = async () => {
    if (targetKeys.length > 0) {
      setShowAlert(false);
      setErrorMessage("");

      let cloneLeadData = {};
      let customFields = {};

      let leadData = props.leadData;
      let customData = props.leadData.hasOwnProperty("custom_params")
        ? JSON.parse(props.leadData["custom_params"])
        : {};

      targetKeys.forEach((item) => {
        let field = item;
        let index = columnsList.findIndex(
          (column) => column["field"] === field
        );
        if (index !== -1) {
          let columnData = columnsList[index];
          if (columnData["is_custom_param"]) {
            let newKey = field.replace("custom_params_", "");
            customFields[newKey] = customData[newKey];
          } else {
            cloneLeadData[field] = leadData[field];
          }
        }
      });
      let payload = {
        contact_id: leadData["contact_id"],
        lead_data: {
          ...cloneLeadData,
          custom_params: { ...customFields },
        },
      };
      let response = await submitLeadApi({
        payload: payload,
      });

      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        showAlert(true);
      } else {
        setNewLeadData(response.data);
        setIsSubmitSuccess(true);
      }
    } else {
      setShowAlert(true);
      setErrorMessage(
        getLocalizedText("please_have_atleast_one_column_in_use")
      );
    }
  };

  return (
    <>
      {
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {!isSubmitSuccess ? (
            <ModelContainer>
              <Box>
                <Close
                  onClick={handleClose}
                  style={{
                    height: "18px",
                    width: "18px",
                    position: "absolute",
                    top: "20px",
                    right: "30px",
                    cursor: "pointer",
                  }}
                />
                <ModelWrapper>
                  <ModalHeader>
                    {getLocalizedText("select_fields_to_clone")}
                  </ModalHeader>

                  {data.length > 0 && (
                    <Transfer
                      dataSource={data.sort((a, b) =>
                        a["title"]
                          .toLowerCase()
                          .localeCompare(b["title"].toLowerCase())
                      )}
                      listStyle={{
                        width: 250,
                        height: 300,
                      }}
                      titles={[
                        getLocalizedText("current_lead_fields", "project"),
                        getLocalizedText("clone_lead_fields", "project"),
                      ]}
                      targetKeys={getSortedTargetKeys(targetKeys)}
                      onChange={handleKeys}
                      render={(item) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {item.title}
                            {item.required && (
                              <Typography color="error">*</Typography>
                            )}
                          </Box>
                        );
                      }}
                    />
                  )}

                  {showAlert && (
                    <Box
                      sx={{
                        marginTop: "16px",
                      }}
                    >
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 20px",
                        }}
                        severity="warning"
                      >
                        {errorMessage}
                      </Alert>
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant="contained"
                      style={{
                        marginTop: "20px",
                        backgroundColor: "blue",
                        color: "white",
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      {getLocalizedText("apply")}
                    </Button>
                  </Box>
                </ModelWrapper>
              </Box>
            </ModelContainer>
          ) : (
            <ModelContainer
              style={{
                width: "400px",
              }}
            >
              <Box
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MessageWrapper>
                  <Message>
                    {getLocalizedText("lead_created_successfully", "project")}
                  </Message>
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="outlined"
                      color="default"
                      style={{
                        backgroundColor: "#EFEFF4",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      onClick={goToLead}
                      variant="contained"
                      id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("go_to_lead", "project")}
                    </Button>
                  </ButtonWrapper>
                </MessageWrapper>
              </Box>
            </ModelContainer>
          )}
        </Modal>
      }
    </>
  );
};

export default CloneLeadModal;

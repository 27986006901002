import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select as MuiSelect,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  addUserAttendanceSettingApi,
  editUserAttendanceSettingApi,
  getBranchLocationsListApi,
} from "../../Api";
import Select from "react-select";
import { attendanceFromDeviceList } from "../../Db";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};

const CreateAttendanceSettingComponent = ({
  setOpen,
  setRefresh,
  editableData,
  isEdit,
  setIsEdit,
  scope,
  selectedUsers,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [locationList, setLocationList] = useState([]);

  const [details, setDetails] = useState({
    capture_face: false,
    mandatory_attendance_in: false,
    attendance_from_device: null,
  });
  const [attendanceIn, setAttendanceIn] = useState(null);
  const [attendanceOut, setAttendanceOut] = useState(null);

  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const addUserAttendanceSetting = async (payload) => {
    setIsSubmitting(true);
    let response = await addUserAttendanceSettingApi({
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };

  const addMultiUserAttendanceSetting = async (payload) => {
    setIsSubmitting(true);
    for (let i = 0; i < selectedUsers.length; i++) {
      let tempUserData = selectedUsers[i];
      let tempPayload = { ...payload };
      tempPayload["user_id"] = tempUserData["id"];

      if (tempUserData["attendance_settings"] !== null) {
        let response = await editUserAttendanceSettingApi({
          payload: tempPayload,
          attendanceSettingID: tempUserData["attendance_settings"]["id"],
        });
        if (response.hasError()) {
          setIsSubmitSuccess(false);
          setErrorMessage(response.errorMessage);
          setIsSubmitFail(true);
          return;
        }
      } else {
        let response = await addUserAttendanceSettingApi({
          payload: tempPayload,
        });
        if (response.hasError()) {
          setIsSubmitSuccess(false);
          setErrorMessage(response.errorMessage);
          setIsSubmitFail(true);
          return;
        }
      }
    }
    setRefresh(true);
    setIsSubmitting(false);
    setIsSubmitSuccess(true);
  };

  const editUserAttendanceSetting = async (payload) => {
    setIsSubmitting(true);
    let response = await editUserAttendanceSettingApi({
      payload: payload,
      attendanceSettingID: editableData["attendance_settings"]["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...details,
      user_id: editableData["id"],
    };
    if (scope !== "global") {
      payload["user_id"] = editableData["id"];
    }
    payload["attendance_in_location"] =
      attendanceIn !== null ? attendanceIn["value"] : null;
    payload["attendance_out_location"] =
      attendanceOut !== null ? attendanceOut["value"] : null;

    if (scope === "global") {
      addMultiUserAttendanceSetting(payload);
    } else {
      if (isEdit === true) {
        editUserAttendanceSetting(payload);
      } else {
        addUserAttendanceSetting(payload);
      }
    }
  };

  const getLocations = async () => {
    let response = await getBranchLocationsListApi();
    setLocationList(response);
  };
  const init = async () => {
    await getLocations();
    if (isEdit === true) {
      let tempObj = {
        capture_face: editableData["attendance_settings"]["capture_face"],
        mandatory_attendance_in:
          editableData["attendance_settings"]["mandatory_attendance_in"],
        attendance_from_device:
          editableData["attendance_settings"]["attendance_from_device"],
        attendance_in_location:
          editableData["attendance_settings"]["attendance_in_location"],
        attendance_out_location:
          editableData["attendance_settings"]["attendance_out_location"],
      };
      setDetails(tempObj);
      let tempAttendanceIn =
        editableData["attendance_settings"]["attendance_in_location"] !== null
          ? {
              label:
                editableData["attendance_settings"]["attendance_in_location"][
                  "location_name"
                ],
              value:
                editableData["attendance_settings"]["attendance_in_location"][
                  "id"
                ],
            }
          : null;
      let tempAttendanceOut =
        editableData["attendance_settings"]["attendance_out_location"] !== null
          ? {
              label:
                editableData["attendance_settings"]["attendance_out_location"][
                  "location_name"
                ],
              value:
                editableData["attendance_settings"]["attendance_out_location"][
                  "id"
                ],
            }
          : null;
      setAttendanceIn(tempAttendanceIn);
      setAttendanceOut(tempAttendanceOut);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const getLocationOptions = () => {
    let tempList = [...locationList];
    return tempList.map((entry) => ({
      label: entry["location_name"],
      value: entry["id"],
    }));
  };

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          {isEdit === true ? (
            <ModelHeader>Edit Attendance Setting</ModelHeader>
          ) : (
            <ModelHeader>Add Attendance Setting</ModelHeader>
          )}
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Attendance from device*</Label>
                  <FormControl sx={{ width: "100%" }}>
                    <MuiSelect
                      displayEmpty
                      required
                      onChange={handleChange}
                      name="attendance_from_device"
                      disableUnderline
                      variant="outlined"
                      value={details["attendance_from_device"]}
                      style={{
                        width: "100%",
                        height: "36px",
                        fontSize: "12px",
                        borderRadius: "4px",
                        borderColor: "#C4C4C4",
                      }}
                    >
                      {attendanceFromDeviceList.map((item, i) => {
                        return (
                          <MenuItem
                            value={item}
                            key={i}
                            style={{ fontSize: "12px" }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  </FormControl>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Attendance in location</Label>
                  <Select
                    options={getLocationOptions()}
                    value={attendanceIn}
                    onChange={(selectedOption) => {
                      setAttendanceIn(selectedOption);
                    }}
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    isClearable
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Attendance out location</Label>
                  <Select
                    options={getLocationOptions()}
                    value={attendanceOut}
                    onChange={(selectedOption) => {
                      setAttendanceOut(selectedOption);
                    }}
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    isClearable
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <FormControlLabel
                    checked={details["mandatory_attendance_in"]}
                    control={
                      <Checkbox
                        size="medium"
                        onChange={(event) => {
                          setDetails({
                            ...details,
                            mandatory_attendance_in: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      <Detail style={{ fontWeight: "500" }}>
                        Mandatory Attendance In
                      </Detail>
                    }
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper sx={{ flexDirection: "row", alignItems: "center" }}>
                  <FormControlLabel
                    checked={details["capture_face"]}
                    control={
                      <Checkbox
                        size="medium"
                        onChange={(event) => {
                          setDetails({
                            ...details,
                            capture_face: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      <Detail style={{ fontWeight: "500" }}>
                        Capture Face
                      </Detail>
                    }
                  />
                  <Tooltip title="Applicable only in Mobile">
                    <InfoIcon fontSize="small" color="primary" />
                  </Tooltip>
                </InputWrapper>
              </Grid>
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          {isEdit === true ? (
            <Message>Attendance setting updated successfully!</Message>
          ) : (
            <Message>Attendance setting added successfully!</Message>
          )}
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CreateAttendanceSettingComponent;

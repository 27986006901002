import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import {
  consoleLogger,
  getLocalizedText,
  isCoordWithinRange,
  IsoToLocalTime,
} from "../../Functions";
import rootStore from "../../stores/RootStore";
import LocationViewer from "./LocationViewer";
import { markOutUserAttendanceApi } from "../../Api";
import moment from "moment";
import PermHelpImage from "../../assets/images/location_perm_help_image.png";

const dialogWidth = 640;
const center = [28.6139, 77.209];

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: `${dialogWidth}px`,
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "100%",
  padding: "0 16px 16px 16px",
  borderRadius: "8px",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  border: "1px solid #c5d7f1",
  boxShadow: "0px 3px 20px #185dd21f",
  borderRadius: "8px",
  boxSizing: "border-box",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 10px Open Sans",
  color: "#4d4e4f",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const MessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  marginBottom: "16px",
  flexDirection: "column",
  rowGap: "20px",
}));

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px Open Sans",
  color: "green",
  margin: "0px",
  textAlign: "center",
}));

const Text1 = styled(Typography)(({ theme }) => ({
  margin: "0px",
  font: "normal normal 600 14px Open Sans",
  color: "#4d4e4f",
}));

const MapMessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  padding: theme.spacing(1),
  flexDirection: "column",
  height: "480px",
  width: dialogWidth,
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans, sans-serif",
  fontSize: "24px",
  fontWeight: "normal",
  textAlign: "center",
  color: "#4d4e4f",
  margin: 0,
  padding: "5px",
}));
const Image = styled("img")(({ theme }) => ({
  width: "auto",
  height: "320px",
}));

const AttendanceMarkOutComponent = ({ open, setOpen }) => {
  const attendanceConfig = { ...rootStore.userStore.userAttendanceSetting };
  const [position, setPosition] = useState(null);
  const [showError, setShowError] = useState(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [isLocationValid, setIsLocationValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [locationErrorCode, setLocationErrorCode] = useState(0);

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    fetchUserCurrentLatLong(); //get user location and validate in bg
  };

  const handleRetryLocation = () => {
    window.location.reload();
  };

  const handleMarkOut = async () => {
    if (isLocationValid) {
      const latLong = [position.lat, position.lng].join(",");

      let totalInTime =
        rootStore.userStore.latestAttendanceDetails.attendance_in_time !== null
          ? moment().diff(
              IsoToLocalTime(
                rootStore.userStore.latestAttendanceDetails.attendance_in_time
              ),
              "minutes"
            )
          : null;
      const payload = {
        latlong: latLong,
        within_out_location: isLocationValid,
        attendance_id: rootStore.userStore.getLatestAttendanceDetails()["id"],
        total_in_time: totalInTime,
      };
      consoleLogger("payload:", payload);
      const response = await markOutUserAttendanceApi({ payload: payload });

      if (response.hasError()) {
        setIsSubmitSuccess(false);
        setErrorMessage(response.errorMessage);
        setShowError(true);
      } else {
        rootStore.userStore.fetchUserAttendanceList();
        setIsSubmitSuccess(true);
      }
    } else {
      setTimeout(() => {
        setIsSubmitSuccess(true);
      }, "1000");
    }
  };

  const getMessageForErrorCode = (errorCode) => {
    if (errorCode === 1) {
      //location permission denied by user
      return getLocalizedText(
        "location_permission_is_blocked_please_change_your_location_settings_in_browser_to_continue"
      );
    } else if (errorCode === 2) {
      //location not available (geolocation failed because at least one internal source of position returned an internal error.)
      return "Some error occurred!\nError code:1021";
    }
    return getLocalizedText("some_error_occurred");
  };

  const fetchUserCurrentLatLong = async () => {
    //getting browser location
    const location = window.navigator && window.navigator.geolocation;
    let currentPosition = {
      lat: "",
      lng: "",
    };
    //getCurrentPosition will return error callback if permission is denied or location not available
    try {
      const position = await new Promise((resolve, reject) => {
        location.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
        });
      });
      const { latitude, longitude } = position.coords;
      currentPosition["lat"] = latitude;
      currentPosition["lng"] = longitude;

      consoleLogger(
        "latitude, longitude, isValid: ",
        latitude + ", " + longitude
      );
      setPosition({ lat: latitude, lng: longitude });
    } catch (error) {
      console.log("Error getting location:", error);
      setLocationErrorCode(error.code);
      setLocationError(true);
      setMapLoading(false);
      return; //not proceeding if location is not there
    }

    let isValid = true;
    if (
      attendanceConfig.hasOwnProperty("attendance_out_location") &&
      attendanceConfig["attendance_out_location"] !== null &&
      attendanceConfig["attendance_out_location"] !== ""
    ) {
      if (
        attendanceConfig["attendance_out_location"].hasOwnProperty("latlong") &&
        attendanceConfig["attendance_out_location"]["latlong"] &&
        attendanceConfig["attendance_out_location"]["latlong"] !== ""
      ) {
        //validate and set isValid
        isValid = isCurrentLatLongValid({
          position: currentPosition,
          locationLatLong:
            attendanceConfig["attendance_out_location"]["latlong"],
        });
        setIsLocationValid(isValid);
      }
    }
    setMapLoading(false);
  };

  const isCurrentLatLongValid = ({ position, locationLatLong }) => {
    let targetCoordinates = {}; //branch location lat long to compare from attendance config api

    //if target latlong is null or empty, not doing the check
    let array = locationLatLong.split(",");
    targetCoordinates = {
      latitude: parseFloat(array[0]),
      longitude: parseFloat(array[1]),
    };

    //if current coord is within 100 meter radius of target coordinates
    const isValid = isCoordWithinRange(
      { latitude: position.lat, longitude: position.lng }, // current user latlong
      targetCoordinates,
      100
    ); // 100 meters radius buffer
    return isValid;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      {!isSubmitSuccess ? (
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("punch_out")}</Header>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            {!mapLoading ? (
              locationError ? (
                <>
                  <MapMessageWrapper>
                    <ErrorMessage>
                      {getMessageForErrorCode(locationErrorCode)}
                    </ErrorMessage>
                    {/* help image for enabling browser location permission */}
                    {locationErrorCode === 1 && (
                      <Image src={PermHelpImage} alt="location_permission" />
                    )}
                  </MapMessageWrapper>
                  <AppBar
                    position="static"
                    color="inherit"
                    elevation={0}
                    sx={{
                      top: "auto",
                      bottom: 0,
                      width: 500,
                      bgcolor: "#f9f9fc",
                    }}
                  >
                    <Toolbar
                      style={{
                        padding: "16px",
                        width: dialogWidth,
                        position: "relative",
                      }}
                    >
                      {showError && (
                        <ErrorAlert
                          onClose={(e) => {
                            e.stopPropagation();
                            setShowError(false);
                          }}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          {errorMessage}
                        </ErrorAlert>
                      )}
                      <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <Box sx={{ display: "flex", gap: "8px" }}>
                            <Button
                              onClick={handleClose}
                              variant="contained"
                              color="default"
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                            >
                              {getLocalizedText("cancel")}
                            </Button>
                            <Button
                              type="button"
                              onClick={handleRetryLocation}
                              variant="contained"
                              color="primary"
                              style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                width: "fit-content",
                                textTransform: "none",
                              }}
                            >
                              {getLocalizedText("refresh_page")}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Toolbar>
                  </AppBar>
                </>
              ) : (
                <>
                  <Container>
                    <MapContainer
                      center={center}
                      zoom={13}
                      style={{
                        height: "400px",
                        width: "100%",
                      }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <LocationViewer
                        setPosition={setPosition}
                        position={position}
                      />
                    </MapContainer>
                  </Container>
                  <AppBar
                    position="static"
                    color="inherit"
                    elevation={0}
                    sx={{
                      top: "auto",
                      bottom: 0,
                      width: 500,
                      bgcolor: "#f9f9fc",
                    }}
                  >
                    <Toolbar
                      style={{
                        padding: "16px",
                        width: dialogWidth,
                        position: "relative",
                      }}
                    >
                      {showError && (
                        <ErrorAlert
                          onClose={(e) => {
                            e.stopPropagation();
                            setShowError(false);
                          }}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          {errorMessage}
                        </ErrorAlert>
                      )}
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Text1>
                            {getLocalizedText(
                              "punching_out_from_this_location_tap_next_to_punch_out"
                            )}
                          </Text1>
                        </Grid>
                        <Grid item>
                          <Box sx={{ display: "flex", gap: "8px" }}>
                            <Button
                              onClick={handleClose}
                              variant="contained"
                              color="default"
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                            >
                              {getLocalizedText("cancel")}
                            </Button>
                            <Button
                              type="button"
                              onClick={handleMarkOut}
                              variant="contained"
                              color="primary"
                              style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                width: "fit-content",
                                textTransform: "none",
                              }}
                            >
                              {getLocalizedText("next")}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Toolbar>
                  </AppBar>
                </>
              )
            ) : (
              <Box
                sx={{
                  width: dialogWidth,
                  height: "480px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </ModelContainer>
      ) : (
        <ModelContainer
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
            <Message>
              {isLocationValid
                ? getLocalizedText("attendance_punched_out_successfully")
                : getLocalizedText(
                    "attendance_punched_out_but_you_are_not_within_designated_location"
                  )}
            </Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        </ModelContainer>
      )}
    </Modal>
  );
};

export default AttendanceMarkOutComponent;

import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import appLogo from "../../assets/logo.png";
import { toJS } from "mobx";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import {
  AccountBalanceWallet,
  AccountCircle,
  Assessment,
  Assignment,
  Business,
  BusinessCenter,
  Call,
  Close,
  Contacts,
  Dashboard,
  EventNote,
  ExitToApp,
  Facebook,
  Folder,
  FormatListBulleted,
  Group,
  History,
  ListAlt,
  LocalOffer,
  LocationOn,
  Menu as MenuIcon,
  Notifications,
  Place,
  Search,
  Settings,
  TrackChanges,
  Tune,
  WhatsApp,
} from "@material-ui/icons";
import FlareIcon from "@mui/icons-material/Flare";
import SecurityIcon from "@mui/icons-material/Security";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { ChevronLeftOutlined } from "@material-ui/icons";
import { ChevronRightOutlined } from "@material-ui/icons";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ListItem from "@mui/material/ListItem";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  AdminPanelSettings,
  CalendarMonth,
  Campaign,
  CellTower,
  ConnectWithoutContact,
  CorporateFare,
  Diversity3,
  DriveFolderUpload,
  DynamicForm,
  GroupWork,
  IntegrationInstructions,
  ManageAccountsOutlined,
  ProductionQuantityLimits,
  Segment,
  SupportAgent,
  Upload,
  Work,
} from "@mui/icons-material";
import UploadModal from "../upload_csv_modal/UploadModal";
import {
  getAdminProjectApi,
  getLeadProfileApi,
  getNotificationsApi,
  sendUserActiveStatus,
  userLogoutApi,
} from "../../Api";
import { Badge, Select, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import MainFilterComponent from "../filter_modal/MainFilterComponent";
import { useEffect } from "react";
import FilterComponent from "../filter_modal/FilterComponent";
import {
  checkFeaturePermission,
  checkFeaturePermissionInModule,
  consoleLogger,
  getLocalizedText,
  hasIntegrationsAccess,
  hasReportAccess,
  isTeckinfoDialer,
  isUnderWorkSchedule,
  updateViewList,
  validateDisabledFeature,
  validateDisabledSubFeature,
  canShowJobs,
  isDateInRange,
  hasAttendanceMarkInOutAccess,
  hasAccessToWABAFeatures,
} from "../../Functions";
import {
  AdminProjectMenuList,
  TeamLeadAccessNameList,
  domainName,
} from "../../Config";
import NotificationComponent from "../notification_component/NotificationIcon";
import AlertDialogue from "../alert_dialogue/AlertDialogue";
import WorkScheduleAlert from "../alert_dialogue/WorkScheduleAlert";
import CallInfoDialog from "../call/CallInfoDialog";
import { callInfoSheetScope } from "../../utils/enum";
import CallLogModal from "../call_log_modal/CallLogModal";
import moment from "moment";
import {
  Sidebar,
  Menu as Menuv2,
  MenuItem as MenuItemv2,
  SubMenu as SubMenuV2,
  sidebarClasses,
} from "react-pro-sidebar";
import Cookies from "js-cookie";
import NotificationIcon from "../notification_component/NotificationIcon";
import profile_placeholder from "../../assets/profile_placeholder.png";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import UserProfileImageComponent from "../file_upload_component/user_profile_image/UserProfileImageComponent";
import AttendanceAppbarControlsComponent from "../attendance_mark_in_out_components/AttendanceAppbarControlsComponent";

const drawerWidth = 275;

const Logo = styled("img")(({ theme }) => ({
  // maxWidth: "120px",
  height: "16px",
  // height: "auto",
  width: "auto",
  cursor: "pointer",
}));
const SearchBarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "40px",
  width: "100%",
  padding: "0px 20px",
}));

const ProfileWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
const SearchInput = styled("input")(({ theme }) => ({
  height: "40px",
  border: "none",
  outline: "none",
  width: "100%",
  color: "#4D4E4F",
  font: "normal normal 500 14px Poppins",
  padding: "2px",
  backgroundColor: "#EFEFF4",
  "&::placeholder": {
    fontSize: "14px",
    color: "#2B2B2B",
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  gap: "12px",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 3,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
  }),
}));

const useStyles = makeStyles({
  blink: {
    animation: "$blink 1s linear infinite",
  },
  "@keyframes blink": {
    "0%": {
      opacity: 0,
      transform: "scale(1)",
    },
    "50%": {
      opacity: 1,
      transform: "scale(1.5)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1)",
    },
  },
});

const AppbarV2 = (props) => {
  const disableTopBar = !rootStore.authStore.isLocationValid;
  const classes = useStyles();
  const theme = useTheme();
  const { userStore, authStore, filterStore, phoneCallState } = rootStore;
  let pid = authStore.projectId;
  let tid = authStore.teamId;
  const [openCsv, setOpenCsv] = useState(false);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [moduleList, setModuleList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isInWorkHours, setIsInWorkHours] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showAdminProject, setShowAdminProject] = useState(false);
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [openCallLogModal, setOpenCallLogModal] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [tasksBadgeCount, setTasksBadgeCount] = useState(0);

  const [callInfoScope, setCallInfoScope] = useState(
    callInfoSheetScope.home_call_icon
  );
  const [viewProfileImage, setViewProfileImage] = useState(false);
  const getProfileImageUrl = () => {
    if (rootStore.userStore.UserData !== null) {
      if (
        rootStore.userStore.UserData.hasOwnProperty("profile_pic") &&
        rootStore.userStore.UserData["profile_pic"] !== null
      ) {
        return rootStore.userStore.UserData["profile_pic"];
      }
    }

    return profile_placeholder;
  };
  const uid = localStorage.getItem("uid");

  const view = userStore.view;

  let navigate = useNavigate();
  const [query, setQuery] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChange = async (e) => {
    if (phoneCallState.hasPendingCallLog()) {
      setOpenCallInfo(true);
      setCallInfoScope(callInfoSheetScope.home_project);
    } else {
      navigate("/");
      authStore.updateOnProjectChange(e.target.value);
    }
  };

  const clearLocalStorageWithoutDeviceID = () => {
    let device_id = localStorage.getItem("device_id");
    localStorage.clear();
    localStorage.setItem("device_id", device_id);
  };

  const handleLogout = async () => {
    try {
      let response = await userLogoutApi();
      Cookies.remove("token", { path: "/", domain: domainName });
      Cookies.remove("uid", { path: "/", domain: domainName });
      navigate("/login");
      clearLocalStorageWithoutDeviceID();

      authStore.resetUserAuth();
    } catch (error) {
      authStore.resetUserAuth();
      clearLocalStorageWithoutDeviceID();

      Cookies.remove("token", { path: "/", domain: domainName });
      Cookies.remove("uid", { path: "/", domain: domainName });
      navigate("/login");
      console.log(error);
    }
  };

  const handleTeam = (e) => {
    navigate("/");
    authStore.setTeamId(e.target.value);
  };

  const MINUTE_MS = 60000;

  const setupTasksBadgeCount = () => {
    const items = rootStore.userStore.tasksPageTaskList;
    if (items.length > 0) {
      const today = moment();
      let itemsForToday = items.filter(
        (item) =>
          isDateInRange(item.start_time, item.end_time, today) &&
          item.task_status === "Open"
      );
      setTasksBadgeCount(itemsForToday.length);
    } else {
      setTasksBadgeCount(0);
    }
  };

  const shouldAdminAndProjectVisible = async () => {
    try {
      let response = await getAdminProjectApi();
      if (response.data.length > 0) {
        setShowAdminProject(true);
      }

      //allowing only admin or owner for administration menu
      if (userStore.UserData !== null) {
        if (
          userStore.UserData.role === "admin" ||
          userStore.UserData.role === "owner"
        ) {
          setShowAdmin(true);
        }
      }
    } catch (error) {
      if (error.response.request.status !== 401) {
        setIsError(true);
      }
    }
  };

  const isQuarterHour = () => {
    const currentMinute = moment().minute();
    return currentMinute % 15 === 0;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (isQuarterHour()) {
        updateUserActiveStatus();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const getUserActiveStatus = () => {
    if (authStore.lastActiveTime !== "") {
      const currentTime = moment();
      const lastActiveMoment = moment(authStore.lastActiveTime);
      const diffInMinutes = currentTime.diff(lastActiveMoment, "minutes");
      if (diffInMinutes >= 15) {
        return "Inactive";
      } else {
        return "Active";
      }
    }
    return "Inactive";
  };

  const updateUserActiveStatus = async () => {
    let payload = { status: getUserActiveStatus() };
    let response = await sendUserActiveStatus(payload);
    consoleLogger(response);
  };

  useEffect(() => {
    const getData = async () => {
      if (userStore.UserData !== null) {
        let userAcc = userStore.UserData.account;
        if (userAcc.modules !== null) {
          let str = userAcc.modules;
          let newList = str.split(";");
          setModuleList(newList);
        }
      }
    };

    if (location.pathname !== "/login") {
      getData();
      shouldAdminAndProjectVisible();
    }
    if (pid !== null) {
      updateViewList();
    }
  }, [pid]);

  const handleQuery = () => {
    if (query !== "") {
      rootStore.userStore.updateSearchBarState({ key: "query", value: query });
      const searchBarRoute = rootStore.userStore.searchBarState["route"];

      if (searchBarRoute.startsWith("/contacts")) {
        navigate("/contacts/search");
      } else if (searchBarRoute.startsWith("/customers")) {
        navigate("/customers/search");
      } else {
        navigate("/");
      }
    }
  };

  const handleClearSearch = () => {
    setQuery("");
    const searchBarRoute = rootStore.userStore.searchBarState["route"];
    rootStore.userStore.updateSearchBarState({ key: "query", value: "" });

    // it is to prvent routing to leads page - when we are in different page other than customer and contact but query in search bar is not cleared
    if (searchBarRoute === "/") {
      navigate("/");
    } else if (searchBarRoute.startsWith("/contacts")) {
      navigate("/contacts");
    } else if (searchBarRoute.startsWith("/customers")) {
      navigate("/customers");
    }
  };

  useEffect(() => {
    if (phoneCallState.showProgressiveCallDialog && !openCallLogModal) {
      setOpenCallInfo(true);
      setCallInfoScope(callInfoSheetScope.home_call_icon);
    }
  }, [phoneCallState.callStatus, phoneCallState.showProgressiveCallDialog]);

  useEffect(() => {
    setupTasksBadgeCount();
  }, [rootStore.userStore.tasksPageTaskList]);

  useEffect(() => {
    rootStore.userStore.updateTasksPageTaskList();
  }, []);

  const canShowCallBadge = () => {
    consoleLogger(
      `can show call badge ::: ${JSON.stringify(phoneCallState.callStatus)}`
    );
    if (phoneCallState.isCallCompleted()) {
      return true;
    }
    return false;
  };

  const getCallStatusLeadID = () => {
    /*
    this func is being used, because on submit call log, we are resetting call status
    So if we directly pass callstatus lead id, it will result in white screen
    */
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      return phoneCallState.callStatus["lead"]["id"];
    } else {
      return "";
    }
  };

  const userAppLogoGetter = () => {
    // if (userStore.UserData !== null) {
    //   if (userStore.UserData.hasOwnProperty("account")) {
    //     let account_info = userStore.UserData["account"];
    //     let logo_url = account_info.hasOwnProperty("logo_url")
    //       ? account_info["logo_url"]
    //       : "";
    //     return logo_url !== null && logo_url !== "" ? logo_url : appLogo;
    //   }
    // }
    return appLogo;
  };

  const cobrandingLogoGetter = () => {
    if (userStore.UserData !== null) {
      if (userStore.UserData.hasOwnProperty("account")) {
        let account_info = userStore.UserData["account"];
        let cobranding_config = account_info.hasOwnProperty(
          "cobranding_configuration"
        )
          ? account_info["cobranding_configuration"]
          : "";
        if (
          cobranding_config !== "" &&
          cobranding_config !== null &&
          cobranding_config !== undefined
        ) {
          let logo_url = cobranding_config.hasOwnProperty("text_logo")
            ? cobranding_config["text_logo"]
            : "";
          return logo_url !== null ? logo_url : "";
        } else {
          return "";
        }
      }
      return "";
    }
    return "";
  };

  const canShowProjectAdministration = () => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let projectRole = authStore.getCurrentProjectRole();
    if (accountRole === "admin" || accountRole === "owner") {
      return true;
    } else {
      if (projectRole !== "member") {
        return true;
      }
      return false;
    }
  };

  const canShowProjectAdministrationSubmenu = (menuName) => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let projectRole = authStore.getCurrentProjectRole();
    if (accountRole === "admin" || accountRole === "owner") {
      return true;
    } else {
      if (projectRole !== "member") {
        if (projectRole === "manager") {
          return true;
        }
        return TeamLeadAccessNameList.includes(menuName);
      }
      return false;
    }
  };

  const canShowRawLeads = () => {
    if (moduleList.includes("raw_leads")) {
      return checkFeaturePermission("raw_leads");
    }
    return false;
  };

  const getTasksIcon = () => {
    return tasksBadgeCount !== 0 ? (
      <Box>
        <Badge badgeContent={tasksBadgeCount} color="secondary">
          <Assignment />
        </Badge>
      </Box>
    ) : (
      <Assignment />
    );
  };

  const getSearchBarHintText = () => {
    const searchBarRoute = rootStore.userStore.searchBarState["route"];
    if (searchBarRoute.startsWith("/contacts")) {
      return "Search contacts...";
    } else if (searchBarRoute.startsWith("/customers")) {
      return "Search customers...";
    } else {
      return `${getLocalizedText("search_leads", "project")}...`;
    }
  };

  useEffect(() => {
    // update route value in search bar state whenever pathname changes
    rootStore.userStore.updateSearchBarState({
      key: "route",
      value: location.pathname,
    });

    //1. if search query is not emtpy and if we are in customer and contact pages
    // - if its contact or customer listing page - reset the search state directly

    //2. else if search query is not empty and if we are in leads page - "/" or if we are in lead details page starts with"/user/" then if the search is not lead search then reset the search state

    //3. by defaylt if none of above case satisfy then if the search query is not empty - reset it

    const searchState = rootStore.userStore.searchBarState;
    if (searchState.query !== "") {
      if (
        searchState.route.startsWith("/contacts") ||
        searchState.route.startsWith("/customers")
      ) {
        if (
          searchState.route === "/contacts" ||
          searchState.route === "/customers"
        ) {
          rootStore.userStore.updateSearchBarState({ key: "query", value: "" });

          setQuery("");
        }
      } else if (
        searchState.route === "/" ||
        searchState.route.startsWith("/users/")
      ) {
        if (!rootStore.userStore.isLeadSearch()) {
          rootStore.userStore.updateSearchBarState({ key: "query", value: "" });
          setQuery("");
        }
      } else {
        rootStore.userStore.updateSearchBarState({ key: "query", value: "" });
        setQuery("");
      }
    } else {
      setQuery("");
    }
  }, [location]);

  const handleFilterTap = () => {
    const searchBarRoute = rootStore.userStore.searchBarState["route"];
    if (searchBarRoute.startsWith("/contacts")) {
      navigate("/contacts/filter");
    } else if (searchBarRoute.startsWith("/customers")) {
      navigate("/customers/filter");
    } else {
      navigate("/filter-page");
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "white",
            height: "64px",
          }}
        >
          <Toolbar>
            <ListItem
              style={{
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "20px",
                paddingLeft: open ? drawerWidth : "64px",
              }}
              // ref={myRef}
            >
              <LogoContainer>
                <Logo src={userAppLogoGetter()} />
                {cobrandingLogoGetter() !== "" && (
                  <Logo src={cobrandingLogoGetter()} />
                )}
              </LogoContainer>
            </ListItem>
            {hasAttendanceMarkInOutAccess() && (
              <ListItem
                style={{
                  width: "fit-content",
                  justifyContent: "flex-end",
                  padding: "20px",
                  paddingLeft: "0px",
                  height: "100%",
                }}
              >
                <AttendanceAppbarControlsComponent disabled={disableTopBar} />
              </ListItem>
            )}
            <ListItem
              style={{
                boxShadow: "0px 4px 10px #B8B8CE26",
                border: "1px solid #E7E7F0",
                borderRadius: "8px",
                height: "40px",
                backgroundColor: "#EFEFF4",
                padding: "0px",
                marginRight: "20px",
                position: "relative",
                width: "440px",
              }}
            >
              <SearchBarWrapper style={{ backgroundColor: "#EFEFF4" }}>
                <Search
                  style={{
                    width: "22px",
                    height: "22px",
                    color: "#4D4E4F",
                    marginRight: "4px",
                    opacity: disableTopBar ? 0.5 : 1,
                  }}
                />
                <SearchInput
                  disabled={disableTopBar}
                  style={{
                    opacity: disableTopBar ? 0.5 : 1,
                  }}
                  placeholder={getSearchBarHintText()}
                  value={query}
                  onChange={(e) => {
                    isUnderWorkSchedule()
                      ? setQuery(e.target.value)
                      : setIsInWorkHours(true);
                  }}
                  onKeyUp={(e) => (e.key === "Enter" ? handleQuery() : null)}
                />
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {rootStore.userStore.searchBarState.query !== "" && (
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={handleClearSearch}
                    >
                      <Close
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  )}
                  <Tune
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#4D4E4F",
                      opacity: disableTopBar ? 0.5 : 1,
                      cursor: disableTopBar ? "auto" : "pointer",
                    }}
                    onClick={() => {
                      if (!disableTopBar) {
                        userStore.updateFilterDrawerState(true);
                        if (isUnderWorkSchedule()) {
                          handleFilterTap();
                        } else {
                          setIsInWorkHours(true);
                        }
                      }
                    }}
                  />
                </Box>
              </SearchBarWrapper>
            </ListItem>
            {/* <LogoTitle>Lead Pixie</LogoTitle> */}

            <ListItem
              style={{
                justifyContent: "flex-end",
                width: "fit-content",
                paddingLeft: "0px",
              }}
              sx={{
                pointerEvents: props.open ? "none" : "unset",
              }}
            >
              {phoneCallState.hasPendingCallLog() && (
                <IconButton
                  onClick={() => {
                    consoleLogger(
                      "Phone state ::: post submit calllog ::: call end button"
                    );

                    phoneCallState.postSubmitCallLog();
                  }}
                  disabled={!phoneCallState.dispositionFailed}
                  // style={{ marginRight: "16px" }}
                >
                  <PhoneDisabledIcon
                    color={
                      phoneCallState.dispositionFailed ? "error" : "disabled"
                    }
                  />
                </IconButton>
              )}
              {phoneCallState.hasPendingCallLog() && (
                <IconButton
                  onClick={() => {
                    phoneCallState.fetchUserStatus();
                    setOpenCallInfo(true);
                    setCallInfoScope(callInfoSheetScope.home_call_icon);
                  }}
                  style={{ marginRight: "16px" }}
                >
                  {canShowCallBadge() ? (
                    <Badge
                      variant="dot"
                      badgeContent=" "
                      color="error"
                      invisible={!canShowCallBadge()}
                    >
                      <Call color="primary" />
                    </Badge>
                  ) : (
                    <Call color="primary" className={classes.blink} />
                  )}
                </IconButton>
              )}
              {toJS(authStore.projectList).length > 0 && (
                <Select
                  disabled={disableTopBar}
                  disableUnderline
                  value={pid}
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={handleChange}
                  style={{
                    minWidth: "125px",
                    backgroundColor: "#EFEFF4",
                    padding: "0px 8px",
                    marginRight: "20px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  {toJS(authStore.projectList).map((e) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "14px" }}
                        id={e.id}
                        value={e.id}
                      >
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {authStore.currentProject !== null && tid !== null && (
                <Select
                  disabled={disableTopBar}
                  disableUnderline
                  variant="standard"
                  value={tid}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={(e) =>
                    isUnderWorkSchedule()
                      ? handleTeam(e)
                      : setIsInWorkHours(true)
                  }
                  style={{
                    minWidth: "125px",
                    backgroundColor: "#EFEFF4",
                    padding: "0px 8px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  {authStore.currentProject.hasOwnProperty("teams") &&
                    authStore.currentProject["teams"].length > 0 &&
                    authStore.currentProject["teams"].map((e) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "14px" }}
                          id={e.id}
                          value={e.id}
                        >
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  ;
                </Select>
              )}
              <NotificationIcon
                setIsInWorkHours={setIsInWorkHours}
                disabled={disableTopBar}
              />
            </ListItem>
          </Toolbar>
        </AppBar>

        <Sidebar
          width={drawerWidth}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              backgroundColor: "white",
              position: "sticky",
              left: 0,
              top: 0,
              height: "100vh",
              width: open ? drawerWidth : "unset",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              transition: "all 0.5s ease",
            },
          }}
          collapsed={!open}
        >
          <div>
            <div
              style={{
                position: "sticky",
                top: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                height: "64px",
                padding: "8px",
                justifyContent: "space-between",
                backgroundColor: "white",
                zIndex: 2,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {open ? (
                  <Box
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#757575",
                      marginRight: "8px",
                    }}
                  >
                    <UserProfileImageComponent
                      url={getProfileImageUrl()}
                      handleImageClick={() => {
                        setViewProfileImage(true);
                      }}
                    />
                  </Box>
                ) : (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    sx={{ marginLeft: "10px" }}
                  >
                    <MenuIcon style={{ color: "#4D4E4F" }} />
                  </IconButton>
                )}
                <ProfileWrapper>
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      opacity: open ? "1" : "0",
                    }}
                  >
                    {userStore.UserData !== null ? userStore.UserData.name : ""}
                  </p>
                  <p
                    style={{
                      color: "#949494",
                      fontSize: "14px",
                      margin: "0px",
                      fontWeight: "bold",
                      opacity: open ? "1" : "0",
                    }}
                  >
                    {userStore.UserData !== null
                      ? userStore.UserData.account.name
                      : ""}
                  </p>
                </ProfileWrapper>
              </div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightOutlined />
                ) : (
                  <ChevronLeftOutlined />
                )}
              </IconButton>
            </div>

            <Divider />
            <Menuv2
              closeOnClick={true}
              menuItemStyles={{
                subMenuContent: {
                  maxHeight: `calc(100vh - 148px)`,
                  overflowY: "auto",
                  width: "unset",
                  minWidth: open ? "unset" : "265px",
                },
                root: {
                  "&.ps-active": {
                    backgroundColor: "#EDF4FB",
                  },
                  "&.ps-disabled": {
                    opacity: 0.5,
                    pointerEvents: "none",
                    color: "gray",
                  },
                },
                label: {
                  color: "#3E2121",
                  "&.ps-active": {
                    color: "#185DD2",
                  },
                },
                icon: {
                  color: "#757575",
                  "&.ps-active": {
                    color: "#185DD2",
                  },
                },
              }}
            >
              <MenuItemv2
                active={location.pathname === "/summary"}
                component={<Link to="/summary" />}
                icon={<Dashboard />}
              >
                Dashboard
              </MenuItemv2>
              <SubMenuV2 label="Sales" icon={<Business />}>
                <MenuItemv2
                  icon={<ListAlt />}
                  active={location.pathname === "/"}
                  onClick={() => {
                    rootStore.userStore.updateSearchBarState({
                      key: "query",
                      value: "",
                    });
                    setQuery("");
                    navigate("/");
                  }}
                >
                  {getLocalizedText("leads", "project")}
                </MenuItemv2>

                {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                  "Deals"
                ) && (
                  //previously there was a deals_navigation disabledSubfeatureCheck here
                  <MenuItemv2
                    active={location.pathname === "/deals"}
                    icon={<LocalOffer />}
                    component={<Link to="/deals" />}
                  >
                    Deals
                  </MenuItemv2>
                )}
                {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                  "Quotations"
                ) && (
                  <>
                    <MenuItemv2
                      active={location.pathname === "/quotes"}
                      icon={<ReceiptLongIcon />}
                      component={<Link to="/quotes" />}
                    >
                      Quotes
                    </MenuItemv2>
                    <MenuItemv2
                      active={location.pathname === "/invoices"}
                      icon={<ReceiptLongIcon />}
                      component={<Link to="/invoices" />}
                    >
                      Invoices
                    </MenuItemv2>
                  </>
                )}
              </SubMenuV2>
              {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                "Tasks"
              ) && (
                <MenuItemv2
                  active={location.pathname === "/tasks"}
                  icon={getTasksIcon()}
                  component={<Link to="/tasks" />}
                >
                  Tasks
                </MenuItemv2>
              )}
              {moduleList.includes("marketing") &&
                checkFeaturePermission("marketing") && (
                  <SubMenuV2 label="Marketing" icon={<ConnectWithoutContact />}>
                    <MenuItemv2
                      active={location.pathname === "/segments"}
                      icon={<Segment />}
                      component={<Link to="/segments" />}
                    >
                      Segments
                    </MenuItemv2>
                    <MenuItemv2
                      active={location.pathname === "/campaigns"}
                      icon={<Campaign />}
                      component={<Link to="/campaigns" />}
                    >
                      Campaigns
                    </MenuItemv2>

                    <MenuItemv2
                      active={location.pathname === "/web-forms"}
                      icon={<DynamicForm />}
                      component={<Link to="/web-forms" />}
                    >
                      Web Forms
                    </MenuItemv2>
                  </SubMenuV2>
                )}
              {checkFeaturePermission("entities") && (
                <SubMenuV2 label="Entities" icon={<GroupWork />}>
                  <MenuItemv2
                    active={location.pathname === "/products"}
                    component={<Link to="/products" />}
                    icon={<ProductionQuantityLimits />}
                  >
                    Products
                  </MenuItemv2>
                  {/* Disabling contacts in sidebar */}
                  {/* <MenuItemv2
                    active={location.pathname === "/contacts"}
                    component={<Link to="/contacts" />}
                    icon={<Contacts />}
                  >
                    Contacts
                  </MenuItemv2> */}
                  <MenuItemv2
                    active={location.pathname === "/customers"}
                    component={<Link to="/customers" />}
                    icon={<Diversity3 />}
                  >
                    Customers
                  </MenuItemv2>
                  {/* <MenuItemv2
                    active={location.pathname === "/organizations"}
                    component={<Link to="/organizations" />}
                    icon={<CorporateFare />}
                  >
                    Organizations
                  </MenuItemv2> */}
                </SubMenuV2>
              )}
              {/* for showing Customers menu for member role */}
              {checkFeaturePermission("member_entities") && (
                <SubMenuV2 label="Entities" icon={<GroupWork />}>
                  <MenuItemv2
                    active={location.pathname === "/customers"}
                    component={<Link to="/customers" />}
                    icon={<Diversity3 />}
                  >
                    Customers
                  </MenuItemv2>
                </SubMenuV2>
              )}
              {hasReportAccess() && (
                <MenuItemv2
                  active={location.pathname === "/reports"}
                  component={<Link to="/reports" />}
                  icon={<Assessment />}
                >
                  Reports
                </MenuItemv2>
              )}
              {checkFeaturePermission("imports") && (
                <MenuItemv2
                  active={location.pathname === "/imports"}
                  component={<Link to="/imports" />}
                  icon={<Upload />}
                >
                  Imports
                </MenuItemv2>
              )}
              {moduleList.includes("tva") &&
                checkFeaturePermission("target") && (
                  <MenuItemv2
                    active={location.pathname === "/target"}
                    component={<Link to="/target" />}
                    icon={<TrackChanges />}
                  >
                    Target
                  </MenuItemv2>
                )}
              {showAdmin && (
                <SubMenuV2 label="Administration" icon={<AdminPanelSettings />}>
                  {checkFeaturePermission("administrator_users") && (
                    <MenuItemv2
                      active={location.pathname === "/admin/users"}
                      icon={<Group />}
                      component={<Link to="/admin/users" />}
                    >
                      Users
                    </MenuItemv2>
                  )}
                  {canShowRawLeads() && (
                    <MenuItemv2
                      active={location.pathname === "/admin/raw-leads"}
                      icon={<DriveFolderUpload />}
                      component={<Link to="/admin/raw-leads" />}
                    >
                      Raw Leads
                    </MenuItemv2>
                  )}
                  {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                    "Tasks"
                  ) && (
                    <MenuItemv2
                      active={location.pathname === "/admin/task-types"}
                      icon={<FormatListBulleted />}
                      component={<Link to="/admin/task-types" />}
                    >
                      Task Types
                    </MenuItemv2>
                  )}
                  {/* allowing Locations if either Attendance or Advanced Security addon is present */}
                  {(rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                    "Attendance"
                  ) ||
                    rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                      "Advanced Security"
                    )) && (
                    <MenuItemv2
                      active={location.pathname === "/admin/branch-locations"}
                      icon={<LocationOn />}
                      component={<Link to="/admin/branch-locations" />}
                    >
                      Locations
                    </MenuItemv2>
                  )}
                  {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                    "Attendance"
                  ) && (
                    <SubMenuV2 label="Attendance" icon={<EventNote />}>
                      <MenuItemv2
                        active={
                          location.pathname === "/admin/attendance-history"
                        }
                        icon={<History />}
                        component={<Link to="/admin/attendance-history" />}
                      >
                        User Attendance
                      </MenuItemv2>

                      <MenuItemv2
                        active={
                          location.pathname === "/admin/attendance-settings"
                        }
                        icon={<CalendarMonth />}
                        component={<Link to="/admin/attendance-settings" />}
                      >
                        Attendance Settings
                      </MenuItemv2>
                    </SubMenuV2>
                  )}
                  {moduleList.includes("billing") &&
                    checkFeaturePermission("billing") && (
                      <MenuItemv2
                        active={location.pathname === "/admin/billing"}
                        icon={<AccountBalanceWallet />}
                        component={<Link to="/admin/billing" />}
                      >
                        Billing
                      </MenuItemv2>
                    )}
                  <MenuItemv2
                    active={location.pathname === "/admin/branding"}
                    icon={<FlareIcon />}
                    component={<Link to="/admin/branding" />}
                  >
                    Branding
                  </MenuItemv2>
                  {hasIntegrationsAccess() && (
                    <MenuItemv2
                      active={location.pathname === "/admin/integrations"}
                      icon={<IntegrationInstructions />}
                      component={<Link to="/admin/integrations" />}
                    >
                      Integrations
                    </MenuItemv2>
                  )}
                  {/* disabled securty menu */}
                  {rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                    "Advanced Security"
                  ) && (
                    <MenuItemv2
                      active={location.pathname === "/admin/security"}
                      icon={<SecurityIcon />}
                      component={<Link to="/admin/security" />}
                    >
                      Security
                    </MenuItemv2>
                  )}
                  {hasAccessToWABAFeatures() && (
                    <SubMenuV2 label="WhatsApp Templates" icon={<WhatsApp />}>
                      {/* <MenuItemv2
                      active={
                        location.pathname ===
                        "/admin/whatsapp-templates/personal"
                      }
                      icon={<AccountCircle />}
                      component={
                        <Link to="/admin/whatsapp-templates/personal" />
                      }
                    >
                      Personal
                    </MenuItemv2> */}

                      <MenuItemv2
                        active={
                          location.pathname ===
                          "/admin/whatsapp-templates/business"
                        }
                        icon={<Work />}
                        component={
                          <Link to="/admin/whatsapp-templates/business" />
                        }
                      >
                        Business
                      </MenuItemv2>
                    </SubMenuV2>
                  )}
                </SubMenuV2>
              )}
              {canShowProjectAdministration() && (
                <SubMenuV2
                  label="Project Administration"
                  icon={<BusinessCenter />}
                >
                  {AdminProjectMenuList.map((menuData) => {
                    if (canShowProjectAdministrationSubmenu(menuData.name)) {
                      if (menuData.hasOwnProperty("submenu_list")) {
                        return (
                          <SubMenuV2 label={menuData.name} icon={menuData.icon}>
                            {menuData.submenu_list.map((subMenuData) => {
                              return (
                                <MenuItemv2
                                  active={
                                    location.pathname === subMenuData.path
                                  }
                                  icon={subMenuData.icon}
                                  component={<Link to={subMenuData.path} />}
                                >
                                  {subMenuData.name}
                                </MenuItemv2>
                              );
                            })}
                          </SubMenuV2>
                        );
                      } else {
                        if (menuData["name"] === "Quotations") {
                          if (
                            rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                              "Quotations"
                            )
                          ) {
                            return (
                              <MenuItemv2
                                active={location.pathname === menuData.path}
                                icon={menuData.icon}
                                component={<Link to={menuData.path} />}
                              >
                                {menuData.name}
                              </MenuItemv2>
                            );
                          }
                        } else if (menuData["name"] === "Jobs") {
                          if (canShowJobs()) {
                            return (
                              <MenuItemv2
                                active={location.pathname === menuData.path}
                                icon={menuData.icon}
                                component={<Link to={menuData.path} />}
                              >
                                {menuData.name}
                              </MenuItemv2>
                            );
                          }
                        } else if (menuData["name"] === "Predefined Deals") {
                          if (
                            rootStore.subscriptionState.isFeatureAvailableWithAddOns(
                              "Deals"
                            )
                          ) {
                            return (
                              <MenuItemv2
                                active={location.pathname === menuData.path}
                                icon={menuData.icon}
                                component={<Link to={menuData.path} />}
                              >
                                {menuData.name}
                              </MenuItemv2>
                            );
                          }
                        } else if (menuData["name"] === "Lead Form") {
                          if (validateDisabledSubFeature("lead_forms")) {
                            return (
                              <MenuItemv2
                                active={location.pathname === menuData.path}
                                icon={menuData.icon}
                                component={<Link to={menuData.path} />}
                              >
                                {menuData.name}
                              </MenuItemv2>
                            );
                          }
                        } else {
                          return (
                            <MenuItemv2
                              active={location.pathname === menuData.path}
                              icon={menuData.icon}
                              component={<Link to={menuData.path} />}
                            >
                              {menuData.name}
                            </MenuItemv2>
                          );
                        }
                      }
                    }
                  })}
                </SubMenuV2>
              )}
              <SubMenuV2 label="Settings" icon={<Settings />}>
                <MenuItemv2
                  active={location.pathname === "/account-setting"}
                  icon={<ManageAccountsOutlined />}
                  component={<Link to="/account-setting" />}
                >
                  User Settings
                </MenuItemv2>
                {validateDisabledFeature("checkins") && (
                  <MenuItemv2
                    active={location.pathname === "/checkin"}
                    icon={<Place />}
                    component={<Link to="/checkin" />}
                  >
                    CheckIn
                  </MenuItemv2>
                )}
              </SubMenuV2>
              <MenuItemv2
                active={location.pathname === "/files"}
                component={<Link to="/files" />}
                icon={<Folder />}
              >
                Files
              </MenuItemv2>
            </Menuv2>
          </div>
          <div>
            <Menuv2
              closeOnClick={true}
              menuItemStyles={{
                label: {
                  color: "#3E2121",
                },
                icon: {
                  color: "#757575",
                },
              }}
            >
              {isTeckinfoDialer() && (
                <MenuItemv2
                  icon={<SupportAgent />}
                  onClick={() => {
                    if (!disableTopBar) {
                      window.open(
                        rootStore.phoneCallState.callServiceInstance.getSSOUrl(),
                        "_blank"
                      );
                    }
                  }}
                >
                  SSO
                </MenuItemv2>
              )}
              <MenuItemv2 onClick={handleLogout} icon={<ExitToApp />}>
                Logout
              </MenuItemv2>
            </Menuv2>
          </div>
        </Sidebar>
      </Box>
      {openCsv && <UploadModal open={openCsv} setOpen={setOpenCsv} />}

      {isError && <AlertDialogue open={isError} setOpen={setIsError} />}
      {isInWorkHours && (
        <WorkScheduleAlert open={isInWorkHours} setOpen={setIsInWorkHours} />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          // openCallLogCallback={async () => {
          //   let lead_id = getCallStatusLeadID();
          //   navigate(`/users/${uid}/leads/${lead_id}`, {
          //     state: { scope: "call_info_dialog_submit_log" },
          //   });
          //   setOpenCallInfo(false);
          // }}
          openCallLogCallback={async () => {
            const getLeadDetails = async () => {
              let leadID = getCallStatusLeadID();
              let response = await getLeadProfileApi({ leadID: leadID });
              if (!response.hasError()) {
                setLeadData(response.data.lead);
              }
            };

            await getLeadDetails();
            setOpenCallLogModal(true);
            setOpenCallInfo(false);
          }}
        />
      )}
      {openCallLogModal && (
        <CallLogModal
          scope={"app_bar"}
          open={openCallLogModal}
          setOpen={setOpenCallLogModal}
          lid={getCallStatusLeadID()}
          uid={uid}
          leadData={leadData}
        />
      )}
      {viewProfileImage && (
        <PreviewImageDialog
          open={viewProfileImage}
          setOpen={setViewProfileImage}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  );
};
export default observer(AppbarV2);

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Button,
  Divider,
  FormControl,
  makeStyles,
  Select,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import "./market.css";
import moment from "moment";
import { submitSegmentApi } from "../../Api";
import { useEffect } from "react";
import { DatePicker } from "antd";
import SegmentFilterComponent from "../filter_modal/SegmentFilterComponent";
import { Alert, Box, Typography } from "@mui/material";
import analytics from "../../Analytics";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { getLocalizedText } from "../../Functions";

const CustomAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      color="error"
      style={{ backgroundColor: "rgba(249,86,104,1)", color: "#F9E1E1" }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 500px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  padding: 30px;
`;

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  row-gap: 20px;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content;
  row-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 440px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  row-gap: 20px;
  width: 500px;
  padding: 30px;
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const AlertAndButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);

const typeList = [
  "All Leads",
  "Leads Created Between",
  "Leads Updated Between",
];

const CreateSegmentSidePanel = (props) => {
  const { authStore, userStore } = rootStore;
  const classes = useStyles();
  const [user, setUser] = useState({
    title: "",
    desc: "",
    type: typeList[0],
  });
  const [projectId, setProjectId] = useState("");

  const { RangePicker } = DatePicker;
  const [dateRangeReport, setDateRangeReport] = useState({});
  const [tabVal, selectTabVal] = useState(0);
  const [dateRange, setDateRange] = useState({});
  const [dateRangeVal, setDateRangeVal] = useState(null);
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const [filters, setFilters] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showProjectSelectionAlert, setShowProjectSelectionAlert] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [filterSelectionAlert, setShowFilterSelectionAlert] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = () => {
    userStore.setReportFilters({
      filters,
      dateRange,
      numRange,
      selectedVal,
    });

    const submitSegment = async () => {
      let data = {
        title: user.title,
        desc: user.desc,
        leads_filter_type: user.type,
        filters: userStore.reportFilters,
        project_id: projectId,
      };
      if (user.type !== "All Leads") {
        let start_date = new Date(dateRangeReport.start_date);
        start_date = start_date.toISOString();
        let end_date = new Date(dateRangeReport.end_date);
        end_date = end_date.toISOString();
        data["start_date"] = start_date;
        data["end_date"] = end_date;
      }

      let response = await submitSegmentApi({ data: data });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setShowError(true);
      } else {
        setShowError(false);
        setIsSubmitSuccess(true);
        userStore.resetReportFilters();
        props.setRefresh(true);
      }
    };

    if (Object.keys(userStore.reportFilters).length === 0) {
      setShowFilterSelectionAlert(true);
    } else {
      if (user.type === "All Leads") {
        setShowAlert(false);
        submitSegment();
      } else {
        if (validateDateRange()) {
          setShowAlert(false);
          submitSegment();
        } else {
          setShowAlert(true);
        }
      }
    }
  };

  function disabledDate(current) {
    return current > moment() || current < moment().subtract(90, "days");
  }

  const validateDateRange = () => {
    return dateRangeVal !== null;
  };

  const resetSelectedFilters = () => {
    setFilters({});
    setSelectedVal({});
    setDateRange({});
    setNumRange({});
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000236,
      "create_segment_form_load",
      "segments_page",
      "",
      {}
    );
  }, []);
  return (
    <>
      {!isSubmitSuccess ? (
        <Container>
          <Header>{getLocalizedText("create_a_new_segment")}</Header>

          <SubHeader>{getLocalizedText("fill_in_the_below_details")}</SubHeader>
          <Divider style={{ margin: "10px 0px" }} />
          <Tabs
            style={{ marginBottom: "20px" }}
            value={tabVal}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              if (projectId !== "") {
                if (newValue === 0) {
                  analytics.triggerEvent(
                    4625000237,
                    "basic_info_tab_tap",
                    "create_segment_form",
                    "basic_info_tab",
                    {}
                  );
                } else {
                  analytics.triggerEvent(
                    4625000238,
                    "select_filters_tab_tap",
                    "create_segment_form",
                    "select_filters_tab",
                    {}
                  );
                }
                setShowFilterSelectionAlert(false);
                selectTabVal(newValue);
              } else {
                setShowProjectSelectionAlert(true);
              }
            }}
          >
            <CustomTab label={getLocalizedText("basic_info")} />
            <CustomTab label={getLocalizedText("select_filters")} />
          </Tabs>

          <Form
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <InputContainer>
              {(() => {
                if (tabVal === 0) {
                  return (
                    <>
                      <InputWrapper>
                        <Label>{getLocalizedText("title")}*</Label>
                        <Input
                          required
                          type="text"
                          placeholder="Enter title"
                          name="title"
                          value={user.title}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Label>{getLocalizedText("description")}</Label>
                        <TextArea
                          value={user.desc}
                          type="text"
                          placeholder="Enter description"
                          name="desc"
                          onChange={handleChange}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Label>{getLocalizedText("select_project")}*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="project_id"
                            value={projectId}
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            onChange={(e) => {
                              setProjectId(e.target.value);
                              setShowAlert(false);
                              setShowProjectSelectionAlert(false);
                              resetSelectedFilters();
                            }}
                            required
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {authStore.projectList.map((e, i) => {
                              return (
                                <MenuItem id={i} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                      <InputWrapper>
                        <Label>
                          {getLocalizedText("select_leads_filter_type","project")}
                        </Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="type"
                            value={user.type}
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            onChange={(e) => {
                              handleChange(e);
                              setShowAlert(false);
                            }}
                            required
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {typeList.map((e, i) => {
                              return (
                                <MenuItem id={i} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>

                      <InputWrapper>
                        <Label>{getLocalizedText("select_date_range")}</Label>
                        <RangePicker
                          disabledDate={disabledDate}
                          disabled={user.type === "All Leads"}
                          placeholder={["From", "To"]}
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            dateRangeVal && [
                              moment(dateRangeVal["start_date"], "YYYY-MM-DD"),
                              moment(dateRangeVal["end_date"], "YYYY-MM-DD"),
                            ]
                          }
                          style={{ height: "30px", width: "100%" }}
                          onChange={(value) => {
                            let d1 = moment(value[0]).format("YYYY-MM-DD");
                            let d2 = moment(value[1]).format("YYYY-MM-DD");
                            setDateRangeVal({
                              ...dateRangeVal,
                              start_date: d1,
                              end_date: d2,
                            });
                            setDateRangeReport({
                              ...dateRangeReport,
                              start_date: d1,
                              end_date: d2,
                            });
                          }}
                        />
                      </InputWrapper>
                    </>
                  );
                } else if (tabVal === 1) {
                  return (
                    <SegmentFilterComponent
                      projectId={projectId}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      numRange={numRange}
                      setNumRange={setNumRange}
                      selectedVal={selectedVal}
                      setSelectedVal={setSelectedVal}
                      filters={filters}
                      setFilters={setFilters}
                      type={"edit"}
                    />
                  );
                }
              })()}
            </InputContainer>
            <AlertAndButtonWrapper>
              {showAlert && (
                <Alert
                  style={{
                    width: "100%",
                    padding: "0px 20px",
                  }}
                  severity="warning"
                >
                  {getLocalizedText("please_select_a_date_range")}
                </Alert>
              )}
              {showProjectSelectionAlert && (
                <Alert
                  style={{
                    width: "100%",
                    padding: "0px 20px",
                  }}
                  severity="warning"
                >
                  {getLocalizedText("please_select_a_project")}
                </Alert>
              )}
              {filterSelectionAlert && (
                <Alert
                  style={{
                    width: "100%",
                    padding: "0px 20px",
                  }}
                  severity="warning"
                >
                  {getLocalizedText("please_add_atleast_one_filter")}
                </Alert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000239,
                      "cancel_button_tap",
                      "create_segment_form",
                      "cancel_button",
                      {}
                    );
                    handleClose();
                  }}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    backgroundColor: "#EFEFF4",
                    textTransform: "none",
                    marginRight: "20px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
                <Button
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000240,
                      "submit_button_tap",
                      "create_segment_form",
                      "submit_button",
                      {}
                    );
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  // id="call-log-btn"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {getLocalizedText("submit_details")}
                </Button>
              </ButtonWrapper>
            </AlertAndButtonWrapper>
          </Form>
        </Container>
      ) : (
        <MessageWrapper>
          <Message>{getLocalizedText("segment_created_successfully")}</Message>
          <Button
            onClick={() => {
              analytics.triggerEvent(
                4625000034,
                "close_button_tap",
                "create_lead_form",
                "close_button",
                {}
              );
              props.setOpen(false);
            }}
            variant="outlined"
            color="primary"
            style={{
              width: "fit-content",
              textTransform: "none",
            }}
          >
            {getLocalizedText("close")}
          </Button>
        </MessageWrapper>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showError}
        autoHideDuration={60000}
        onClose={() => {
          setShowError(false);
        }}
      >
        <CustomAlert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </CustomAlert>
      </Snackbar>
    </>
  );
};

export default observer(CreateSegmentSidePanel);

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  CircularProgress,
  Divider,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, makeStyles } from "@material-ui/core";
import CreateLeadProgressStepper from "../stepper_components/CreateLeadProgressStepper";
import rootStore from "../../stores/RootStore";
import { Edit } from "@material-ui/icons";
import { editLeadApi } from "../../Api";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  dateHandler,
  dateTimeHandler,
  getLocalizedText,
} from "../../Functions";
import moment from "moment";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 350px;
  width: 500px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const Message = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: red;
  margin-right: 10px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 20px;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 490px;
  column-gap: 20px;
  row-gap: 20px;
  height: 220px;
  overflow-y: auto;
  align-content: flex-start;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 235px;
  ${`flex-basis: calc(50% - 10px);`};
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
`;
const MultiInputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 150px;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const TimeField = styled(TextField)`
  height: 30px;
  &:focus {
    outline: none;
    border: none;
  }
`;
const InputField = styled(OutlinedInput)`
  font-size: 12px;
  height: 30px;
  &:focus {
    outline: none;
    border: none;
  }
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  /* position: absolute;
  bottom: 30px;
  right: 30px; */
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const MoneyViewCreateLead = (props) => {
  const { userStore, authStore } = rootStore;
  const [flag, setFlag] = useState(false);
  const [
    isAnyRequiredFieldEmptyInitially,
    setIsAnyRequiredFieldEmptyInitially,
  ] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const columnsList = userStore.AllColumnsList;
  const [editableFields, setEditableFields] = useState({});
  const [isUpdatingLead, setIsUpdatingLead] = useState(false);
  const [updateLeadHasError, setUpdateLeadHasError] = useState(false);
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleSubmit = () => {
    setFlag(true);
  };

  const identifyDateFormat = (dateString) => {
    if (!isNaN(dateString)) {
      const m = moment(parseInt(dateString));
      if (m.isValid()) {
        return "epoch";
      }
    } else {
      const m = moment(dateString);
      if (m.isValid()) {
        return "ISO";
      }
    }
    return "unknown";
  };

  const dateFieldHandler = ({ item }) => {
    let value = null;
    let dateStringType = identifyDateFormat(item);
    if (item !== null) {
      if (dateStringType !== "unknown") {
        if (dateStringType === "epoch") {
          value = dateHandler(item);
        } else {
          value = IsoToLocalDate(item);
        }
      }
    }
    return value;
  };

  const handleEdit = (e) => {
    e.preventDefault();
    let normalFieldsPayload = {};
    let customFieldsPayload = {};
    if (Object.keys(editableFields).length !== 0) {
      Object.keys(editableFields).forEach((key) => {
        let index = columnsList.findIndex((column) => column["field"] === key);
        if (index !== -1) {
          if (
            editableFields[key]["type"] === "date" ||
            editableFields[key]["type"] === "datetime"
          ) {
            let newDate = new Date(editableFields[key]["value"]);
            newDate = newDate.toISOString();
            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              customFieldsPayload[new_key_name] = newDate;
            } else {
              normalFieldsPayload[key] = newDate;
            }
          } else {
            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              customFieldsPayload[new_key_name] = editableFields[key]["value"];
            } else {
              normalFieldsPayload[key] = editableFields[key]["value"];
            }
          }
        }
      });
    }

    const updateLeadDetails = async () => {
      setIsUpdatingLead(true);
      let payload = {
        ...normalFieldsPayload,
        custom_params: { ...customFieldsPayload },
      };
      try {
        let response = await editLeadApi({
          id: props.data.id,
          data: payload,
        });
        props.setRefreshLeadDetails(true);
        setFlag(true);
      } catch (error) {
        setUpdateLeadHasError(true);
      }
    };

    updateLeadDetails();
  };

  const isRequiredFieldDataEmpty = (fieldList) => {
    if (fieldList.length > 0) {
      let custom_data = props.data.hasOwnProperty("custom_params")
        ? JSON.parse(props.data["custom_params"])
        : {};

      for (let i = 0; i < fieldList.length; i++) {
        let field = fieldList[i]["field"];
        if (fieldList[i]["is_custom_param"] === false) {
          if (props.data.hasOwnProperty(field)) {
            if (props.data[field] === null || props.data[field] === "") {
              return true;
            }
          } else {
            return true;
          }
        } else {
          let custom_field = field.replace("custom_params_", "");
          if (custom_data.hasOwnProperty(custom_field)) {
            if (
              custom_data[custom_field] === null ||
              custom_data[custom_field] === ""
            ) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    let mandatory_fields =
      authStore.getDigitalLenderMandatoryFields("moneyview");

    let temp_field_list = [];
    let columns_list = [...columnsList];
    if (columns_list.length > 0 && mandatory_fields.length > 0) {
      mandatory_fields.forEach((column_id) => {
        columns_list.forEach((item) => {
          if (column_id === item["id"]) {
            temp_field_list.push(item);
          }
        });
      });

      // if (optional_fields.length > 0) {
      //   optional_fields.map((column_id) => {
      //     columns_list.map((item) => {
      //       if (column_id === item["id"]) {
      //         item["required"] = false;
      //         temp_field_list.push(item);
      //       }
      //     });
      //   });
      // }
    }

    let flag = isRequiredFieldDataEmpty(temp_field_list);
    setIsAnyRequiredFieldEmptyInitially(flag);
    setMandatoryFields(temp_field_list);
    initEditableFields(temp_field_list);
  }, []);

  const getFieldValue = (item, type) => {
    if (item["is_custom_param"] === false) {
      if (props.data.hasOwnProperty(item["field"])) {
        let value = props.data[item["field"]];
        if (type === "date" || type === "datetime") {
          value = dateFieldHandler({ item: value });
        }
        return value !== null ? value : "";
      }
      return "";
    } else {
      let custom_data = props.data["custom_params"];
      custom_data = JSON.parse(custom_data);

      let field_name = item["field"].replace("custom_params_", "");
      if (custom_data.hasOwnProperty(field_name)) {
        let value = custom_data[field_name];
        if (type === "date" || type === "datetime") {
          value = dateFieldHandler({ item: value });
        }
        return value !== null ? value : "";
      }
      return "";
    }
  };

  const initEditableFields = (mandatory_field_list) => {
    let tempObj = {};
    mandatory_field_list.forEach((item) => {
      let value = getFieldValue(item, item["data_type"]);
      if (value === "") {
        tempObj[item["field"]] = { value: value, type: item["data_type"] };
      }
    });
    setEditableFields(tempObj);
  };

  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    setEditableFields({
      ...editableFields,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  const isAllEditableFieldsFilled = () => {
    const allValuesNotEmpty = Object.values(editableFields).every(
      (value) => value["value"] !== ""
    );
    return allValuesNotEmpty;
  };
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {!flag ? (
              <Container>
                <>
                  <IconTextWrapper>
                    <Header>{getLocalizedText("create_lead","project")}</Header>
                  </IconTextWrapper>
                  <SubHeader>
                    {getLocalizedText(
                      "below_are_the_fields_required_for_creating_the_lead","project"
                    )}
                  </SubHeader>
                  <Divider style={{ margin: "20px 0px" }} />
                  {!isUpdatingLead ? (
                    <Form
                      component={"form"}
                      onSubmit={
                        isAnyRequiredFieldEmptyInitially
                          ? handleEdit
                          : handleSubmit
                      }
                    >
                      <InputContainer>
                        {mandatoryFields.map((item) => {
                          let value = editableFields.hasOwnProperty(item.field)
                            ? editableFields[item.field]["value"]
                            : getFieldValue(item, item["data_type"]);
                          return (
                            <InputWrapper>
                              <Label>
                                {item.headerName}

                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <InputField
                                error={value === ""}
                                type={
                                  item.data_type === "email"
                                    ? "email"
                                    : item.data_type === "datetime" ||
                                      item.data_type === "date"
                                    ? "date"
                                    : item.data_type === "integer"
                                    ? "number"
                                    : "text"
                                }
                                value={value}
                                name={item.field}
                                onChange={(event) =>
                                  handleChange({
                                    e: event,
                                    dataType: item["data_type"],
                                  })
                                }
                                placeholder={`Enter ${item.headerName}`}
                                readOnly={
                                  !isAnyRequiredFieldEmptyInitially ||
                                  item.field === "phone"
                                }
                              />
                            </InputWrapper>
                          );
                        })}
                      </InputContainer>

                      <ButtonWrapper>
                        <>
                          <Button
                            onClick={handleClose}
                            type="button"
                            variant="outlined"
                            color="default"
                            style={{
                              textTransform: "none",
                              marginRight: "20px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "100px",
                            }}
                          >
                            {getLocalizedText("cancel")}
                          </Button>
                          <Button
                            disabled={!isAllEditableFieldsFilled()}
                            type="submit"
                            variant="contained"
                            style={{
                              backgroundColor: isAllEditableFieldsFilled()
                                ? "#185DD2"
                                : "#cccccc",
                              color: "white",
                              textTransform: "none",
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "fit-content",
                            }}
                          >
                            {isAnyRequiredFieldEmptyInitially
                              ? getLocalizedText("update")
                              : getLocalizedText("continue")}
                          </Button>
                        </>
                      </ButtonWrapper>
                    </Form>
                  ) : !updateLeadHasError ? (
                    <Form
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <>
                        <CircularProgress
                          style={{ width: "22px", height: "22px" }}
                        />
                        <Message style={{ color: "#4D4E4F", fontSize: "16px" }}>
                          {getLocalizedText("updating_lead_fields")}
                        </Message>
                      </>
                    </Form>
                  ) : (
                    <Form
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <>
                        <Message style={{ fontSize: "16px" }}>
                          {getLocalizedText("something_went_wrong")}
                        </Message>
                        <Button
                          onClick={handleClose}
                          type="button"
                          variant="outlined"
                          color="default"
                          style={{
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "100px",
                          }}
                        >
                          {getLocalizedText("close")}
                        </Button>
                      </>
                    </Form>
                  )}
                </>
              </Container>
            ) : (
              <Container>
                <Header>{getLocalizedText("create_lead","project")}</Header>
                <Divider style={{ margin: "20px 0px" }} />
                <Form
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CreateLeadProgressStepper
                    setRefreshLeadDetails={props.setRefreshLeadDetails}
                    setOpen={props.setOpen}
                    data={props.data}
                  />
                </Form>
              </Container>
            )}
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(MoneyViewCreateLead);

import { Box, Button, Divider, Grid, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import { Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { Circle } from "@mui/icons-material";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  row-gap: 16px;
`;

const Label = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const ChipLable = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled(Box)`
  display: flex;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;

const MultiLeadExportModal = (props) => {
  const renderList = rootStore.userStore.currentFilterRenderList;
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const exportLeads = async () => {
    // let index = statusList.findIndex((status) => status["default"] === true);
    // let lead_status_id = index !== -1 ? statusList[index]["id"] : null;
    // let stageIndex = stageList.findIndex(
    //   (stage) => stage["stage"] === details.stage
    // );
    // let lead_stage_id = stageIndex !== -1 ? stageList[stageIndex]["id"] : null;
    // let payload = {
    //   lead_status_id: lead_status_id,
    //   lead_stage_id: lead_stage_id,
    // };
    // if (details.comments !== "") {
    //   payload["comments"] = details.comments;
    // }
    // let response = await updateLeadStageApi({
    //   leadID: props.id,
    //   payload: payload,
    // });
    // if (response.hasError()) {
    //   setErrorMessage(response.errorMessage);
    //   setIsSubmitFail(true);
    // } else {
    //   props.setRefreshLeadDetails(true);
    //   setIsSubmitSuccess(true);
    // }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    await exportLeads();
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>Export</Header>
                <Divider style={{ margin: "16px 0px" }} />
                <Form component={"form"} onSubmit={handleUpdate}>
                  {Object.keys(renderList).length > 0 && (
                    <Grid item xs={12}>
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Label>Filters</Label>

                        <Grid
                          container
                          spacing={2}
                          style={{
                            overflowY: "auto",
                            height: "250px",
                            width: "100%",
                            padding: "16px 0px",
                          }}
                        >
                          {Object.keys(renderList).map((item, i) => {
                            let index = columnsList.findIndex(
                              (column) => column["field"] === item
                            );
                            let columnMeta = columnsList[index];
                            if (renderList[item]["type"] === "list") {
                              let value = renderList[item]["value"];
                              return (
                                <Grid item xs={12}>
                                  <CustomChip
                                    key={i}
                                    style={{ flexDirection: "column" }}
                                  >
                                    <ChipLable>
                                      {columnMeta["headerName"]}
                                    </ChipLable>
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {value.length > 0
                                        ? value.map((list_item, i) => {
                                            return (
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  columnGap: "4px",
                                                }}
                                                key={i}
                                              >
                                                <Circle
                                                  style={{
                                                    width: "8px",
                                                    height: "8px",
                                                    color: "gray",
                                                    marginTop: "2px",
                                                  }}
                                                />
                                                <ChipValue>
                                                  {list_item}
                                                </ChipValue>
                                              </Box>
                                            );
                                          })
                                        : "-"}
                                    </Box>
                                  </CustomChip>
                                </Grid>
                              );
                            } else if (renderList[item]["type"] === "range") {
                              return (
                                <Grid item xs={12}>
                                  <CustomChip>
                                    <ChipLable>
                                      {columnMeta["headerName"]}
                                    </ChipLable>
                                    <ChipValue>{`${renderList[item]["value"]["start"]} to ${renderList[item]["value"]["end"]}`}</ChipValue>
                                  </CustomChip>
                                </Grid>
                              );
                            } else {
                              return (
                                <Grid item xs={12}>
                                  <CustomChip>
                                    <ChipLable>
                                      {columnMeta["headerName"]}
                                    </ChipLable>
                                    <ChipValue>
                                      {renderList[item]["value"]}
                                    </ChipValue>
                                  </CustomChip>
                                </Grid>
                              );
                            }
                          })}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        {isSubmitFail && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setIsSubmitFail(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </ErrorAlert>
                        )}
                        <Button
                          onClick={() => {
                            props.setOpen(false);
                          }}
                          variant="contained"
                          color="default"
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginRight: "10px",
                            textTransform: "none",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          onClick={() => {}}
                          type="submit"
                          variant="contained"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                            ...myTheme.Button.btnBlue,
                          }}
                        >
                          Create Export Job
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>
                {getLocalizedText("leads_exported_successfully", "project")}
              </Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default MultiLeadExportModal;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { CustomerLeadsColumnHeaders, customerLeadsTabChipData } from "../../Db";
import { Chip, CircularProgress } from "@mui/material";
import {
  getCustomerLeadsListApi,
} from "../../Api";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { useNavigate } from "react-router-dom";
import SwitchProjectToRedirectDialog from "../alert_dialogue/SwitchProjectToRedirectDialog";
import rootStore from "../../stores/RootStore";
import AgGridColumnWithFilterIcon from "../column_header/AgGridColumnWithFilterIcon";
import { dataRenderer } from "../../Functions";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 246px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  margin: 16px 0px;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const LeadsTab = (props) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState({});
  const [switchingProject, setSwitchingProject] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});

  let userID = localStorage.getItem("uid");

  const navigate = useNavigate();

  const getCustomerLeadsList = async ({ page, page_size, query }) => {
    let queryPayload = { query: query };
    let response = await getCustomerLeadsListApi({
      customerID: props.id,
      page: page,
      page_size: page_size,
      queryParams: { scope: rootStore.customerStore.customerLeadsScope },
      payload: queryPayload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    setLoading(true);
    await getCustomerLeadsList({
      page: 0,
      page_size: pageSize,
      query: filterQuery,
    });
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getCustomerLeadsList({
      page: 0,
      page_size: e.target.value,
      query: filterQuery,
    });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getCustomerLeadsList({
      page: page - 1,
      page_size: size,
      query: filterQuery,
    });
    setLoading(false);
  };

  const handleRowTap = (leadData) => {
    let currentProjectID = rootStore.authStore.projectId;
    if (leadData["project_id"] === currentProjectID) {
      navigate(`/users/${userID}/leads/${leadData["id"]}`, {
        state: {
          lead_status: null,
          scope: "customer_leads_tab",
        },
      });
    } else {
      setSelectedLeadData(leadData);
      setOpenAlertDialog(true);
    }
  };
  const handleSwitchToNewProject = async () => {
    setSwitchingProject(true);
    await rootStore.authStore.updateOnProjectChange(
      selectedLeadData["project_id"]
    );
    setSwitchingProject(false);
    navigate(`/users/${userID}/leads/${selectedLeadData["id"]}`, {
      state: {
        lead_status: null,
        scope: "customer_leads_tab",
      },
    });
    setOpenAlertDialog(false);
  };
  const getProjectNameByID = (id) => {
    let index = rootStore.authStore.projectList.findIndex(
      (project) => project["id"] === id
    );
    if (index !== -1) {
      return rootStore.authStore.projectList[index]["name"];
    }
    return "";
  };
  const additionalColumns = [
    {
      field: "project",
      headerName: "PROJECT NAME",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => {
        let value = getProjectNameByID(params.data.project_id);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">
                {value !== "" ? value : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleChip = async (scope) => {
    rootStore.customerStore.updateCustomerLeadsScope(scope);
    setLoading(true);
    setPage(0);
    await getCustomerLeadsList({
      page: page,
      page_size: size,
      query: filterQuery,
    });
    setLoading(false);
  };

  const handleSearch = async (searchValue, fieldName) => {
    let tempFilterQuery = {};
    if (searchValue !== null && searchValue !== "") {
      tempFilterQuery[fieldName] = searchValue;
    }
    setFilterQuery(tempFilterQuery);
    setLoading(true);
    setPage(0);
    await getCustomerLeadsList({
      page: page,
      page_size: size,
      query: tempFilterQuery,
    });
    setLoading(false);
  };

  const flidColumnHeader = [
    {
      field: "flid",
      headerName: "ID",
      resizable: true,
      width: 100,
      headerComponent: AgGridColumnWithFilterIcon,
      headerComponentParams: {
        dataType: "integer",
        handleSearch: (searchValue) => {
          handleSearch(searchValue, "flid");
        },
        headerText: "ID",
        queryData: filterQuery.hasOwnProperty("flid")
          ? filterQuery["flid"]
          : null,
      },
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.flid);
        return (
          <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Tooltip title={value}>
              <TextHighlight>{value}</TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <Wrapper>
          <ChipsWrapper>
            {customerLeadsTabChipData.map((data, index) => {
              return (
                <Chip
                  key={index}
                  color={
                    rootStore.customerStore.customerLeadsScope === data.value
                      ? "primary"
                      : "default"
                  }
                  label={data.label}
                  onClick={() => {
                    handleChip(data.value);
                  }}
                />
              );
            })}
          </ChipsWrapper>
          <DetailsWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    onFilterChanged={(event) => {
                      console.log(event);
                    }}
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={flidColumnHeader
                      .concat(CustomerLeadsColumnHeaders)
                      .concat(additionalColumns)}
                    animateRows={true}
                    suppressCellFocus
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      handleRowTap(row.data);
                    }}
                  />
                </div>
                <PaginationWrapper>
                  <PaginationComponent
                    page_no={page}
                    row={row}
                    page_size={size}
                    size={size}
                    count={count}
                    handlePageSize={handlePageSize}
                    handlePage={handlePagination}
                  />
                </PaginationWrapper>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openAlertDialog && (
        <SwitchProjectToRedirectDialog
          isLoading={switchingProject}
          open={openAlertDialog}
          setOpen={setOpenAlertDialog}
          handleCancel={() => {
            setSelectedLeadData({});
            setOpenAlertDialog(false);
          }}
          handleContinue={() => {
            handleSwitchToNewProject();
          }}
        />
      )}
    </>
  );
};

export default observer(LeadsTab);

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import {
  OrganizationLeadsColumnHeaders,
  customerLeadsTabChipData,
} from "../../Db";
import { Chip, CircularProgress } from "@mui/material";
import { getOrganizationLeadsListApi } from "../../Api";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { useNavigate } from "react-router-dom";
import SwitchProjectToRedirectDialog from "../alert_dialogue/SwitchProjectToRedirectDialog";
import rootStore from "../../stores/RootStore";
import { dataRenderer } from "../../Functions";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 246px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  margin: 16px 0px;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const OrganizationLeadsTab = (props) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState({});
  const [switchingProject, setSwitchingProject] = useState(false);

  let userID = localStorage.getItem("uid");

  const navigate = useNavigate();

  const getOrganizationLeadsList = async ({ page, page_size }) => {
    let response = await getOrganizationLeadsListApi({
      organizationID: props.id,
      page: page,
      page_size: page_size,
      queryParams: { scope: rootStore.organizationStore.organizationLeadScope },
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    setLoading(true);
    await getOrganizationLeadsList({
      page: 0,
      page_size: pageSize,
    });
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getOrganizationLeadsList({
      page: 0,
      page_size: e.target.value,
    });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getOrganizationLeadsList({
      page: page - 1,
      page_size: size,
    });
    setLoading(false);
  };

  const handleLeadIDTap = (leadData) => {
    let currentProjectID = rootStore.authStore.projectId;
    if (leadData["project_id"] === currentProjectID) {
      navigate(`/users/${userID}/leads/${leadData["id"]}`, {
        state: {
          lead_status: null,
          scope: "organization_leads_tab",
        },
      });
    } else {
      setSelectedLeadData(leadData);
      setOpenAlertDialog(true);
    }
  };
  const handleContactTap = (contactID) => {
    navigate(`/contacts/${contactID}`, {
      state: {
        scope: "organization_leads_tab",
      },
    });
  };

  const handleSwitchToNewProject = async () => {
    setSwitchingProject(true);
    await rootStore.authStore.updateOnProjectChange(
      selectedLeadData["project_id"]
    );
    setSwitchingProject(false);
    navigate(`/users/${userID}/leads/${selectedLeadData["id"]}`, {
      state: {
        lead_status: null,
        scope: "organization_leads_tab",
      },
    });
    setOpenAlertDialog(false);
  };

  const handleChip = async (scope) => {
    rootStore.organizationStore.updateOrganizationLeadsScope(scope);
    setLoading(true);
    setPage(0);
    await getOrganizationLeadsList({
      page: page,
      page_size: size,
    });
    setLoading(false);
  };

  const flidColumnHeader = [
    {
      field: "flid",
      headerName: "ID",
      resizable: true,
      width: 100,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.flid);
        return (
          <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Tooltip title={value}>
              <TextHighlight
                onClick={() => {
                  handleLeadIDTap(params.data);
                }}
              >
                {value}
              </TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      width: 150,

      cellRenderer: (params) => {
        let value = params.data.name !== null ? params.data.name : "";
        return (
          <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">
                <TextHighlight
                  onClick={() => {
                    if (
                      params.data.hasOwnProperty("contact_id") &&
                      params.data.contact_id !== null &&
                      params.data.contact_id !== ""
                    ) {
                      handleContactTap(params.data.contact_id);
                    }
                  }}
                >
                  {value !== "" ? value : "-"}
                </TextHighlight>
              </span>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <Wrapper>
          <ChipsWrapper>
            {customerLeadsTabChipData.map((data, index) => {
              return (
                <Chip
                  key={index}
                  color={
                    rootStore.organizationStore.organizationLeadScope ===
                    data.value
                      ? "primary"
                      : "default"
                  }
                  label={data.label}
                  onClick={() => {
                    handleChip(data.value);
                  }}
                />
              );
            })}
          </ChipsWrapper>
          <DetailsWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    onFilterChanged={(event) => {
                      console.log(event);
                    }}
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={flidColumnHeader.concat(
                      OrganizationLeadsColumnHeaders
                    )}
                    animateRows={true}
                    suppressCellFocus
                    className="paginated-ag-grid"
                  />
                </div>
                <PaginationWrapper>
                  <PaginationComponent
                    page_no={page}
                    row={row}
                    page_size={size}
                    size={size}
                    count={count}
                    handlePageSize={handlePageSize}
                    handlePage={handlePagination}
                  />
                </PaginationWrapper>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openAlertDialog && (
        <SwitchProjectToRedirectDialog
          isLoading={switchingProject}
          open={openAlertDialog}
          setOpen={setOpenAlertDialog}
          handleCancel={() => {
            setSelectedLeadData({});
            setOpenAlertDialog(false);
          }}
          handleContinue={() => {
            handleSwitchToNewProject();
          }}
        />
      )}
    </>
  );
};

export default observer(OrganizationLeadsTab);

import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Chip, Tooltip } from "@material-ui/core";
import { Assignment, ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Button, Drawer, IconButton, Typography } from "@mui/material";
import { getCustomersListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useNavigate } from "react-router-dom";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import {
  checkFeaturePermission,
  consoleLogger,
} from "../../Functions";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import AssignCustomerDialog from "../../components/customer_page_components/AssignCustomerDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomerColumnSelectionModal from "../../components/customer_page_components/CustomerColumnSelectionModal";
import CreateCustomerSidepanel from "../../components/customer_page_components/CreateCustomerSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 276px);`};

  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;

const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const chipData = [
  { key: 0, label: "All Customers", value: "all" },
  { key: 1, label: "Customers - Today", value: "customers_today" },
  { key: 2, label: "Customers - This Month", value: "customers_thismonth" },
  { key: 3, label: "Deactivated Customers", value: "deactivated_customers" },
];

const CustomersPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openCreateCustomerPanel, setOpenCreateCustomerPanel] = useState(false);
  const [openSelectCol, setOpenSelectCol] = useState(false);
  const [refreshColumn, setRefreshColumn] = useState(false);
  const columnsList = [...rootStore.customerStore.columnsList];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCustomersList = async () => {
    setLoadingData(true);
    let payload = { view: rootStore.customerStore.listingInfo.scope };

    let response = await getCustomersListApi({
      page: rootStore.customerStore.listingInfo.page_number,
      page_size: rootStore.customerStore.listingInfo.page_size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.customerStore.updateCount(response.item_count);
    } else {
      setRow([]);
      rootStore.customerStore.updateCount(0);
    }
    setLoadingData(false);
  };

  const getCustomerColumns = async () => {
    let storedTabColumns = rootStore.customerStore.getCustomerColumnTabs();
    if (storedTabColumns.length > 0) {
      let newColumnsList = [];
      const columnsList = [...rootStore.customerStore.columnsList];

      storedTabColumns.forEach((columnField) => {
        let index = columnsList.findIndex(
          (column) => column["column_name"] === columnField["column_name"]
        );
        if (index !== -1) {
          newColumnsList.push(columnsList[index]);
        }
      });
      rootStore.customerStore.updateCurrentCustomerTabColumns(newColumnsList);

      let modifiedList = FormattedAgGridColumns([
        ...rootStore.customerStore.customerTabColumns,
      ]);

      setColumns(modifiedList);
      consoleLogger("selCols:: ", modifiedList);
    } else {
      rootStore.customerStore.updateCustomersSelectedWithDefaultColumns();

      let modifiedList = FormattedAgGridColumns([
        ...rootStore.customerStore.customerTabColumns,
      ]);
      setColumns(modifiedList);
    }
  };

  const handleColumns = () => {
    setOpenSelectCol(true);
    handleClose();
  };

  const init = async () => {
    await getCustomersList();
    await getCustomerColumns();
    setLoading(false);
  };

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const refetch = async () => {
    await getCustomersList();
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    if (refreshColumn) {
      refetchOnSelectColumnChange();
    }
  }, [refreshColumn]);

  const refetchOnSelectColumnChange = async () => {
    await refetchTabColumns();
    setRefreshColumn(false);
  };

  const refetchTabColumns = async () => {
    setLoading(true);
    try {
      let colList = rootStore.customerStore.getCustomerSelectedColumns();

      let newColumnsList = [];
      colList.forEach((columnField) => {
        let index = columnsList.findIndex(
          (column) => column["column_name"] === columnField
        );
        if (index !== -1) {
          newColumnsList.push(columnsList[index]);
        }
      });
      rootStore.customerStore.updateCurrentCustomerTabColumns(newColumnsList);

      let modifiedList = FormattedAgGridColumns([
        ...rootStore.customerStore.customerTabColumns,
      ]);

      setColumns(modifiedList);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChip = async (scope) => {
    rootStore.customerStore.updateScope(scope);
    rootStore.customerStore.updateCount(0);
    rootStore.customerStore.udpatePageNumber(0);
    rootStore.customerStore.updatePageSize(pageSize);
    await getCustomersList();
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.customerStore.udpatePageNumber(0);
    rootStore.customerStore.updatePageSize(e.target.value);
    await getCustomersList();
  };
  const handlePagination = async (e, page) => {
    rootStore.customerStore.udpatePageNumber(page - 1);

    await getCustomersList();
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "icons") {
      params.event.stopPropagation();
      params.event.preventDefault();
    }
  };

  const startingColumns = [
    {
      field: "icons",
      headerName: "",
      width: 55,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          ></Box>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",

      width: 230,
      cellRenderer: (params) => {
        let parts = [params.data.first_name, params.data.last_name].filter(
          Boolean
        );
        let fullName = parts.length ? parts.join(" ") : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={fullName}>
                <TextHighlight>{fullName}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  function handleSelectionChanged(event) {
    const selectedRows = event.api.getSelectedRows();
    setSelectedCustomers(selectedRows);
  }

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Customers</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
          </TopWrapper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginY: "16px",
            }}
          >
            <ChipsWrapper>
              {chipData.map((data, index) => {
                return (
                  <Chip
                    key={index}
                    color={
                      rootStore.customerStore.listingInfo.scope === data.value
                        ? "primary"
                        : "default"
                    }
                    label={data.label}
                    onClick={() => {
                      handleChip(data.value);
                    }}
                  />
                );
              })}
            </ChipsWrapper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
              }}
            >
              {" "}
              <Button
                onClick={() => {
                  setOpenCreateCustomerPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "160px",
                  height: "40px",
                }}
              >
                Create Customer
              </Button>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                style={{ display: "flex", flexDirection: "column" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "fit-content",
                  },
                }}
              >
                {checkFeaturePermission("assign_customer") && (
                  <MenuItem
                    disabled={selectedCustomers.length < 1}
                    onClick={() => {
                      setOpenAssignDialog(true);
                      setAnchorEl(null);
                    }}
                  >
                    {"Assign Customer(s)"}
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    handleColumns();
                  }}
                >
                  Select Columns
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={startingColumns.concat(columns)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  onSelectionChanged={handleSelectionChanged}
                  paginationPageSize={
                    rootStore.customerStore.listingInfo.page_size
                  }
                  rowSelection="multiple"
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/customers/${row.data.id}`);
                  }}
                  onCellClicked={onCellClicked}
                  overlayNoRowsTemplate={"No Customers found."}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={rootStore.customerStore.listingInfo.page_number}
              row={row}
              page_size={rootStore.customerStore.listingInfo.page_size}
              size={rootStore.customerStore.listingInfo.page_size}
              count={rootStore.customerStore.listingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openAssignDialog && (
        <AssignCustomerDialog
          customers={selectedCustomers}
          open={openAssignDialog}
          setOpen={setOpenAssignDialog}
          setRefresh={setRefresh}
        />
      )}
      {openCreateCustomerPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateCustomerPanel}
          onClose={() => {
            setOpenCreateCustomerPanel(false);
          }}
        >
          <CreateCustomerSidepanel
            setOpen={setOpenCreateCustomerPanel}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {openSelectCol && (
        <CustomerColumnSelectionModal
          open={openSelectCol}
          setOpen={setOpenSelectCol}
          setRefresh={setRefreshColumn}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(CustomersPage);

import React from "react";
import { Box, OutlinedInput, styled } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { DatePicker } from "antd";
import moment from "moment";

const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const CustomerFilterValueComponent = ({
  setFilter,
  filter,
  filterTypeListMeta,
  selectedColumn,
}) => {
  const { RangePicker } = DatePicker;
  const handleChange = ({ e, data_type, filterType }) => {
    console.log(e.target.name, e.target.value);
    let value =
      data_type === "integer" ? parseInt(e.target.value) : e.target.value;
    setFilter({
      ...filter,
      [e.target.name]: {
        value: value,
        filter_type: filterType,
        data_type: data_type,
      },
    });
  };

  if (selectedColumn !== null) {
    if (
      selectedColumn.filter_type === "normal" ||
      selectedColumn.filter_type === ""
    ) {
      return (
        <InputWrapper>
          <OutlinedInput
            style={{ height: "30px" }}
            inputProps={{
              "aria-label": "weight",
              style: {
                fontSize: "12px",
              },
            }}
            type={
              selectedColumn.data_type === "email"
                ? "email"
                : selectedColumn.data_type === "datetime" ||
                  selectedColumn.data_type === "date"
                ? "date"
                : selectedColumn.data_type === "integer"
                ? "number"
                : "text"
            }
            name={selectedColumn.column_name}
            value={
              filter.hasOwnProperty(selectedColumn.column_name)
                ? filter[selectedColumn.column_name]["value"]
                : ""
            }
            placeholder={`Enter ${selectedColumn.display_name}`}
            onChange={(e) => {
              let data_type = selectedColumn.data_type;
              let filterType = selectedColumn.filter_type;
              handleChange({ e, data_type, filterType });
            }}
          />
        </InputWrapper>
      );
    } else if (selectedColumn.filter_type === "range") {
      if (
        selectedColumn.data_type === "datetime" ||
        selectedColumn.data_type === "date"
      ) {
        return (
          <InputWrapper>
            <RangePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              placeholder={["From", "To"]}
              size="small"
              style={{
                height: "30px",
              }}
              value={
                filter.hasOwnProperty(selectedColumn.column_name)
                  ? [
                      moment(
                        filter[selectedColumn.column_name]["value"]["start"],
                        "YYYY-MM-DD"
                      ),
                      moment(
                        filter[selectedColumn.column_name]["value"]["end"],
                        "YYYY-MM-DD"
                      ),
                    ]
                  : [null, null]
              }
              onChange={(value) => {
                let d1 = moment(value[0]).format("YYYY-MM-DD");
                let d2 = moment(value[1]).format("YYYY-MM-DD");

                setFilter({
                  ...filter,
                  [selectedColumn.column_name]: {
                    value: { start: d1, end: d2 },
                    filter_type: selectedColumn.filter_type,
                    data_type: selectedColumn.data_type,
                  },
                });
              }}
            />
          </InputWrapper>
        );
      } else if (selectedColumn.data_type === "integer") {
        return (
          <InputWrapper>
            <Row>
              <OutlinedInput
                type="number"
                name="start"
                placeholder="min"
                value={
                  filter.hasOwnProperty(selectedColumn.column_name)
                    ? filter[selectedColumn.column_name]["value"]["start"]
                    : ""
                }
                onChange={(e) => {
                  let start = parseInt(e.target.value);
                  let end =
                    filter.hasOwnProperty(selectedColumn.column_name) &&
                    filter[selectedColumn.column_name]["value"]["end"] !== ""
                      ? parseInt(
                          filter[selectedColumn.column_name]["value"]["end"]
                        )
                      : "";

                  setFilter({
                    ...filter,
                    [selectedColumn.column_name]: {
                      value: { start: start, end },
                      filter_type: selectedColumn.filter_type,
                      data_type: selectedColumn.data_type,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  marginRight: "10px",
                  height: "30px",
                }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
              <OutlinedInput
                type="number"
                name="end"
                placeholder="max"
                value={
                  filter.hasOwnProperty(selectedColumn.column_name)
                    ? filter[selectedColumn.column_name]["value"]["end"]
                    : ""
                }
                disabled={
                  !filter.hasOwnProperty(selectedColumn.column_name) ||
                  (filter.hasOwnProperty(selectedColumn.column_name) &&
                    filter[selectedColumn.column_name]["value"]["start"] === "")
                }
                onChange={(e) => {
                  let start =
                    filter.hasOwnProperty(selectedColumn.column_name) &&
                    filter[selectedColumn.column_name]["value"]["start"] !== ""
                      ? parseInt(
                          filter[selectedColumn.column_name]["value"]["start"]
                        )
                      : "";
                  let end = parseInt(e.target.value);

                  setFilter({
                    ...filter,
                    [selectedColumn.column_name]: {
                      value: { start: start, end: end },
                      filter_type: selectedColumn.filter_type,
                      data_type: selectedColumn.data_type,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  marginRight: "10px",
                  height: "30px",
                }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
            </Row>
          </InputWrapper>
        );
      }
    } else if (selectedColumn.filter_type === "list") {
      console.log("filter type list meta:::", filterTypeListMeta);
      let list = filterTypeListMeta["list"];
      if (filterTypeListMeta["type"] === "list") {
        list = list.map((str) => ({
          label: str,
          value: str,
        }));
      } else {
        list = list.map((obj) => ({
          label: obj["name"],
          value: obj["value"],
        }));
      }

      return (
        <Box
          sx={{
            "&": {
              width: "100%",
              fontSize: "12px",
              border: "none",
              alignItems: "center",
              color: "black",
            },
            "& .dropdown-heading": {
              height: "30px",
            },
            "& .options": {
              maxHeight: "100px",
            },
          }}
        >
          <MultiSelect
            options={list}
            value={
              filter.hasOwnProperty(selectedColumn.column_name)
                ? filter[selectedColumn.column_name]["value"]
                : []
            }
            onChange={(selected) => {
              setFilter({
                ...filter,
                [selectedColumn["field"]]: {
                  value: selected,
                  filter_type: selectedColumn["filter_type"],
                  data_type: selectedColumn["data_type"],
                },
              });
            }}
          />
        </Box>
      );
    }
  }
};

export default CustomerFilterValueComponent;

import { Box, Typography, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { IconButton, Tooltip } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { getLocalizedText } from "../../Functions";
const Container = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #3f51b5;
`;
const HotLeadBannerComponent = () => {
  const navigate = useNavigate();
  let uid = localStorage.getItem("uid");
  useEffect(() => {}, [rootStore.hotLeadState.hotLeadInfo]);
  if (rootStore.hotLeadState.getMessage() !== "") {
    return (
      <Container>
        <Text>{rootStore.hotLeadState.getMessage()}</Text>
        <IconButton
          style={{
            border: "1px solid #89b7e7",
            borderRadius: "8px",
            padding: "8px",
          }}
          color={"primary"}
          variant="outlined"
          onClick={() => {
            navigate(
              `/users/${uid}/leads/${rootStore.hotLeadState.hotLeadInfo.id}`
            );
          }}
        >
          <Text>{getLocalizedText("go_to_lead", "project")}</Text>
        </IconButton>

        <IconButton
          onClick={() => {
            rootStore.hotLeadState.onRefresh();
          }}
          style={{
            border: "1px solid #89b7e7",
            borderRadius: "8px",
            padding: "8px",
          }}
          color={"primary"}
          variant="outlined"
        >
          <Tooltip title="Refresh">
            <Refresh style={{ width: "18px", height: "18px" }} />
          </Tooltip>
        </IconButton>
      </Container>
    );
  }
};

export default observer(HotLeadBannerComponent);

import React, { useState, useEffect } from "react";
import { Box, Grid, Select, makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Typography,
  OutlinedInput,
  CircularProgress,
  MenuItem,
  Radio,
} from "@mui/material";
import rootStore from "../../stores/RootStore";
import { consoleLogger } from "../../Functions";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const GroupLabel = styled("legend")`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 0px;
  width: auto;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const VariableSampleTextScreen = ({
  details,
  setDetails,
  components,
  setVariableFormat,
  variableFormat,
  setVariableColumnMapping,
  variableColumnMapping,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [bodyVariables, setBodyVariables] = useState([]);
  const [headerVariables, setHeaderVariables] = useState([]);
  const [buttonVariables, setButtonVariables] = useState({});
  const [columnsList, setColumnsList] = useState([]);
  const contactColumns = [...rootStore.contactStore.columnsList];

  const handleChange = ({
    value,
    type,
    buttonKey,
    buttonType = "",
    placeholderID,
  }) => {
    setDetails((prevDetails) => {
      if (type === "button") {
        return {
          ...prevDetails,
          buttons: {
            ...prevDetails.buttons,
            [buttonType]: {
              ...prevDetails.buttons[buttonType],
              [buttonKey]: value || "",
            },
          },
        };
      } else {
        const updatedMap = { ...prevDetails[type] }; // existing object
        updatedMap[placeholderID] = value; //update value at key

        return {
          ...prevDetails,
          [type]: updatedMap, // type = "body", "header"...
        };
      }
    });
  };

  const handleLeadVariableChange = ({
    value,
    type,
    buttonKey,
    buttonType = "",
    placeholderID,
  }) => {
    setDetails((prevDetails) => {
      if (type === "button") {
        return {
          ...prevDetails,
          buttons: {
            ...prevDetails.buttons,
            [buttonType]: {
              ...prevDetails.buttons[buttonType],
              [buttonKey]: value || "",
            },
          },
        };
      } else {
        const updatedMap = { ...prevDetails[type] }; // existing object
        updatedMap[placeholderID] = value; //update value at key

        return {
          ...prevDetails,
          [type]: updatedMap, // type = "body", "header"...
        };
      }
    });
    //lead variable mapping with variables
    const index = contactColumns.findIndex((col) => col.display_name === value);
    if (index !== -1) {
      let columnID = contactColumns[index]["id"];
      setVariableColumnMapping({
        ...variableColumnMapping,
        [`{{`+placeholderID+`}}`]: columnID,
      });
    }
    consoleLogger("mapping:: ",variableColumnMapping)
  };

  const getAllPlaceholders = (str) => {
    const regex = /\{\{\d+\}\}/g;
    return (str.match(regex) || []).map((placeholder) =>
      placeholder.replace(/{{|}}/g, "")
    );
  };

  const getComponentByType = (type) => {
    return components.filter(
      (x) => x.type.toLowerCase() === type.toLowerCase()
    );
  };

  const init = async () => {
    //set columnsList for lead variables

    let columns = contactColumns.filter((column) => column["is_visible"]);

    columns.sort((a, b) =>
      a["display_name"]
        .toLowerCase()
        .localeCompare(b["display_name"].toLowerCase())
    );
    setColumnsList(columns);

    //determine variables to render
    const headerComponent = getComponentByType("header")[0];
    const bodyComponent = getComponentByType("body")[0];
    const buttonComponent = getComponentByType("buttons");

    const bodyVariables = getAllPlaceholders(bodyComponent["text"]);
    setBodyVariables(bodyVariables);

    //setting state based on present variables
    const bodyEntries = Object.entries(details.body);

    // only keeping entries present in current bodyVariables array
    const filteredEntries = bodyEntries.filter(([key, value]) =>
      bodyVariables.includes(key)
    );

    const updatedBodyVariables = Object.fromEntries(filteredEntries);
    setDetails({
      ...details,
      body: updatedBodyVariables,
    });
    let newVariableFormatObj = bodyVariables.reduce((acc, varName) => {
      acc[varName] = "sample_data";
      return acc;
    }, {});
    setVariableFormat(newVariableFormatObj);
    // disabled buttons and header variables for now

    // if (
    //   headerComponent?.["format"]?.toLowerCase() === "text" &&
    //   headerComponent?.["text"] !== ""
    // ) {
    //   const headerVariables = getAllPlaceholders(headerComponent["text"]);
    //   setHeaderVariables(headerVariables);
    // }

    // let phoneButtonVars = [];
    // let urlButtonVars = [];

    // for (let i = 0; i < buttonComponent.length; i++) {
    //   for (let j = 0; j < buttonComponent[i]["buttons"].length; j++) {
    //     const item = buttonComponent[i]["buttons"][j];
    //     if (item["type"] === "PHONE_NUMBER") {
    //       phoneButtonVars.push(getAllPlaceholders(item["parameter"]));
    //     }
    //     if (item["type"] === "URL") {
    //       urlButtonVars.push(getAllPlaceholders(item["parameter"]));
    //     }
    //   }
    // }
    // //console.log(phoneButtonVars);
    // const newButtonVars = {
    //   url: urlButtonVars,
    //   phone: phoneButtonVars,
    // };
    // setButtonVariables(newButtonVars);

    // const urlButtons = {};
    // const phoneButtons = {};

    // for (let i = 0; i <= urlButtonVars.length - 1; i++) {
    //   urlButtons[`button_${i}`] = "";
    // }

    // for (let i = 0; i <= phoneButtonVars.length - 1; i++) {
    //   phoneButtons[`button_${i}`] = "";
    // }

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {!loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <SectionHeader sx={{ marginBottom: "8px" }}>
            Sample Data
          </SectionHeader>
          {/* Header fields disabled for now */}
          {/* {headerVariables.length > 0 && (
                  <>
                    <SectionHeader>Header</SectionHeader>
                    {headerVariables.map((value, index) => (
                      <InputWrapper key={index}>
                        <Label>{value}</Label>
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            name={`header_${index}`}
                            onChange={(e) => {
                              handleChange({
                                value: e.target.value,
                                type: "header",
                                index: index,
                              });
                            }}
                            value={details["header"][value] || ""}
                            inputProps={{
                              "aria-label": `body text ${index}`,
                              style: { fontSize: "12px" },
                            }}
                            sx={{ height: "30px" }}
                          />
                        </FormControl>
                      </InputWrapper>
                    ))}
                  </>
                )} */}
          {bodyVariables.length > 0 && (
            <Box
              component={"fieldset"}
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
                border: "1px solid #cccccc",
                padding: "12px",
                width: "100%",
              }}
            >
              <GroupLabel style={{ fontSize: "12px" }}>Body</GroupLabel>
              <Grid container spacing={2}>
                {bodyVariables.map((value, index) => (
                  <Grid item xs={12}>
                    <InputWrapper key={index}>
                      <Label>
                        {"{{"}
                        {value}
                        {"}}"}*
                      </Label>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          checked={variableFormat[index + 1] === "sample_data"}
                          onChange={(e) => {
                            setVariableFormat((prevState) => ({
                              ...prevState,
                              [index + 1]: "sample_data",
                            }));
                            handleChange({
                              value: "",
                              type: "body",
                              placeholderID: value,
                            });
                          }}
                          value="sample_data"
                          name="radio-buttons"
                        />
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            required={
                              variableFormat[index + 1] === "sample_data"
                            }
                            disabled={
                              variableFormat[index + 1] !== "sample_data"
                            }
                            name={`body_${index}`}
                            onChange={(e) => {
                              handleChange({
                                value: e.target.value,
                                type: "body",
                                placeholderID: value,
                              });
                            }}
                            value={
                              variableFormat[index + 1] === "sample_data"
                                ? details["body"][value] || ""
                                : ""
                            }
                            inputProps={{
                              "aria-label": `body text ${index}`,
                              style: { fontSize: "12px" },
                            }}
                            sx={{ height: "30px" }}
                          />
                        </FormControl>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          checked={
                            variableFormat[index + 1] === "lead_variable"
                          }
                          onChange={(e) => {
                            setVariableFormat((prevState) => ({
                              ...prevState,
                              [index + 1]: "lead_variable",
                            }));
                            handleChange({
                              value: "",
                              type: "body",
                              placeholderID: value,
                            });
                          }}
                          value="lead_variable"
                          name="radio-buttons"
                        />
                        <Select
                          required={
                            variableFormat[index + 1] === "lead_variable"
                          }
                          disabled={
                            variableFormat[index + 1] !== "lead_variable"
                          }
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          onChange={(e) => {
                            handleLeadVariableChange({
                              value: e.target.value,
                              type: "body",
                              placeholderID: value,
                            });
                          }}
                          name="header_format"
                          disableUnderline
                          variant="outlined"
                          value={
                            variableFormat[index + 1] === "lead_variable"
                              ? details["body"][value] || ""
                              : ""
                          }
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "4px",
                          }}
                        >
                          {columnsList.map((item) => {
                            return (
                              <MenuItem
                                value={item.display_name}
                                style={{ fontSize: "12px" }}
                              >
                                {item.display_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                      {/* <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        required
                        onChange={(e) => {
                        
                        }}
                        name="header_format"
                        disableUnderline
                        variant="outlined"
                        value={variableFormat[index + 1]}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "4px",
                          marginBottom: "10px",
                        }}
                      >
                        <MenuItem value={"text"} style={{ fontSize: "12px" }}>
                          Text
                        </MenuItem>
                        <MenuItem
                          value={"lead_variable"}
                          style={{ fontSize: "12px" }}
                        >
                          Lead Variable
                        </MenuItem>
                      </Select> */}
                    </InputWrapper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Button fields disabled for now */}
          {/* {(buttonVariables["phone"].length > 0 ||
                  buttonVariables["url"].length > 0) && (
                  <>
                    <SectionHeader>Button</SectionHeader>
                    {buttonVariables["phone"].length > 0 && (
                      <Box
                        component={"fieldset"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                          border: "1px solid #cccccc",
                          padding: "12px",
                          width: "100%",
                        }}
                      >
                        <GroupLabel style={{ fontSize: "12px" }}>
                          Call
                        </GroupLabel>
                        {buttonVariables["phone"].map((value, index) => (
                          <InputWrapper key={index}>
                            <Label>{value}</Label>
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                name={`button_${index}`}
                                onChange={(e) => {
                                  handleChange({
                                    value: e.target.value,
                                    type: "button",
                                    buttonKey: `button_${index}`,
                                    buttonType: "phone",
                                  });
                                }}
                                value={
                                  details.buttons.phone?.[value][`button_${index}`] ||
                                  ""
                                }
                                inputProps={{
                                  "aria-label": `body text ${index}`,
                                  style: { fontSize: "12px" },
                                }}
                                sx={{ height: "30px" }}
                              />
                            </FormControl>
                          </InputWrapper>
                        ))}
                      </Box>
                    )}
                    {buttonVariables["url"].length > 0 && (
                      <Box
                        component={"fieldset"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                          border: "1px solid #cccccc",
                          padding: "12px",
                          width: "100%",
                        }}
                      >
                        <GroupLabel style={{ fontSize: "12px" }}>
                          Visit
                        </GroupLabel>
                        {buttonVariables["url"].map((value, index) => (
                          <InputWrapper key={index}>
                            <Label>{value}</Label>
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                name={`button_${index}`}
                                onChange={(e) => {
                                  handleChange({
                                    value: e.target.value,
                                    type: "button",
                                    buttonKey: `button_${index}`,
                                    buttonType: "url",
                                  });
                                }}
                                value={
                                  details.buttons.url?.[value][`button_${index}`] || ""
                                }
                                inputProps={{
                                  "aria-label": `body text ${index}`,
                                  style: { fontSize: "12px" },
                                }}
                                sx={{ height: "30px" }}
                              />
                            </FormControl>
                          </InputWrapper>
                        ))}
                      </Box>
                    )}
                  </>
                )} */}
          {/* <Toolbar
            style={{
              padding: "16px",
              width: dialogWidth,
              position: "relative",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "fit-content",
                    textTransform: "none",
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Toolbar> */}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default VariableSampleTextScreen;

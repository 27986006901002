import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { getCampaignLeadsListApi, getColumnsForMapping } from "../../../Api";
import { Divider } from "@material-ui/core";
import {
  IsoToLocalTime,
  capitalizeWord,
  getLocalizedText,
} from "../../../Functions";
import Select from "react-select";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  margin-bottom: 30px;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  width: 400px;
  padding: 10px 0px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;
const CheckBoxTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 190px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 0px;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 50%;
`;
const MapCampaignLeadColumnToProject = (props) => {
  const [campaignLeadColumns, setCampaignLeadColumns] = useState([]);
  const [projectColumns, setProjectColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWieght: "bold",
      // Font size for options
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "200px", // your desired height
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      width: "190px",
      fontWieght: "bold",
      // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "loading" message
    }),
  };
  const getProjectColumnsForMapping = async () => {
    let response = await getColumnsForMapping(props.projectId);
    return response;
  };
  const initCampaignToProjectList = (campaignColumnList, projectColumnList) => {
    let initList = {};
    campaignColumnList.forEach((column) => {
      let checked = false;
      let projectLead = {};
      let index = findClosestMatchColumn(column["field"], projectColumnList);
      if (index !== -1) {
        checked = true;
        projectLead = {
          id: projectColumnList[index]["id"],
          field: projectColumnList[index]["field"],
          display_name: projectColumnList[index]["headerName"],
          data_type: projectColumnList[index]["data_type"],
          is_custom_param: projectColumnList[index]["is_custom_param"],
          label: projectColumnList[index].headerName,
          value: projectColumnList[index].field,
          isDisabled: isAlreadySelectedProjectField(
            projectColumnList[index].field
          ),
          required: projectColumnList[index].required,
        };
      }
      let tempObj = {
        campaign_lead: {
          field: column["field"],
          display_name: column["display_name"],
        },
        project_lead: projectLead,
        checked: checked,
      };

      initList[column["field"]] = tempObj;
    });
    return initList;
  };

  const findClosestMatchColumn = (field, projectColumns) => {
    const searchStr = field;
    const titleStr = capitalizeWord(field);
    const words = field.split("_");

    // 1. Exact match for field
    let index = projectColumns.findIndex((obj) => obj.field === searchStr);
    if (index !== -1) return index;

    // 2. Exact match for headerName
    index = projectColumns.findIndex((obj) => obj.headerName === titleStr);
    if (index !== -1) return index;

    // 3. Case-insensitive match for field
    index = projectColumns.findIndex(
      (obj) => obj.field.toLowerCase() === searchStr.toLowerCase()
    );
    if (index !== -1) return index;

    // 4. Case-insensitive match for headerName
    index = projectColumns.findIndex(
      (obj) => obj.headerName.toLowerCase() === titleStr.toLowerCase()
    );
    if (index !== -1) return index;

    // 5. Match for any word from the snake_case string in field or headerName
    index = projectColumns.findIndex((obj) => {
      const fieldWords = obj.field.toLowerCase().split("_");
      const headerNameWords = obj.headerName.toLowerCase().split(" ");
      return words.some(
        (word) =>
          fieldWords.includes(word.toLowerCase()) ||
          headerNameWords.includes(word.toLowerCase())
      );
    });

    return -1;
  };

  const setup = async () => {
    let campaignColumns = await setupColumns();
    setCampaignLeadColumns(campaignColumns);
    let projectColumns = await getProjectColumnsForMapping();

    const requiredList = projectColumns.filter((item) => item.required);
    const nonRequiredList = projectColumns.filter((item) => !item.required);
    // Sort each list alphabetically
    const sortedRequiredList = requiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    const sortedNonRequiredList = nonRequiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    projectColumns = [...sortedRequiredList, ...sortedNonRequiredList];

    let new_init_list = initCampaignToProjectList(
      campaignColumns,
      projectColumns
    );
    props.setProjectColumnList(projectColumns);
    props.setCampaignToProjectList(new_init_list);
    setProjectColumns(projectColumns);

    setLoading(false);
  };

  const getProjectColumnsForDropdown = () => {
    let tempList = [];
    projectColumns.forEach((column) => {
      let tempObj = {
        label: column.headerName,
        value: column.field,
        isDisabled: isAlreadySelectedProjectField(column["field"]),
        required: column.required,
      };
      tempList.push(tempObj);
    });
    return tempList;
  };

  const getCampaignFieldHeaderName = (field) => {
    let index = campaignLeadColumns.findIndex(
      (column) => column["field"] === field
    );
    if (index !== -1) {
      return campaignLeadColumns[index]["display_name"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setup();
  }, []);

  const setupColumns = async () => {
    let data = [];
    let page_index = 0; //initialize with 0 as we are taking the base index as 0
    while (data.length < props.count) {
      //above count is the state variable which gets set on tab change or first time page load with the first tab
      let response = await getCampaignLeadsListApi({
        campaign_id: props.id,
        page_size: 1000,
        page_no: page_index,
        scope: "submitted",
        filter: "all",
      });
      if (Object.keys(response).length > 0) {
        data = data.concat(response.items);
        page_index++;
      } else {
        break;
      }
    }

    let column = [
      {
        display_name: props.campaignType,
        field: "target",
      },
      { display_name: "Submitted On", field: "submitted_on" },
    ];
    let keys = [];
    data.forEach((item) => {
      let columnItem = {
        ...item,
      };
      if (columnItem.submitted_on !== null) {
        columnItem["submitted_on"] = IsoToLocalTime(columnItem["submitted_on"]);
      }
      delete columnItem["submit_data"];

      if (
        item["submit_data"] !== null &&
        item["submit_data"] !== "" &&
        item["submit_data"] !== undefined
      ) {
        Object.keys(item["submit_data"]).forEach((key) => {
          if (key.toLowerCase() !== "custom_params") {
            columnItem[key] = item["submit_data"][key];
            if (keys.includes(key) === false) {
              keys.push(key);
            }
          }
        });
      }
    });
    keys.forEach((key) => {
      column.push({
        display_name: capitalizeWord(key),
        field: key,
      });
    });
    return column;
  };

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(props.campaignToProjectList).forEach((item) => {
      if (
        props.campaignToProjectList[item]["project_lead"].hasOwnProperty(
          "field"
        ) &&
        props.campaignToProjectList[item]["project_lead"]["field"] === field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  return !loading ? (
    <Container>
      <Header>{getLocalizedText("move_to_project")}</Header>
      <SubHeader>{getLocalizedText("please_fill_the_below_details")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <ColumnWrapper>
        <ColumnHeader>
          {getLocalizedText("campaign_lead_field", "project")}
        </ColumnHeader>
        <ColumnHeader>
          {getLocalizedText("project_lead_field", "project")}
        </ColumnHeader>
      </ColumnWrapper>
      <Wrapper>
        {Object.keys(props.campaignToProjectList).map((item, index) => {
          return (
            <RowWrapper>
              <CheckBoxTextWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.setShowAlert(false);
                        props.setShowRequiredColumnAlert(false);
                        props.setCampaignToProjectList({
                          ...props.campaignToProjectList,
                          [item]: {
                            ...props.campaignToProjectList[item],
                            checked: e.target.checked,
                          },
                        });
                      }}
                      size="small"
                      checked={props.campaignToProjectList[item]["checked"]}
                    />
                  }
                  label={
                    <Label>
                      {getCampaignFieldHeaderName(
                        props.campaignToProjectList[item]["campaign_lead"][
                          "field"
                        ]
                      )}
                    </Label>
                  }
                />
              </CheckBoxTextWrapper>
              <Select
                key={index}
                maxMenuHeight={150}
                value={props.campaignToProjectList[item]["project_lead"]}
                required={props.campaignToProjectList[item]["checked"]}
                onChange={(e) => {
                  if (e === null) {
                    props.setCampaignToProjectList({
                      ...props.campaignToProjectList,
                      [item]: {
                        ...props.campaignToProjectList[item],
                        project_lead: {},
                      },
                    });
                  } else {
                    let index = projectColumns.findIndex(
                      (column) => column["field"] === e.value
                    );

                    props.setCampaignToProjectList({
                      ...props.campaignToProjectList,
                      [item]: {
                        ...props.campaignToProjectList[item],
                        project_lead: {
                          ...e,
                          id: projectColumns[index]["id"],
                          field: projectColumns[index]["field"],
                          display_name: projectColumns[index]["headerName"],
                          data_type: projectColumns[index]["data_type"],
                          is_custom_param:
                            projectColumns[index]["is_custom_param"],
                        },
                      },
                    });
                  }
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={getProjectColumnsForDropdown()}
                closeMenuOnSelect={true}
                isClearable
                styles={customStyles}
                menuShouldScrollIntoView={false}
                formatOptionLabel={(option) => {
                  if (option.required) {
                    return (
                      <Typography sx={{ fontSize: "12px" }}>
                        <span style={{ color: "red" }}>*</span>
                        {option.label}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography sx={{ fontSize: "12px" }}>
                        {option.label}
                      </Typography>
                    );
                  }
                }}
              ></Select>
            </RowWrapper>
          );
        })}
      </Wrapper>
    </Container>
  ) : (
    <Container
      style={{
        height: "420px",
        width: "400px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default MapCampaignLeadColumnToProject;

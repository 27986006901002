import { Drawer } from "@material-ui/core";
import { AddBoxOutlined, Delete, Edit } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { deleteAdminCustomColumnApi, getAdminColumnsApi } from "../../Api";
import { AdminViewColumns } from "../../Db";
import analytics from "../../Analytics";
import AddColumnSidepanel from "../admin_setting_modals/AddColumnSidepanel";
import ViewColumnSidepanel from "../view_column_sidepanel/ViewColumnSidepanel";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import { Box, IconButton, styled, Typography, Tooltip } from "@mui/material";
import CustomAlertMessageDialog from "../alert_dialogue/CustomAlertMessageDialog";
import LoadingAnimationComponent from "../loading_component/LoadingAnimationComponent";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { consoleLogger } from "../../Functions";

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const ColumnsTab = (props) => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openInfoSidepanel, setOpenInfoSidepanel] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [fetchingApi, setFetchingApi] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedColumnID, setSelectedColumnID] = useState("");

  const handleCustomColumnDelete = async () => {
    setShowConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = { column_id: selectedColumnID };
    let response = await deleteAdminCustomColumnApi({
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      // removing row from list instead of refetching
      let tempData = [...row];

      const foundIndex = tempData.findIndex((x) => x.id === selectedColumnID);
      if (foundIndex !== -1) {
        tempData.splice(foundIndex, 1);
      }
      setRow(tempData);
      rootStore.userStore.decrementCustomColumnsNumber();
      setSuccessMessage("Column deleted successfully!");

      setFetchingApi(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
    setSelectedColumnID("");
  };

  const getColumns = async () => {
    setRow([]);
    let columns = [];
    try {
      let response = await getAdminColumnsApi({
        pid: props.id,
        page: 0,
        page_size: 100,
      });
      columns = columns.concat(response.data.items);
      if (response.data.item_count > 100) {
        let numberOfPages = Math.ceil(response.data.item_count / 100);
        for (let i = 1; i < numberOfPages; i++) {
          let response = await getAdminColumnsApi({
            pid: props.id,
            page: i,
            page_size: 100,
          });
          columns = columns.concat(response.data.items);
        }
      }
      //set custom columns number in store
      let currentNumColumns = 0;
      columns.forEach((column) => {
        if (column.is_custom_param) {
          currentNumColumns += 1;
        }
      });
      rootStore.userStore.setCurrentCustomColumnsNumber(currentNumColumns);
      consoleLogger("currCols:: " + rootStore.userStore.customColumnsCount);
      setRow(columns);
    } catch (error) {
      console.log(error);
    }
  };
  const init = async () => {
    await getColumns();
    setLoading(false);
  };
  const refetch = async () => {
    await getColumns();
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
  }, []);
  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      width: 100,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Edit"}>
              <IconButton
                disabled={params.data.child_of !== null}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  analytics.triggerEvent(
                    4625000230,
                    "edit_button_tap",
                    "project_columns_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.data);
                  setIsEdit(true);
                  setOpen(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Delete"}>
              <IconButton
                color="error"
                disabled={params.data.system || params.data.child_of !== null}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedColumnID(params.data.id);
                  setShowConfirmationDialog(true);
                }}
              >
                <Delete
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleAddColumn = () => {
    //allow if current custom columns is less than limit
    if (
      rootStore.subscriptionState.hasReachedMaxCustomColumnsLimit({
        currentTotal: rootStore.userStore.customColumnsCount,
      })
    ) {
      setShowLimitAlert(true);
    } else {
      setOpen(true);
    }
  };
  const defaultColDef = React.useMemo(() => ({
    resizable: true,
  }));

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            {!loading ? (
              <TableWrapper>
                <AddBtnWrapper>
                  <AddBoxOutlined
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000226,
                        "add_button_tap",
                        "project_columns_page",
                        "add_button",
                        {}
                      );
                      handleAddColumn();
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </AddBtnWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={AdminViewColumns.concat(actionColumn)}
                    checkboxSelection={false}
                    animateRows={true}
                    suppressCellFocus
                    pagination={true}
                    paginationPageSize={50}
                    suppressColumnVirtualisation={true}
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      setEditableData(row.data);
                      setOpenInfoSidepanel(true);
                    }}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingAnimationComponent size={"medium"} />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
            setOpen(false);
            setIsEdit(false);
          }}
        >
          <AddColumnSidepanel
            id={props.id}
            setOpen={setOpen}
            open={open}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={editableData}
            postAddCallback={(data) => {
              let tempData = [...row];
              tempData.push(data);
              tempData.sort((a, b) =>
                a["name"].toLowerCase().localeCompare(b["name"].toLowerCase())
              );
              setRow(tempData);
              rootStore.userStore.incrementCustomColumnsNumber(); //incrementing custom column count in store for addons check
            }}
            postEditCallback={(data) => {
              let tempData = [...row];
              var foundIndex = tempData.findIndex((x) => x.id === data.id);
              tempData[foundIndex] = data;
              setRow(tempData);
            }}
            setShowLimitAlert={setShowLimitAlert}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {showLimitAlert && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showLimitAlert}
          handleClose={() => setShowLimitAlert(false)}
          handleCloseOutside={() => setShowLimitAlert(false)}
          message={
            "Action Failed: You've reached the maximum number of custom columns allowed for your account. Please get in touch with support for assistance or to upgrade your plan."
          }
        />
      )}
      {openInfoSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openInfoSidepanel}
          onClose={() => {
            setOpenInfoSidepanel(false);
          }}
        >
          <ViewColumnSidepanel
            data={editableData}
            handleEdit={() => {
              setIsEdit(true);
              setOpen(true);
              setOpenInfoSidepanel(false);
            }}
            handleDelete={() => {
              setSelectedColumnID(editableData.id);
              setShowConfirmationDialog(true);
              setOpenInfoSidepanel(false);
            }}
          />
        </Drawer>
      )}

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this column?"}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          handleConfirm={() => {
            handleCustomColumnDelete();
          }}
        />
      )}
    </>
  );
};

export default ColumnsTab;

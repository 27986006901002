import {
  Box,
  Button,
  IconButton,
  styled,
  Menu,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";
import {
  consoleLogger,
  getLocalizedText,
  IsoToLocalTime,
} from "../../Functions";
import moment from "moment";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AttendanceInfoDropdownComponent from "./AttendanceInfoDropdownComponent";
import AttendanceMarkInComponent from "./AttendanceMarkInComponent";
import AttendanceMarkOutComponent from "./AttendanceMarkOutComponent";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    leadpixieBlue: {
      main: "#21589F",
      light: "#4d79b2",
      dark: "#21589F",
      contrastText: "#fff",
    },
    leadpixieAmber: {
      main: "#edaa38",
      light: "#f0bb5f",
      dark: "#edaa38",
      contrastText: "#fff",
    },
  },
});

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "4px",
  height: "40px",
}));

const AttendanceAppbarControlsComponent = (props) => {
  const attendanceList = [...rootStore.userStore.userAttendanceList];
  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [totalMinutes, setTotalMinutes] = React.useState(0);
  const [details, setDetails] = useState({
    min_time: null,
    max_time: null,
    total_time_in: null,
  });
  const [timer, setTimer] = useState(null);
  const [openMarkInPopup, setOpenMarkInPopup] = useState(false);
  const [openMarkOutPopup, setOpenMarkOutPopup] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let minTime = null;
    let maxTime = null;
    let totalInTime = 0;

    attendanceList.forEach((record) => {
      if (record["attendance_in_time"] !== null) {
        const attendanceInTime = moment(
          IsoToLocalTime(record["attendance_in_time"])
        );
        if (minTime === null || attendanceInTime.isBefore(minTime)) {
          minTime = attendanceInTime;
        }
      }

      if (record["attendance_out_time"] !== null) {
        const attendanceOutTime = moment(
          IsoToLocalTime(record["attendance_out_time"])
        );
        if (maxTime === null || attendanceOutTime.isAfter(maxTime)) {
          maxTime = attendanceOutTime;
        }
      }

      if (record["total_in_time"] !== null) {
        totalInTime += record["total_in_time"];
      }
    });

    setDetails({
      min_time: minTime,
      max_time: maxTime,
      total_time_in: totalInTime,
    });
    consoleLogger("min_time", minTime);
    consoleLogger("max_time", maxTime);
    consoleLogger("totalInTime", totalInTime);
    setLoading(false);

    if (!rootStore.userStore.isLatestAttendanceEmpty()) {
      const latestAttendanceDetails =
        rootStore.userStore.getLatestAttendanceDetails();
      const attendanceInTime = IsoToLocalTime(
        latestAttendanceDetails["attendance_in_time"]
      );
      const elapsed = moment().diff(moment(attendanceInTime), "minutes");
      const updatedTime = elapsed + totalInTime;
      setTotalMinutes(updatedTime);

      const interval = setInterval(() => {
        setTotalMinutes((prevMinutes) => prevMinutes + 1 / 60);
      }, 1000);
      setTimer(interval);
    } else {
      // Stop the timer and set total minutes without count-up
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setTotalMinutes(totalInTime);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [rootStore.userStore.isLatestAttendanceEmpty()]);

  const formatTimeFromMinutes = (totalMinutes) => {
    const duration = moment.duration(totalMinutes, "minutes");
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss") + " hrs";
  };

  const getProgressPercentage = (minTime, maxTime) => {
    if (!minTime) {
      return 0;
    }

    const currentTime = moment();
    let tempMaxTime;

    if (!rootStore.userStore.isLatestAttendanceEmpty()) {
      const minTimeDiff = currentTime.diff(moment(minTime), "hours");
      if (minTimeDiff > 10) {
        tempMaxTime = moment(currentTime).add(2, "hours");
      } else {
        tempMaxTime = moment(currentTime).add(10 - minTimeDiff, "hours");
      }
    } else {
      tempMaxTime = maxTime
        ? moment(maxTime)
        : moment(minTime).add(10, "hours");
    }

    if (currentTime.isBefore(moment(minTime))) {
      return 0;
    } else if (currentTime.isAfter(tempMaxTime)) {
      return 100;
    } else {
      const totalDuration = tempMaxTime.diff(moment(minTime), "seconds");
      const elapsedDuration = currentTime.diff(moment(minTime), "seconds");
      return (elapsedDuration / totalDuration) * 100;
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px 6px",
            }}
          >
            {!loading ? (
              <>
                <IconButton
                  disabled={props.disabled}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                  aria-controls={openMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  sx={{ padding: "8px", borderRadius: "50%" }}
                >
                  <AlarmOnIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openMenu}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      width: "500px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      bgcolor: "#f9f9fc",
                      borderRadius: "0px",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <AttendanceInfoDropdownComponent
                    value={getProgressPercentage(
                      details.min_time,
                      details.max_time
                    )}
                    details={details}
                    totalMinutes={totalMinutes}
                  />
                </Menu>
                {rootStore.userStore.isLatestAttendanceEmpty() ? (
                  <Button
                    disabled={props.disabled}
                    onClick={() => {
                      setOpenMarkInPopup(true);
                    }}
                    onMouseEnter={() => {
                      setHovered(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(false);
                    }}
                    variant={hovered ? "contained" : "outlined"}
                    style={{
                      textTransform: "none",
                      width: "140px",
                      font: " normal 500 14px Poppins",
                      height: "95%",
                    }}
                    color={"leadpixieBlue"}
                  >
                    {hovered ? (
                      <>
                        <LoginIcon
                          sx={{ marginRight: "4px", fontSize: "16px" }}
                        />{" "}
                        {getLocalizedText("punch_in")}
                      </>
                    ) : (
                      formatTimeFromMinutes(totalMinutes)
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={props.disabled}
                    onClick={() => {
                      setOpenMarkOutPopup(true);
                    }}
                    variant={hovered ? "contained" : "outlined"}
                    onMouseEnter={() => {
                      setHovered(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(false);
                    }}
                    style={{
                      textTransform: "none",
                      width: "140px",
                      font: " normal 500 14px Poppins",
                      height: "95%",
                    }}
                    color={"leadpixieAmber"}
                  >
                    {hovered ? (
                      <>
                        <LogoutIcon
                          sx={{ marginRight: "4px", fontSize: "16px" }}
                        />{" "}
                        {getLocalizedText("punch_out")}
                      </>
                    ) : (
                      formatTimeFromMinutes(totalMinutes)
                    )}
                  </Button>
                )}
              </>
            ) : (
              <CircularProgress size="30px" />
            )}
          </Box>
          <>
            {openMarkInPopup && (
              <AttendanceMarkInComponent
                open={openMarkInPopup}
                setOpen={setOpenMarkInPopup}
                isMandatory={false}
              />
            )}
            {openMarkOutPopup && (
              <AttendanceMarkOutComponent
                open={openMarkOutPopup}
                setOpen={setOpenMarkOutPopup}
              />
            )}
          </>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default observer(AttendanceAppbarControlsComponent);

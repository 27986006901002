import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import { MenuItem, Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { leadSubmitApi } from "../../Api";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const SubmitLeadModal = (props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("");
  const [typeName, setTypeName] = useState("");
  const [dataList, setDataList] = useState([]);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const stageStatusList = [...rootStore.authStore.stageStatusList];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      submit_to: {
        type: typeName,
        id: selectedValue,
      },
    };
    let response = await leadSubmitApi({
      leadID: props.id,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefreshLeadDetails(true);
      setIsSubmitSuccess(true);
    }
  };

  const init = () => {
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_stage"]["stage"] === props.leadData["lead_stage"] &&
        item["lead_status"]["status"] === props.leadData["lead_status"]
    );
    if (index !== -1) {
      let submit_to_list = stageStatusList[index]["submit_to"];
      if (submit_to_list.length > 0) {
        let type = submit_to_list[0]["type"];
        setTypeName(type);
        setDataList(submit_to_list);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);
  const handleClose = () => {
    props.setOpen(false);
  };

  const getLabelName = () => {
    if (typeName === "user") {
      return "Select User";
    } else if (typeName === "team") {
      return "Select Team";
    }
    return "";
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>
                      {getLocalizedText("submit_lead", "project")}
                    </Header>
                    <SubHeader>
                      {getLocalizedText(
                        "submit_this_lead_to_team_or_user",
                        "project"
                      )}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>{getLabelName()}</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="stage"
                            displayEmpty
                            variant="outlined"
                            required
                            value={selectedValue}
                            onChange={(e) => {
                              setSelectedValue(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {dataList.map((e) => {
                              return (
                                <MenuItem id={e.id} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          props.setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {}}
                        type="submit"
                        variant="contained"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>
                {getLocalizedText("lead_submitted_successfully", "project")}
              </Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default SubmitLeadModal;

import React from "react";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Grid, Modal } from "@material-ui/core";

import { consoleLogger, getLocalizedText } from "../../Functions.js";
import WhatsappTextMessagePreview from "../whatsapp_templates_page_components/message_preview/WhatsappTextMessagePreview.js";

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "16px",
  width: "600px",
  maxHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
}));
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));
const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  boxSizing: "border-box",
  overflow: "auto",
  maxHeight: "60vh",
  flexShrink: 0,
}));
const PreviewHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
}));

function WhatsappPersonalTemplateSendPopup(props) {
  consoleLogger("data::", props.data);

  const handleSubmit = () => {
    const encodedTextData = encodeURIComponent(props.data.text);

    if (props.data.lead_phone !== "") {
      window.open(
        `https://wa.me/${props.data.lead_phone}/?text=${encodedTextData}`,
        "_blank"
      );
    } else {
      window.open(`https://wa.me/?text=${encodedTextData}`, "_blank");
    }

    handleClose();
  };

  const handleClose = () => {
    props.setPersonalWhatsappData({});
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            top: "-16px",
            bottom: "auto",
            width: "100%",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ height: "50px" }}
            >
              <PreviewHeader>{getLocalizedText("send_template")}</PreviewHeader>
            </Grid>
          </Toolbar>
        </AppBar>
        <Wrapper
          component={"form"}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <PreviewWrapper>
            <WhatsappTextMessagePreview text={props.data.text} />
          </PreviewWrapper>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ bottom: 0, width: "100%", height: 60 }}
          >
            <Toolbar
              style={{
                padding: "0px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("send")}
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Wrapper>
      </ModelContainer>
    </Modal>
  );
}

export default WhatsappPersonalTemplateSendPopup;

import { Box, Button, Divider, Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { markUnmarkMultipleLeadAsPriorityApi } from "../../Api";
import analytics from "../../Analytics";
import moment from "moment";
import Select from "react-select";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  column-gap: 10px;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const PriorityIndividualLeadDialog = (props) => {
  const [timeSlot, setTimeSlot] = useState(null);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [todaySlots, setTodaySlots] = useState([]);
  const [tomorrowSlots, setTomorrowSlots] = useState([]);

  const submitLeadsAsPriority = async () => {
    let date_time_string = generateISODateTimeByTimeSlot(timeSlot);
    let payload = {
      status: true,
      hard_update_by: date_time_string,
      lead_ids: props.selectedLeadIdsData,
    };
    let response = await markUnmarkMultipleLeadAsPriorityApi({
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (timeSlot !== null) {
      await submitLeadsAsPriority();
    } else {
      setErrorMessage("Kindly pick time slot before submitting the form.");
      setIsSubmitFail(true);
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const generateISODateTimeByTimeSlot = (timeSlot) => {
    let dateTimeIsoString = null;
    if (timeSlot["type"] === "today") {
      dateTimeIsoString = moment(timeSlot["value"], "h:mm A").toISOString();
    } else if (timeSlot["type"] === "tomorrow") {
      dateTimeIsoString = moment(timeSlot["value"], "h:mm A")
        .add(1, "day")
        .toISOString();
    }
    return dateTimeIsoString;
  };

  const generateTimeSlots = () => {
    let currentTime = moment();
    let nextHour = moment().add(1, "hour").startOf("hour");

    // Adjust start time
    if (nextHour.diff(currentTime, "minutes") < 30) {
      currentTime = nextHour.add(1, "hour").startOf("hour");
    } else {
      currentTime = nextHour;
    }

    const todaySlots = [];
    const tomorrowSlots = [];
    const windowEnd = currentTime.clone().add(24, "hours");

    let currentTimeSlot = currentTime.clone();

    while (currentTimeSlot.isSameOrBefore(windowEnd)) {
      const formattedTime = currentTimeSlot.format("h:mm A");

      if (currentTimeSlot.isSame(moment(), "day")) {
        todaySlots.push(formattedTime);
      } else if (currentTimeSlot.isSame(moment().add(1, "day"), "day")) {
        tomorrowSlots.push(formattedTime);
      }

      currentTimeSlot.add(30, "minutes");
    }

    // Handle overlap between first slot of today and last slot of tomorrow
    if (
      todaySlots.length > 0 &&
      tomorrowSlots.length > 0 &&
      todaySlots[0] === tomorrowSlots[tomorrowSlots.length - 1]
    ) {
      tomorrowSlots.pop(); // Remove last slot from tomorrow
    }

    return {
      todaySlots,
      tomorrowSlots,
    };
  };
  const init = () => {
    let generatedTimeSlots = generateTimeSlots();
    let tempTodaySlots = [];
    let tempTomorrowSlots = [];
    generatedTimeSlots["todaySlots"].forEach((slot) => {
      tempTodaySlots.push({ label: slot, value: slot, type: "today" });
    });

    generatedTimeSlots["tomorrowSlots"].forEach((slot) => {
      tempTomorrowSlots.push({ label: slot, value: slot, type: "tomorrow" });
    });
    setTodaySlots(tempTodaySlots);
    setTomorrowSlots(tempTomorrowSlots);
  };

  useEffect(() => {
    init();
  }, []);

  const getOptionsToRender = () => {
    let tempGroupSlotsList = [{ label: "Today", options: todaySlots }];
    if (tomorrowSlots.length > 0) {
      tempGroupSlotsList.push({ label: "Tomorrow", options: tomorrowSlots });
    }
    return tempGroupSlotsList;
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>Mark as Priority</Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form component={"form"} onSubmit={handleUpdate}>
                  <Box
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>Process by</Label>
                          <Select
                            menuPosition="fixed"
                            options={getOptionsToRender()}
                            value={timeSlot}
                            onChange={(selectedOption) => {
                              setTimeSlot(selectedOption);
                            }}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            isClearable
                          />
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        {isSubmitFail && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setIsSubmitFail(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </ErrorAlert>
                        )}
                        <Button
                          onClick={() => {
                            props.setOpen(false);
                          }}
                          variant="contained"
                          color="default"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          onClick={() => {}}
                          type="submit"
                          variant="contained"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                            ...myTheme.Button.btnBlue,
                          }}
                        >
                          Update
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>
                {getLocalizedText(
                  "leads_successfully_marked_as_priority",
                  "project"
                )}
              </Message>

              <Button
                onClick={() => {
                  analytics.triggerEvent(
                    4625000259,
                    "close_button_tap",
                    "update_stage_form",
                    "close_button",
                    { order: props.scope }
                  );
                  handleClose();
                }}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default PriorityIndividualLeadDialog;

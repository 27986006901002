import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useEffect } from "react";
import { DatePicker } from "antd";
import { myTheme } from "../../themeUtils";
import { consoleLogger, getLocalizedText } from "../../Functions";
import { Alert } from "@mui/material";
import { LeadFilterTypeList } from "../../Config";
import { createRawLeadJobApi, getRawLeadsColumnsApi } from "../../Api";
import ExportRawLeadFilterScreen from "./export_raw_lead_screens/ExportRawLeadFilterScreen";
import { MultiSelect } from "react-multi-select-component";
import analytics from "../../Analytics";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 320px;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const Input = styled.input`
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Message = styled.p`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  margin-bottom: 12px;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const ExportRawLeadModal = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    title: "",
  });

  const { RangePicker } = DatePicker;
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [rawColumnsList, setRawColumnsList] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [stepCount, setStepCount] = useState(0);
  const [dateRangeVal, setDateRangeVal] = useState(null);
  const [selectedVal, setSelectedVal] = useState({});
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [numRange, setNumRange] = useState({});
  const [showDateRangeAlert, setShowDateRangeAlert] = useState(false);
  const [showSelectColumnAlert, setShowSelectColumnAlert] = useState(false);
  const [leadFilterType, setLeadFilterType] = useState("All Leads");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const validateDateRange = () => {
    if (leadFilterType !== "All Leads") {
      return dateRangeVal !== null;
    }
    return true;
  };
  const handleNext = () => {
    setStepCount(stepCount + 1);
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };
  const generateFilerPayload = () => {
    let new_filter_payload = { ...selectedVal };
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let new_date = new Date(filters[key]["value"]);
          new_date = new_date.toISOString();
          new_filter_payload[key] = new_date;
        } else if (filters[key]["type"] === "integer") {
          let new_value = parseInt(filters[key]["value"]);
          new_filter_payload[key] = new_value;
        } else {
          new_filter_payload[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = new Date(dateRange[key]["start"]);
        d1 = d1.toISOString();
        let d2 = new Date(dateRange[key]["end"]);
        d2 = d2.toISOString();
        new_filter_payload[key] = {
          start: d1,
          end: d2,
        };
      });
    }
    if (Object.keys(numRange).length !== 0) {
      Object.keys(numRange).map((key) => {
        let min_value = parseInt(numRange[key]["start"]);
        let max_value = parseInt(numRange[key]["end"]);

        new_filter_payload[key] = {
          start: min_value,
          end: max_value,
        };
      });
    }

    return new_filter_payload;
  };
  const exportRawLead = async () => {
    let payload = { ...formData };
    let filter_payload = generateFilerPayload();
    let input = {
      filter: filter_payload,
      leads_date_filter_type: leadFilterType,
    };
    let column_list = [];
    selectedColumns.forEach((option) => {
      column_list.push(option.value);
    });

    input["columns"] = column_list;

    if (leadFilterType !== "All Leads") {
      let start_date = new Date(dateRangeVal["start_date"]);
      start_date = start_date.toISOString();
      let end_date = new Date(dateRangeVal["end_date"]);
      end_date = end_date.toISOString();
      input["start_date"] = start_date;
      input["end_date"] = end_date;
    }

    payload["input"] = input;

    let response_flag = await createRawLeadJobApi({ data: payload });
    if (response_flag) {
      setIsSubmitFailed(false);
      setIsFormSubmitted(true);
    } else {
      setIsSubmitFailed(true);
      setIsFormSubmitted(true);
    }

    consoleLogger("payload for export rawlead::::", JSON.stringify(payload));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (stepCount === 0) {
      if (validateDateRange()) {
        setShowDateRangeAlert(false);
        if (selectedColumns.length > 0) {
          setShowSelectColumnAlert(false);
          handleNext();
        } else {
          setShowSelectColumnAlert(true);
        }
      } else {
        setShowDateRangeAlert(true);
      }
    } else {
      exportRawLead();
    }
  };

  const updateColumnsList = (list) => {
    let newList = [];
    list.map((e) => {
      let newObj = { label: e["display_name"], value: e["column_name"] };
      newList.push(newObj);
    });
    return newList;
  };

  const getRawLeadColumns = async () => {
    let response = await getRawLeadsColumnsApi();
    setRawColumnsList([...response]);
    let updated_columns = updateColumnsList([...response]);
    setColumnsList(updated_columns);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000153,
      "export_raw_leads_form_load",
      "exports_tab",
      "",
      {}
    );
    getRawLeadColumns();
  }, []);

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              {!isFormSubmitted ? (
                <Form onSubmit={handleSubmit}>
                  <Header>{getLocalizedText("export_raw_leads","project")}</Header>

                  {(() => {
                    if (stepCount === 0) {
                      return (
                        <>
                          <SubHeader>
                            {getLocalizedText("fill_in_the_below_details")}
                          </SubHeader>
                          <Divider style={{ margin: "10px 0px" }} />
                          <InputContainer>
                            <InputWrapper style={{ marginTop: "12px" }}>
                              <Label>{getLocalizedText("title")}*</Label>
                              <Input
                                required
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                              />
                            </InputWrapper>
                            <SelectColumn>
                              <Label>
                                {getLocalizedText("select_leads_filter_type","project")}
                              </Label>
                              <FormControl
                                sx={{ m: 1, minWidth: 400 }}
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <Select
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  value={leadFilterType}
                                  displayEmpty
                                  disableUnderline
                                  variant="outlined"
                                  onChange={(e) => {
                                    setShowDateRangeAlert(false);
                                    setShowSelectColumnAlert(false);
                                    setLeadFilterType(e.target.value);
                                    setDateRangeVal(null);
                                  }}
                                  required
                                  style={{
                                    marginBottom: "20px",
                                    width: "400px",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                  }}
                                >
                                  {LeadFilterTypeList.map((e, i) => {
                                    return (
                                      <MenuItem id={i} value={e.label}>
                                        {e.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </SelectColumn>

                            <InputWrapper style={{ marginBottom: "20px" }}>
                              <Label>
                                {getLocalizedText("select_date_range")}
                              </Label>
                              <RangePicker
                                disabledDate={disabledDate}
                                popupClassName="export_raw_lead_daterange"
                                disabled={leadFilterType === "All Leads"}
                                placeholder={["From", "To"]}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                value={
                                  dateRangeVal && [
                                    moment(
                                      dateRangeVal["start_date"],
                                      "YYYY-MM-DD"
                                    ),
                                    moment(
                                      dateRangeVal["end_date"],
                                      "YYYY-MM-DD"
                                    ),
                                  ]
                                }
                                defaultValue={
                                  dateRangeVal && [
                                    moment(
                                      dateRangeVal["start_date"],
                                      "YYYY-MM-DD"
                                    ),
                                    moment(
                                      dateRangeVal["end_date"],
                                      "YYYY-MM-DD"
                                    ),
                                  ]
                                }
                                style={{ height: "30px", width: "400px" }}
                                onChange={(value) => {
                                  let d1 = moment(value[0]).format(
                                    "YYYY-MM-DD"
                                  );
                                  let d2 = moment(value[1]).format(
                                    "YYYY-MM-DD"
                                  );
                                  setDateRangeVal({
                                    ...dateRangeVal,
                                    start_date: d1,
                                    end_date: d2,
                                  });
                                }}
                              />
                            </InputWrapper>
                            <InputWrapper style={{ marginBottom: "20px" }}>
                              <Label>
                                {getLocalizedText("select_columns")}
                              </Label>
                              <MultiSelect
                                hasSelectAll={true}
                                options={columnsList}
                                value={selectedColumns}
                                className="job_multi_select"
                                onChange={(e) => {
                                  setShowSelectColumnAlert(false);
                                  setSelectedColumns(e);
                                }}
                                labelledBy="Select Columns"
                              />
                            </InputWrapper>
                          </InputContainer>
                        </>
                      );
                    } else if (stepCount === 1) {
                      return (
                        <>
                          <SubHeader>
                            {getLocalizedText("select_filters")}
                          </SubHeader>
                          <Divider style={{ margin: "10px 0px" }} />
                          <ExportRawLeadFilterScreen
                            columnsList={rawColumnsList}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            numRange={numRange}
                            setNumRange={setNumRange}
                            selectedVal={selectedVal}
                            setSelectedVal={setSelectedVal}
                            filters={filters}
                            setFilters={setFilters}
                          />
                        </>
                      );
                    }
                  })()}
                  {showDateRangeAlert && (
                    <Alert
                      style={{
                        width: "100%",
                        padding: "0px 20px",
                      }}
                      severity="warning"
                    >
                      {getLocalizedText("please_select_a_date_range")}
                    </Alert>
                  )}

                  {showSelectColumnAlert && (
                    <Alert
                      style={{
                        width: "100%",
                        padding: "0px 20px",
                      }}
                      severity="warning"
                    >
                      {getLocalizedText("please_select_atleast_one_column")}
                    </Alert>
                  )}

                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        if (stepCount === 1) {
                          analytics.triggerEvent(
                            4625000156,
                            "previous_button_tap",
                            "export_raw_leads_form",
                            "previous_button",
                            {}
                          );
                          handlePrev();
                        } else {
                          analytics.triggerEvent(
                            4625000154,
                            "cancel_button_tap",
                            "export_raw_leads_form",
                            "cancel_button",
                            {}
                          );
                          handleClose();
                        }
                      }}
                      type="button"
                      variant="outlined"
                      style={{
                        textTransform: "none",
                        marginRight: "20px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100px",
                        ...myTheme.Button.btnLight,
                      }}
                    >
                      {stepCount === 1
                        ? getLocalizedText("prev")
                        : getLocalizedText("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        if (stepCount === 0) {
                          analytics.triggerEvent(
                            4625000155,
                            "next_button_tap",
                            "export_raw_leads_form",
                            "next_button",
                            {}
                          );
                        } else {
                          analytics.triggerEvent(
                            4625000157,
                            "submit_button_tap",
                            "export_raw_leads_form",
                            "submit_button",
                            {}
                          );
                        }
                      }}
                      color="primary"
                      type="submit"
                      variant="contained"
                      // id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      {stepCount === 0
                        ? getLocalizedText("next")
                        : getLocalizedText("submit")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              ) : (
                <>
                  {!isSubmitFailed ? (
                    <MessageWrapper>
                      <Message style={{ textAlign: "center" }}>
                        {getLocalizedText(
                          "export_job_has_been_created_successfully_please_check_the_status_in_the_export_listing"
                        )}
                      </Message>
                      <Button
                        variant="text"
                        onClick={() => {
                          props.setRefresh(true);
                          props.setOpen(false);
                        }}
                        style={{
                          width: "fit-content",
                          fontSize: "12px",
                          fontWeight: "bold",
                          margin: "auto",

                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        {getLocalizedText("ok")}
                      </Button>
                    </MessageWrapper>
                  ) : (
                    <MessageWrapper>
                      <Message style={{ textAlign: "center" }}>
                        {getLocalizedText("something_went_wrong")}
                      </Message>
                      <Button
                        variant="text"
                        onClick={() => {
                          props.setOpen(false);
                        }}
                        style={{
                          width: "fit-content",
                          fontSize: "12px",
                          fontWeight: "bold",
                          margin: "auto",

                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        {getLocalizedText("ok")}
                      </Button>
                    </MessageWrapper>
                  )}
                </>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ExportRawLeadModal);

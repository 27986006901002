import {
  CircularProgress,
  Divider,
  InputLabel,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  createLeadForProject,
  deleteLeadApi,
  getAdminProjectApi,
  getLeadProfileApi,
  getMembersToAssignByProject,
} from "../../Api";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { myTheme } from "../../themeUtils";
import { v4 as uuid } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import {
  consoleLogger,
  getLocalizedText,
  isLeadDeleteProtected,
  isNumber,
  isValidDateTime,
} from "../../Functions";
import MapProjectLeadToProject from "./MapProjectLeadToProject";
import rootStore from "../../stores/RootStore";
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const Message = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  margin-bottom: 10px;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 30px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const MoveProjectLeadModal = (props) => {
  const [project, setProject] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [member, setMember] = useState("");
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creatingLeads, setCreatingLeads] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [currToDestProjectList, setCurrToDestProjectList] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showRequiredColumnAlert, setShowRequiredColumnAlert] = useState(false); //this state variable is to track the required columns filled or not
  const [projectColumnList, setDestProjectColumnList] = useState([]);
  const [deleteSourceLead, setDeleteSourceLead] = useState(false);

  const responseColumnHeader = [
    {
      field: "target",
      headerName: <Text>{"Lead ID"}</Text>,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.target}>
              <span className="csutable-cell-trucate">
                {params.row.target !== null ? params.row.target : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: <Text>Status</Text>,
      sortable: false,

      width: 100,

      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.status}>
              <span className="csutable-cell-trucate">
                {params.row.status !== null ? params.row.status : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "message",
      headerName: <Text>Message</Text>,
      sortable: false,

      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.message}>
              <span className="csutable-cell-trucate">
                {params.row.message !== null ? params.row.message : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const leadDeletedColumn = [
    {
      field: "deleted",
      headerName: <Text>{"Deleted"}</Text>,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return params.row.deleted ? "Yes" : "No";
      },
    },
  ];

  const moveRecordsToProject = async () => {
    setShowConfirmMessage(false);
    setCreatingLeads(true);
    let responseData = [];
    let createdLeads = [];
    for (const leadID of props.leads) {
      let response = await getLeadProfileApi({ leadID: leadID });
      if (!response.hasError()) {
        let lead = response.data.lead;
        console.log(lead);
        let regularData = {
          lead_owner_id: member,
        };
        let customData = {};
        let hasParseError = false;

        for (const item in currToDestProjectList) {
          if (currToDestProjectList[item]["checked"]) {
            let current_project =
              currToDestProjectList[item]["current_project"];
            let dest_project = currToDestProjectList[item]["dest_project"];
            let value = "";
            if (current_project["is_custom_param"]) {
              let field_name = current_project["field"].replace(
                "custom_params_",
                ""
              );

              let leadCustomData = lead.hasOwnProperty("custom_params")
                ? JSON.parse(lead["custom_params"])
                : {};
              value = leadCustomData[field_name] || null;
            } else {
              value = lead[current_project["field"]] || null;
            }

            if (value !== null) {
              if (
                dest_project["data_type"] === "date" ||
                dest_project["data_type"] === "datetime"
              ) {
                if (isValidDateTime(value) === false) {
                  hasParseError = true;
                  consoleLogger(
                    `data mismatch ::: date ::: ${value} :::  ${dest_project["field"]} , ${current_project["field"]}`
                  );
                  break;
                }
              } else if (dest_project["data_type"] === "integer") {
                if (isNumber(value) === false) {
                  hasParseError = true;
                  consoleLogger(
                    `data mismatch ::: integer ::: ${value} :::  ${dest_project["field"]} , ${current_project["field"]}`
                  );

                  break;
                }
              }
              if (dest_project["is_custom_param"]) {
                let field_name = dest_project["field"].replace(
                  "custom_params_",
                  ""
                );

                customData[field_name] = value;
              } else {
                regularData[dest_project["field"]] = value;
              }
            }
          }
        }
        consoleLogger(`regular data ::: ${JSON.stringify(regularData)}`);
        consoleLogger(`custom data ::: ${JSON.stringify(customData)}`);
        if (hasParseError) {
          let tempObj = {
            status: "failure",
            target: lead["flid"],
            message: getLocalizedText(
              "error_creating_lead_in_the_project",
              "project"
            ),
          };
          if (deleteSourceLead) {
            tempObj["deleted"] = false;
          }
          responseData.push(tempObj);
        } else {
          let createLeadPayload = {
            contact_id: lead["contact_id"],
            lead_data: {
              ...regularData,
              custom_params: customData,
            },
          };
          let response = await createLeadForProject({
            pid: project,
            data: createLeadPayload,
          });
          if (response.hasError()) {
            let tempObj = {
              status: "failure",
              target: lead["flid"],
              message: getLocalizedText(
                "error_creating_lead_in_the_project",
                "project"
              ),
            };
            if (deleteSourceLead) {
              tempObj["deleted"] = false;
            }
            responseData.push(tempObj);
          } else {
            let tempObj = {
              status: "success",
              target: lead["flid"],
              message: getLocalizedText(
                "lead_created_successfully_in_the_project",
                "project"
              ),
            };
            if (deleteSourceLead) {
              if (isLeadDeleteProtected({ leadData: lead })) {
                tempObj["deleted"] = false;
                consoleLogger(`lead is delete protected.`);
              } else {
                let deleteLeadResponse = await deleteLeadApi({
                  leadID: leadID,
                });
                if (deleteLeadResponse.hasError()) {
                  tempObj["deleted"] = false;
                  consoleLogger(`delete lead api failed.`);
                } else {
                  tempObj["deleted"] = true;
                  consoleLogger(`lead is deleted.`);
                }
              }
            }
            responseData.push(tempObj);
            createdLeads.push(lead["id"]);
          }
        }
      }
    }

    setResponseData(responseData);
    setCreatingLeads(false);
    setIsSubmit(true);

    let tempMapping = {};
    Object.keys(currToDestProjectList).forEach((key) => {
      if (currToDestProjectList[key]["checked"]) {
        tempMapping[key] = currToDestProjectList[key]["dest_project"]["field"];
      }
    });
    rootStore.userStore.updateProjectToProjectColumnMapping({
      destProjectID: project,
      mapping: tempMapping,
    });
  };

  const handleAssign = async (e) => {
    e.preventDefault();
    if (counter === 1) {
      if (isAtleastOneColumnMapped()) {
        setShowAlert(false);
        if (allProjectRequiredColumnsFilled()) {
          setShowRequiredColumnAlert(false);
          setShowConfirmMessage(true);
        } else {
          setShowRequiredColumnAlert(true);
        }
      } else {
        setShowAlert(true);
      }
    } else {
      setCounter(counter + 1);
      setShowAlert(false);
      setShowRequiredColumnAlert(false);
    }
  };
  const getProjectMemberList = async (id) => {
    try {
      let response = await getMembersToAssignByProject(id);
      setMemberList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async () => {
    try {
      let response = await getAdminProjectApi();
      let filteredProjects = response.data.filter((e) => e.id !== props.pid);
      setProjectList(filteredProjects);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleProject = (event) => {
    setMemberList([]);
    getProjectMemberList(event.target.value);
    setMember("");
    setProject(event.target.value);
  };

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(currToDestProjectList).forEach((item) => {
      if (currToDestProjectList[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to check if all the required field of the selected project are mapped or not
  const allProjectRequiredColumnsFilled = () => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    const checkedCurrToDestProjectList = Object.keys(currToDestProjectList)
      .filter((key) => currToDestProjectList[key].checked === true)
      .reduce((result, key) => {
        result[key] = currToDestProjectList[key];
        return result;
      }, {});

    let flag = required_columns_list.every((item) => {
      return Object.values(checkedCurrToDestProjectList).some(
        (obj) => obj["dest_project"]["id"] === item.id
      );
    });

    return flag;
  };

  const getProjectName = () => {
    let index = projectList.findIndex((e) => e.id === project);
    if (index !== -1) {
      return projectList[index]["name"];
    } else {
      return "";
    }
  };
  useEffect(() => {
    setLoading(true);
    getProjects();
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {creatingLeads || loading ? (
            <Box
              style={{
                height: "200px",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {creatingLeads && (
                <Message
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  {`Moving ${getLocalizedText("leads", "project")}...`}
                </Message>
              )}
              <CircularProgress />
            </Box>
          ) : !isSubmit ? (
            <Box>
              <ModelWrapper component={"form"} onSubmit={handleAssign}>
                {counter === 0 ? (
                  <>
                    <Header>{getLocalizedText("move_to_project")}</Header>
                    <SubHeader>
                      {getLocalizedText("select_from_below_dropdown")}
                    </SubHeader>
                    <Divider style={{ margin: "10px 0px" }} />

                    <Row>
                      <InputWrapper>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_project")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={project}
                          label="Select Member"
                          required
                          onChange={handleProject}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {projectList.length > 0 &&
                            projectList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_member")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={member}
                          required
                          label="Select Member"
                          onChange={(e) => {
                            setMember(e.target.value);
                          }}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {memberList.length > 0 &&
                            memberList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </InputWrapper>
                    </Row>
                  </>
                ) : (
                  <MapProjectLeadToProject
                    projectId={project}
                    currToDestProjectList={currToDestProjectList}
                    setCurrToDestProjectList={setCurrToDestProjectList}
                    setShowAlert={setShowAlert}
                    setDestProjectColumnList={setDestProjectColumnList}
                    setShowRequiredColumnAlert={setShowRequiredColumnAlert}
                    deleteSourceLead={deleteSourceLead}
                    setDeleteSourceLead={setDeleteSourceLead}
                  />
                )}

                {showAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 10px",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText(
                      "please_map_atleast_one_column_to_proceed"
                    )}
                  </Alert>
                )}

                {showRequiredColumnAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 10px",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText(
                      "all_required_fields_for_lead_creation_are_not_mapped",
                      "project"
                    )}
                  </Alert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (counter === 0) {
                        props.setOpen(false);
                      } else {
                        setCounter(counter - 1);
                        setShowAlert(false);
                        setShowRequiredColumnAlert(false);
                      }
                    }}
                    variant="contained"
                    color="inherit"
                    style={{
                      width: "100%",
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {counter === 0
                      ? getLocalizedText("cancel")
                      : getLocalizedText("prev")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (counter === 0) {
                      } else {
                      }
                    }}
                    type="submit"
                    id="assign-btn"
                    variant="contained"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "100%",
                      textTransform: "none",
                    }}
                  >
                    {counter === 0 ? getLocalizedText("next") : "Move"}
                  </Button>
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          ) : responseData.length > 0 ? (
            <Box style={{ width: "600px" }}>
              <Column style={{ rowGap: "20px" }}>
                <Header>{getLocalizedText("result")}</Header>
                <div
                  style={{
                    width: "100%",
                    maxHeight: "40vh",
                    overflowY: "auto",
                  }}
                >
                  <DataGrid
                    getRowId={() => uuid() + Math.random()}
                    autoHeight={true}
                    rows={responseData}
                    columns={
                      deleteSourceLead
                        ? responseColumnHeader.concat(leadDeletedColumn)
                        : responseColumnHeader
                    }
                    checkboxSelection={false}
                    style={{ cursor: "pointer" }}
                    hideFooter={true}
                    disableColumnMenu={true}
                  />
                </div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  style={{
                    width: "fit-content",
                    ...myTheme.Button.btnLight,

                    fontSize: "12px",
                    fontWeight: "bold",
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </Column>
            </Box>
          ) : (
            <Box>
              <MessageWrapper>
                <Message
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  {getLocalizedText("no_response_data_to_show")}
                </Message>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  color="inherit"
                  style={{
                    width: "fit-content",

                    fontSize: "12px",
                    fontWeight: "bold",
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </Box>
          )}
        </ModelContainer>
      </Modal>

      <Dialog
        disableEnforceFocus
        open={showConfirmMessage}
        onClose={() => {
          setShowConfirmMessage(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
          {getLocalizedText("alert")}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            style={{ fontWeight: "bold" }}
            id="alert-dialog-description"
          >
            {`Are you sure you want to move the records to the project "${getProjectName()}" ?`}
          </DialogContentText>
        </DialogContent>
        {
          <DialogActions>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={() => {
                setShowConfirmMessage(false);
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={moveRecordsToProject}
              autoFocus
            >
              {getLocalizedText("continue")}
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
};

export default observer(MoveProjectLeadModal);

import { Button, Modal } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import { Box, styled } from "@mui/material";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ColorPickerModalV2 = ({ color, handlePostApply, open, handleClose }) => {
  const [selectedColor, setSelectedColor] = useState("#000000");

  const handleColorChange = (newColor) => {
    setSelectedColor(newColor.hex);
  };
  useEffect(() => {
    if (color !== "") {
      setSelectedColor(color);
    }
  }, []);
  return (
    <Modal open={open} onClose={handleClose} disableScrollLock={true}>
      <ModalContainer>
        <SketchPicker
          color={selectedColor}
          onChange={handleColorChange}
          disableAlpha={false}
        />
        <ButtonWrapper>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              backgroundColor: "#EFEFF4",
              textTransform: "none",
              marginRight: "20px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("cancel")}
          </Button>
          <Button
            onClick={() => {
              handlePostApply(selectedColor);
            }}
            type="button"
            variant="contained"
            id="call-log-btn"
            style={{
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              ...myTheme.Button.btnBlue,
            }}
          >
            {getLocalizedText("apply")}
          </Button>
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default ColorPickerModalV2;

import {
  Box,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  Typography,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { ExpandMore } from "@material-ui/icons";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import { indianStatesList, quotationCountriesList } from "../../../Db";
import { Rectangle } from "@mui/icons-material";
import { getLocalizedText } from "../../../Functions";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;

const NilColorBox = styled(Box)`
  position: relative;
  width: 25px;
  height: 20px;
  background-color: white;
  border: 1px solid #bfbfbf;
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 125%;
    height: 100%;
    border-top: 1px solid #bfbfbf;
    transform: rotate(37deg);
    transform-origin: top left;
  }
`;

const ColorBox = styled(Box)`
  position: relative;
  width: 25px;
  height: 20px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
`;

const AdditionalInformationScreen = ({
  companyDetails,
  handleChange,
  billingDetails,
  handleBillingDetails,
  additionalDetails,
  handleAdditionalDetails,
  postClickCallback,
  handleBillingVariables,
  addressConfig,
  styleDetails,
  handleStyleDetails,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const accordionDetailsRef = useRef(null);

  const handleAccordion = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="styles-content"
              id="styles-header"
            >
              {getLocalizedText("styles")}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    component={"fieldset"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      border: "1px solid #cccccc",
                      padding: "12px",
                      paddingTop: "6px ",
                    }}
                  >
                    <legend
                      style={{
                        width: "auto",
                        marginBottom: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {getLocalizedText("table_header")}
                    </legend>
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <InputWrapper
                          style={{
                            flexDirection: "row",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <Label>
                            {getLocalizedText("background_color")} :
                          </Label>
                          {styleDetails["table_header"]["background_color"] !==
                          "" ? (
                            <ColorBox
                              onClick={() =>
                                handleStyleDetails(
                                  "table_header",
                                  "background_color"
                                )
                              }
                              style={{
                                backgroundColor:
                                  styleDetails["table_header"][
                                    "background_color"
                                  ],
                              }}
                            />
                          ) : (
                            <NilColorBox
                              onClick={() =>
                                handleStyleDetails(
                                  "table_header",
                                  "background_color"
                                )
                              }
                            />
                          )}
                        </InputWrapper>
                      </Grid>
                      <Grid item>
                        <InputWrapper
                          style={{
                            flexDirection: "row",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <Label>{getLocalizedText("text_color")} :</Label>

                          {styleDetails["table_header"]["text_color"] !== "" ? (
                            <ColorBox
                              onClick={() =>
                                handleStyleDetails("table_header", "text_color")
                              }
                              style={{
                                backgroundColor:
                                  styleDetails["table_header"]["text_color"],
                              }}
                            />
                          ) : (
                            <NilColorBox
                              onClick={() =>
                                handleStyleDetails("table_header", "text_color")
                              }
                            />
                          )}
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    component={"fieldset"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      border: "1px solid #cccccc",
                      padding: "12px",
                      paddingTop: "6px ",
                    }}
                  >
                    <legend
                      style={{
                        width: "auto",
                        marginBottom: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {getLocalizedText("grand_total")}
                    </legend>
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <InputWrapper
                          style={{
                            flexDirection: "row",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <Label>
                            {getLocalizedText("background_color")} :
                          </Label>
                          {styleDetails["grand_total"]["background_color"] !==
                          "" ? (
                            <ColorBox
                              onClick={() =>
                                handleStyleDetails(
                                  "grand_total",
                                  "background_color"
                                )
                              }
                              style={{
                                backgroundColor:
                                  styleDetails["grand_total"][
                                    "background_color"
                                  ],
                              }}
                            />
                          ) : (
                            <NilColorBox
                              onClick={() =>
                                handleStyleDetails(
                                  "grand_total",
                                  "background_color"
                                )
                              }
                            />
                          )}
                        </InputWrapper>
                      </Grid>
                      <Grid item>
                        <InputWrapper
                          style={{
                            flexDirection: "row",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <Label>{getLocalizedText("text_color")} :</Label>

                          {styleDetails["grand_total"]["text_color"] !== "" ? (
                            <ColorBox
                              onClick={() =>
                                handleStyleDetails("grand_total", "text_color")
                              }
                              style={{
                                backgroundColor:
                                  styleDetails["grand_total"]["text_color"],
                              }}
                            />
                          ) : (
                            <NilColorBox
                              onClick={() =>
                                handleStyleDetails("grand_total", "text_color")
                              }
                            />
                          )}
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion expanded={expanded} onChange={handleAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              {getLocalizedText("billing_address")}
            </AccordionSummary>
            <AccordionDetails ref={accordionDetailsRef}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("billing_name")}</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={(e) =>
                        handleBillingVariables(e.target.name, e.target.value)
                      }
                      name="billing_name"
                      value={addressConfig["billing_name"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("billing_address")}</Label>
                    <OutlinedInput
                      multiline
                      rows={3}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={(e) =>
                        handleBillingVariables(e.target.name, e.target.value)
                      }
                      name="billing_address"
                      value={addressConfig["billing_address"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("billing_country")}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        onChange={(e) =>
                          handleBillingVariables(e.target.name, e.target.value)
                        }
                        name="billing_country"
                        value={addressConfig["billing_country"]}
                        disableUnderline
                        variant="outlined"
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "4px",
                        }}
                      >
                        {quotationCountriesList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("billing_state")}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        onChange={(e) =>
                          handleBillingVariables(e.target.name, e.target.value)
                        }
                        name="billing_state"
                        value={addressConfig["billing_state"]}
                        disableUnderline
                        variant="outlined"
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "4px",
                        }}
                      >
                        {addressConfig["billing_country"] === "India" &&
                          indianStatesList.map((item, i) => {
                            return (
                              <MenuItem
                                value={item}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("billing_city")}</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={(e) =>
                        handleBillingVariables(e.target.name, e.target.value)
                      }
                      name="billing_city"
                      value={addressConfig["billing_city"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("billing_zipcode")}</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={(e) =>
                        handleBillingVariables(e.target.name, e.target.value)
                      }
                      name="billing_zipcode"
                      value={addressConfig["billing_zipcode"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("gst")}</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={(e) =>
                        handleBillingVariables(e.target.name, e.target.value)
                      }
                      name="billing_gst"
                      value={addressConfig["billing_gst"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={6}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={billingDetails["show_shipping_address"]}
                  color="primary"
                />
              }
              label={
                <FlagText>{getLocalizedText("show_shipping_address")}</FlagText>
              }
              onChange={(e) => {
                handleBillingDetails("show_shipping_address", e.target.checked);
              }}
            />
            <Tooltip title="Configure shipping address">
              <IconButton
                aria-label="configure"
                color="primary"
                disabled={!billingDetails["show_shipping_address"]}
                onClick={() => {
                  postClickCallback();
                }}
              >
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={additionalDetails["show_terms_and_conditions"]}
                color="primary"
              />
            }
            label={
              <FlagText>
                {getLocalizedText("show_terms_and_conditions")}
              </FlagText>
            }
            onChange={(e) => {
              handleAdditionalDetails(
                "show_terms_and_conditions",
                e.target.checked
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={additionalDetails["show_additional_information"]}
                color="primary"
              />
            }
            label={
              <FlagText>
                {getLocalizedText("show_additional_information")}
              </FlagText>
            }
            onChange={(e) => {
              handleAdditionalDetails(
                "show_additional_information",
                e.target.checked
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={additionalDetails["show_quotation_number"]}
                color="primary"
              />
            }
            label={
              <FlagText>{getLocalizedText("show_quotation_number")}</FlagText>
            }
            onChange={(e) => {
              handleAdditionalDetails(
                "show_quotation_number",
                e.target.checked
              );
            }}
          />
        </Grid>
        {additionalDetails["show_terms_and_conditions"] && (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>{getLocalizedText("terms_and_conditions")}</Label>
              <OutlinedInput
                multiline
                rows={3}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                onChange={(e) =>
                  handleAdditionalDetails(e.target.name, e.target.value)
                }
                name="terms_and_conditions"
                value={additionalDetails["terms_and_conditions"]}
                placeholder="Enter value"
              />
            </InputWrapper>
          </Grid>
        )}
        {additionalDetails["show_additional_information"] && (
          <Grid item xs={12}>
            <Label>{getLocalizedText("additional_information")}</Label>
            <div className="container" data-color-mode="light">
              <MDEditor
                value={additionalDetails["additional_information"]}
                onChange={(value) => {
                  handleAdditionalDetails("additional_information", value);
                }}
                preview="edit"
                hideToolbar={false}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.hr,
                  commands.group(
                    [
                      commands.title1,
                      commands.title2,
                      commands.title3,
                      commands.title4,
                      commands.title5,
                      commands.title6,
                    ],
                    {
                      name: "title",
                      groupName: "title",
                      buttonProps: { "aria-label": "Insert title" },
                    }
                  ),
                  commands.divider,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.codeBlock,
                  commands.divider,
                  commands.unorderedListCommand,
                  commands.orderedListCommand,
                  commands.checkedListCommand,
                ]}
                commandsFilter={(cmd) => {
                  if (
                    cmd.name === "edit" ||
                    cmd.name === "live" ||
                    cmd.name === "preview" ||
                    cmd.name === "fullscreen"
                  ) {
                    return false;
                  }
                  return cmd;
                }}
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputWrapper>
            <Label>{getLocalizedText("footer_text")}</Label>
            <div className="container" data-color-mode="light">
              <MDEditor
                value={companyDetails["footer_text"]}
                onChange={(value) => {
                  //only allowing 1 line in markdown
                  const lines = value.split("\n");
                  if (lines.length <= 1) {
                    handleChange("footer_text", value);
                  }
                }}
                height={"75px"}
                preview="edit"
                hideToolbar={false}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.hr,
                  commands.group(
                    [
                      commands.title1,
                      commands.title2,
                      commands.title3,
                      commands.title4,
                      commands.title5,
                      commands.title6,
                    ],
                    {
                      name: "title",
                      groupName: "title",
                      buttonProps: { "aria-label": "Insert title" },
                    }
                  ),
                  commands.divider,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.codeBlock,
                  commands.divider,
                  commands.unorderedListCommand,
                  commands.orderedListCommand,
                  commands.checkedListCommand,
                ]}
                commandsFilter={(cmd) => {
                  if (
                    cmd.name === "edit" ||
                    cmd.name === "live" ||
                    cmd.name === "preview" ||
                    cmd.name === "fullscreen"
                  ) {
                    return false;
                  }
                  return cmd;
                }}
              />
            </div>
          </InputWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdditionalInformationScreen;

import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Divider } from "@material-ui/core";
import { capitalizeWord, getLocalizedText } from "../../Functions";
import { getColumnsForMapping } from "../../Api";
import rootStore from "../../stores/RootStore";
import Select from "react-select";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  /* margin-bottom: 30px; */
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  width: 400px;
  padding: 10px 0px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;
const CheckBoxTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 195px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 50%;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
`;
const MapProjectLeadToProject = (props) => {
  const [currentProjectColumns, setCurrentProjectColumns] = useState([]);
  const [destProjectColumns, setDestProjectColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWieght: "bold",
      // Font size for options
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "200px", // your desired height
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      width: "195px",
      fontWieght: "bold",
      // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "loading" message
    }),
  };

  const getProjectColumnsForMapping = async () => {
    let response = await getColumnsForMapping(props.projectId);
    return response;
  };

  const getColumnIndexMappedInStore = ({
    currProjectColumnField,
    destProjectColumnList,
    mappingDataKey,
  }) => {
    let tempMapping =
      rootStore.userStore.projectToProjectColumnMapping[mappingDataKey];
    if (tempMapping.hasOwnProperty(currProjectColumnField)) {
      let destProjectColumnField = tempMapping[currProjectColumnField];
      let index = destProjectColumnList.findIndex(
        (col) => col["field"] === destProjectColumnField
      );
      return index;
    }

    return -1;
  };

  const setupMappingFromStoredData = (currProjColumns, destProjColumns) => {
    let initList = {};
    let mappingDataKey = rootStore.authStore.projectId + props.projectId;

    currProjColumns.forEach((column) => {
      let checked = false;
      let projectLead = {};

      let index = getColumnIndexMappedInStore({
        currProjectColumnField: column["field"],
        destProjectColumnList: destProjColumns,
        mappingDataKey: mappingDataKey,
      });

      if (index !== -1) {
        checked = true;
        projectLead = {
          id: destProjColumns[index]["id"],
          field: destProjColumns[index]["field"],
          display_name: destProjColumns[index]["headerName"],
          data_type: destProjColumns[index]["data_type"],
          is_custom_param: destProjColumns[index]["is_custom_param"],
          label: destProjColumns[index].headerName,
          value: destProjColumns[index].field,
          isDisabled: isAlreadySelectedProjectField(
            destProjColumns[index].field
          ),
          required: destProjColumns[index].required,
        };
      }
      let tempObj = {
        current_project: {
          field: column["field"],
          display_name: column["display_name"],
          is_custom_param: column["is_custom_param"],
        },
        dest_project: projectLead,
        checked: checked,
      };

      initList[column["field"]] = tempObj;
    });
    return initList;
  };

  const canUseColumnForMapping = ({
    mappedDataList,
    destProjectColumnField,
  }) => {
    Object.values(mappedDataList).forEach((value) => {
      if (
        value["dest_project"].hasOwnProperty("field") &&
        value["dest_project"]["field"] === destProjectColumnField
      ) {
        return value["checked"] === false;
      }
    });
    return true;
  };

  const setupMappingFromClosestMatch = (currProjColumns, destProjColumns) => {
    let initList = {};

    currProjColumns.forEach((column) => {
      let checked = false;
      let projectLead = {};

      let filteredProjectColumnList = destProjColumns.filter((col) =>
        canUseColumnForMapping({
          mappedDataList: initList,
          destProjectColumnField: col["field"],
        })
      );
      let index = findClosestMatchColumn(
        column["field"],
        filteredProjectColumnList
      );

      if (index !== -1) {
        checked = true;
        projectLead = {
          id: destProjColumns[index]["id"],
          field: destProjColumns[index]["field"],
          display_name: destProjColumns[index]["headerName"],
          data_type: destProjColumns[index]["data_type"],
          is_custom_param: destProjColumns[index]["is_custom_param"],
          label: destProjColumns[index].headerName,
          value: destProjColumns[index].field,
          isDisabled: isAlreadySelectedProjectField(
            destProjColumns[index].field
          ),
          required: destProjColumns[index].required,
        };
      }
      let tempObj = {
        current_project: {
          field: column["field"],
          display_name: column["display_name"],
          is_custom_param: column["is_custom_param"],
        },
        dest_project: projectLead,
        checked: checked,
      };

      initList[column["field"]] = tempObj;
    });
    return initList;
  };

  const initCurrToDestProjectFieldsList = (
    currProjColumns,
    destProjColumns
  ) => {
    let mappingDataKey = rootStore.authStore.projectId + props.projectId;
    const hasMapping =
      rootStore.userStore.projectToProjectColumnMapping.hasOwnProperty(
        mappingDataKey
      );
    if (hasMapping) {
      return setupMappingFromStoredData(currProjColumns, destProjColumns);
    } else {
      return setupMappingFromClosestMatch(currProjColumns, destProjColumns);
    }
  };
  const findClosestMatchColumn = (field, projectColumns) => {
    const searchStr = field;
    const titleStr = capitalizeWord(field);
    const words = field.split("_");

    // 1. Exact match for field
    let index = projectColumns.findIndex((obj) => obj.field === searchStr);
    if (index !== -1) return index;

    // 2. Exact match for headerName
    index = projectColumns.findIndex((obj) => obj.headerName === titleStr);
    if (index !== -1) return index;

    // 3. Case-insensitive match for field
    index = projectColumns.findIndex(
      (obj) => obj.field.toLowerCase() === searchStr.toLowerCase()
    );
    if (index !== -1) return index;

    // 4. Case-insensitive match for headerName
    index = projectColumns.findIndex(
      (obj) => obj.headerName.toLowerCase() === titleStr.toLowerCase()
    );
    if (index !== -1) return index;

    // 5. Match for any word from the snake_case string in field or headerName
    index = projectColumns.findIndex((obj) => {
      const fieldWords = obj.field.toLowerCase().split("_");
      const headerNameWords = obj.headerName.toLowerCase().split(" ");
      return words.some(
        (word) =>
          fieldWords.includes(word.toLowerCase()) ||
          headerNameWords.includes(word.toLowerCase())
      );
    });

    return -1;
  };
  const setup = async () => {
    let currentProjectColumns = await setupColumns();
    setCurrentProjectColumns(currentProjectColumns);
    let projectColumns = await getProjectColumnsForMapping();

    const requiredList = projectColumns.filter((item) => item.required);
    const nonRequiredList = projectColumns.filter((item) => !item.required);
    // Sort each list alphabetically
    const sortedRequiredList = requiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    const sortedNonRequiredList = nonRequiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    projectColumns = [...sortedRequiredList, ...sortedNonRequiredList];
    let new_init_list = initCurrToDestProjectFieldsList(
      currentProjectColumns,
      projectColumns
    );
    props.setCurrToDestProjectList(new_init_list);

    props.setDestProjectColumnList(projectColumns);
    setDestProjectColumns(projectColumns);
    setLoading(false);
  };
  const getProjectColumnsForDropdown = () => {
    let tempList = [];
    destProjectColumns.forEach((column) => {
      let tempObj = {
        label: column.headerName,
        value: column.field,
        isDisabled: isAlreadySelectedProjectField(column["field"]),
        required: column.required,
      };
      tempList.push(tempObj);
    });
    return tempList;
  };

  const getCurrentProjectFieldHeader = (field) => {
    let index = currentProjectColumns.findIndex(
      (column) => column["field"] === field
    );
    if (index !== -1) {
      return currentProjectColumns[index]["display_name"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setup();
  }, []);

  const setupColumns = async () => {
    let data = [];

    let columns = rootStore.userStore.AllColumnsList;

    const requiredList = columns.filter((item) => item.required);
    const nonRequiredList = columns.filter((item) => !item.required);
    // Sort each list alphabetically
    const sortedRequiredList = requiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    const sortedNonRequiredList = nonRequiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    columns = [...sortedRequiredList, ...sortedNonRequiredList];
    columns.forEach((column) => {
      data.push({
        display_name: capitalizeWord(column["headerName"]),
        field: column["field"],
        is_custom_param: column["is_custom_param"],
      });
    });
    return data;
  };

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(props.currToDestProjectList).forEach((item) => {
      if (
        props.currToDestProjectList[item]["dest_project"].hasOwnProperty(
          "field"
        ) &&
        props.currToDestProjectList[item]["dest_project"]["field"] === field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  return !loading ? (
    <Container>
      <Header>{getLocalizedText("move_to_project")}</Header>
      <SubHeader>{getLocalizedText("please_fill_the_below_details")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <ColumnWrapper>
        <ColumnHeader>Current Project Field</ColumnHeader>
        <ColumnHeader>Move-To Project Field</ColumnHeader>
      </ColumnWrapper>
      <Wrapper>
        {Object.keys(props.currToDestProjectList).map((item, index) => {
          return (
            <RowWrapper>
              <CheckBoxTextWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.setShowAlert(false);
                        props.setShowRequiredColumnAlert(false);

                        if (e.target.checked) {
                          props.setCurrToDestProjectList({
                            ...props.currToDestProjectList,
                            [item]: {
                              ...props.currToDestProjectList[item],
                              checked: e.target.checked,
                            },
                          });
                        } else {
                          props.setCurrToDestProjectList({
                            ...props.currToDestProjectList,
                            [item]: {
                              ...props.currToDestProjectList[item],
                              checked: e.target.checked,
                              dest_project: {},
                            },
                          });
                        }
                      }}
                      size="small"
                      checked={props.currToDestProjectList[item]["checked"]}
                    />
                  }
                  label={
                    <Label>
                      {getCurrentProjectFieldHeader(
                        props.currToDestProjectList[item]["current_project"][
                          "field"
                        ]
                      )}
                    </Label>
                  }
                />
              </CheckBoxTextWrapper>

              <Select
                key={index}
                maxMenuHeight={150}
                value={props.currToDestProjectList[item]["dest_project"]}
                required={props.currToDestProjectList[item]["checked"]}
                onChange={(e) => {
                  if (e === null) {
                    props.setCurrToDestProjectList({
                      ...props.currToDestProjectList,
                      [item]: {
                        ...props.currToDestProjectList[item],
                        dest_project: {},
                      },
                    });
                  } else {
                    let index = destProjectColumns.findIndex(
                      (column) => column["field"] === e.value
                    );

                    props.setCurrToDestProjectList({
                      ...props.currToDestProjectList,
                      [item]: {
                        ...props.currToDestProjectList[item],
                        dest_project: {
                          ...e,
                          id: destProjectColumns[index]["id"],
                          field: destProjectColumns[index]["field"],
                          display_name: destProjectColumns[index]["headerName"],
                          data_type: destProjectColumns[index]["data_type"],
                          is_custom_param:
                            destProjectColumns[index]["is_custom_param"],
                        },
                      },
                    });
                  }
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={getProjectColumnsForDropdown()}
                closeMenuOnSelect={true}
                isClearable
                styles={customStyles}
                menuShouldScrollIntoView={false}
                formatOptionLabel={(option) => {
                  if (option.required) {
                    return (
                      <Typography sx={{ fontSize: "12px" }}>
                        <span style={{ color: "red" }}>*</span>
                        {option.label}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography sx={{ fontSize: "12px" }}>
                        {option.label}
                      </Typography>
                    );
                  }
                }}
              ></Select>
            </RowWrapper>
          );
        })}
      </Wrapper>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={props.deleteSourceLead}
            onChange={(e) => {
              props.setDeleteSourceLead(e.target.checked);
            }}
          />
        }
        label={
          <FlagText>
            {getLocalizedText("delete_source_lead", "project")}
          </FlagText>
        }
      />
    </Container>
  ) : (
    <Container
      style={{
        height: "420px",
        width: "400px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default MapProjectLeadToProject;

import React from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import WhatsappTextMessagePreview from "./WhatsappTextMessagePreview";
import WhatsappMetaWABAPreview from "./WhatsappMetaWABAPreview";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #c5d7f1;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 560px;
  height: max-content;
  max-height: 95vh;
  overflow: auto;
  box-sizing: border-box;
`;
const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  min-width: 500px;
`;

const PreviewHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "20px",
  },
}));

function WhatsappPreviewPopup(props) {
  const toolbarStyle = useStyles();

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            top: "-16px",
            bottom: "auto",
            width: "100%",
          }}
        >
          <Toolbar
            classes={{ root: toolbarStyle.root }}
            style={{
              padding: "0px",
              width: "100%",
              position: "relative",
              minHeight: 40,
              height: 40,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ height: "50px" }}
            >
              <PreviewHeader>Preview</PreviewHeader>
              <Grid item>
                <IconButton onClick={handleClose} style={{ padding: "0px" }}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <ContentWrapper>
          {props.contentType === "metaWaba" ? (
            <WhatsappMetaWABAPreview data={props.components} images={props.images}/>
          ) : (
            <WhatsappTextMessagePreview text={props.content} />
          )}
        </ContentWrapper>
      </ModelContainer>
    </Modal>
  );
}

export default WhatsappPreviewPopup;

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import {
  consoleLogger,
  getLocalizedText,
  isAfterCurrentTime,
} from "../../Functions";
import {
  AppBar,
  MenuItem,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { updateLeadStageApi, editContactApi } from "../../Api";
import UpdateFieldsWidget from "../status_param_column_rendere/UpdateFieldsWidget";
import moment from "moment";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 420px;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const MessageSm = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const UpdateStageModal = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    stage: "",
    comments: "",
  });
  const [prevStage, setPrevStage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [stageList, setStageList] = useState([]);
  const [updateFieldList, setUpdateFieldList] = useState([]);
  const [updateFieldInputData, setUpdateFieldInputData] = useState({});
  const [nextFollowupRelativeDate, setNextFollowupRelativeDate] = useState("");
  const columns = [...rootStore.userStore.AllColumnsList];

  const getExistingLeadFieldValue = (fieldName) => {
    const columnIndex = columns.findIndex(
      (entry) => entry["field"] === fieldName
    );

    if (columnIndex !== -1) {
      const columnData = columns[columnIndex];
      if (columnData["is_custom_param"]) {
        let newKey = fieldName.replace("custom_params_", "");
        let customParamData = props.leadData.hasOwnProperty("custom_params")
          ? JSON.parse(props.leadData["custom_params"])
          : {};
        return customParamData.hasOwnProperty(newKey)
          ? customParamData[newKey]
          : null;
      } else {
        return props.leadData.hasOwnProperty(fieldName)
          ? props.leadData[fieldName]
          : null;
      }
    }
  };

  const getUpdateFieldParsedValue = (updateFieldData) => {
    if (updateFieldData["value"] !== null && updateFieldData["value"] !== "") {
      if (
        updateFieldData["type"] === "date" ||
        updateFieldData["type"] === "datetime"
      ) {
        return updateFieldData["value"].toISOString();
      } else if (updateFieldData["type"] === "integer") {
        return parseInt(updateFieldData["value"]);
      }
      return updateFieldData["value"];
    }
    return null;
  };

  const hasChange = () => {
    const newStage = details.hasOwnProperty("stage") ? details["stage"] : "";

    // check if stage is same
    if (newStage === prevStage) {
      // check if atleast one update field value is not same as existing lead field value
      for (const key in updateFieldInputData) {
        let updateFieldValue = getUpdateFieldParsedValue(
          updateFieldInputData[key]
        );
        let existingLeadFieldValue = getExistingLeadFieldValue(key);
        if (updateFieldValue !== null && updateFieldValue !== "") {
          // if its date or datetime then compare date of existing lead field value and update field value
          if (
            updateFieldInputData[key]["type"] === "date" ||
            updateFieldInputData[key]["type"] === "datetime"
          ) {
            if (
              existingLeadFieldValue !== null &&
              existingLeadFieldValue !== ""
            ) {
              let existingDate = moment(existingLeadFieldValue);
              let newDate = moment(updateFieldValue);
              // comparing only date - ignoring time
              if (
                existingDate.isValid() &&
                newDate.isValid() &&
                !newDate.isSame(existingDate, "day")
              ) {
                return true;
              }
            } else {
              return true;
            }
          } else {
            if (updateFieldValue !== existingLeadFieldValue) {
              return true;
            }
          }
        }
      }
      return false;
    } else {
      return true;
    }
  };

  const getUpdateFieldsInStage = (stageList, stageName) => {
    let tempList = [];

    let index = stageList.findIndex((item) => item["stage"] === stageName);
    if (index !== -1) {
      if (
        stageList[index].hasOwnProperty("update_fields") &&
        stageList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = stageList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            columnData["_overwrite"] = data["overwrite"];
            columnData["_append"] = data.hasOwnProperty("append")
              ? data["append"]
              : false;
            tempList.push(columnData);
          }
        });
      }
    }

    return tempList;
  };

  const initUpdateFieldList = (stageList, stageName) => {
    let updateFields = getUpdateFieldsInStage(stageList, stageName);
    if (updateFields.length > 0) {
      setUpdateFieldList(updateFields);
    }
  };

  const hasValidDateAndDateTime = (updateFieldInputData) => {
    for (const key in updateFieldInputData) {
      if (
        updateFieldInputData[key]["type"] === "datetime" ||
        updateFieldInputData[key]["type"] === "date"
      ) {
        let value = updateFieldInputData[key]["value"];
        if (value !== null) {
          try {
            if (!value.isValid()) {
              const index = columns.findIndex(
                (column) => column["field"] === key
              );

              if (index !== -1) {
                setErrorMessage(`Invalid ${columns[index]["headerName"]}`);
                return false;
              }
            }
          } catch (error) {
            setErrorMessage("Some error occurred!\nError code:1020");
            return false;
          }
        }
      }
    }
    return true;
  };

  const isAllMandatoryFieldsFilled = () => {
    let filled_fields = { ...updateFieldInputData };
    for (let i = 0; i < updateFieldList.length; i++) {
      let item = updateFieldList[i];
      if (item["required"] === true) {
        if (filled_fields.hasOwnProperty(item["field"])) {
          if (
            filled_fields[item["field"]]["value"] === "" ||
            filled_fields[item["field"]]["value"] === null
          ) {
            setErrorMessage(`Please fill "${item["headerName"]}" field`);
            return false;
          }
        } else {
          setErrorMessage(`Please fill "${item["headerName"]}" field`);
          return false;
        }
      }
    }

    return true;
  };

  const isValidNextFollowupDatetime = () => {
    let tempDatetime = updateFieldInputData.hasOwnProperty("next_follow_up_on")
      ? updateFieldInputData["next_follow_up_on"]["value"]
      : null;
    if (tempDatetime !== null) {
      return isAfterCurrentTime(tempDatetime.toISOString());
    }
    return true;
  };

  const generateContactPayload = (data) => {
    let contactData = {};

    for (const key in data) {
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columns[index];
        if (columnData["contact_column"] !== null) {
          let fieldData = data[columnData["field"]];
          let value = fieldData["value"];
          if (value !== "" && value !== null) {
            if (
              fieldData["type"] === "datetime" ||
              fieldData["type"] === "date"
            ) {
              value = value.toISOString();
            } else if (fieldData["type"] === "integer") {
              value = parseInt(value);
            }
            contactData[columnData["contact_column"]] = value;
          }
        }
      }
    }
    return contactData;
  };

  const updateStage = async () => {
    let stageIndex = stageList.findIndex(
      (stage) => stage["stage"] === details.stage
    );
    let lead_stage_id = stageIndex !== -1 ? stageList[stageIndex]["id"] : null;
    let payload = {
      lead_stage_id: lead_stage_id,
    };
    if (details.comments !== "") {
      payload["comments"] = details.comments;
    }
    let updateFieldsPayload =
      generateUpdateFieldsPayloadData(updateFieldInputData);
    payload["update_fields"] = updateFieldsPayload;
    payload["updated_fields"] = updateFieldsPayload;
    if (updateFieldsPayload.hasOwnProperty("next_follow_up_on")) {
      payload["next_followup_on"] = updateFieldsPayload["next_follow_up_on"];
    }
    let response = await updateLeadStageApi({
      leadID: props.id,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefreshLeadDetails(true);
      setIsSubmitSuccess(true);
    }
  };

  const generateUpdateFieldsPayloadData = (data) => {
    let normalList = {};
    let customList = {};
    for (const key in data) {
      let value = data[key]["value"];
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        if (value !== "" && value !== null) {
          if (
            data[key]["type"] === "datetime" ||
            data[key]["type"] === "date"
          ) {
            value = value.toISOString();
          } else if (data[key]["type"] === "integer") {
            value = parseInt(value);
          }
          if (columns[index]["is_custom_param"]) {
            let newKey = key.replace("custom_params_", "");
            customList[newKey] = value;
          } else {
            normalList[key] = value;
          }
        }
      }
    }
    if (Object.keys(customList).length > 0) {
      normalList["custom_params"] = customList;
    }

    return normalList;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (isAllMandatoryFieldsFilled()) {
      if (isValidNextFollowupDatetime()) {
        if (hasValidDateAndDateTime(updateFieldInputData)) {
          if (hasChange()) {
            let contactPayload = generateContactPayload(updateFieldInputData);

            if (Object.keys(contactPayload).length > 0) {
              if (
                props.leadData["contact_id"] !== null &&
                props.leadData["contact_id"] !== ""
              ) {
                let response = await editContactApi({
                  contactID: props.leadData["contact_id"],
                  payload: contactPayload,
                });
                if (!response.hasError()) {
                  await updateStage();
                } else {
                  setErrorMessage(response.errorMessage);
                  setIsSubmitFail(true);
                }
              }
            } else {
              await updateStage();
            }
          } else {
            setErrorMessage(
              "Kindly make a modification before submitting the form."
            );
            setIsSubmitFail(true);
          }
        } else {
          setIsSubmitFail(true);
        }
      } else {
        setErrorMessage("Invalid Next Followup On Time");
        setIsSubmitFail(true);
      }
    } else {
      setIsSubmitFail(true);
    }
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const init = () => {
    let tempStage =
      props.leadData.hasOwnProperty("lead_stage") &&
      props.leadData["lead_stage"] !== null
        ? props.leadData["lead_stage"]
        : "";
    let sortedStageList = [...rootStore.authStore.stageList].sort(
      (a, b) => a.rank - b.rank
    );

    setStageList(sortedStageList);
    setDetails({ ...details, stage: tempStage });
    setPrevStage(tempStage);
    initUpdateFieldList(sortedStageList, tempStage);
  };

  useEffect(() => {
    init();
  }, []);
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container component={"form"} onSubmit={handleUpdate}>
                <AppBar
                  component={"nav"}
                  elevation={0}
                  position="sticky"
                  color="inherit"
                  sx={{
                    top: 0,
                    bottom: "auto",
                    width: "100%",
                    right: 0,
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <Toolbar
                    style={{
                      padding: "16px",
                    }}
                  >
                    <HeaderWrapper>
                      <Header>
                        {getLocalizedText("update_lead_stage", "project")}
                      </Header>
                      <SubHeader>
                        {getLocalizedText("select_from_below_dropdown")}
                      </SubHeader>
                    </HeaderWrapper>
                  </Toolbar>
                </AppBar>

                <Divider style={{ margin: "16px", marginTop: "0px" }} />

                <Box
                  style={{
                    height: "400px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "0px 16px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Stage</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="stage"
                            displayEmpty
                            variant="outlined"
                            required
                            value={details.stage}
                            onChange={(e) => {
                              handleChange(e);
                              setUpdateFieldList([]);
                              setUpdateFieldInputData({});
                              initUpdateFieldList(stageList, e.target.value);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {stageList.map((e) => {
                              return (
                                <MenuItem id={e} value={e.stage}>
                                  {e.stage}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Comments</Label>
                        <OutlinedInput
                          multiline={true}
                          rows={3}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={details.comments}
                          name={"comments"}
                          type={"text"}
                          placeholder={"Enter Value"}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Grid>
                    {updateFieldList.length > 0 && (
                      <UpdateFieldsWidget
                        value={updateFieldInputData}
                        setValue={setUpdateFieldInputData}
                        fieldList={updateFieldList}
                        nextFollowupRelativeDate={nextFollowupRelativeDate}
                        setNextFollowupRelativeDate={
                          setNextFollowupRelativeDate
                        }
                        leadData={props.leadData}
                      />
                    )}
                  </Grid>
                </Box>
                <AppBar
                  position="static"
                  color="inherit"
                  elevation={0}
                  sx={{
                    top: "auto",
                    bottom: 0,
                    width: "100%",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Toolbar
                    style={{
                      padding: "16px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {isSubmitFail && (
                      <ErrorAlert
                        onClose={(e) => {
                          e.stopPropagation();
                          setIsSubmitFail(false);
                        }}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {errorMessage}
                      </ErrorAlert>
                    )}
                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: "16px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              props.setOpen(false);
                            }}
                            variant="contained"
                            color="default"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                          >
                            {getLocalizedText("cancel")}
                          </Button>
                          <Button
                            onClick={() => {}}
                            type="submit"
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "fit-content",
                              textTransform: "none",
                              ...myTheme.Button.btnBlue,
                            }}
                          >
                            Update
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>Stage updated successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default UpdateStageModal;

import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import {
  getFormattedMultilineText,
  dataRenderer,
  getLocalizedText,
} from "../../../Functions";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewCustomItemInfopanel = ({ details }) => {
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>{getLocalizedText("custom_item_details")}</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("item_name")}</Label>
              <Detail>{dataRenderer(details.product.name)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper style={{ width: "100%" }}>
              <Label>{getLocalizedText("description")}</Label>
              {details["product"].hasOwnProperty("desc") &&
              details["product"]["desc"] !== null &&
              details["product"]["desc"] !== "" ? (
                <Detail
                  component="div"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {getFormattedMultilineText(details["product"]["desc"])}
                </Detail>
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("quantity")}</Label>
              <Detail>{dataRenderer(details.quantity)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("rate")}</Label>
              <Detail>{dataRenderer(details.rate)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("amount")}</Label>
              <Detail>{dataRenderer(details.amount)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("discount")}(%)</Label>
              <Detail>{dataRenderer(details.discount)}</Detail>
            </InputWrapper>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewCustomItemInfopanel;

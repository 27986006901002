import { Button, makeStyles } from "@material-ui/core";
import {
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  styled,
} from "@mui/material";
import React from "react";
import { getLocalizedText } from "../../Functions";
import PageSizeDropdownV2 from "../page_size_dropdown/PageSizeDropdownV2";

const Counter = styled(Box)`
  font: normal normal 600 14px Open Sans;
  display: flex;
  width: fit-content;
  height: fit-content;
  color: #4d4e4f;
  padding: 6px;
  gap: 4px;
`;
const TableFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  @media (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "white",
      backgroundColor: "#4D4E4F",
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
    "& .MuiPaginationItem-root": {
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
  },
}));
const EntityPaginationComponent = ({
  handlePage,
  handlePageSize,
  page_size,
  page_no,
  count,
  paginationData,
  pageSizeOptionList
}) => {
  const classes = useStyles();
  const PrevButton = () => {
    return (
      <Button
        variant="text"
        color="default"
        style={{
          fontWeight: "bold",
          width: "65px",
          fontSize: "12px",
          height: "25px",
        }}
      >
        {getLocalizedText("prev")}
      </Button>
    );
  };
  const NextButton = () => {
    return (
      <Button
        variant="text"
        color="primary"
        style={{
          fontWeight: "bold",
          width: "65px",
          fontSize: "12px",
          height: "25px",
        }}
      >
        {getLocalizedText("next")}
      </Button>
    );
  };
  const RenderPaginationCounter = () => {
    if (paginationData["count"] === null) {
      return (
        <Counter>
          {paginationData["min"]} - {paginationData["max"]} of{" "}
          <CircularProgress style={{ width: "20px", height: "20px" }} />
        </Counter>
      );
    } else {
      return (
        <Counter>
          {paginationData["min"]} - {paginationData["max"]} of{" "}
          {paginationData["count"]}
        </Counter>
      );
    }
  };
  return (
    <TableFooter>
      <PageSizeDropdownV2 handlePageSize={handlePageSize} pageSize={page_size} pageSizeOptionList={pageSizeOptionList} />

      <Row>
        <RenderPaginationCounter />
        {count !== null ? (
          <Pagination
            classes={{ ul: classes.ul }}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: PrevButton,
                  next: NextButton,
                }}
                {...item}
              />
            )}
            variant="outlined"
            shape="rounded"
            count={Math.ceil(count / page_size)}
            color="primary"
            onChange={(e, page) => {
              handlePage(e, page);
            }}
            page={page_no + 1}
          />
        ) : (
          <Pagination
            classes={{ ul: classes.ul }}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: PrevButton,
                  next: NextButton,
                }}
                {...item}
              />
            )}
            variant="outlined"
            shape="rounded"
            count={
              paginationData["page_addon"] === true ? page_no + 2 : page_no + 1
            }
            color="primary"
            onChange={(e, page) => {
              handlePage(e, page);
            }}
            page={page_no + 1}
          />
        )}
      </Row>
    </TableFooter>
  );
};

export default EntityPaginationComponent;

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Avatar, CircularProgress, Typography } from "@mui/material";
import { CheckCircle, Error } from "@material-ui/icons";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  moneyViewCollectOffersApi,
  moneyViewCreateLeadApi,
  moneyViewFetchJourneyURLApi,
} from "../../Api";
import { digitalLenderState } from "../../utils/enum";
import { consoleLogger, getLocalizedText } from "../../Functions";
const Message = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #5cb85c;
  text-align: center;
  margin-top: 20px;
`;

const steps = [
  getLocalizedText("creating_lead","project"),
  getLocalizedText("collecting_offers"),
  getLocalizedText("fetching_journey_url"),
];

const CreateLeadProgressStepper = (props) => {
  const { userStore } = rootStore;
  const [isSuccess, setIsSuccess] = useState(false);

  const moneyViewFetchJourneyURL = async () => {
    userStore.updateMoneyViewStepsStatus(2, digitalLenderState.in_progress);

    let flag = await moneyViewFetchJourneyURLApi(props.data.id);
    if (flag) {
      userStore.updateMoneyViewStepsStatus(2, digitalLenderState.success);
      setIsSuccess(true);
      props.setRefreshLeadDetails(true);
    } else {
      userStore.updateMoneyViewStepsStatus(2, digitalLenderState.error);
      props.setRefreshLeadDetails(true);
    }
    userStore.updateMoneyViewCurrentStep(3);
  };

  const moneyViewCollectOffers = async () => {
    userStore.updateMoneyViewStepsStatus(1, digitalLenderState.in_progress);

    let flag = await moneyViewCollectOffersApi(props.data.id);
    if (flag) {
      userStore.updateMoneyViewStepsStatus(1, digitalLenderState.success);
      moneyViewFetchJourneyURL();
    } else {
      userStore.updateMoneyViewStepsStatus(1, digitalLenderState.error);
      props.setRefreshLeadDetails(true);
    }
    userStore.updateMoneyViewCurrentStep(2);
  };

  const moneyViewCreateLead = async () => {
    userStore.updateMoneyViewCurrentStep(0);
    userStore.updateMoneyViewStepsStatus(0, digitalLenderState.in_progress);
    let flag = await moneyViewCreateLeadApi(props.data.id);
    if (flag) {
      userStore.updateMoneyViewStepsStatus(0, digitalLenderState.success);
      moneyViewCollectOffers();
    } else {
      userStore.updateMoneyViewStepsStatus(0, digitalLenderState.error);
      props.setRefreshLeadDetails(true);
    }
    userStore.updateMoneyViewCurrentStep(1);
  };

  useEffect(() => {
    userStore.updateMoneyViewStepsStatus(0, digitalLenderState.idle);
    moneyViewCreateLead();
  }, []);

  // useEffect(() => {}, [userStore.moneyViewStepperState.step]);

  const handleClose = () => {
    userStore.resetCreateLeadStepper();
    props.setOpen(false);
  };

  const stepStateIcon = (state, step) => {
    consoleLogger("state::::", state, "step::::", step);
    if (state === digitalLenderState.in_progress) {
      return <CircularProgress style={{ width: "20px", height: "20px" }} />;
    } else if (state === digitalLenderState.error) {
      return <Error color="error" />;
    } else if (state === digitalLenderState.success) {
      return <CheckCircle style={{ color: "#5cb85c" }} />;
    } else {
      return (
        <Avatar
          sx={{
            bgcolor: "#90CAF9",
            color: "black",
            width: "20px",
            height: "20px",
            fontSize: "10px",
          }}
        >
          {step + 1}
        </Avatar>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stepper activeStep={userStore.moneyViewStepperState["currentStep"]}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={() =>
                  stepStateIcon(
                    userStore.moneyViewStepperState["step"][index]["status"],
                    index
                  )
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {isSuccess && (
        <Message>{getLocalizedText("lead_created_successfully","project")}</Message>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "20px",
          width: "100%",
        }}
      >
        <Button
          onClick={handleClose}
          type="button"
          variant="outlined"
          color="default"
          style={{
            textTransform: "none",
            marginTop: "20px",
            fontSize: "12px",
            fontWeight: "bold",
            width: "100px",
          }}
        >
          {isSuccess ? getLocalizedText("close") : getLocalizedText("cancel")}
        </Button>
      </Box>
    </Box>
  );
};
export default observer(CreateLeadProgressStepper);

import { AppBar, Box, Divider, Toolbar } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../../Functions";
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  OutlinedInput,
} from "@mui/material";
import rootStore from "../../../../stores/RootStore";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ProjectAndNameFieldScreen = ({
  title,
  selectedProjectID,
  nameFieldValue,
  handleNameField,
  handleProject,
}) => {
  const projectList = [...rootStore.authStore.projectList];

  return (
    <>
      <Box>
        <AppBar
          component={"nav"}
          elevation={0}
          position="sticky"
          color="inherit"
          sx={{
            top: 0,
            bottom: "auto",
            width: "100%",
            right: 0,
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
            }}
          >
            <HeaderWrapper>
              <Header>{title}</Header>
              <SubHeader>
                {" "}
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
            </HeaderWrapper>
          </Toolbar>
        </AppBar>
        <Divider style={{ margin: "0px 16px" }} />
        <Container>
          <InputWrapper>
            <Label>Name*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={(e) => {
                  handleNameField(e.target.value);
                }}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={nameFieldValue}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>Select Destination Project*</Label>
            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                required
                onChange={(event) => {
                  handleProject(event.target.value);
                }}
                name="selected_predefined_deal"
                disableUnderline
                variant="outlined"
                value={selectedProjectID}
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "0px",
                }}
              >
                {projectList.map((item, i) => {
                  return (
                    <MenuItem
                      value={item.id}
                      key={i}
                      style={{ fontSize: "12px" }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Container>
      </Box>
    </>
  );
};

export default ProjectAndNameFieldScreen;

import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Chip, Button } from "@material-ui/core";
import { ChevronRight, Visibility } from "@material-ui/icons";
import { AttendanceHistoryPageColumns } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { IconButton, Tooltip, Typography, Drawer } from "@mui/material";
import { getAdminUsersApi, getUsersAttendanceListApi } from "../../Api";
import moment from "moment";
import DatePickerDialog from "../../components/attendance_history_page_components/DatePickerDialog";
import { useLocation, useNavigate } from "react-router-dom";
import DateNavigationComponent from "../../components/attendance_history_page_components/DateNavigationComponent";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import { FileDownload } from "@mui/icons-material";
import AttendanceReportDownloadSidepanel from "../../components/attendance_history_page_components/AttendanceReportDownloadSidepanel";
import { consoleLogger, getLocalizedText } from "../../Functions";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 185px);`};
  background-color: white;
  padding: 20px;
  position: relative;
  padding-top: 0px;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const presetOrder = ["owner", "admin", "member"];
const AttendanceSettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const receivedProps = location.state;
  const [loading, setLoading] = useState(true);
  const [userWithAttendanceList, setUserWithAttendanceList] = useState([]);
  const [date, setDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDownloadReport, setOpenDownloadReport] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filteredRows, setFilteredRows] = useState();
  const chipData = [
    { key: 0, label: "Present" },
    { key: 1, label: "Not Marked" },
    { key: 2, label: "All" },
  ];

  const evaluateAttendanceSummary = ({ list }) => {
    if (list.length === 0) {
      return {
        attendance_in_time: null,
        attendance_out_time: null,
        total_in_time: 0,
        face_image_in: null,
        face_image_out: null,
        attendance_in_latlong: null,
        attendance_out_latlong: null,
      };
    }

    let earliestInTime = null;
    let latestOutTime = null;
    let totalInTime = 0;
    let faceImageIn = null;
    let faceImageOut = null;
    let inLatLong = null;
    let outLatLong = null;

    list.forEach((entry) => {
      if (entry.attendance_in_time) {
        const inTime = moment(entry.attendance_in_time);
        if (!earliestInTime || inTime.isBefore(earliestInTime)) {
          earliestInTime = inTime;
          faceImageIn = entry.face_image_in;
          inLatLong = entry.attendance_in_latlong;
        }
      }

      if (entry.attendance_out_time) {
        const outTime = moment(entry.attendance_out_time);
        if (!latestOutTime || outTime.isAfter(latestOutTime)) {
          latestOutTime = outTime;
          faceImageOut = entry.face_image_out;
          outLatLong = entry.attendance_out_latlong;
        }
      }

      totalInTime += entry.total_in_time || 0;
    });

    return {
      attendance_in_time: earliestInTime ? earliestInTime.toISOString() : null,
      attendance_out_time: latestOutTime ? latestOutTime.toISOString() : null,
      total_in_time: totalInTime,
      face_image_in: faceImageIn,
      face_image_out: faceImageOut,
      attendance_in_latlong: inLatLong,
      attendance_out_latlong: outLatLong,
    };
  };

  const mapAttendanceSettingToUser = ({ data }) => {
    let tempList = {};
    data.forEach((entry) => {
      if (tempList.hasOwnProperty(entry["user"]["id"])) {
        tempList[entry["user"]["id"]].push(entry);
      } else {
        tempList[entry["user"]["id"]] = [entry];
      }
    });
    return tempList;
  };

  const getUsersList = async () => {
    try {
      let response = await getAdminUsersApi();
      let filteredList = response.data.filter(
        (obj) => obj["active"] === true && obj["role"] === "member"
      );
      const map = new Map();
      presetOrder.forEach((x, i) => map.set(x, i));
      filteredList.sort((x, y) => map.get(x.role) - map.get(y.role));
      return filteredList;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getUserAttendance = async ({ date, userList }) => {
    let payload = { attendance_date: date };
    let response = await getUsersAttendanceListApi({ payload: payload });
    consoleLogger("res:: ", response);

    let mappedUserAttendance = mapAttendanceSettingToUser({ data: response });
    let tempList = [];
    Object.keys(mappedUserAttendance).forEach((key) => {
      let tempEvaluatedData = evaluateAttendanceSummary({
        list: mappedUserAttendance[key],
      });
      let tempObj = {
        ...mappedUserAttendance[key][0],
        ...tempEvaluatedData,
      };
      tempList.push(tempObj);
    });
    let tempUserList = [];
    userList.forEach((entry) => {
      let index = tempList.findIndex(
        (obj) => obj["user"]["id"] === entry["id"]
      );

      let tempObj = { ...entry };

      if (index !== -1) {
        tempObj["attendance_data"] = tempList[index];
      } else {
        tempObj["attendance_data"] = null;
      }
      tempUserList.push(tempObj);
    });
    setUserWithAttendanceList(tempUserList);
    //init filter with temp list
    filter(tempUserList, rootStore.attendancePageState.attendanceChipIndex);
  };
  const getInitialDateToSet = () => {
    const scope = receivedProps?.scope;
    const date = receivedProps?.date;

    if (scope === "attendance_details_page" && date && date !== "") {
      return moment(date);
    }

    return moment().startOf("day");
  };

  const init = async () => {
    setLoading(true);
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns("Attendance")
    ) {
      let response = await getUsersList();
      setUserList(response);
      let initialDate = getInitialDateToSet();
      setDate(initialDate);
      await getUserAttendance({ date: initialDate, userList: [...response] });
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handleNext = async () => {
    setLoading(true);
    let tempDate = date.clone().add(1, "day");
    setDate(tempDate);
    await getUserAttendance({ date: tempDate, userList: [...userList] });
    setLoading(false);
  };

  const handlePrev = async () => {
    setLoading(true);
    let tempDate = date.clone().subtract(1, "day");
    setDate(tempDate);
    await getUserAttendance({ date: tempDate, userList: [...userList] });
    setLoading(false);
  };

  const handleSet = async (date) => {
    setLoading(true);
    setDate(date);
    await getUserAttendance({ date: date, userList: [...userList] });
    setLoading(false);
  };

  const filter = (row, index) => {
    let data = [...row];

    if (index === 0) {
      data = data.filter((element) => element["attendance_data"] != null);
    }
    if (index === 1) {
      data = data.filter((element) => element["attendance_data"] === null);
    }
    if (index === 2) {
      data = [...row];
    }
    setFilteredRows(data);
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Tooltip title={"View"}>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/admin/attendance-history/${params.data.id}`, {
                  state: {
                    date: date !== null ? date.toISOString() : "",
                    data: params.data,
                  },
                });
              }}
            >
              <Visibility
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns(
    "Attendance"
  ) ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Admin</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Attendance</Header>
              </TopWrapper>
            </Row>
            <Box sx={{ display: "flex" }}>
              <ChipsWrapper>
                {chipData.map((data) => {
                  return (
                    <Chip
                      key={data.key}
                      color={
                        rootStore.attendancePageState.attendanceChipIndex ===
                        data.key
                          ? "primary"
                          : "default"
                      }
                      label={data.label}
                      onClick={() => {
                        rootStore.attendancePageState.updateAttendanceChipIndex(
                          data.key
                        );
                        filter(userWithAttendanceList, data.key);
                      }}
                    />
                  );
                })}
              </ChipsWrapper>
              <Box>
                <Button
                  onClick={() => {
                    setOpenDownloadReport(true);
                  }}
                  variant="contained"
                  style={{
                    width: "170px",
                    backgroundColor: "#185DD2",
                    color: "white",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  <FileDownload />
                  {getLocalizedText("download_report")}
                </Button>
              </Box>
            </Box>
            <TableWrapper>
              <DateNavigationComponent
                date={date}
                handleNextButton={handleNext}
                handlePrevButton={handlePrev}
                handleDatePicker={() => {
                  setOpenDatePicker(true);
                }}
              />
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={filteredRows}
                  defaultColDef={defaultColDef}
                  columnDefs={AttendanceHistoryPageColumns.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/admin/attendance-history/${row.data.id}`, {
                      state: {
                        date: date !== null ? date.toISOString() : "",
                        data: row.data,
                      },
                    });
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingAnimationComponent size={"small"} />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openDatePicker && (
        <DatePickerDialog
          editableDate={date}
          handleSet={(dateValue) => {
            handleSet(dateValue);
            setOpenDatePicker(false);
          }}
          open={openDatePicker}
          setOpen={setOpenDatePicker}
        />
      )}
      {openDownloadReport && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openDownloadReport}
          onClose={() => {
            setOpenDownloadReport(false);
          }}
        >
          <AttendanceReportDownloadSidepanel
            open={openDownloadReport}
            setOpen={setOpenDownloadReport}
          />
        </Drawer>
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(AttendanceSettingsPage);

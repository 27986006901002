import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import ContactFilterPanel from "../../components/customer_page_components/filter/CustomerFilterPanel";
import rootStore from "../../stores/RootStore";
import { getIsoString } from "../../Functions";
import { pageSize } from "../../Config";
import { useNavigate } from "react-router-dom";
import ContactFilterTableComponent from "../../components/customer_page_components/filter/CustomerFilterTableComponent";
const drawerWidth = 300;

const CustomerFilterPage = () => {
  const columnsList = [...rootStore.customerStore.columnsList];
  const navigate = useNavigate();
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  const generateFilterPayload = () => {
    const storedAppliedFilters = [...rootStore.customerStore.appliedFilterList];
    let normalFields = {};
    storedAppliedFilters.forEach((item) => {
      let filterData = item["filter"];
      let value = null;
      Object.keys(filterData).map((key) => {
        if (
          filterData[key]["filter_type"] === "normal" ||
          filterData[key]["filter_type"] === ""
        ) {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            value = getIsoString(filterData[key]["value"]);
          } else {
            value = filterData[key]["value"];
          }
        } else if (filterData[key]["filter_type"] === "range") {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            let d1 = filterData[key]["value"][0].toISOString();
            let d2 = filterData[key]["value"][1].toISOString();
            value = {
              start: d1,
              end: d2,
            };
          } else if (filterData[key]["data_type"] === "integer") {
            let num1 = filterData[key]["value"]["start"];
            let num2 = filterData[key]["value"]["end"];
            value = {
              start: num1,
              end: num2,
            };
          }
        } else if (filterData[key]["filter_type"] === "list") {
          let tempList = [];
          filterData[key]["value"].forEach((obj) => {
            tempList.push(obj["value"]);
          });
          value = tempList;
        }
      });
      const fieldName = Object.keys(filterData)[0];

      let index = columnsList.findIndex(
        (column) => column["column_name"] === fieldName
      );
      if (index !== -1) {
        normalFields[fieldName] = {
          value: value,
          op: item["operator"]["value"],
        };
      }
    });
    return normalFields;
  };

  const getFilteredContacts = async () => {
    const filterPayload = generateFilterPayload();
    await rootStore.customerStore.fetchFilteredCustomersAndCountSeperate({
      payload: { query: filterPayload },
    });
  };

  const checkAndFetchFilteredContacts = async () => {
    setLoading(true);
    if (rootStore.customerStore.appliedFilterList.length > 0) {
      await getFilteredContacts();
    } else {
      rootStore.customerStore.resetFilterListingInfo();
    }
    setLoading(false);
  };
  const init = async () => {
    await checkAndFetchFilteredContacts();
  };
  useEffect(() => {
    init();
  }, []);

  const handleSearch = async () => {
    rootStore.customerStore.updateFilterListingInfo({
      key: "page_number",
      value: 0,
    });
    rootStore.customerStore.updateFilterListingInfo({
      key: "page_size",
      value: pageSize,
    });
    await checkAndFetchFilteredContacts();
  };

  const handlePageSize = async (e) => {
    rootStore.customerStore.updateFilterListingInfo({
      key: "page_number",
      value: 0,
    });
    rootStore.customerStore.updateFilterListingInfo({
      key: "page_size",
      value: e.target.value,
    });
    await checkAndFetchFilteredContacts();
  };
  const handlePagination = async (e, page) => {
    rootStore.customerStore.updateFilterListingInfo({
      key: "page_number",
      value: page - 1,
    });

    await checkAndFetchFilteredContacts();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />
        <Drawer
          sx={{
            width: isPanelOpen ? drawerWidth : 70,
            flexShrink: 0,
            zIndex: 10,
            "& .MuiDrawer-paper": {
              width: isPanelOpen ? drawerWidth : 70,
              boxSizing: "border-box",
              overflow: isPanelOpen ? "visible" : "hidden",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <ContactFilterPanel
            postSaveReportFilter={(filterList) => {
              rootStore.customerStore.updateAppliedFilterList({
                list: filterList,
              });
              handleSearch();
            }}
            setOpen={setIsPanelOpen}
            handleCancel={() => {
              rootStore.customerStore.resetAppliedFilterList();
              rootStore.customerStore.resetFilterListingInfo();
              navigate(-1);
            }}
            handleReset={() => {
              rootStore.customerStore.resetAppliedFilterList();
              rootStore.customerStore.resetFilterListingInfo();
            }}
            isOpen={isPanelOpen}
            setIsOpen={setIsPanelOpen}
          />
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "80%", height: "100%" }}
        >
          <ContactFilterTableComponent
            loading={loading}
            handlePageSize={handlePageSize}
            handlePagination={handlePagination}
          />
        </Box>
      </Box>
    </>
  );
};

export default observer(CustomerFilterPage);

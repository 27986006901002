import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer } from "@material-ui/core";
import {
  Add,
  AddBoxOutlined,
  ChevronRight,
  Delete,
  Edit,
  Settings,
} from "@material-ui/icons";
import { AttendanceSettingsPageColumns } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  removeUserAttendanceSettingApi,
  getAdminUsersApi,
  getAttendanceSettingListApi,
} from "../../Api";
import CreateAttendanceSettingComponent from "../../components/attendance_setting_page_components/CreateAttendanceSettingComponent";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import CustomConfirmationDialog from "../../components/alert_dialogue/CustomConfirmationDialog";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 185px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;
let presetOrder = ["owner", "admin", "member"];
const AttendanceSettingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [
    openCreateAttendanceSettingPanel,
    setOpenCreateAttendanceSettingPanel,
  ] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [scope, setScope] = useState("individual");

  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const getUsersList = async () => {
    try {
      let response = await getAdminUsersApi();
      let filteredList = response.data.filter(
        (obj) => obj["active"] === true && (obj["role"] === "member" || obj["role"] === "admin")
      );
      const map = new Map();
      presetOrder.forEach((x, i) => map.set(x, i));
      filteredList.sort((x, y) => map.get(x.role) - map.get(y.role));
      return filteredList;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const init = async () => {
    setLoading(true);
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns("Attendance")
    ) {
      let tempSettingsList = await getAttendanceSettingListApi();
      let tempUserList = await getUsersList();
      tempUserList.forEach((user) => {
        let index = tempSettingsList.findIndex(
          (obj) => obj["user"]["id"] === user["id"]
        );
        if (index !== -1) {
          user["attendance_settings"] = tempSettingsList[index];
        } else {
          user["attendance_settings"] = null;
        }
      });
      setUserList(tempUserList);
    }
    setLoading(false);
  };
  const refetch = async () => {
    setSelectedUsers([]);
    setLoading(true);
    let tempSettingsList = await getAttendanceSettingListApi();
    let tempUserList = [...userList];
    tempUserList.forEach((user) => {
      let index = tempSettingsList.findIndex(
        (obj) => obj["user"]["id"] === user["id"]
      );
      if (index !== -1) {
        user["attendance_settings"] = tempSettingsList[index];
      } else {
        user["attendance_settings"] = null;
      }
    });
    setUserList(tempUserList);
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data.attendance_settings === null) {
          return (
            <Tooltip title={"Add Configuration"}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setScope("individual");
                  setSelectedRowData(params.data);
                  setOpenCreateAttendanceSettingPanel(true);
                }}
              >
                <AddBoxOutlined
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={"Edit Configuration"}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setScope("individual");
                    setSelectedRowData(params.data);
                    setIsEdit(true);
                    setOpenCreateAttendanceSettingPanel(true);
                  }}
                >
                  <Edit
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Delete Configuration"}>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedRowData(params.data);
                    setShowConfirmationDialog(true);
                  }}
                >
                  <Delete
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      },
    },
  ];

  const handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedUsers(selectedRows);
  };

  const handleDeleteAttendanceSetting = async () => {
    setShowConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await removeUserAttendanceSettingApi({
      attendanceSettingID: selectedRowData["attendance_settings"]["id"],
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("Attendance configuration removed successfully!");
      setFetchingApi(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns(
    "Attendance"
  ) ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Admin</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Attendance Settings</Header>
              </TopWrapper>
              <Button
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                color="primary"
                disabled={selectedUsers.length === 0}
                onClick={() => {
                  setScope("global");
                  setOpenCreateAttendanceSettingPanel(true);
                }}
              >
                <Add /> Attendance Setting
              </Button>
            </Row>
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={userList}
                  defaultColDef={defaultColDef}
                  columnDefs={AttendanceSettingsPageColumns.concat(
                    actionColumn
                  )}
                  animateRows={true}
                  rowSelection="multiple"
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                  }}
                  onSelectionChanged={handleSelectionChange}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingAnimationComponent size={"small"} />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateAttendanceSettingPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateAttendanceSettingPanel}
          onClose={() => {
            setIsEdit(false);
            setOpenCreateAttendanceSettingPanel(false);
          }}
        >
          <CreateAttendanceSettingComponent
            setOpen={setOpenCreateAttendanceSettingPanel}
            setRefresh={setRefresh}
            editableData={selectedRowData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            scope={scope}
            selectedUsers={selectedUsers}
          />
        </Drawer>
      )}
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          message={
            "Are you sure you want to remove this user's attendance configuration?"
          }
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          handleConfirm={() => {
            handleDeleteAttendanceSetting();
          }}
        />
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(AttendanceSettingsPage);

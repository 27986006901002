import { AppBar, Box, Divider, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Chip,
  Link,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { dataRenderer, getLocalizedText } from "../../../Functions";
import { Close, Settings } from "@material-ui/icons";
import WhatsappProviderDialog from "./whatsapp_mapping_screens/WhatsappProviderDialog";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const ViewWhatsappConfigDialog = ({
  open,
  setOpen,
  formData,
  setRefresh,
  setRefreshType,
}) => {
  const [selectedProviderData, setSelectedProviderData] = useState({});
  const [openProviderConfigDialog, setOpenProviderConfigDialog] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const init = async () => {
    formData.forEach((entry) => {
      let index = formData.findIndex(
        (provider) => provider["provider_name"] === entry["provider_name"]
      );
      if (index !== -1) {
        entry["status"] = "Connected";
      }
    });

    setData(formData);
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columns = [
    {
      field: "sl_no",
      headerName: "S.NO",
      width: 70,

      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Tooltip title={value} placement="bottom-start">
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {value}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "provider",
      headerName: "PROVIDER",
      flex: 1,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.provider_name);
        return (
          <Tooltip title={value} placement="bottom-start">
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {value}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      width: 95,

      cellRenderer: (params) => {
        return (
          <IconButton
            disabled={params.data.provider_name !== "Meta WABA"} // enabling only Meta WABA
            style={{ padding: "0px" }}
            color="primary"
            onClick={() => {
              setSelectedProviderData(params.data);
              setIsEdit(true);
              setOpenProviderConfigDialog(true);
            }}
          >
            <Settings />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const renderLinkPagesComponent = () => {
    return (
      <Link
        onClick={() => {
          setSelectedProviderData({});
          setOpenProviderConfigDialog(true);
        }}
      >
        {" "}
        <Typography
          style={{
            width: "fit-content",
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {getLocalizedText("create_config")}
        </Typography>
      </Link>
    );
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("whatsapp_configuration")}</Header>
                  <Box sx={{ display: "flex", gap: "8px" }}>
                    {data.length > 0 && renderLinkPagesComponent()}
                    <IconButton
                      style={{ padding: "0px" }}
                      color="primary"
                      onClick={handleClose}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              {!loading ? (
                <Box
                  style={{
                    height: "300px",
                    overflowY: "auto",
                  }}
                >
                  <div className="ag-theme-alpine" style={{ width: "100%" }}>
                    <AgGridReact
                      domLayout="autoHeight"
                      gridOptions={gridOptions}
                      rowData={formData}
                      defaultColDef={defaultColDef}
                      columnDefs={columns}
                      animateRows={true}
                      suppressCellFocus
                      overlayNoRowsTemplate={"No Pages Found"}
                    />
                  </div>
                </Box>
              ) : (
                <Box
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>

      {openProviderConfigDialog && (
        <WhatsappProviderDialog
          open={openProviderConfigDialog}
          setOpen={setOpenProviderConfigDialog}
          currentProviderData={selectedProviderData}
          formData={formData}
          setRefresh={setRefresh}
          setRefreshType={setRefreshType}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
    </>
  );
};

export default ViewWhatsappConfigDialog;

import { Box, Button, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Close } from "@material-ui/icons";
import { Transfer } from "antd";
import "antd/dist/antd.css";
import "../../styles/multiselect.css";
import { Alert } from "@mui/material";
import { MenuOutlined } from "@ant-design/icons";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { consoleLogger, getLocalizedText } from "../../Functions";
import rootStore from "../../stores/RootStore";

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "10px",
  padding: "30px 50px",
  [theme.breakpoints.down("sm")]: {
    height: "80vh",
    overflowY: "scroll",
  },
}));

const ModelWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  position: "unset",
});

const ColumnsWrapper = styled(Box)({
  // display: 'flex', // Uncomment if needed
  marginBottom: "20px",
});

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "& > .label": {
    display: "inline-block",
    maxWidth: "calc(100% - 20px)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "&.drop-over-downward": {
    borderBottom: "2px dashed #1890ff",
  },
  "&.drop-over-upward": {
    borderTop: "2px dashed #1890ff",
  },
}));

const ModalHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 700 24px Open Sans",
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    font: "normal normal 700 18px Open Sans",
  },
}));

const CustomerColumnSelectionModal = (props) => {
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const columnsList = [...rootStore.customerStore.columnsList];
  const handleClick = () => {
    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const setColumnsForTab = async (data) => {
    consoleLogger("data:: ", data);
    //storing selected locally
    rootStore.customerStore.updateCustomersSelectedColumns(data);
    props.setOpen(false);
    props.setRefresh(true);
  };
  useEffect(() => {
    let newData = [];
    if (columnsList.length > 0) {
      columnsList.forEach((e) => {
        let newObj = {
          key: e.column_name,
          title: e.display_name,
        };
        newData.push(newObj);
      });
      setData(newData);

      const getColumnsList = async () => {
        let targetKeys = [];
        let selectedColumns =
          rootStore.customerStore.getCustomerSelectedColumns();
        selectedColumns.forEach((e) => {
          newData.forEach((item) => {
            if (item["key"] === e) {
              targetKeys.push(e); // e.column_name - old
            }
          });
        });
        setTargetKeys(targetKeys);
      };
      getColumnsList();
    }
  }, []);
  const handleKeys = (keys) => {
    setShowAlert(false);
    setTargetKeys(Array.from(new Set(keys)));
  };
  const handleSubmit = () => {
    if (targetKeys.length > 0) {
      setShowAlert(false);
      let columnListToSet = [];

      targetKeys.forEach((item) => {
        columnsList.forEach((key) => {
          if (key["column_name"] === item) {
            if (!columnListToSet.includes(item)) {
              columnListToSet.push(key["column_name"]);
            }
          }
        });
      });
      setColumnsForTab(columnListToSet);
    } else {
      setShowAlert(true);
    }
  };

  //order select column related functions

  // change order
  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    handleKeys(clonedList);
  };

  //end//

  return (
    <>
      {
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <Close
                onClick={handleClick}
                style={{
                  height: "18px",
                  width: "18px",
                  position: "absolute",
                  top: "20px",
                  right: "30px",
                  cursor: "pointer",
                }}
              />
              <ModelWrapper>
                <ModalHeader>
                  {getLocalizedText("select_columns_to_display")}
                </ModalHeader>
                <ColumnsWrapper>
                  {data.length > 0 && (
                    <DndProvider backend={HTML5Backend}>
                      <Transfer
                        dataSource={data}
                        listStyle={{
                          width: "fit-content",
                          height: 300,
                        }}
                        titles={[
                          getLocalizedText("columns_available"),
                          getLocalizedText("columns_in_use"),
                        ]}
                        targetKeys={targetKeys}
                        onChange={handleKeys}
                        render={(it) => (
                          <DraggableItem
                            index={targetKeys.findIndex(
                              (key) => key === it.key
                            )}
                            label={it.title}
                            moveRow={moveRow}
                          />
                        )}
                      />
                    </DndProvider>
                  )}
                </ColumnsWrapper>
                {showAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText("please_have_atleast_one_column_in_use")}
                  </Alert>
                )}
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  variant="contained"
                  style={{
                    marginTop: "20px",
                    backgroundColor: "blue",
                    color: "white",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  {getLocalizedText("apply")}
                </Button>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

const type = "DraggableItem";

const DraggableItem = ({ index, label, moveRow }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <ItemWrapper
      key={label}
      ref={ref}
      className={`${isOver ? dropClassName : ""}`}
    >
      <span className="label">{label}</span>
      {index !== -1 && (
        <span ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </ItemWrapper>
  );
};

export default CustomerColumnSelectionModal;
